import { PATHS } from "route-dictionary";
import PhoneLink from "components/PhoneLink";
import { useTranslation } from "react-i18next";
import { MemberLinkProps } from "../../../_types";
import { ARLink } from "components/ARComponents/index";
import { formatPhoneNumber } from "utils/phoneHandlers";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";

const MemberLink = ({ paused_by }: MemberLinkProps) => {
  const { t } = useTranslation();

  if (!paused_by) {
    return null;
  }

  return (
    <span className="MemberLink" id="MemberLink">
      , {t("table:status.pausedBy")}
      <div className="MemberLink__links-block">
        {hasGlobalPermission(ACTIONS.VIEW_MEMBER_DETAILS) ? (
          <ARLink
            href={PATHS.MEMBERS.getDetailsIdPath?.(paused_by.id)}
            id="member_link"
            className="MemberLink__link_color"
          >
            {paused_by.first_name || paused_by.email} {paused_by.last_name}
          </ARLink>
        ) : (
          <>
            {paused_by.first_name || paused_by.email} {paused_by.last_name}
          </>
        )}{" "}
        {paused_by.phone && (
          <PhoneLink className="MemberLink__link_color">{formatPhoneNumber(paused_by.phone)}</PhoneLink>
        )}
      </div>
    </span>
  );
};

export default MemberLink;
