import {useActions} from "store/actions";
import i18next from "services/language/i18next";
import {updateTelegramInfoRequest} from "services/api";
import {setTelegramInfoAction} from "store/actions/user";
import {showModalWindow, showNotification} from "utils/actionWrappers";
import {IUser} from "../../../components/ReactHookForm/_types";
// import {TelegramUser} from "@v9v/ts-react-telegram-login";
import {TelegramUser} from "telegram-login-button";

type TelegramInfo = Pick<IUser, "telegram_id" | "telegram_name">;

const useTelegramWidget = () => {
  const [updateTelegramInfo] = useActions(setTelegramInfoAction);

  const saveTelegramInfo = (telegramInfo: TelegramInfo) => {
    updateTelegramInfoRequest(telegramInfo).then(({data}) => {
      showNotification(
        i18next.t("notification:title.success", "Success!"),
        i18next.t("notification:message.updated.profile", "Profile has been updated."),
        "success"
      );
      updateTelegramInfo(data);
    });
  };

  return (telegramUserData: TelegramUser) => {
    const newTelegramInfo: TelegramInfo = {
      telegram_id: String(telegramUserData.id) || null,
      telegram_name: telegramUserData.username || null,
    };

    if (!telegramUserData.id) {
      showModalWindow.confirm({
        message: i18next.t(
          "modal:message.telegramLogout",
          "Are you sure you want to log out of your Telegram account?"
        ),
        color: "danger",
        onConfirm: () => saveTelegramInfo({
          telegram_id: null,
          telegram_name: null,
        }),
      });
      return;
    }

    saveTelegramInfo(newTelegramInfo);
  };
};

export default useTelegramWidget;
