import "react-phone-input-2";
import { RHFLabel } from "./wrappers";
import { RHFPhoneInput } from "./custom";
import "./custom/RHFPhoneInput/RHFPhoneInput.scss";
import { IRHFLabelProps } from "./wrappers/RHFLabel";
import { IRHFPhoneInputProps } from "./custom/RHFPhoneInput";

interface IRHFPhoneInputWithLabelProps extends IRHFPhoneInputProps, IRHFLabelProps {}

const RHFPhoneInputWithLabel = ({
  id,
  labelText,
  prependText,
  appendText,
  labelColClassName,
  groupClassName,
  ...phoneProps
}: IRHFPhoneInputWithLabelProps) => {
  return (
    <RHFLabel
      id={id}
      labelText={labelText}
      prependText={prependText}
      appendText={appendText}
      groupClassName={groupClassName}
      labelColClassName={labelColClassName}
    >
      <RHFPhoneInput id={id} {...phoneProps} />
    </RHFLabel>
  );
};

export default RHFPhoneInputWithLabel;
