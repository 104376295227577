import { useState, useEffect, useRef } from "react";
import { Timeout } from "models/types";

type SpinnerProps = {
  fullScreen: boolean;
  show: boolean;
  timeout: number;
  requestsNum: number;
};

const Spinner = (props: SpinnerProps) => {
  const { fullScreen, show, timeout = 100, requestsNum } = props;
  const [showSpinner, setShowSpinner] = useState<boolean>(show);
  const hideTimeoutRef = useRef<Timeout | null>(null);

  useEffect(() => {
    if (show) {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
      setShowSpinner(true);
    } else if (requestsNum === undefined || requestsNum <= 0) {
      hideTimeoutRef.current = setTimeout(() => setShowSpinner(false), Number(timeout) || 100);
    }
  }, [requestsNum, show, timeout]);

  if (!showSpinner) {
    return null;
  }

  return (
    <div className={fullScreen ? "Spinner-container Spinner-container_fullscreen" : "Spinner-container"}>
      <div className="Spinner" />
    </div>
  );
};

export default Spinner;
