import { useTableConfig } from "utils/hooks";
import i18next from "services/language/i18next";
import { MANAGE_CHECK_ACTIONS } from "utils/constants";
import { showNotification } from "utils/actionWrappers";
import { numberToTimeDuration } from "utils/timeHandlers";
import { getIncidentRequest, getIncidentsRequest, manageCheckRequest } from "services/api";
import { ICheckData } from "./../../checks/hooks/useChecksData";
import { checkId, incidentId } from "pages/checks/_types";

const useIncidentsData = () => {
  const tableConfigObj = useTableConfig(getIncidentsRequest);
  const { setProjectId, setTableItems } = tableConfigObj;

  const onSelectedProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => setProjectId(event.target.value!);

  const updateIncident = (id: number) => {
    return getIncidentRequest(id).then(({ data: incidentData }) =>
      setTableItems((oldIncidents) => oldIncidents.map((incident) => ((incident as ICheckData).id === id ? incidentData : incident)))
    );
  };

  const onReturnButtonClick = (checkId: checkId, incidentId?: incidentId) => {
    manageCheckRequest(checkId, MANAGE_CHECK_ACTIONS.RESUME)!
      .finally(() => updateIncident(incidentId!))
      .then(() =>
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t("notification:message.returned.check", "Check has been returned."),
          "success"
        )
      );
  };

  const onTakeToWorkButtonClick = (checkId: checkId, time: number, incidentId?: incidentId) => {
    manageCheckRequest(checkId, MANAGE_CHECK_ACTIONS.PAUSE, { duration: numberToTimeDuration(time) })!
      .finally(() => updateIncident(incidentId!))
      .then(() =>
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t("notification:message.paused.check", "Check has been taken to work."),
          "success"
        )
      );
  };

  return {
    tableConfigObj,
    onReturnButtonClick,
    onTakeToWorkButtonClick,
    onSelectedProjectChange,
  };
};

export default useIncidentsData;
