import {instance, requestPromiseWrapper, requestWithSpinnerNoAlertErrorsPromiseWrapper} from "./instance";
import {getFormDataObject} from "../../utils/imagesHandlers";

export function getProfileInfoRequest() {
  return requestPromiseWrapper(instance.get("/current_user"));
}

export function saveProfileInfoRequest(profileInfo) {
  const formData = getFormDataObject(profileInfo);

  return requestWithSpinnerNoAlertErrorsPromiseWrapper(instance.patch("/current_user", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }));
}

export function updateTelegramInfoRequest(telegramInfo) {
  return requestPromiseWrapper(instance.patch("/current_user", telegramInfo));
}