import * as yup from "yup";
import { REG_EXS } from "utils/constants";
import { Message } from "../schemes/messages";

function ldapHost(this: yup.StringSchema, message: Message) {
  return this.test("ldapHost", message, (value) => {
    if (!value) {
      return true;
    }
    return REG_EXS.VALID_HOST.test(value);
  });
}

export default ldapHost;
