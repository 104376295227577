import React from "react";
import {ACTIONS} from "utils/constants";
import {useTranslation} from "react-i18next";
import SubscriptionInfo from "./SubscriptionInfo";
import CurrentPlanBadge from "../CurrentPlanBadge";
import PaidPlanDescription from "./PaidPlanDescription";
import {withErrorBoundary} from "components/ErrorBoundary";
import {hasSubscriptionPermission} from "utils/user-access";
import {ARCard, ARCardBody, ARCardHeader} from "components/ARComponents";

const PaidPlan = (props) => {
  const {className} = props;
  const {t} = useTranslation();

  return (
    <ARCard className={`PremiumPlan-card ${className}`}>
      <ARCardHeader className="PremiumPlan-card__header">
        <h4 className="card-header-text">
          {t("card.title.paidPlan", "Standard")}
        </h4>
        {
          hasSubscriptionPermission(ACTIONS.VIEW_CURRENT_BADGE_FOR_PAID_PLAN) &&
          <CurrentPlanBadge/>
        }
      </ARCardHeader>
      <ARCardBody className="PremiumPlan-card__body">
        {
          hasSubscriptionPermission(ACTIONS.VIEW_SUBSCRIPTION_INFO) ?
            <SubscriptionInfo/> :
            <PaidPlanDescription/>
        }
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(PaidPlan);