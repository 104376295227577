import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const ns = ["translation", "notification", "form", "modal", "table", "plans"];

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    supportedLngs: ["en", "ru"],
    fallbackLng: "en",
    nonExplicitSupportedLngs: false,
    cleanCode: true,
    ns: ns,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      format: function (value, format) {
        if (format === "uppercase") return value.toUpperCase();
        if (format === "lowercase") return value.toLowerCase();
      }
    }
  });

export default i18next;