import { useActions } from "store/actions";
import { timeZonesNames } from "@vvo/tzdb";
import { yupResolver } from "@hookform/resolvers/yup";
import { settingsDataFields } from "../Settings.model";
import { useCallback, useEffect, useState } from "react";
import { ERR_MESSAGES, TIME_ZONES } from "utils/constants";
import { getFormDataObj } from "components/ReactHookForm/utils";
import { setTelegramTokenStatusAction } from "store/actions/global";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { settingsFormSchema } from "components/ReactHookForm/schemes";
import { cancelSource, getCompanySettingsRequest, updateCompanySettingsRequest } from "services/api";
import { useFormWithCustomError } from "components/ReactHookForm/hooks";
import { ICompanySettings } from "services/api/types";

interface ICompanyName {
  companyName?: string | null;
}

const useSettingsFormConfig = () => {
  const [setTelegramTokenStatus] = useActions(setTelegramTokenStatusAction);
  const [settingsInfo, setSettingsInfo] = useState<ICompanyName>({ companyName: null });

  const formControl = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(settingsFormSchema),
    defaultValues: getFormDataObj({ arrOfFields: settingsDataFields, serverData: {} }),
  });
  const { reset, setError } = formControl;

  const updateSettingsFormData = useCallback(
    async (data: Partial<ICompanySettings>, stateOptions = {}) => {
      const formData: Partial<ICompanySettings> = getFormDataObj({ arrOfFields: settingsDataFields, serverData: data });
      formData.timezone = timeZonesNames.find((timezone) => timezone === formData.timezone) || TIME_ZONES.DEFAULT;

      await reset(formData, stateOptions);
      setSettingsInfo({ companyName: formData.name });
      setTelegramTokenStatus(Boolean(data.telegram_token_is_set));
    },
    [setSettingsInfo, setTelegramTokenStatus, reset]
  );

  useEffect(() => {
    getCompanySettingsRequest().then(({ data }) => updateSettingsFormData(data));

    return () => {
      cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
    };
  }, [reset, updateSettingsFormData]);

  const onSaveButtonClick = (formData: Partial<ICompanySettings>) => {
    return updateCompanySettingsRequest(formData)
      .then(({ data }) => updateSettingsFormData(data, { keepIsSubmitted: false }))
      .catch((error) => {
        if (error.response.data.telegram_token) {
          setError("telegram_token", { type: "FieldError", message: MESSAGES.NO_TG_BOT }, { shouldFocus: true });
          window.scrollTo(0, 0);
          throw new Error();
        } else {
          throw new Error(Object.values(error.response.data).join(" \n"));
        }
      });
  };

  const resetTelegramToken = () => {
    return updateCompanySettingsRequest({
      telegram_token: "",
      is_telegram_auth_enabled: false,
    }).then(({ data }) => updateSettingsFormData(data));
  };

  return {
    formControl,
    onSaveButtonClick,
    resetTelegramToken,
    settingsInfo,
  };
};

export default useSettingsFormConfig;
