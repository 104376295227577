import { useParams } from "react-router-dom";
import { ERR_MESSAGES } from "utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { sortArraysAndReturnNotEqualItems } from "utils/arrays";
import { getFormDataObj } from "components/ReactHookForm/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { groupFormSchema } from "components/ReactHookForm/schemes";
import { IMember } from "models/types";
import { useFormWithCustomError } from "components/ReactHookForm/hooks";
import {
  CancelToken,
  deleteGroupRequest,
  getGroupInfoRequest,
  getMembersRequest,
  groupCancelSource,
  updateGroupRequest,
} from "services/api";

export interface IGroupMembers {
  members: IMember[];
  membersIds: number[];
}

const useGroupInfo = () => {
  const { id } = useParams<{ id: string }>();
  const [header, setHeader] = useState("");
  const [groupMembers, setGroupMembers] = useState<IGroupMembers | {}>({});
  const [availableMembers, setAvailableMembers] = useState<IMember[]>([]);
  const serverMembersRef = useRef([]);

  const formControl = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(groupFormSchema),
    defaultValues: { name: "" },
  });

  const { reset } = formControl;

  const deleteGroup = () => {
    return deleteGroupRequest(id);
  };
  const updateGroupInfo = (groupInfo: { name?: string; members_ids: number[] }) => {
    return updateGroupRequest(id, groupInfo).then(({ data }) =>
      setGroupMembers({
        members: data.members_representation,
        membersIds: data.members_ids,
      })
    );
  };

  const getGroupInfo = useCallback(() => {
    return Promise.all([getGroupInfoRequest(id), getMembersRequest()]).then(([groupInfoResponse, membersResponse]) => {
      setHeader(groupInfoResponse.data.name);
      serverMembersRef.current = membersResponse.data;
      setGroupMembers({
        members: groupInfoResponse.data.members_representation,
        membersIds: groupInfoResponse.data.members_ids,
      });

      const formData = getFormDataObj({ arrOfFields: ["name"], serverData: groupInfoResponse.data });
      reset(formData);
    });
  }, [id, reset]);

  useEffect(() => {
    if (Object.keys(groupMembers!).length) {
      setAvailableMembers(
        sortArraysAndReturnNotEqualItems(
          serverMembersRef.current.filter((member: IMember) => member.is_superuser !== true),
          (groupMembers as IGroupMembers).members
        )
      );
    }
  }, [groupMembers]);

  useEffect(() => {
    if (id) {
      groupCancelSource.value = CancelToken.source();
      getGroupInfo();
    }

    return () => {
      groupCancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
      groupCancelSource.value = null;
    };
  }, [id, getGroupInfo]);

  return {
    header,
    deleteGroup,
    formControl,
    groupMembers,
    updateGroupInfo,
    availableMembers,
  };
};

export default useGroupInfo;
