import React, {useContext} from "react";
import Switcher from "../../Switcher";
import CollapseCard from "./CollapseCard";
import {ARCallout} from "components/ARComponents";
import {SUBSCRIPTION_PERIOD} from "utils/constants";
import {PlansContext} from "../../subscribe-context";
import {Trans, useTranslation} from "react-i18next";
import {getPriceString} from "../../Subscribe.model";
import {useAutoPayment, useCardInfo} from "../../hooks";
import {withErrorBoundary} from "components/ErrorBoundary";
import {getPaymentPeriod, getPrice} from "../PaidPlan.model";
import PaymentCardChangeContent from "./PaymentCardChangeContent";
import SubscriptionChangeContent from "./SubscriptionChangeContent";

const SubscriptionInfo = () => {
  const {companyBilling, monthlyPrice, yearlyPrice} = useContext(PlansContext);
  const {cardInfo} = useCardInfo();
  const {t} = useTranslation();

  const checkLimit = cardInfo.checkLimit;
  const subscriptionPeriod = companyBilling.price?.period;
  const isAutoPaymentOn = !companyBilling.subscription_cancel_at_period_end;
  const isAnnualSubscription = subscriptionPeriod === SUBSCRIPTION_PERIOD.YEAR;

  const translatedSubscriptionPeriod = getPaymentPeriod(isAnnualSubscription);
  const subscriptionPrice = getPrice({
    itemsAmount: checkLimit,
    hasDiscount: isAnnualSubscription,
    usualPrice: monthlyPrice,
    discountPrice: yearlyPrice
  });
  const nextStripePayment = subscriptionPrice - cardInfo.invoiceAmount;

  const nextCardPaymentString = getPriceString(cardInfo.invoiceAmount);
  const nextStripePaymentString = getPriceString(nextStripePayment);
  const stripeBalanceString = getPriceString(cardInfo.stripeBalance);
  const subscriptionPriceString = getPriceString(subscriptionPrice);

  const {hasAutoPayment, autoPaymentHandler} = useAutoPayment(isAutoPaymentOn);

  return (
    <>
      <h3>
        <Trans i18nKey="plans:subscriptionInfo.price">
          Current subscription price:<br/>
          {{price: subscriptionPriceString}}/{{period: translatedSubscriptionPeriod}}
        </Trans>
      </h3>
      <p>
        <Trans i18nKey="plans:subscriptionInfo.checksAmount">
          Amount of checks in current subscription: <strong>{{checks: `${checkLimit}`}} for
          a {{period: translatedSubscriptionPeriod}}</strong><br/>
        </Trans>
        <Trans i18nKey="plans:subscriptionInfo.stripeBalance">
          Your current stripe balance is: <strong>{{balance: stripeBalanceString}}</strong>
        </Trans>
      </p>
      <p className="SubscriptionInfo__payment-info">
        <Trans
          i18nKey="plans:subscriptionInfo.paymentCard">
          Payment card: <strong>**** **** **** {{last4: `${cardInfo.last4}`}}</strong> exp.
          period: {{month: `${cardInfo.expMonth}`}}/{{year: `${cardInfo.expYear}`}}<br/>
        </Trans>
        {
          isAutoPaymentOn &&
          <>
            <Trans i18nKey="plans:subscriptionInfo.paymentDate" values={{date: `${cardInfo.invoiceDate}`}}>
              Next payment date: {`${cardInfo.invoiceDate}`}<br/>
            </Trans>
            <Trans i18nKey="plans:subscriptionInfo.cardPayment">
              Next payment amount from the card: <strong>{{payment: nextCardPaymentString}}</strong><br/>
            </Trans>
            <Trans i18nKey="plans:subscriptionInfo.stripePayment">
              Next payment amount from the stripe balance: <strong>{{payment: nextStripePaymentString}}</strong><br/>
            </Trans>
          </>
        }
      </p>
      {
        !hasAutoPayment &&
        <ARCallout color="danger" className="bg-white">
          <strong>
            <Trans i18nKey="plans:autoPayment.offText" values={{date: `${cardInfo.subscriptionEnd}`}}>
              You have disabled auto payment, your subscription will expire: {`${cardInfo.subscriptionEnd}`}
            </Trans>
          </strong>
        </ARCallout>
      }
      <Switcher
        className="SubscriptionInfo__switcher-group"
        switcherId="auto_payment"
        switcherText={hasAutoPayment ? t("plans:autoPayment.on", "Auto payment is enabled") : t("plans:autoPayment.off", "Enable auto payment")}
        switcherHandler={autoPaymentHandler}
        checked={hasAutoPayment}
      />
      <CollapseCard
        className={"SubscriptionInfo__collapse-card"}
        headerText={t("plans:change.subscription", "Subscription changing")}
        collapseBodyContent={<SubscriptionChangeContent/>}
      />
      <CollapseCard
        headerText={t("plans:change.card", "Payment card changing")}
        collapseBodyContent={<PaymentCardChangeContent/>}
      />
    </>
  );
};

export default withErrorBoundary(SubscriptionInfo);