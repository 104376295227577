import { useMemo } from "react";
import { PATHS } from "route-dictionary";
import { useSelector } from "react-redux";
import { LOCATIONS } from "utils/constants";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SubscribeButton from "./SubscribeButton/SubscribeButton";
import { withErrorBoundary } from "components/ErrorBoundary";
import HeaderProfileDropdown from "./HeaderProfileDropdown/HeaderProfileDropdown";
import HeaderLanguageDropdown from "./HeaderLanguageDropdown/HeaderLanguageDropdown";
import { checkNameSelector, globalSelector } from "store/selectors/global";
import { ACTIONS, hasAccess, hasGlobalPermission } from "utils/user-access";
import { ARBreadcrumbRouter, ARHeader, ARHeaderNav, ARSubheader, ARToggler } from "components/ARComponents";
import { IRoute } from "components/PageWrappers/_types";

interface IHeaderProps {
  routes: IRoute[];
  showSidebar: boolean | "responsive";
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean | "responsive">>;
}

const Header = (props: IHeaderProps) => {
  const { pathname } = useLocation();
  const isSubscribePage = pathname === PATHS.SUBSCRIBE.ROOT;
  const checkName = useSelector(checkNameSelector);
  const { isShowingSpinner } = useSelector(globalSelector);
  //TODO: don't forget to remove it when it'll become unnecessary
  const isProductionMode = window.location.hostname.endsWith(LOCATIONS.PROD);
  const isSubscribeButtonVisible: boolean =
    !isSubscribePage && !isProductionMode && hasAccess(ACTIONS.VIEW_SUBSCRIBE_BUTTON);

  const toggleSidebar = () => {
    const value = [true, "responsive"].includes(props.showSidebar) ? false : "responsive";
    props.setShowSidebar(value);
  };

  const toggleSidebarMobile = () => {
    const value = [false, "responsive"].includes(props.showSidebar) ? true : "responsive";
    props.setShowSidebar(value);
  };

  const { t, i18n } = useTranslation();

  const routes = useMemo(
    () => {
      return props.routes.map((route) => {
        const newRoute = { ...route };
        newRoute.name = t(route.name);

        if (route.path === PATHS.CHECKS.DETAILS) {
          newRoute.name = `${checkName}`;
        }
        return newRoute;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.routes, i18n.language, checkName]
  );

  return (
    <ARHeader withSubheader className="header">
      <ARToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />
      <ARToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      {isSubscribeButtonVisible && (
        <ARHeaderNav className="header-nav__subscribe-button">
          <SubscribeButton />
        </ARHeaderNav>
      )}

      <ARHeaderNav className={isSubscribeButtonVisible ? "header-nav" : "header-nav header-nav__production_mode"}>
        <HeaderLanguageDropdown />
        <HeaderProfileDropdown />
      </ARHeaderNav>

      <ARSubheader className="px-3 justify-content-between subheader">
        {hasGlobalPermission(ACTIONS.VIEW_BREADCRUMBS) && !isShowingSpinner && (
          <ARBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={routes} />
        )}
      </ARSubheader>
    </ARHeader>
  );
};

export default withErrorBoundary(Header);
