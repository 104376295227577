/**
 * Extracts only changed values from form data
 * @param dirtyFields - Object of changed fields
 * @param allValues - Whole form data
 * @returns {{[p: string]: *}|*} - Object of changed values
 */
export function getDirtyValues({ dirtyFields, allValues }) {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is true for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyValues({ dirtyFields: dirtyFields[key], allValues: allValues[key] }),
    ])
  );
}

/**
 * Converts data from the server into form data object
 * @param {arrOfFields: Array<string>, serverData: Object} - Fields that should be in form data object and server data object which contains same fields that are in arrOfFields
 * @returns {{[p: string]: any}} - Form data object
 */
export function getFormDataObj({ arrOfFields, serverData = {} }) {
  if (!Array.isArray(arrOfFields) || !arrOfFields.length) return serverData;

  return Object.fromEntries(arrOfFields.map((field) => [field, serverData[field] ?? ""]));
}

/**
 * Generates new event object with changed "value" property
 * @param event - Original event object from a function
 * @param newValue - New value, that should appear in the new event object
 * @returns {*&{target: (*&{value})}} - New event object with changed "value" property
 */
export function generateEvent({ event, newValue }) {
  return { ...event, target: { ...event.target, value: newValue } };
}
