import RuleForm from "../RuleForm";
import { useRuleForm } from "../hooks";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import { useProjectAdminStatus } from "pages/checks/hooks";
import AuthorizedError404 from "pages/error/AuthorizedError404";
import FormControlsButtons from "components/FormControlButtons";
import { ARCard, ARCardBody, ARCardFooter, ARCardHeader } from "components/ARComponents";

const RuleAdd = () => {
  const { t } = useTranslation();
  const { onFormSubmit, onFormReset, Prompt, formControl, formId, checkId, projectId, onTypeChange } = useRuleForm();
  const isProjectAdmin = useProjectAdminStatus(parseInt(checkId!), parseInt(projectId!));

  if ((checkId || projectId) && !isProjectAdmin) {
    return <AuthorizedError404 />;
  }

  return (
    <ARCard className="RuleAdd-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.rule.add", "Add rule")}</h1>
      </ARCardHeader>
      <ARCardBody>
        <Prompt />
        <RuleForm
          formId={formId}
          formControl={formControl}
          onFormSubmit={onFormSubmit}
          onTypeSelectChange={onTypeChange}
        />
      </ARCardBody>
      <ARCardFooter>
        <FormControlsButtons form={formId} onCancelClick={onFormReset} />
      </ARCardFooter>
    </ARCard>
  );
};

export default withErrorBoundary(RuleAdd);
