import { Link } from "react-router-dom";
import { useDirectories } from "./hooks";
import { PATHS } from "route-dictionary";
import { useTranslation } from "react-i18next";
import DirectoryCard from "./DirectoryCard/DirectoryCard";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ARButton, ARCard, ARCardBody, ARCardHeader, ARListGroup } from "components/ARComponents";

const Directories = () => {
  const { t } = useTranslation();
  const { intervalRef, directoriesData, updateDirectories, getDirectories } = useDirectories();

  return (
    <ARCard className="Directories">
      <ARCardHeader>
        <h1 className="card-header-text">{t("page.directories", "Directories")}</h1>
      </ARCardHeader>
      <ARCardBody>
        <ARCard>
          <ARCardHeader className="Directories__directories-header">
            <h3 className="card-header-text">{t("card.title.directory.your", "Your user directories")}</h3>
            <ARButton
              tag={Link}
              to={PATHS.DIRECTORIES.ADD}
              variant="outline"
              color="dark"
              className="Directories__add-button"
            >
              {t("button.addDirectory", "Add Directory")}
            </ARButton>
          </ARCardHeader>
          <ARCardBody>
            <ARListGroup accent className="Directories__list-group">
              {!directoriesData.length ? (
                <p>{t("noData.directories", "No user directories are available")}</p>
              ) : (
                directoriesData.map((item) => (
                  <DirectoryCard
                    intervalRef={intervalRef}
                    key={`${item.name}__${item.id}`}
                    onDeleteDirectorySuccess={getDirectories}
                    updateDirectories={updateDirectories}
                    directoryData={item}
                  />
                ))
              )}
            </ARListGroup>
          </ARCardBody>
        </ARCard>
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(Directories);
