import { useSettingsForm } from "./hooks";
import { useSelector } from "react-redux";
import { getTgLangOptions, timeZoneOptions } from "./Settings.model";
import { Trans, useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import FormControlsButtons from "components/FormControlButtons";
import { telegramTokenIsSetSelector } from "store/selectors/global";
import { RHFInputCheckbox } from "components/ReactHookForm/fields/custom";
import {
  RHFInputWithLabel,
  RHFPhoneInputWithLabel,
  RHFSelectWithLabel,
  RHFTextareaWithLabel,
} from "components/ReactHookForm/fields";
import {
  ARButton,
  ARCard,
  ARCardBody,
  ARCardFooter,
  ARCardHeader,
  ARCol,
  ARForm,
  ARFormGroup,
  ARLabel,
} from "components/ARComponents";

const Settings = () => {
  const { t } = useTranslation();
  const isTelegramTokenSet = useSelector(telegramTokenIsSetSelector);
  const { 
    onFormSubmit, 
    onFormReset, 
    settingsInfo, 
    onResetTelegramTokenClick, 
    Prompt, 
    form }
   = useSettingsForm();
  const { handleSubmit, control } = form;

  return (
    <ARCard className="SettingsCard">
      <ARCardHeader>
        <h1 className="card-header-text">
          {t("form:section.company", "Company")} {settingsInfo.companyName}
        </h1>
      </ARCardHeader>
      <ARCardBody>
        <Prompt />
        <ARForm onSubmit={handleSubmit(onFormSubmit)} id="settings-form">
          <ARFormGroup>
            <h2>Telegram</h2>
            <RHFInputWithLabel
              id="telegram_connect_key"
              name="telegram_connect_key"
              labelText="Telegram Connect Key"
              placeholder={t("form:placeholder.default", "Enter Telegram Connect Key", {
                what: "Telegram Connect Key",
              })}
              control={control}
              autoComplete="off"
            />
            <RHFInputWithLabel
              id="telegram_token"
              labelText={t("form:label.specificToken", "Telegram token", { what: "Telegram" })}
              name="telegram_token"
              control={control}
              placeholder={
                isTelegramTokenSet
                  ? t("form:placeholder.newSpecificToken", "Enter new Telegram token", { what: "Telegram" })
                  : t("form:placeholder.specificToken", "Enter Telegram token", { what: "Telegram" })
              }
              autoComplete="off"
            />
            <ARFormGroup row className="SettingsCard__token-status-group">
              <ARCol md="3">
                <ARLabel>
                  <Trans i18nKey={`form:status.telegramToken_${isTelegramTokenSet ? "set" : "unset"}`}>
                    Telegram token is <b>{isTelegramTokenSet ? "SET" : "UNSET"}</b>
                  </Trans>
                </ARLabel>
              </ARCol>
              {isTelegramTokenSet && (
                <ARCol xs="12" md="9">
                  <ARButton
                    color="danger"
                    variant="outline"
                    onClick={onResetTelegramTokenClick}
                    className="form-control-button"
                  >
                    {t("form:button.clearToken", "Clear token")}
                  </ARButton>
                </ARCol>
              )}
            </ARFormGroup>
            {isTelegramTokenSet && (
              <>
                <RHFSelectWithLabel
                  id="telegram_language"
                  name="telegram_language"
                  control={control}
                  options={getTgLangOptions()}
                  labelText={t("form:label.tgLanguage.label", "Telegram language")}
                />
                <RHFInputCheckbox
                  id="is_telegram_auth_enabled"
                  name="is_telegram_auth_enabled"
                  control={control}
                  className="checkbox"
                  labelClassName="checkbox__label"
                  labelText={t("form:label.telegramAuth", "Enable Telegram authorization")}
                />
              </>
            )}
          </ARFormGroup>
          <ARFormGroup>
            <h2>Nexmo</h2>
            <RHFInputWithLabel
              id="nexmo_app_id"
              name="nexmo_app_id"
              labelText="App ID"
              placeholder={t("form:placeholder.default", "Enter App ID", { what: "App ID" })}
              control={control}
              autoComplete="off"
            />
            <RHFPhoneInputWithLabel
              id="nexmo_from_phone"
              labelText={t("form:label.callFromPhone", "Call from phone")}
              name="nexmo_from_phone"
              control={control}
              autoComplete="off"
              block
            />
            <RHFTextareaWithLabel
              id="nexmo_private_key"
              name="nexmo_private_key"
              labelText={t("form:label.privateKey", "Private key")}
              labelColClassName="form-textarea-label"
              placeholder={t("form:placeholder.privateKey", "Enter private key")}
              className="textarea-size"
              control={control}
              autoComplete="off"
            />
          </ARFormGroup>
          <ARFormGroup>
            <h2>Jira</h2>
            <RHFInputWithLabel
              id="jira_token"
              name="jira_token"
              labelText={t("form:label.specificToken", "Jira token", { what: "Jira" })}
              placeholder={t("form:placeholder.specificToken", "Enter Jira token", { what: "Jira" })}
              control={control}
              autoComplete="off"
            />
            <RHFInputWithLabel
              id="jira_url"
              name="jira_url"
              labelText="Jira URL"
              placeholder={t("form:placeholder.default", "Enter Jira URL", { what: "Jira URL" })}
              control={control}
              autoComplete="off"
            />
          </ARFormGroup>
          <ARFormGroup>
            <RHFSelectWithLabel
              id="timezone"
              name="timezone"
              control={control}
              options={timeZoneOptions}
              labelText={t("form:label.timeZone", "Time zone")}
            />
          </ARFormGroup>
        </ARForm>
      </ARCardBody>
      <ARCardFooter>
        <FormControlsButtons form="settings-form" onCancelClick={onFormReset} />
      </ARCardFooter>
    </ARCard>
  );
};
export default withErrorBoundary(Settings);
