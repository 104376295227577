import {useState} from "react";

const useDiscount = () => {
  const [hasDiscount, setHasDiscount] = useState(false);

  const discountHandler = () => setHasDiscount((prevState) => !prevState);

  return {
    hasDiscount,
    discountHandler
  };
};

export default useDiscount;