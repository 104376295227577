import Error from "./Error";
import { setDocumentTitle } from "utils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { tenantErrorSelector } from "store/selectors/global";

const TenantError = () => {
  const { t } = useTranslation();
  const error = useSelector(tenantErrorSelector);

  setDocumentTitle(t("page.error.default", "Error 404", { status: error?.status || 404 }));

  return <Error error={{ status: error?.status || 404, statusText: error?.statusText || "Tenant not found" }} />;
};

export default TenantError;
