import Cookies from "js-cookie";
import { AUTH } from "../actions/types";
import { IBaseAuthState, AuthActionType } from "./authTypes";

const initialState: IBaseAuthState = {
  isAuthorized: !!Cookies.get("csrftoken"),
  error: null,
};

const authReducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
    case AUTH.LOGIN_SUCCESS:
    case AUTH.SIGN_UP_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        error: null,
      };
    case AUTH.LOGOUT_SUCCESS:
      Cookies.remove("csrftoken");
      return {
        ...state,
        isAuthorized: false,
        error: null,
      };
    case AUTH.LOGIN_FAILURE:
    case AUTH.SIGN_UP_FAILURE:
      Cookies.remove("csrftoken");
      return {
        ...state,
        isAuthorized: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default authReducer;
