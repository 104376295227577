import {instance, requestNoSpinnerPromiseWrapper, requestPromiseWrapper} from "./instance";
import {MANAGE_CHECK_ACTIONS} from "../../utils/constants";

export function getChecksRequest(query) {
  return requestNoSpinnerPromiseWrapper(instance.get(`/checks?${query}`));
}

export function manageCheckRequest(id, command, data = {}, showFetching = false) {
  if ([MANAGE_CHECK_ACTIONS.PAUSE, MANAGE_CHECK_ACTIONS.RESUME, MANAGE_CHECK_ACTIONS.ENABLE, MANAGE_CHECK_ACTIONS.DISABLE].indexOf(command) !== -1) {
    if (showFetching) {
      return requestPromiseWrapper(instance.post(`/checks/${id}/${command}`, data));
    } else {
      return requestNoSpinnerPromiseWrapper(instance.post(`/checks/${id}/${command}`, data));
    }
  }
}

export function deleteCheckRequest(id, showFetching = false) {
  if (showFetching) {
    return requestPromiseWrapper(instance.delete(`/checks/${id}`));
  } else {
    return requestNoSpinnerPromiseWrapper(instance.delete(`/checks/${id}`));
  }
}

export function changeCheckProjectRequest(id, checkData) {
  return requestPromiseWrapper(instance.patch(`/checks/${id}/change_project`, checkData));
}

export function createCheckRequest(check) {
  return requestPromiseWrapper(instance.post("/checks", check));
}

export function editCheckRequest(checkInfo, id) {
  return requestPromiseWrapper(instance.patch(`/checks/${id}`, checkInfo));
}

export function getDetailedCheckRequest(id, showSpinner = true) {
  if (!showSpinner) {
    return requestNoSpinnerPromiseWrapper(instance.get(`/checks/${id}`));
  }

  return requestPromiseWrapper(instance.get(`/checks/${id}`));
}

export function getCheckHistoryRequest(id) {
  return requestPromiseWrapper(instance.get(`/checks/${id}/history`));
}