import { useChecksData } from "./index";
import i18next from "services/language/i18next";
import { showModalWindow } from "utils/actionWrappers";
import ReturnButton from "components/ReturnButton/ReturnButton";
import TakeToWorkDropdown from "components/TakeToWorkDropdown/TakeToWorkDropdown";
import { ICheckData } from "./useChecksData";

const useChecksTable = () => {
  const { tableConfig, deleteCheck, pauseCheck, returnCheck, enableDisableCheck, onSelectedProjectChange } =
    useChecksData();

  const { tableItems: checksData } = tableConfig;

  const getDisplayingActionButton = (checkData: ICheckData) => {
    const { is_up, is_paused, is_enabled, id: checkId } = checkData;
    if (is_enabled && is_paused) {
      return <ReturnButton checkId={checkId} onReturnButtonClick={returnCheck} />;
    } else if (is_up === false && is_enabled) {
      return <TakeToWorkDropdown checkId={checkId} pauses={checkData.pauses_nn} onTakeToWorkButtonClick={pauseCheck} />;
    }
    return null;
  };

  const onDeleteButtonClick = (id: string | number) => {
    showModalWindow.confirm({
      title: i18next.t("modal:title.confirm", "Confirm your action"),
      message: i18next.t("modal:message.delete.check", "Are you sure you want to delete this check?"),
      color: "danger",
      onConfirm: () => deleteCheck(id),
    });
  };

  const hasActionButton = !!checksData.find((check) => !!getDisplayingActionButton(check));

  return {
    tableConfig,
    hasActionButton,
    enableDisableCheck,
    onDeleteButtonClick,
    getDisplayingActionButton,
    onSelectedProjectChange,
  };
};

export default useChecksTable;
