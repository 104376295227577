import { useEffect, useState } from "react";
import { ERR_MESSAGES } from "utils/constants";
import { cancelSource, getIntegrationsRequest } from "services/api";

const useIntegrationsData = () => {
  const [integrationsInfo, setIntegrationsInfo] = useState({
    integrationsData: [],
    loading: true,
  });

  useEffect(() => {
    getIntegrationsRequest().then((response) => {
      setIntegrationsInfo({
        integrationsData: response.data,
        loading: false,
      });
    });

    return () => cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
  }, []);

  return integrationsInfo;
};

export default useIntegrationsData;
