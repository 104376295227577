import { PATHS } from "route-dictionary";
import { useActions } from "store/actions";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { numberToTimeDuration } from "utils/timeHandlers";
import { ERR_MESSAGES, MANAGE_CHECK_ACTIONS } from "utils/constants";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { resetCheckNameAction, setCheckNameAction } from "store/actions/global";
import { cancelSource, deleteCheckRequest, getDetailedCheckRequest, manageCheckRequest } from "services/api";
import { ICheck } from "../_types";

const useCheckDetailsData = () => {
  const { id } = useParams<{ id: string }>();
  const [resetCheckName] = useActions([resetCheckNameAction]);
  const [setCheckName] = useActions([setCheckNameAction]);
  const history = useHistory();
  const { t } = useTranslation();
  const [checkDetailsData, setCheckDetailsData] = useState<ICheck | {}>({});

  const updateCheckDetailsData = useCallback(
    (id, showSpinner = true) => {
      return getDetailedCheckRequest(id, showSpinner).then((response) => {
        setCheckDetailsData(response.data);
        setCheckName(response.data);
      });
    },
    [setCheckName]
  );

  useEffect(() => {
    updateCheckDetailsData(id);
    return () => {
      resetCheckName();
      cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
    };
  }, [id, resetCheckName, updateCheckDetailsData]);

  const onDeleteCheckClick = () => {
    showModalWindow.confirm({
      title: t("modal:title.confirm", "Confirm your action"),
      message: t("modal:message.delete.check", "Are you sure you want to delete this check?"),
      color: "danger",
      onConfirm: () => {
        deleteCheckRequest(id, true).then(() => {
          showNotification(
            t("notification:title.success", "Success!"),
            t("notification:message.deleted.check", "Check has been deleted."),
            "success"
          );
          history.push(PATHS.CHECKS.ROOT);
        });
      },
    });
  };

  const onEditCheckClick = () => {
    history.push(PATHS.CHECKS.getEditIdPath!(id));
  };

  const onEnableDisableCheckClick = (id: number, isEnabled?: boolean) => {
    const command = isEnabled ? MANAGE_CHECK_ACTIONS.DISABLE : MANAGE_CHECK_ACTIONS.ENABLE;
    manageCheckRequest(id, command, {}, true)!
      .finally(() => updateCheckDetailsData(id))
      .then(() =>
        showNotification(
          t("notification:title.success", "Success!"),
          t(`notification:message.${command + "d"}.check`, `Check has been ${command + "d"}`),
          "success"
        )
      );
  };

  const onPauseCheckClick = (id: number | string, time: number) => {
    manageCheckRequest(id, MANAGE_CHECK_ACTIONS.PAUSE, { duration: numberToTimeDuration(time) }, true)!
      .finally(() => updateCheckDetailsData(id))
      .then(() =>
        showNotification(
          t("notification:title.success", "Success!"),
          t("notification:message.paused.check", "Check has been taken to work."),
          "success"
        )
      );
  };

  const onReturnCheckClick = (id: number) => {
    manageCheckRequest(id, MANAGE_CHECK_ACTIONS.RESUME, {}, true)!
      .finally(() => updateCheckDetailsData(id))
      .then(() =>
        showNotification(
          t("notification:title.success", "Success!"),
          t("notification:message.returned.check", "Check has been returned."),
          "success"
        )
      );
  };

  const onCheckHistoryClick = (id: number) => history.push(PATHS.CHECKS.getHistoryIdPath!(id));

  return {
    checkDetailsData,
    actionFunctions: {
      onEditCheckClick,
      onEnableDisableCheckClick,
      onPauseCheckClick,
      onReturnCheckClick,
      onDeleteCheckClick,
      onCheckHistoryClick,
    },
    updateCheckDetailsData,
  };
};

export default useCheckDetailsData;
