import {FieldValues, useForm} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { showNotification } from "utils/actionWrappers";
import { DAY_TYPES, ERR_MESSAGES } from "utils/constants";
import { ruleDataFields } from "../RuleForm/RuleForm.model";
import { useCheckIdParams, useProjectsIdParams, useRuleIdParams } from "./index";
import { useCallback, useEffect, useRef, useState } from "react";
import { sliceRuleNotificationTimes } from "utils/timeHandlers";
import { getFormDataObj } from "components/ReactHookForm/utils";
import { ruleFormSchema } from "components/ReactHookForm/schemes";
import {
  cancelSource,
  createRuleRequest,
  deleteRuleRequest,
  getRuleByIdRequest,
  updateRuleRequest,
} from "services/api";
import { Day, IRule } from "../../../components/RulesComponents/_types";
import { IChat, IUser } from "../../../components/ReactHookForm/_types";

interface IRuleFormDataBase extends FieldValues {
  from_time: string | null;
  to_time: string | null;
  delay: string;
  recur: string;
  day_type: Day;
  phones: string[];
  is_inverted_condition: boolean;
}

export interface IRuleFormData extends IRuleFormDataBase {
  telegram_chats: IChat[];
  members_to_call: IUser[];
}

export interface IRuleFormDataSubmit extends IRuleFormDataBase {
  telegram_chats: string[];
  members_to_call: string[];
  my_check?: string | null;
  project?: string | null;
}

const useRuleFormConfig = () => {
  const { t } = useTranslation();
  const { ruleId } = useRuleIdParams();
  const { projectId } = useProjectsIdParams();
  const { checkId } = useCheckIdParams();
  const isAddPage = !ruleId;
  const [userIsProjectAdmin, setUserIsProjectAdmin] = useState(true);

  const formControl = useForm({
    mode: "onChange",
    resolver: yupResolver(ruleFormSchema),
    defaultValues: {
      from_time: null,
      to_time: null,
      delay: "00:03:00",
      recur: "00:03:00",
      day_type: "i",
      telegram_chats: [],
      phones: [],
      members_to_call: [],
      is_inverted_condition: false,
    } as IRuleFormData,
  });

  const { reset, setValue } = formControl;

  const resetFormSubscriber = useRef<React.Dispatch<IRuleFormData>>();
  const subscribeToResetForm = (subscriber: React.Dispatch<IRuleFormData>) => {
    resetFormSubscriber.current = subscriber;
  };

  const addRule = (ruleData: IRuleFormDataSubmit) => {
    return createRuleRequest(ruleData).then(() => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.added.rule", "Rule has been successfully added."),
        "success"
      );
    });
  };

  const editRule = (ruleData: IRuleFormDataSubmit, ruleId?: string) => {
    return updateRuleRequest(ruleData, ruleId).then(() => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.updated.rule", "Rule has been updated."),
        "success"
      );
    });
  };

  const deleteRule = (ruleId: string) => {
    return deleteRuleRequest(ruleId).then(() => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.deleted.rule", "Rule has been deleted."),
        "success"
      );
    });
  };

  const getRuleInfo = useCallback(() => {
    return getRuleByIdRequest(ruleId).then(({ data }) => {
      const formData = getFormDataObj({ arrOfFields: ruleDataFields, serverData: data }) as IRule;
      sliceRuleNotificationTimes(formData);

      setUserIsProjectAdmin(data.is_user_project_admin);
      reset(formData);

      resetFormSubscriber.current && resetFormSubscriber.current(formData);
    });
  }, [ruleId, reset]);

  const onTypeChange = (typeValue: DAY_TYPES) => {
    const isAnyDayType = typeValue === DAY_TYPES.ANY_DAY;
    if (isAnyDayType) {
      setValue("is_inverted_condition", false, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (ruleId) {
      getRuleInfo();
    }

    return () => cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
  }, [ruleId, getRuleInfo]);

  return {
    ruleId,
    checkId,
    projectId,
    isAddPage,
    formControl,
    onTypeChange,
    userIsProjectAdmin,
    onDeleteBtnClick: deleteRule,
    onSaveBtnClick: isAddPage ? addRule : editRule,
    formId: isAddPage ? "rule_add_form" : `rule_${ruleId}_form`,
    subscribeToResetForm,
  };
};

export default useRuleFormConfig;
