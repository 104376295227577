import i18next from "services/language/i18next";
import {patchCompanyBilling} from "services/api";
import {PlansContext} from "../subscribe-context";
import {useContext, useEffect, useState} from "react";
import {showModalWindow, showNotification} from "utils/actionWrappers";

const useAutoPayment = (isAutoPaymentOn) => {
  const {updateCompanyBilling} = useContext(PlansContext);
  const [hasAutoPayment, setAutoPaymentTo] = useState(true);

  const changeAutoPayment = () => {
    setAutoPaymentTo((prevState) => !prevState);
    patchCompanyBilling({subscription_cancel_at_period_end: hasAutoPayment})
      .then(() => updateCompanyBilling())
      .then(() => {
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t("notification:message.updated.autoPaymentSettings", "Auto payment settings have been updated."),
          "success"
        );
      });
  };

  const autoPaymentHandler = () => {
    if (hasAutoPayment) {
      showModalWindow.confirm({
        message: i18next.t("modal:message.autoPayment", "Are you sure you want to disable auto payment?"),
        color: "warning",
        onConfirm: () => changeAutoPayment()
      });
      return;
    }

    changeAutoPayment();
  };

  useEffect(() => setAutoPaymentTo(isAutoPaymentOn), [isAutoPaymentOn]);

  return {
    hasAutoPayment,
    autoPaymentHandler
  };
};

export default useAutoPayment;