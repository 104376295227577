import { useLoginForm } from "./hooks";
import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import { REG_EXS } from "utils/constants";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { RHFPrependAppendInput } from "components/ReactHookForm/fields";
import { RHFInputCheckbox } from "components/ReactHookForm/fields/custom";
import { ARAlert, ARButton, ARCol, ARForm, ARRow } from "components/ARComponents";

const Login = () => {
  const { t } = useTranslation();
  const { form, onFormSubmit, externalErrorText, internalErrorText } = useLoginForm();

  const { handleSubmit, control, setFormError } = form;

  return (
    <ARForm onSubmit={handleSubmit(onFormSubmit)}>
      <h1>{t("card.title.login", "Login")}</h1>
      <ARAlert color="danger" show={!!externalErrorText}>
        {externalErrorText}
      </ARAlert>
      <ARAlert color="danger" show={!!internalErrorText}>
        {internalErrorText}
      </ARAlert>
      <p className="text-muted d-flex justify-content-between">
        <span>{t("card.description.login", "Sign In to your account")}</span>
        <ARButton tag={Link} to={PATHS.FORGOT_PASSWORD.ROOT} color="link" className="p-0">
          {t("button.forgotPassword", "Forgot password?")}
        </ARButton>
      </p>
      <RHFPrependAppendInput
        iconName="cilUser"
        className="mb-3"
        id="email"
        name="email"
        control={control}
        placeholder={t("form:label.email", "Email")}
        pattern={REG_EXS.EMAIL_CHARACTERS}
        patternMismatchMessage={MESSAGES.EMAIL_INVALID_CHARACTERS}
        setFormError={setFormError}
        autoComplete="email"
        autoFocus
      />
      <RHFPrependAppendInput
        iconName="cilLockLocked"
        className="mb-4"
        id="password"
        name="password"
        type="password"
        control={control}
        placeholder={t("form:label.password", "Password")}
        autoComplete="current-password"
      />
      <RHFInputCheckbox
        id="remember_me"
        name="remember_me"
        control={control}
        className="checkbox"
        labelClassName="checkbox__label"
        labelText={t("form:label.rememberMe", "Remember me")}
      />
      <ARRow>
        <ARCol xs={12} className="card-column-buttons">
          <ARButton type="submit" color="primary" className="card-column-buttons__button">
            {t("button.login", "Login")}
          </ARButton>
          <ARButton tag={Link} to={PATHS.SIGN_UP.ROOT} color="link" className="card-column-buttons__button">
            {t("button.register", "Register")}
          </ARButton>
        </ARCol>
      </ARRow>
    </ARForm>
  );
};

export default withErrorBoundary(Login);
