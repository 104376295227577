import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ARButton } from "components/ARComponents";

const SubscribeButton = () => {
  const { t } = useTranslation();

  return (
    <ARButton tag={Link} to={"/subscribe"} color="primary" variant="outline" className="SubscribeButton__button">
      {t("button.subscribe", "Subscribe")}
    </ARButton>
  );
};

export default SubscribeButton;
