import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import { useLoginSubForm } from "./hooks";
import { DOMAIN, REG_EXS } from "utils/constants";
import { Trans, useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { RHFPrependAppendInput } from "components/ReactHookForm/fields";
import { ARAlert, ARButton, ARCol, ARForm, ARRow } from "components/ARComponents";

const LoginSub = () => {
  const { t } = useTranslation();
  const { onFormSubmit, subdomain, nonExistentSubdomain, form } = useLoginSubForm();
  const { control, handleSubmit, setFormError } = form;
  return (
    <ARForm onSubmit={handleSubmit(onFormSubmit)}>
      <h1>{t("card.title.loginSubdomain", "Login by subdomain")}</h1>
      <p className="text-muted">{t("card.description.loginSubdomain", "Sign In to your subdomain")}</p>
      <ARAlert
        className="LoginSub__non-existent-alert"
        color="danger"
        show={!!nonExistentSubdomain && nonExistentSubdomain === subdomain}
      >
        <p className="LoginSub__non-existent-alert__text">
          <Trans
            i18nKey="notification:message.error.domain.nonExistent"
            values={{ domain: `${nonExistentSubdomain}.${DOMAIN}` }}
          >
            The domain{" "}
            <b>
              {nonExistentSubdomain}.{DOMAIN}
            </b>{" "}
            does not exist. But you can
            <ARButton
              className="LoginSub__non-existent-alert__register"
              tag={Link}
              to={PATHS.SIGN_UP.getRouteWithQueryParam("subdomain", nonExistentSubdomain)}
              color="danger"
            >
              Register
            </ARButton>
            this domain for your company!
          </Trans>
        </p>
      </ARAlert>
      <RHFPrependAppendInput
        iconName="cilLink"
        className="mb-3"
        appendText={`.${DOMAIN}`}
        id="subdomain"
        name="subdomain"
        inputClassName="LoginSub__subdomain-field"
        setFormError={setFormError}
        control={control}
        patternMismatchMessage={MESSAGES.DOMAIN_INVALID_CHARACTERS}
        pattern={REG_EXS.DOMAIN_CHARACTERS}
        placeholder={t("form:placeholder.subdomain", "subdomain")}
        autoComplete="your-company-name"
        autoFocus
      />
      <ARRow>
        <ARCol xs={12} className="card-column-buttons">
          <ARButton type="submit" color="primary" className="card-column-buttons__button">
            {t("button.login", "Login")}
          </ARButton>
          <ARButton tag={Link} to={PATHS.SIGN_UP.ROOT} color="link" className="card-column-buttons__button">
            {t("button.register", "Sign Up")}
          </ARButton>
        </ARCol>
      </ARRow>
    </ARForm>
  );
};

export default withErrorBoundary(LoginSub);
