import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useActions } from "store/actions";
import { useTranslation } from "react-i18next";
import { logoutAction } from "store/actions/auth";
import { showModalWindow } from "utils/actionWrappers";
import { resetUserInfoAction } from "store/actions/user";
import { withErrorBoundary } from "components/ErrorBoundary";
import { avatarSelector, fullNameSelector } from "store/selectors/user";
import { ARDropdown, ARDropdownItem, ARDropdownMenu, ARDropdownToggle, ARIcon, ARImg } from "components/ARComponents";

const HeaderProfileDropdown = () => {
  const { t } = useTranslation();
  const [logout] = useActions(logoutAction);
  const [resetUser] = useActions(resetUserInfoAction);
  const avatar: string = useSelector(avatarSelector);
  const fullName: string = useSelector(fullNameSelector);
  const history = useHistory();

  const onLogoutClick = () => {
    showModalWindow.confirm({
      title: t("modal:title.confirm", "Confirm your action"),
      message: t("modal:message.logout", "Are you sure you want to log out of your account?"),
      color: "danger",
      onConfirm: async () => {
        await logout();
        return () => {
          resetUser();
          history.push(PATHS.LOGIN.ROOT);
        };
      },
    });
  };

  return (
    <ARDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <ARDropdownToggle className="c-header-nav-link" caret={false}>
        <span className="mr-3">{fullName}</span>
        <div className="c-avatar">
          <ARImg src={avatar} className="c-avatar-img" alt="You" />
        </div>
      </ARDropdownToggle>
      <ARDropdownMenu className="pt-0" placement="bottom-end">
        <ARDropdownItem header tag="div" color="light" className="text-center">
          <strong>{t("header.avatarDropdown.title", "Account")}</strong>
        </ARDropdownItem>
        <ARDropdownItem className="mt-0" divider />
        <ARDropdownItem tag={Link} to={PATHS.PROFILE.ROOT}>
          <ARIcon name="cilUser" className="mfe-2" />
          {t("header.avatarDropdown.profile", "Profile")}
        </ARDropdownItem>
        <ARDropdownItem divider />
        <ARDropdownItem onClick={onLogoutClick}>
          <ARIcon name="cilAccountLogout" className="mfe-2" />
          {t("header.avatarDropdown.logout", "Logout")}
        </ARDropdownItem>
      </ARDropdownMenu>
    </ARDropdown>
  );
};

export default withErrorBoundary(HeaderProfileDropdown);
