import { ARCard, ARCardBody } from "../ARComponents";
import i18next from "services/language/i18next";

const FallbackStub = () => {
  return (
    <ARCard>
      <ARCardBody>
        <div className="text-center">
          {i18next.t("notification:message.error.boundary", "Unfortunately, an error has occurred in this component.")}
        </div>
      </ARCardBody>
    </ARCard>
  );
};

export default FallbackStub;
