import { RHFInput } from "./custom";
import { RHFPrependAppend } from "./wrappers";
import { IRHFPrependAppendProps } from "./wrappers/RHFPrependAppend";
import { IRHFInputProps } from "./custom/RHFInput";

interface IRHFPrependAppendInputProps extends IRHFInputProps, IRHFPrependAppendProps {}

const RHFPrependAppendInput = ({
  className,
  iconName,
  prependText,
  appendText,
  inputClassName,
  ...inputProps
}: IRHFPrependAppendInputProps) => {
  return (
    <RHFPrependAppend className={className} iconName={iconName} prependText={prependText} appendText={appendText}>
      <RHFInput className={inputClassName} {...inputProps} />
    </RHFPrependAppend>
  );
};

export default RHFPrependAppendInput;
