import i18next from "services/language/i18next";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";

export function getTableColumnHeaders() {
  const hasViewChecksIncidentsPermission = hasGlobalPermission(ACTIONS.VIEW_CHECKS_INCIDENTS);
  return [
    {
      key: "status",
      label: i18next.t("table:header.state", "State"),
      _style: { width: "7%" },
      isShown: hasViewChecksIncidentsPermission,
    },
    {
      key: "name",
      label: i18next.t("table:header.alert", "Alert"),
      isShown: hasViewChecksIncidentsPermission,
    },
    {
      key: "project",
      label: i18next.t("table:header.project", "Project"),
      _style: { width: "15%" },
      isShown: hasViewChecksIncidentsPermission,
    },
    {
      key: "down",
      label: i18next.t("table:header.down", "Down"),
      _style: { width: "15%" },
      isShown: hasViewChecksIncidentsPermission,
    },
    {
      key: "up",
      label: i18next.t("table:header.up", "Up"),
      _style: { width: "15%" },
      isShown: hasViewChecksIncidentsPermission,
    },
    {
      key: "actions_column",
      label: "",
      _style: { width: "3%" },
      isShown: hasGlobalPermission(ACTIONS.TAKE_CHECKS_TO_WORK),
    },
  ].filter((item) => item.isShown);
}
