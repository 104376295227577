import { useEffect, useMemo, useState } from "react";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Content from "../Content/Content";
import routeNetwork from "routes";
import { ARContainer } from "components/ARComponents";
import { useDispatch, useSelector } from "react-redux";
import { tenantSelector } from "store/selectors/global";
import { globalRoleSelector } from "store/selectors/user";
import { setAuthorizedUserInfo } from "store/actions/user";
import { isAuthorizedSelector } from "store/selectors/auth";
import { withErrorBoundary } from "components/ErrorBoundary";
import { IRoute } from "components/PageWrappers/_types";

const AuthorizedPageWrapper = () => {
  const [showSidebar, setShowSidebar] = useState<boolean | "responsive">("responsive");
  const dispatch = useDispatch();
  const isAuthorized = useSelector(isAuthorizedSelector);
  const tenant = useSelector(tenantSelector);
  const globalRole = useSelector(globalRoleSelector);
  const routes: IRoute[] = useMemo(
    () => routeNetwork.getRoutes(tenant, isAuthorized),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [globalRole, isAuthorized, tenant]
  );

  useEffect(() => {
    dispatch(setAuthorizedUserInfo());
  }, [dispatch]);

  return (
    <div className="c-app c-default-layout">
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="c-wrapper">
        <Header routes={routes} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        <div className="c-body">
          <main className="c-main">
            <ARContainer fluid>
              <Content routes={routes} className="card-width-limiter" />
            </ARContainer>
          </main>
        </div>
        {/*<Footer/>*/}
      </div>
    </div>
  );
};

export default withErrorBoundary(AuthorizedPageWrapper);
