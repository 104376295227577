import React from "react";
import {PATHS} from "route-dictionary";
import {useProjectsTable} from "./hooks";
import {getTableNoItemsView} from "utils";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import AddButtonRow from "components/AddButtonRow";
import {getTableColumnHeaders} from "./Projects.model";
import {withErrorBoundary} from "components/ErrorBoundary";
import {ACTIONS, getProjectRole, hasGlobalPermission, hasProjectPermission} from "utils/user-access";
import classNames from "classnames";
import {
  ARCard,
  ARCardBody,
  ARCardHeader,
  ARDataTable,
  ARDropdown,
  ARDropdownItem,
  ARDropdownMenu,
  ARDropdownToggle,
  ARIcon
} from "components/ARComponents";

const Projects = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const {projects, onAddButtonClick, onDeleteButtonClick} = useProjectsTable();

  const getClickAction = (id, is_user_project_admin) => {
    hasProjectPermission(ACTIONS.VIEW_PROJECT, getProjectRole(is_user_project_admin)) &&
    history.push(PATHS.PROJECTS.getDetailsIdPath(id));
  };

  return (
    <ARCard className="Projects-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.projects", "Projects")}</h1>
      </ARCardHeader>
      <ARCardBody>
        <ARDataTable
          items={projects}
          addTableClasses="Projects-table"
          fields={getTableColumnHeaders()}
          noItemsView={getTableNoItemsView()}
          overTableSlot={
            <AddButtonRow
              onClick={onAddButtonClick}
              hasAddPermission={hasGlobalPermission(ACTIONS.CREATE_PROJECTS)}
            />
          }
          hover
          striped
          border
          clickableRows={hasGlobalPermission(ACTIONS.VIEW_PROJECTS_DETAILS) || undefined}
          scopedSlots={
            {
              "name": hasGlobalPermission(ACTIONS.VIEW_PROJECTS) ?
                (item) => (
                  <td
                    className={classNames("Projects-table__cell", {"cursor_default": !hasProjectPermission(ACTIONS.VIEW_PROJECT, getProjectRole(item.is_user_project_admin))})}
                    onClick={() => getClickAction(item.id, item.is_user_project_admin)}
                  >
                    {item.name || ""}
                  </td>
                ) : null,
              "actions_column": hasGlobalPermission(ACTIONS.VIEW_PROJECTS_ACTION_DROPDOWN) ?
                (item) => (
                  <td>
                    <ARDropdown className="dropdown_no-caret">
                      <ARDropdownToggle
                        className="btn-block"
                        size="sm"
                        color="dark"
                        variant="outline"
                      >
                        <ARIcon name="cilMinus" size="sm"/>
                      </ARDropdownToggle>
                      <ARDropdownMenu placement="left">
                        {
                          hasGlobalPermission(ACTIONS.EDIT_PROJECTS) &&
                          <ARDropdownItem tag={Link} to={PATHS.PROJECTS.getDetailsIdPath(item.id)}>
                            {t("button.edit", "Edit")}
                          </ARDropdownItem>
                        }
                        {
                          !item.is_main && hasGlobalPermission(ACTIONS.DELETE_PROJECTS) &&
                          <ARDropdownItem onClick={() => onDeleteButtonClick(item.id)}>
                            {t("button.delete", "Delete")}
                          </ARDropdownItem>
                        }
                      </ARDropdownMenu>
                    </ARDropdown>
                  </td>
                ) : null
            }
          }
        />
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(Projects);