import { Redirect, useLocation } from "react-router";

interface IRedirectSaveCurrentPageProps {
  to: string;
}

const RedirectSaveCurrentPage = ({ to }: IRedirectSaveCurrentPageProps) => {
  const location = useLocation();

  return <Redirect to={`${to}?redirect=${location.pathname}`} />;
};

export default RedirectSaveCurrentPage;
