import {useCallback, useContext, useState} from "react";
import {PlansContext} from "../subscribe-context";
import {getPrice} from "../PaidPlan/PaidPlan.model";

const usePrice = () => {
  const {hasDiscount, monthlyPrice, yearlyPrice} = useContext(PlansContext);
  const [price, setPrice] = useState(null);

  const updatePrice = useCallback((newAmount) => {
    const newPrice = getPrice({
      itemsAmount: newAmount,
      hasDiscount,
      usualPrice: monthlyPrice,
      discountPrice: yearlyPrice
    });
    setPrice(newPrice);
  }, [hasDiscount, monthlyPrice, yearlyPrice]);

  return {price, updatePrice};
};

export default usePrice;