import { useTranslation } from "react-i18next";
import CheckForm from "../CheckForm/CheckForm";
import { withErrorBoundary } from "components/ErrorBoundary";
import FormControlsButtons from "components/FormControlButtons";
import { ARCard, ARCardBody, ARCardFooter, ARCardHeader } from "components/ARComponents";
import useCheckForm from "../hooks/useCheckForm";

const CheckAdd = () => {
  const { Prompt, form, formConfig, checkId } = useCheckForm();
  const { formId, onFormReset } = formConfig;
  const { t } = useTranslation();

  return (
    <ARCard>
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.check.add", "Add check")}</h1>
      </ARCardHeader>
      <ARCardBody>
        {checkId && <Prompt />}
        <CheckForm {...form} {...formConfig} />
      </ARCardBody>
      <ARCardFooter>
        <FormControlsButtons form={formId} onCancelClick={onFormReset} />
      </ARCardFooter>
    </ARCard>
  );
};

export default withErrorBoundary(CheckAdd);
