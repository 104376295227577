import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ARButton, ARCol, ARForm, ARFormGroup, ARRow } from "components/ARComponents";
import { FormEventHandler } from "react";

interface IRecoveryPassword {
  children: React.ReactNode;
  onFormSubmit: FormEventHandler;
  infoText: string;
}

const RecoveryPassword = ({ children, onFormSubmit, infoText }: IRecoveryPassword) => {
  const { t } = useTranslation();

  return (
    <ARForm className="RecoveryPassword" onSubmit={onFormSubmit}>
      <p className="RecoveryPassword__info-text">{infoText}</p>
      {children}
      <ARFormGroup>
        <ARButton className="RecoveryPassword__submit-button" type="submit" color="primary">
          {t("button.send", "Send")}
        </ARButton>
      </ARFormGroup>
      <ARRow>
        <ARCol xs={12} className="card-column-buttons">
          <ARButton color="link" tag={Link} to={PATHS.LOGIN.ROOT} className="card-column-buttons__button">
            {t("button.login", "Sign In")}
          </ARButton>
          <ARButton color="link" tag={Link} to={PATHS.SIGN_UP.ROOT} className="card-column-buttons__button">
            {t("button.registerNewAccount", "Create a new account")}
          </ARButton>
        </ARCol>
      </ARRow>
    </ARForm>
  );
};

export default withErrorBoundary(RecoveryPassword);
