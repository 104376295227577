import classNames from "classnames";
import { useEffect } from "react";
import { Control } from "react-hook-form";
import { MESSAGES_TYPES } from "../../schemes/messages";
import { ARTextarea } from "components/ARComponents";
import { ARTextareaProps } from "components/ARComponents/_types";
import { ICustomError } from "components/ReactHookForm/_types";
import { useControllerWithCustomError } from "components/ReactHookForm/hooks";
import { ClassName } from "models/types";

export interface IRHFTextareaProps extends Omit<ARTextareaProps, "children"> {
  id: string;
  name: string;
  control: Control;
  className: ClassName;
  setError?: (error: ICustomError | undefined) => void;
}

const RHFTextarea = ({ name, control, className, setError, ...props }: IRHFTextareaProps) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isDirty, error },
    formState: { isSubmitted },
  } = useControllerWithCustomError({ name, control });

  useEffect(() => {
    setError && setError(error);
  }, [setError, error]);

  const isDangerType = error?.message?.type === MESSAGES_TYPES.DANGER;

  return (
    <ARTextarea
      className={classNames("field_right-border-radius", className)}
      invalid={invalid && isDangerType}
      valid={(isDirty || isSubmitted) && !invalid}
      {...props}
      {...inputProps}
    />
  );
};

export default RHFTextarea;
