import i18next from "services/language/i18next";
import {SUBSCRIPTION_INFO} from "../../utils/constants";

/**
 * Returns translated amount of checks
 * @param {number} checksAmount
 * @returns {string}
 */
const getSubscriptionPlanName = (checksAmount) => {
  return `${checksAmount} ${i18next.t("plans:checks", "checks")}`;
};

/**
 * Returns array of subscription plans objects
 * @param {Array<number>} checksAmountArr - Checks amounts according to existing in the service plans
 * @returns {Array<Object>}
 */
export const getSubscriptionPlans = (checksAmountArr) => {
  const subscriptionPlans = checksAmountArr.reduce((acc, checksAmount) => {
    const subscriptionPlan = {};
    subscriptionPlan.name = getSubscriptionPlanName(checksAmount);
    subscriptionPlan.value = checksAmount;
    acc.push(subscriptionPlan);
    return acc;
  }, []);

  return subscriptionPlans;
};

/**
 * Returns price with fixed amount of digits after the decimal place
 * @param {number} price
 * @returns {string}
 */
export const getToFixedPrice = (price) => price.toFixed(2);

/**
 * Converts price in cents to a price in dollars
 * @param {number} moneyAmount - Amount of money in cents
 * @returns {string} - Price in format 0.00$
 */
export const getPriceString = (moneyAmount) => getToFixedPrice(moneyAmount / 100) + SUBSCRIPTION_INFO.CURRENCY;

export const getFreeBenefitsArr = () => {
  return [
    i18next.t("plans:freePlan.benefits.checksTypes", "All types of checks"),
    i18next.t("plans:freePlan.benefits.integrationTypes", "All types of integrations"),
    i18next.t("plans:freePlan.benefits.usersAmount", "Unlimited users"),
    i18next.t("plans:freePlan.benefits.ldap", "Ldap catalogs"),
    i18next.t("plans:freePlan.benefits.usersGroups", "User groups and access policies"),
    i18next.t("plans:freePlan.benefits.incidents", "Incidents response"),
    i18next.t("plans:freePlan.benefits.alerting", "All types of alerting"),
    i18next.t("plans:freePlan.benefits.checksAmount", "3 checks available")
  ];
};

export const getPaidBenefitsArr = () => {
  return [
    i18next.t("plans:paidPlan.benefits.features", "All type of features"),
    i18next.t("plans:paidPlan.benefits.checksAmount", "Purchase as many checks as you need"),
  ];
};