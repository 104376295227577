import RuleForm from "../RuleForm";
import { useRuleForm } from "../hooks";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import AuthorizedError404 from "pages/error/AuthorizedError404";
import FormControlsButtons from "components/FormControlButtons";
import useSetCheckName from "pages/checks/hooks/useSetCheckName";
import { ARCard, ARCardBody, ARCardFooter, ARCardHeader } from "components/ARComponents";

const RuleDetails = () => {
  const { t } = useTranslation();
  const {
    Prompt,
    formId,
    checkId,
    formControl,
    onTypeChange,
    onFormReset,
    onFormSubmit,
    onFormDelete,
    userIsProjectAdmin,
  } = useRuleForm();
  useSetCheckName(checkId!);

  if (checkId && !userIsProjectAdmin) {
    return <AuthorizedError404 />;
  }

  return (
    <ARCard className="RuleDetails-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.rule.details", "Rule details")}</h1>
      </ARCardHeader>
      <ARCardBody>
        <Prompt />
        <RuleForm
          formId={formId}
          formControl={formControl}
          onFormSubmit={onFormSubmit}
          onTypeSelectChange={onTypeChange}
        />
      </ARCardBody>
      <ARCardFooter>
        <FormControlsButtons form={formId} onCancelClick={onFormReset} onDeleteClick={onFormDelete} />
      </ARCardFooter>
    </ARCard>
  );
};

export default withErrorBoundary(RuleDetails);
