import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import i18next from "services/language/i18next";
import { ICheckHistoryChange } from "../_types";
import { IHistory } from "./../_types";
import { IUser } from "components/ReactHookForm/_types";

export const getTranslatedField = (fieldName: string) => {
  const translatedFields = {
    //check fields
    integration: i18next.t("form:label.integration", "Integration"),
    name: i18next.t("form:label.checkName", "Check name"),
    description: i18next.t("form:label.description", "Description"),
    type: i18next.t("form:label.checkType", "Check type"),
    url: i18next.t("form:label.url", "Url"),
    timeout: i18next.t("form:label.timeout", "Timeout"),
    down_timeout: i18next.t("form:label.downTimeout", "Down timeout"),
    balance_threshold: i18next.t("form:label.balance", "Balance threshold"),
    project_name: i18next.t("form:label.project", "Project"),
    is_telegram_auth_enabled: i18next.t("form:label.checkTelegramAuth.label", "Telegram authorization"),

    //rule fields
    from_time: i18next.t("form:label.fromTime", "From"),
    to_time: i18next.t("form:label.toTime", "To"),
    day_type: i18next.t("form:label.dayType", "Day type"),
    delay: i18next.t("form:label.delay", "Delay"),
    recur: i18next.t("form:label.recurment", "Recurment"),
    phones: i18next.t("form:label.phones", "Phones"),
    telegram_chat_names: i18next.t("form:label.chats", "Chats"),
    member_to_call_names: i18next.t("form:label.membersToCall", "Members to call"),
    is_enabled: i18next.t("form:label.ruleEnabled", "Enabled"),
  };
  type key = keyof typeof translatedFields;
  return translatedFields[fieldName as key] || fieldName;
};

export const getTranslatedValues = (fieldName: string, value: string) => {
  const translatedValues = {
    day_type: {
      i: i18next.t("rule.time.day.any", "Any"),
      w: i18next.t("rule.time.day.weekday", "Working Days"),
      h: i18next.t("rule.time.day.weekend", "Not Working Days"),
    },
  };
  type key = keyof typeof translatedValues;
  type valueKey = keyof typeof translatedValues.day_type;
  return translatedValues[fieldName as key]?.[value as valueKey] || value;
};

export const convertValues = (changesFields: ICheckHistoryChange[]) => {
  return changesFields.map((field) => {
    if (["member_to_call_names", "telegram_chat_names", "phones"].includes(field.field)) {
      const regexp = /['[\]]/gi;
      const newFieldValue = field.new.replace(regexp, "");
      const oldFieldValue = field.old.replace(regexp, "");
      return { ...field, new: newFieldValue, old: oldFieldValue };
    } else if (field.field === "integration") {
      const { name: oldName, type: oldType } = JSON.parse(field.old);
      const { name: newName, type: newType } = JSON.parse(field.new);
      const newFieldValue = [newName, newType].find((item) => item)
        ? i18next.t("history.integration", `Name: ${newName}, type: ${newType}`, { name: newName, type: newType })
        : null;
      const oldFieldValue = [oldName, oldType].find((item) => item)
        ? i18next.t("history.integration", `Name: ${oldName}, type: ${oldType}`, { name: oldName, type: oldType })
        : null;
      return { ...field, new: newFieldValue, old: oldFieldValue };
    }
    return field;
  });
};

const CHECK_HISTORY_CHANGES = {
  RULE: "rule",
  CHECK: "check",
  ACTIONS: {
    ADD: "+",
    CHANGE: "~",
    DELETE: "-",
  },
};

export function getTableColumnHeaders() {
  return [
    {
      key: "field",
      label: i18next.t("table:header.field", "Field"),
      _style: { width: "30%" },
    },
    {
      key: "old",
      label: i18next.t("table:header.old", "Original value"),
      _style: { width: "35%" },
    },
    {
      key: "new",
      label: i18next.t("table:header.new", "New value"),
      _style: { width: "35%" },
    },
  ];
}
export function getMemberLink(memberData: IUser) {
  const fullName = memberData?.first_name && `${memberData.first_name} ${memberData.last_name}`;
  return <Link to={PATHS.MEMBERS.getDetailsIdPath!(memberData?.id)}>{fullName || memberData?.email}</Link>;
}
export function getChangesText({ member, type, id, action }: IHistory, checkId: string) {
  const memberLink = getMemberLink(member);
  switch (type) {
    case CHECK_HISTORY_CHANGES.CHECK:
      const tableHeaderText = i18next.t("card.description.checkChanged", "made changes");
      return { memberLink, tableHeaderText };
    case CHECK_HISTORY_CHANGES.RULE:
      const ruleLink = PATHS.CHECKS.getAttachedRuleDetailsPath!({ ruleId: id, attachedId: checkId });
      if (action === CHECK_HISTORY_CHANGES.ACTIONS.ADD) {
        return {
          ruleCreatorText: memberLink,
          ruleCreationText: (
            <Trans i18nKey="card.description.ruleCreated">
              <>
                created the <Link to={ruleLink}> rule_{{ id }}</Link>
              </>
            </Trans>
          ),
        };
      } else {
        return {
          memberLink,
          tableHeaderText: (
            <Trans i18nKey="card.description.ruleChanged">
              <>
                made changes in <Link to={ruleLink}> rule_{{ id }} </Link>
              </>
            </Trans>
          ),
        };
      }
    default:
      return {};
  }
}
