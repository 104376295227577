import { useActions } from "store/actions";
import { getProjectsRequest } from "services/api";
import { setProjectsAction } from "store/actions/user";

const useUpdateProjects = () => {
  const [setProjects] = useActions(setProjectsAction);
  return () => {
    return getProjectsRequest().then(({ data }) => setProjects(data));
  };
};

export default useUpdateProjects;
