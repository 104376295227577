import { store } from "store";
import { ACTIONS, SUBSCRIPTION_TYPES } from "../constants";
import { subscriptionTypeSelect } from "store/selectors/global";

const permissions = new Map<ACTIONS, SUBSCRIPTION_TYPES[]>();

permissions
  .set(ACTIONS.VIEW_SUBSCRIBE_BUTTON, [SUBSCRIPTION_TYPES.FREE])
  .set(ACTIONS.VIEW_PLANS_AND_PRICING, [SUBSCRIPTION_TYPES.PAID])
  .set(ACTIONS.VIEW_SUBSCRIPTION_INFO, [SUBSCRIPTION_TYPES.PAID])
  .set(ACTIONS.VIEW_CURRENT_BADGE_FOR_PAID_PLAN, [SUBSCRIPTION_TYPES.PAID]);

function hasSubscriptionStoreSubscribeWrapper() {
  let subscriptionType: null | SUBSCRIPTION_TYPES | undefined = null;
  store.subscribe(() => {
    const newSubscriptionType = subscriptionTypeSelect(store.getState());
    if (subscriptionType !== newSubscriptionType) subscriptionType = newSubscriptionType;
  });

  return function (action: ACTIONS) {
    if (!subscriptionType) {
      return false;
    }

    if (permissions.has(action)) {
      return permissions.get(action)!.includes(subscriptionType);
    }

    return false;
  };
}

export const hasSubscriptionPermission = hasSubscriptionStoreSubscribeWrapper();
