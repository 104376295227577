import { store } from "store";
import { ACTIONS, ROLES } from "../constants";
import { globalRoleSelect } from "store/selectors/user";

//Global role dependent permissions
const permissions = new Map<ACTIONS, ROLES[]>();

permissions
  //Viewer
  .set(ACTIONS.VIEW_PROJECTS, [ROLES.TENANT_OWNER, ROLES.ADMIN, ROLES.VIEWER])
  .set(ACTIONS.TAKE_CHECKS_TO_WORK, [ROLES.TENANT_OWNER, ROLES.ADMIN, ROLES.VIEWER])
  .set(ACTIONS.VIEW_CHECKS, [ROLES.TENANT_OWNER, ROLES.ADMIN, ROLES.VIEWER])
  .set(ACTIONS.VIEW_CHECKS_INCIDENTS, [ROLES.TENANT_OWNER, ROLES.ADMIN, ROLES.VIEWER])
  .set(ACTIONS.VIEW_CHECKS_DETAILS, [ROLES.TENANT_OWNER, ROLES.ADMIN, ROLES.VIEWER])

  .set(ACTIONS.VIEW_PROFILE, [ROLES.TENANT_OWNER, ROLES.ADMIN, ROLES.VIEWER])
  .set(ACTIONS.VIEW_BREADCRUMBS, [ROLES.TENANT_OWNER, ROLES.ADMIN, ROLES.VIEWER])
  .set(ACTIONS.VIEW_DOCUMENT_TITLE, [ROLES.TENANT_OWNER, ROLES.ADMIN, ROLES.VIEWER])

  //Admin
  .set(ACTIONS.VIEW_PROJECTS_DETAILS, [ROLES.TENANT_OWNER, ROLES.ADMIN])

  .set(ACTIONS.EDIT_CHECKS, [ROLES.TENANT_OWNER, ROLES.ADMIN])
  .set(ACTIONS.CREATE_CHECKS, [ROLES.TENANT_OWNER, ROLES.ADMIN])
  .set(ACTIONS.VIEW_CHECKS_HISTORY, [ROLES.TENANT_OWNER, ROLES.ADMIN])

  .set(ACTIONS.VIEW_CREATE_CHECKS_RULES_PAGE, [ROLES.TENANT_OWNER, ROLES.ADMIN])
  .set(ACTIONS.VIEW_EDIT_CHECKS_RULES_PAGE, [ROLES.TENANT_OWNER, ROLES.ADMIN])

  .set(ACTIONS.VIEW_INTEGRATIONS, [ROLES.TENANT_OWNER, ROLES.ADMIN])
  .set(ACTIONS.VIEW_DEFAULT_RULES, [ROLES.TENANT_OWNER, ROLES.ADMIN])
  .set(ACTIONS.VIEW_MEMBERS_NAV_ITEM, [ROLES.TENANT_OWNER, ROLES.ADMIN])
  .set(ACTIONS.VIEW_MEMBERS_LIST, [ROLES.TENANT_OWNER, ROLES.ADMIN])
  .set(ACTIONS.VIEW_MEMBER_DETAILS, [ROLES.TENANT_OWNER, ROLES.ADMIN])

  //Owner
  .set(ACTIONS.CREATE_PROJECTS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.EDIT_PROJECTS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.DELETE_PROJECTS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.VIEW_PROJECTS_ACTION_DROPDOWN, [ROLES.TENANT_OWNER])
  .set(ACTIONS.CREATE_DEFAULT_RULES, [ROLES.TENANT_OWNER])
  .set(ACTIONS.EDIT_DEFAULT_RULES, [ROLES.TENANT_OWNER])
  .set(ACTIONS.DELETE_DEFAULT_RULES, [ROLES.TENANT_OWNER])
  .set(ACTIONS.ENABLE_DISABLE_DEFAULT_RULES, [ROLES.TENANT_OWNER])
  .set(ACTIONS.CREATE_MEMBERS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.EDIT_MEMBERS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.DELETE_MEMBERS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.VIEW_MEMBERS_DIRECTORIES, [ROLES.TENANT_OWNER])
  .set(ACTIONS.CREATE_MEMBERS_DIRECTORIES, [ROLES.TENANT_OWNER])
  .set(ACTIONS.EDIT_MEMBERS_DIRECTORIES, [ROLES.TENANT_OWNER])
  .set(ACTIONS.DELETE_MEMBERS_DIRECTORIES, [ROLES.TENANT_OWNER])
  .set(ACTIONS.VIEW_MEMBERS_GROUPS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.CREATE_MEMBERS_GROUPS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.EDIT_MEMBERS_GROUPS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.DELETE_MEMBERS_GROUPS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.CREATE_INTEGRATIONS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.EDIT_INTEGRATIONS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.DELETE_INTEGRATIONS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.VIEW_SETTINGS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.EDIT_SETTINGS, [ROLES.TENANT_OWNER])
  .set(ACTIONS.VIEW_PLANS_AND_PRICING, [ROLES.TENANT_OWNER])
  .set(ACTIONS.EDIT_PLANS_AND_PRICING, [ROLES.TENANT_OWNER])
  .set(ACTIONS.VIEW_SUBSCRIBE_BUTTON, [ROLES.TENANT_OWNER]);

function globalRoleSubscribeWrapper() {
  let globalRole: ROLES | null = null;

  store.subscribe(() => {
    const newGlobalRole: ROLES | null = globalRoleSelect(store.getState());
    if (globalRole !== newGlobalRole) globalRole = newGlobalRole;
  });

  return function (action: ACTIONS) {
    if (!globalRole) {
      return false;
    }

    if (permissions.has(action)) {
      return permissions.get(action)!.includes(globalRole);
    }

    return false;
  };
}

export const hasGlobalPermission = globalRoleSubscribeWrapper();
