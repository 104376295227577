import { USER } from "./types";
import { ROLES } from "utils/constants";
import { setSubscriptionAction, setTelegramTokenStatusAction } from "./global";
import { getCompanyBilling, getProfileInfoRequest, getProjectsRequest, getTelegramAuthRequest } from "services/api";
import { IUser } from "../../components/ReactHookForm/_types";
import { IProject } from "../../models/types";
import { Dispatch } from "redux";

interface IUserData extends IUser {
  is_any_project_admin: boolean;
}

export function setUserInfoAction(userData: IUserData) {
  const { first_name: firstName, last_name: lastName, email, avatar, id } = userData;

  return {
    type: USER.SET_USER_INFO,
    payload: {
      email,
      firstName,
      lastName,
      avatar,
      id,
    },
  };
}

export function setProjectsAction(projects: IProject) {
  return {
    type: USER.SET_PROJECTS,
    payload: {
      projects,
    },
  };
}

export function setGlobalRoleAction(userData: IUserData) {
  const { is_superuser, is_any_project_admin } = userData;

  const globalRole = is_superuser ? ROLES.TENANT_OWNER : is_any_project_admin ? ROLES.ADMIN : ROLES.VIEWER;

  return {
    type: USER.SET_GLOBAL_ROLE,
    payload: {
      globalRole,
    },
  };
}

export function resetUserInfoAction() {
  return {
    type: USER.RESET_USER_STATE,
  };
}

export function setTelegramInfoAction(userData: IUser) {
  const { telegram_id: id, telegram_name: userName } = userData;

  return {
    type: USER.SET_TELEGRAM_INFO,
    payload: {
      id,
      userName,
    },
  };
}

export function setAuthorizedUserInfo() {
  return async (dispatch: Dispatch) => {
    const { data: userData } = await getProfileInfoRequest();
    const { data: projects } = await getProjectsRequest();
    const {
      data: { telegram_bot },
    } = await getTelegramAuthRequest();

    dispatch(setUserInfoAction(userData));
    dispatch(setProjectsAction(projects));
    dispatch(setGlobalRoleAction(userData));
    dispatch(setTelegramInfoAction(userData));
    dispatch(setTelegramTokenStatusAction(!!telegram_bot));

    if (userData.is_superuser) {
      const companyBillingResponse = await getCompanyBilling();
      dispatch(setSubscriptionAction(!!companyBillingResponse.data.id));
    }
  };
}
