import React from "react";
import {PATHS} from "route-dictionary";
import {useTranslation} from "react-i18next";
import {Redirect, useHistory} from "react-router";
import {withErrorBoundary} from "components/ErrorBoundary";
import {ARButton, ARCard, ARCardBody, ARIcon, ARInputGroup} from "components/ARComponents";

const SubscribeSuccess = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const referrer = document.referrer;

  return (
    !referrer ? //prohibition of visiting this page by straight URL
      <Redirect to={PATHS.SUBSCRIBE.ROOT}/> :
      <ARCard className="SubscribeSuccess-card">
        <ARCardBody>
          <div className="SubscribeSuccessContent">
            <ARIcon className="SubscribeSuccessContent__icon" name="cilCheck" color="var(--success)" size="3xl"/>
            <div>
              <h4>{t("plans:subscriptionSuccess.header", "Success!")}</h4>
              <p>{t("plans:subscriptionSuccess.text", "Subscription has been successfully purchased")}</p>
            </div>
          </div>
          <ARInputGroup className="input-prepend">
            <ARButton
              color="info"
              block
              size="lg"
              onClick={() => history.push(PATHS.CHECKS.ROOT)}
            >
              {t("button.startChecking", "Start checking!")}
            </ARButton>
          </ARInputGroup>
        </ARCardBody>
      </ARCard>
  );
};

export default withErrorBoundary(SubscribeSuccess);