import { createSelector } from "reselect";
import { RootState } from "../store";
import { ROLES } from "utils/constants";

const userStoreSelect = (state: RootState) => state.user;

const selectProfileInfo = createSelector(userStoreSelect, (state) => state.info);

export const telegramInfoSelector = createSelector(userStoreSelect, (state) => state.telegram);

export const userIdSelector = createSelector(selectProfileInfo, (state) => state.id);

export const projectsSelector = createSelector(userStoreSelect, (state) => state.projects);

// export const adminProjectsSelector = createSelector(userStoreSelect, (state) =>
//   state.projects.filter((project: IProject) => project.is_user_project_admin)
// );

export const avatarSelector = createSelector(selectProfileInfo, (state) => state.avatar);

export const fullNameSelector = createSelector(
  selectProfileInfo,
  (state) => `${state.firstName || state.email} ${state.lastName || ""}`
);

export const globalRoleSelector = createSelector(userStoreSelect, (state) => state.globalRole);

export const globalRoleSelect = (state: RootState): ROLES | null => state.user.globalRole;
