import Error from "./Error";
import { useTranslation } from "react-i18next";
import { ARCard, ARCardBody } from "components/ARComponents";

const AuthorizedError404 = () => {
  const { t } = useTranslation();

  return (
    <ARCard>
      <ARCardBody>
        <Error error={{ status: 404, statusText: t("card.description.error.404", "Page not found") }} />
      </ARCardBody>
    </ARCard>
  );
};

export default AuthorizedError404;
