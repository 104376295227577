import i18next from "services/language/i18next";

/**
 * Returns price
 * @param {number} itemsAmount
 * @param {boolean} [hasDiscount=false]
 * @param {number} usualPrice - Price for one item without discount in cents
 * @param {number} discountPrice - Price for one item with discount in cents
 * @returns {number}
 */
export function getPrice({itemsAmount, hasDiscount = false, usualPrice, discountPrice}) {
  if (hasDiscount) {
    return +(itemsAmount * discountPrice).toFixed(2);
  }

  return +(itemsAmount * usualPrice).toFixed(2);
}

/**
 * Returns translated payment period
 * @param {boolean} hasDiscount
 * @returns {string}
 */
export function getPaymentPeriod(hasDiscount) {
  const month = i18next.t("plans:paymentPeriod.month", "month");
  const year = i18next.t("plans:paymentPeriod.year", "year");

  return hasDiscount ? year : month;
}