import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ARButton, ARInputGroup } from "components/ARComponents";
import { IError } from "../../store/reducers/globalTypes";

interface IErrorProps {
  error: IError;
}

const Error = ({ error }: IErrorProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div className="clearfix">
        <h1 className="float-left display-3 mr-4">{error.status}</h1>
        <h4 className="pt-3">{t("card.title.error", "Houston, we have a problem!")}</h4>
        <p className="text-muted float-left">{error.statusText}</p>
      </div>
      <ARInputGroup className="input-prepend">
        <ARButton color="info" block size="lg" onClick={() => history.goBack()}>
          {t("button.back", "Back")}
        </ARButton>
      </ARInputGroup>
    </>
  );
};

export default withErrorBoundary(Error);
