import { useTranslation } from "react-i18next";
import useARAvatar from "components/ReactHookForm/hooks/useARAvatar";
import { ARButton, ARCol, ARIcon, ARImg, ARInputFile, ARRow } from "components/ARComponents";
import { Control } from "components/ReactHookForm/_types";
import { ClassName } from "models/types";

interface IAvatarRowProps {
  name: string;
  control: Control;
  className: ClassName;
  headerText: string;
  isAvatarUploadAvailable?: boolean;
  isSubmittingField?: (value: unknown) => boolean;
}

const AvatarRow = (props: IAvatarRowProps) => {
  const { t } = useTranslation();
  const { name, control, className, headerText, isAvatarUploadAvailable = true } = props;
  const { avatarState, onUploadImageClick, onUploadImage, onDeleteUploadedImage } = useARAvatar({ control, name });

  return (
    <ARRow className={`${className} ARAvatarRow`}>
      <ARCol className="ARAvatarRow__column">
        <div className="user-info-content">
          <ARImg
            className="user-info-content__image"
            src={avatarState.src}
            alt={"Avatar"}
            shape="rounded-circle"
            width={60}
            height={60}
            placeholderColor={"lightgrey"}
          />
          <h1 className="user-info-content__text">{headerText}</h1>
        </div>
        {isAvatarUploadAvailable && (
          <div className="upload-avatar-content">
            {avatarState.name && (
              <div className="upload-avatar-content__image-info">
                <ARButton className="upload-avatar-content__delete-button" onClick={onDeleteUploadedImage}>
                  <ARIcon name="cilTrash" className="upload-avatar-content__delete-button-icon" />
                </ARButton>
                <span className="upload-avatar-content__text">{avatarState.name}</span>
              </div>
            )}
            <ARButton
              className="upload-avatar-content__upload-button"
              color="dark"
              variant="outline"
              onClick={onUploadImageClick}
            >
              {t("form:button.uploadAvatar", "Upload user image")}
            </ARButton>
            <ARInputFile
              className="upload-avatar-content__input"
              type="file"
              value=""
              accept=".jpg, .jpeg, .png"
              onChange={onUploadImage}
            />
          </div>
        )}
      </ARCol>
    </ARRow>
  );
};

export default AvatarRow;
