import { instance, requestPromiseWrapper, requestWithSpinnerNoAlertErrorsPromiseWrapper } from "./instance";
import { ICompanySettings, IGetCompanySettings } from "./types";

export function getCompanySettingsRequest(): Promise<IGetCompanySettings> {
  return requestPromiseWrapper(instance.get("/company-settings/"));
}

export function updateCompanySettingsRequest(newSettingsInfo: Partial<ICompanySettings>) {
  return requestWithSpinnerNoAlertErrorsPromiseWrapper(instance.put("/company-settings/", newSettingsInfo));
}
