import i18next from "services/language/i18next";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";

export enum MemberKeys {
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  PHONE = "phone",
  EMAIL = "email",
  DIRECTORY_NAME = "member_directory_name",
  AVATAR = "avatar",
}

type MemberKeysFields = {[key in MemberKeys]: string};

export interface IMember extends MemberKeysFields {
  id: number;
  is_superuser: boolean;
  telegram_id: string;
  telegram_name: string;
}

const getTableColumnHeaders = () =>
  [
    {
      key: MemberKeys.FIRST_NAME,
      label: i18next.t("table:header.firstName", "First Name"),
      _style: { width: "20%" },
      isShown: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST),
    },
    {
      key: MemberKeys.LAST_NAME,
      label: i18next.t("table:header.lastName", "Last Name"),
      _style: { width: "20%" },
      isShown: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST),
    },
    {
      key: MemberKeys.PHONE,
      label: i18next.t("table:header.phone", "Phone"),
      _style: { width: "20%" },
      sorter: false,
      isShown: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST),
    },
    {
      key: MemberKeys.EMAIL,
      label: i18next.t("table:header.email", "Email"),
      _style: { width: "20%" },
      sorter: false,
      isShown: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST),
    },
    {
      key: MemberKeys.DIRECTORY_NAME,
      label: i18next.t("table:header.directory", "Directory"),
      _style: { width: "20%" },
      isShown: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST),
    },
  ].filter((item) => item.isShown);

const getTableFilterText = () => ({
  label: i18next.t("table:filter.label", "Search:"),
  placeholder: i18next.t("table:filter.placeholder.members", "Search for members"),
  external: true,
});

export { getTableColumnHeaders, getTableFilterText };
