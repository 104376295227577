import i18next from "services/language/i18next";
import { TABLE_ITEMS_PER_PAGE_OPTIONS } from "./constants";

/**
 * Returns full name for the passed value (or returns passed value if match haven't been found)
 * @param type - String that matches a "value" prop in matchingObjArray
 * @param arrOfObj - Contains a "name" prop for the passed type
 * @returns - Full name of the passed type (or passed type if match haven't been found)
 */

// TODO move arrOfObj to check.model.js
export function getFullNameFromType(
  type: string,
  arrOfObj: Array<{
    name: string;
    value: string;
    isUsed?: boolean;
  }>
) {
  const foundObj = arrOfObj.find((matchingObj) => matchingObj.value?.toLowerCase() === type.toLowerCase());

  if (foundObj) {
    return foundObj.name;
  }

  return type;
}

export function getTableNoItemsView() {
  return {
    noResults: i18next.t("noData.table.noResults", "No filtering results"),
    noItems: i18next.t("noData.table.noItems", "No items"),
  };
}

export function setDocumentTitle(title: string) {
  document.title = `Alertrules - ${title}`;
}

export function getAllProjectsSelectItem() {
  return { id: "", name: i18next.t("table:projectSelector.all", "All") };
}

export function getItemsPerPageSelectValues() {
  return {
    label: i18next.t("table:itemsAmount", "items per page:"),
    values: TABLE_ITEMS_PER_PAGE_OPTIONS,
  };
}

export function validateUrl(string: string): URL | boolean {
  try {
    new URL(string);
  } catch {
    return false;
  }
  return true;
}

/**
 * Creates an array of id's (for members_to_call and telegram_chats)
 * @param arrayOfItems - Array of objects which content the "id" prop
 * @returns -  The array of id's for sending it to the server
 */

export function getArrayOfId<T extends { id: unknown }[]>(arrayOfItems: T) {
  const arrayItemsIsNotObjects = !!arrayOfItems.find((object) => typeof object !== "object");

  if (arrayItemsIsNotObjects) {
    return arrayOfItems;
  }
  return arrayOfItems.map((object) => object.id);
}
