import React from "react";
import {useTranslation} from "react-i18next";
import CurrentPlanBadge from "../CurrentPlanBadge";
import {getFreeBenefitsArr} from "../Subscribe.model";
import {withErrorBoundary} from "components/ErrorBoundary";
import {ACTIONS, hasSubscriptionPermission} from "utils/user-access";
import {ARCard, ARCardBody, ARCardHeader, ARIcon} from "components/ARComponents";

const FreePlan = (props) => {
  const {className} = props;
  const {t} = useTranslation();
  const freeBenefitsArr = getFreeBenefitsArr();

  return (
    <ARCard className={`FreePlan-card ${className}`}>
      <ARCardHeader className="FreePlan-card__header">
        <h4 className="card-header-text">
          {t("card.title.freePlan", "Free")}
        </h4>
        {
          !hasSubscriptionPermission(ACTIONS.VIEW_CURRENT_BADGE_FOR_PAID_PLAN) &&
          <CurrentPlanBadge/>
        }
      </ARCardHeader>
      <ARCardBody className="FreePlan-card__body">
        <h3>{t("plans:freePlan.price", "Free")}</h3>
        <ul className="FreePlan-card__list">
          {freeBenefitsArr.map((benefit) => {
            return (
              <li key={benefit}>
                <ARIcon name="cilCheckAlt" color="var(--info)"/> {benefit}
              </li>
            );
          })}
        </ul>
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(FreePlan);