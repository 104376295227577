import i18next from "i18next";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { isEqual as compareObjects } from "lodash";
import { Watch } from "../_types";

type usePreventTransitionProps = {
  isDirty?: boolean;
  watch?: Watch;
};

const usePreventTransition = ({ isDirty, watch }: usePreventTransitionProps) => {
  const [hasBlocking, setHasBlocking] = useState(false);
  const [defaultValues, setDefaultValues] = useState((watch && watch()) || {});
  const history = useHistory();

  const formValues = watch && watch();

  /**
   * async to provide redirect after set hasBlocking to {false}, it's need to enable redirect on <PromptComponent/>
   */
  const redirect = async (route: string) => {
    await setHasBlocking(false);
    history.push(route);
  };

  /** update blocking status */
  useEffect(() => {
    if (isDirty !== undefined) {
      setHasBlocking(isDirty);
    } else {
      const isEqual = compareObjects(formValues, defaultValues);
      setHasBlocking(!isEqual);
    }
  }, [isDirty, formValues, defaultValues]);

  const PromptComponent = () => {
    return (
      <Prompt
        when={hasBlocking}
        message={i18next.t(
          "modal:message.transitionConfirm",
          "Changes weren't saved. Are you sure you want to leave the page?"
        )}
      />
    );
  };

  return {
    Prompt: PromptComponent,
    redirect,
    setDefaultValues,
    hasBlocking,
  };
};

export default usePreventTransition;
