import i18next from "i18next";
import { restorePasswordRequest } from "services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { showNotification } from "utils/actionWrappers";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { forgotPasswordFormSchema } from "components/ReactHookForm/schemes";
import { useFormWithCustomError } from "components/ReactHookForm/hooks";
import { AxiosError } from "axios";

interface IPasswordForm {
  email: string;
}

const useForgotPasswordForm = () => {
  const { handleSubmit, reset, control, setError } = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(forgotPasswordFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const onFormSubmit = ({ email }: IPasswordForm) => {
    restorePasswordRequest(email)
      .then(async () => {
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t(
            "notification:message.recovery.password",
            "Password recovery instructions have been sent to your email."
          ),
          "success"
        );
        await reset({ email });
      })
      .catch(async (error: AxiosError) => {
        if (error.response?.status === 404) {
          setError(
            "email",
            {
              type: "FieldError",
              message: MESSAGES.EMAIL_NOT_EXIST,
            },
            { shouldFocus: true }
          );
        } else {
          showNotification(
            i18next.t("notification:title.error", "Something went wrong!"),
            Object.values(error.response?.data).join(" \n"),
            "danger"
          );
          await reset({ email });
        }
      });
  };

  return {
    onFormSubmit,
    form: {
      control,
      handleSubmit,
      setFormError: setError,
    },
  };
};

export default useForgotPasswordForm;
