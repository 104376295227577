import {useCallback, useEffect, useState} from "react";
import {getProratedPrice} from "services/api";

const useRecalculatedPrice = ({checksAmount, selectedPlan, isCurrentSubscriptionAmount}) => {
  const [payment, setPayment] = useState({
    realPayment: 0,
    stripePayment: 0,
    totalPayment: 0
  });

  const recalculatePrice = useCallback(() => {
    const recalculationParams = {
      quantity: `${checksAmount}`,
      price_id: selectedPlan.id || ""
    };
    const recalculationQuery = new URLSearchParams(recalculationParams).toString();

    return getProratedPrice(recalculationQuery)
      .then(({data}) => {
        const totalPayment = data.total;
        const realPayment = data.amount_due;
        const stripePayment = totalPayment - realPayment;

        return {realPayment, stripePayment, totalPayment};
      });
  }, [checksAmount, selectedPlan.id]);

  useEffect(() => {
    let cancelled = false;
    if (isCurrentSubscriptionAmount) {
      setPayment({
        realPayment: 0,
        stripePayment: 0,
        totalPayment: 0
      });
      return () => cancelled = true;
    }

    recalculatePrice()
      .then(({realPayment, stripePayment, totalPayment}) => {
        if (!cancelled) {
          setPayment({
            realPayment,
            stripePayment,
            totalPayment
          });
        }
      });

    return () => cancelled = true;
  }, [isCurrentSubscriptionAmount, recalculatePrice]);

  return payment;
};

export default useRecalculatedPrice;