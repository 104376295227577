export const getCardDataFromCompanyBilling = (companyBillingObject) => {
  return {
    id: companyBillingObject.id,
    last4: companyBillingObject.card_last4,
    expMonth: companyBillingObject.card_exp_month,
    expYear: companyBillingObject.card_exp_year,
    invoiceDate: companyBillingObject.next_invoice_date,
    invoiceAmount: companyBillingObject.next_invoice_amount,
    checkLimit: companyBillingObject.company?.check_limit,
    stripeBalance: companyBillingObject.customer_balance,
    subscriptionEnd: companyBillingObject.subscription_end,
  };
};