import { ERR_MESSAGES } from "utils/constants";
import camelCaseItemNames from "camelcase-keys";
import { useEffect, useRef, useState } from "react";
import { intervalRefObj } from "../Directories.model";
import { cancelSource, getDirectoriesRequest } from "services/api";
import { IMemberDirectoryElement } from "services/api/types";

const useDirectories = () => {
  const [directoriesData, setDirectoriesData] = useState<IMemberDirectoryElement[]>([]);
  const intervalRef = useRef({ ...intervalRefObj });

  const getDirectories = async () => {
    const directoriesResponse = await getDirectoriesRequest();
    setDirectoriesData(directoriesResponse.data.map((directory) => camelCaseItemNames(directory)));
    return directoriesResponse;
  };

  const updateDirectories = async () => {
    const directoriesResponse = await getDirectories();
    intervalRef.current.checkForClearInterval(directoriesResponse.data);
  };

  useEffect(() => {
    getDirectories();
    const clearDirectoriesInterval = intervalRef.current.clearDirectoriesInterval.bind(intervalRef.current);

    return () => {
      cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
      clearDirectoriesInterval();
    };
  }, []);

  return { intervalRef, directoriesData, updateDirectories, getDirectories };
};

export default useDirectories;
