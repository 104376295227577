import { alertErrors, instance, requestPromiseWrapper, setShowingSpinner } from "./instance";
import { Email, Token } from "./types";

export function authRequest(email: string, password: string, isRememberMe: boolean) {
  delete instance.defaults.headers.common["Authorization"];
  return setShowingSpinner(
    instance.post(
      "/login",
      {
        email,
        password,
        keep_logged_in: isRememberMe,
      },
      {
        headers: { Authorization: null },
      }
    )
  );
}

export function logoutRequest() {
  return requestPromiseWrapper(instance.get("/session_logout"));
}

export function newPasswordRequest(email: Email, token: Token, password: string) {
  return setShowingSpinner(instance.post("/new-password", { email, token, password }));
}

export function companySignUpRequest(name: string) {
  return setShowingSpinner(alertErrors(instance.post("/company/", { name })));
}

export function userSignUpRequest(email: Email, password: string) {
  return requestPromiseWrapper(instance.post("/members", { email, password }));
}

export function restorePasswordRequest(email: Email) {
  return setShowingSpinner(instance.post("/restore-password", { email }));
}

export function getTenantInfoRequest(domain: string | null = null) {
  return instance.get(`/domain/${domain ? `?domain=${domain}` : ""}`, { headers: { Authorization: null } }).then(
    (result) => {
      return {
        isPublicTenant: !!result.data.is_public_tenant,
        isExist: true,
        error: null,
        hasAdmin: !!result.data.has_admin,
      };
    },
    (error) => {
      return {
        isPublicTenant: false,
        isExist: false,
        error: error.response || { status: 418, statusText: error.message },
      };
    }
  );
}
