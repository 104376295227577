import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import { ARButton, ARCol, ARRow } from "components/ARComponents";

const License = () => {
  return (
    <>
      <h1>Terms of the service</h1>
      <div className="License__text-wrapper">
        <h3>End-User License Agreement (EULA) of Alertrules</h3>
        <p>This End-User License Agreement ("EULA") is a legal agreement between you and Oleg Bogumirski</p>
        <p>
          This EULA agreement governs your acquisition and use of our Alertrules software ("Software") directly from
          Oleg Bogumirski or indirectly through a Oleg Bogumirski authorized reseller or distributor (a "Reseller").
        </p>
        <p>
          Please read this EULA agreement carefully before completing the installation process and using the Alertrules
          software. It provides a license to use the Alertrules software and contains warranty information and liability
          disclaimers.
        </p>
        <p>
          If you register for a free trial of the Alertrules software, this EULA agreement will also govern that trial.
          By clicking "accept" or installing and/or using the Alertrules software, you are confirming your acceptance of
          the Software and agreeing to become bound by the terms of this EULA agreement.
        </p>
        <p>
          If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that
          you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not
          have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not
          install or use the Software, and you must not accept this EULA agreement.
        </p>
        <p>
          This EULA agreement shall apply only to the Software supplied by Oleg Bogumirski herewith regardless of
          whether other software is referred to or described herein. The terms also apply to any Oleg Bogumirski
          updates, supplements, Internet-based services, and support services for the Software, unless other terms
          accompany those items on delivery. If so, those terms apply. This EULA was created by EULA Template for
          Alertrules.
        </p>
        <h3>License Grant</h3>
        <p>
          Oleg Bogumirski hereby grants you a personal, non-transferable, non-exclusive licence to use the Alertrules
          software on your devices in accordance with the terms of this EULA agreement.
        </p>
        <p>
          You are permitted to load the Alertrules software (for example a PC, laptop, mobile or tablet) under your
          control. You are responsible for ensuring your device meets the minimum requirements of the Alertrules
          software.
        </p>
        <p>You are not permitted to:</p>
        <ul>
          <li>
            Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit
            the whole or any part of the Software to be combined with or become incorporated in any other software, nor
            decompile, disassemble or reverse engineer the Software or attempt to do any such things
          </li>
          <li>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose</li>
          <li>Allow any third party to use the Software on behalf of or for the benefit of any third party</li>
          <li>Use the Software in any way which breaches any applicable local, national or international law</li>
          <li>Use the Software for any purpose that Oleg Bogumirski considers is a breach of this EULA agreement</li>
        </ul>
        <h3>Intellectual Property and Ownership</h3>
        <p>
          Oleg Bogumirski shall at all times retain ownership of the Software as originally downloaded by you and all
          subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property
          rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the
          property of Oleg Bogumirski.
        </p>
        <p>Oleg Bogumirski reserves the right to grant licences to use the Software to third parties.</p>
        <h3>Termination</h3>
        <p>
          This EULA agreement is effective from the date you first use the Software and shall continue until terminated.
          You may terminate it at any time upon written notice to Oleg Bogumirski.
        </p>
        <p>
          It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such
          termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all
          access and use of the Software. The provisions that by their nature continue and survive will survive any
          termination of this EULA agreement.
        </p>
        <h3>Governing Law</h3>
        <p>
          This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be
          governed by and construed in accordance with the laws of ru.
        </p>
        <h3>Payments</h3>
        <p>We use third-party service (Stripe) to process payments for any paid products and services.</p>
        <p>
          We won’t store or collect your payment card details. Any card details you give will go straight to our
          third-party payment processor Stripe who use your personal information according to their Privacy Policy. To
          ensure your payment information is handled securely, they adhere to PCI-DSS standards, managed by the PCI
          Security Standards Council – a collaboration between brands like Visa, Mastercard, American Express and
          Discover.
        </p>
        <p>
          Read our processors’ terms and privacy policies:
          <br />
          <a href="https://stripe.com/privacy" target="_blank" rel="noreferrer">
            https://stripe.com/privacy
          </a>
          <br />
          <a href="https://stripe.com/checkout/legal" target="_blank" rel="noreferrer">
            https://stripe.com/checkout/legal
          </a>
        </p>
      </div>
      <ARRow>
        <ARCol xs={12} className="card-column-buttons">
          <ARButton tag={Link} to={PATHS.SIGN_UP.ROOT} color="link" className="card-column-buttons__button">
            &larr; back to sign up
          </ARButton>
        </ARCol>
      </ARRow>
    </>
  );
};

export default License;
