import { PATHS } from "route-dictionary";
import { useHistory } from "react-router-dom";
import { useIntegrationsData } from "./hooks";
import { useTranslation } from "react-i18next";
import AddButtonRow from "components/AddButtonRow";
import { withErrorBoundary } from "components/ErrorBoundary";
import { getFullNameFromType, getTableNoItemsView } from "utils";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";
import { getTableColumnHeaders, integrationsTypes } from "./Integrations.model";
import { ARCard, ARCardBody, ARCardHeader, ARDataTable } from "components/ARComponents";
import { Iintegration } from "pages/checks/_types";

const Integrations = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { loading, integrationsData } = useIntegrationsData();

  const getClickAction = (id: number) =>
    hasGlobalPermission(ACTIONS.EDIT_INTEGRATIONS) && history.push(PATHS.INTEGRATIONS.getDetailsIdPath!(id));

  return (
    <ARCard className="Integrations-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.integrations", "Integrations")}</h1>
      </ARCardHeader>
      <ARCardBody>
        <ARDataTable
          addTableClasses="Integrations-card__integrations-table integrations-table"
          items={integrationsData}
          fields={getTableColumnHeaders()}
          noItemsView={getTableNoItemsView()}
          overTableSlot={
            <AddButtonRow
              path={PATHS.INTEGRATIONS.ADD}
              hasAddPermission={hasGlobalPermission(ACTIONS.CREATE_INTEGRATIONS)}
            />
          }
          hover
          striped
          border
          loading={loading}
          clickableRows={hasGlobalPermission(ACTIONS.EDIT_INTEGRATIONS) || undefined}
          onRowClick={(item: Iintegration) => getClickAction(item.id)}
          scopedSlots={{
            name: hasGlobalPermission(ACTIONS.VIEW_INTEGRATIONS)
              ? (item: Iintegration) => <td className="integrations-table__cell">{item.name || ""}</td>
              : null,
            type: hasGlobalPermission(ACTIONS.VIEW_INTEGRATIONS)
              ? (item: Iintegration) => (
                  <td className="integrations-table__cell">
                    {getFullNameFromType(item.type, integrationsTypes) || ""}
                  </td>
                )
              : null,
          }}
        />
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(Integrations);
