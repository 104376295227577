import { useEffect, useState } from "react";
import { ERR_MESSAGES } from "utils/constants";
import { checksRulesInitialDataState } from "../Rules.model";
import { CancelToken, getMembersRequest, getTelegramChatsRequest, groupCancelSource } from "services/api";

const useChecksRulesData = () => {
  const [checksRulesData, setChecksRulesData] = useState(checksRulesInitialDataState);

  useEffect(() => {
    groupCancelSource.value = CancelToken.source();

    Promise.all([getMembersRequest(), getTelegramChatsRequest()]).then(([membersResponse, telegramChatsResponse]) => {
      setChecksRulesData({
        members: membersResponse.data,
        telegramChats: telegramChatsResponse.data,
      });
    });

    return () => {
      groupCancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
      groupCancelSource.value = null;
    };
  }, []);

  return {
    members: checksRulesData.members,
    telegramChats: checksRulesData.telegramChats,
  };
};

export default useChecksRulesData;
