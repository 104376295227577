import React from "react";
import i18next from "i18next";
import { getMemberText, getPhoneNumberText, getTelegramChatText } from "../RulesComponents.model";
import { IUser, IChat, IItemObject } from "components/ReactHookForm/_types";

import { Day } from "../_types";

const DayTypes = {
  i: ["rule.time.day.any", "any day"],
  w: ["rule.time.day.weekday", "every weekday"],
  h: ["rule.time.day.weekend", "every weekend"],
  _default: ["unknown"],
};

export enum ElementName {
  members = "members",
  phones = "phones",
  chats = "chats",
  // default = "_default"
}

type ElementItem<T extends ElementName> = T extends ElementName.members
  ? IUser
  : T extends ElementName.phones
  ? string
  : IChat;

// type ToStringSignature = (item: object) => string

// const toStringConverter: ToStringSignature = (item) => item.toString();

export function getDayType(day: Day) {
  if (DayTypes[day]) return i18next.t(DayTypes[day][0], DayTypes[day][1]);
  return i18next.t(DayTypes["_default"][0]);
}

export function getStringFromArray(array: ElementItem<ElementName>[], name: ElementName) {
  if (!array.length) {
    return `there are no ${name} yet`;
  }

  // TODO: make it check somehow if item is proper type and handle error otherwise
  const convert = (item: ElementItem<ElementName>): IItemObject => {
    switch (name) {
      case ElementName.members:
        /*@ts-ignore*/
        return getMemberText(item as IUser);
      case ElementName.phones:
        return getPhoneNumberText(item as string);
      case ElementName.chats:
        return getTelegramChatText(item as IChat);
    }
  };

  return array.map((element, index) => {
    return (
      <React.Fragment key={`${name}_${index}`}>
        {convert(element).jsx}
        {index < array.length - 1 && ", "}
      </React.Fragment>
    );
  });
}

export function compareElements(element: IUser | IChat, fullElement: IUser | IChat) {
  return typeof element === "object" ? element.id === fullElement.id : element === fullElement.id;
}

/**
 * Converts time to the word format.
 *
 * @param firstWord First word before time.
 * @param time Time in the format: d hh:mm:ss.
 * @returns Generated string.
 */
export function convertTimeToWordTime(firstWord: "every" | "after", time: string) {
  function getS(str: string, type: string) {
    return i18next.t(`rule.time.${firstWord}.${type}`, { count: +str });
  }

  const splitTime = time.split(" ");

  if (splitTime[0] === "00:00:00") {
    return i18next.t("rule.time.immediately", "immediately");
  }

  const timeHasDays = splitTime.length > 1;
  const hms = timeHasDays ? splitTime[1].split(":") : splitTime[0].split(":");

  const days = !timeHasDays ? "" : getS(splitTime[0], "day");
  const hours = hms[0] === "00" ? "" : getS(hms[0], "hour");
  const minutes = hms[1] === "00" ? "" : getS(hms[1], "minute");
  const seconds = hms[2] === "00" ? "" : getS(hms[2], "second");
  const timeArray = [days, hours, minutes, seconds];

  const count = Number([timeHasDays ? splitTime[0] : 0, ...hms].find((element) => +element > 0));
  const before = i18next.t(`rule.time.${firstWord}.${firstWord}`, {
    count: isNaN(count) ? undefined : count,
  });

  return `${before} ${timeArray.join(" ").trim()}`;
}

export function restoreArray<T extends IUser | IChat>(
  compare: (element: T, fullElement: T) => boolean,
  array: T[],
  fullArray?: T[]
) {
  if (!fullArray) {
    return array || [];
  }

  return fullArray.filter((fullElement) => array.some((element) => compare(element, fullElement)));
}
