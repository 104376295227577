import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import { Ref } from "react-hook-form";

interface IARPhoneInputProps extends PhoneInputProps {
  numberInputRef: Ref;
}

const ARPhoneInput = (props: IARPhoneInputProps) => {
  return <PhoneInput {...props} />;
};

export default ARPhoneInput;
