import { Control } from "components/ReactHookForm/_types";
import React from "react";

interface IARModalWrapperProps {
  control?: Control;
  children: React.ReactNode[];
}

const ARModalWrapper = (props: IARModalWrapperProps) => {
  const { control, children } = props;
  const elemProps = { control };

  return <>{children.map((child) => React.isValidElement(child) && React.cloneElement(child, elemProps))}</>;
};

export default ARModalWrapper;
