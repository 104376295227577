import React, { useState } from "react";
import classNames from "classnames";
import ValidationFeedback from "../../ValidationFeedback";
import {
  ARIcon,
  ARInputGroup,
  ARInputGroupAppend,
  ARInputGroupPrepend,
  ARInputGroupText,
} from "components/ARComponents";
import { ICustomError } from "../../_types";
import { freeSet } from "@coreui/icons";
import { ClassName } from "models/types";

export interface IRHFPrependAppendProps {
  prependText?: string;
  className?: ClassName;
  appendText?: string;
  iconName?: keyof typeof freeSet;
}

const RHFPrependAppend: React.FC<IRHFPrependAppendProps> = (props) => {
  const { children, className, iconName, prependText, appendText } = props;
  const [error, setError] = useState<ICustomError | undefined>(undefined);

  return (
    <ARInputGroup className={classNames(className)}>
      {(!!prependText || !!iconName) && (
        <ARInputGroupPrepend>
          <ARInputGroupText>
            {prependText}
            {iconName && <ARIcon name={iconName} />}
          </ARInputGroupText>
        </ARInputGroupPrepend>
      )}
      {React.isValidElement(children) && React.cloneElement(children, { setError })}
      {!!appendText && (
        <ARInputGroupAppend>
          <ARInputGroupText className="field_right-border-radius">{appendText}</ARInputGroupText>
        </ARInputGroupAppend>
      )}
      <ValidationFeedback error={error} />
    </ARInputGroup>
  );
};

export default RHFPrependAppend;
