import { ARButton } from "components/ARComponents";
import { ARButtonProps } from "components/ARComponents/_types";
import { withErrorBoundary } from "components/ErrorBoundary";

interface ICheckActionButtonProps extends ARButtonProps {
  text: string;
  checkId: number;
  onCheckActionButtonClick: (checkId: number, isEnabled?: boolean) => void;
  isEnabled?: boolean;
}

const CheckActionButton = (props: ICheckActionButtonProps) => {
  const { color, variant, text, className, checkId, onCheckActionButtonClick } = props;

  const isReturnBtn = text === "Return";

  return (
    <ARButton
      className={className}
      color={color}
      variant={variant}
      onClick={
        isReturnBtn ? () => onCheckActionButtonClick(checkId) : () => onCheckActionButtonClick(checkId)
      }
    >
      {text}
    </ARButton>
  );
};

export default withErrorBoundary(CheckActionButton);
