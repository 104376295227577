import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ARDropdown, ARDropdownItem, ARDropdownMenu, ARDropdownToggle, ARFlag } from "components/ARComponents";

const HeaderLanguageDropdown = () => {
  const { i18n } = useTranslation();
  const onLanguageClick = (language: string) => {
    i18n.changeLanguage(language);
  };

  const allLanguages = useMemo(() => {
    const allSupportLngs = i18n.options.supportedLngs;
    if (!allSupportLngs) return [];
    return process.env.NODE_ENV === "development" ? allSupportLngs : allSupportLngs.slice(0, -1);
  }, [i18n.options.supportedLngs]);

  return (
    <ARDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <ARDropdownToggle className="c-header-nav-link" caret>
        <ARFlag country={i18n.language} className="mr-2" useSVG />
        <span className="HeaderLanguageDropdown__short-language">{i18n.language.toUpperCase()}</span>
      </ARDropdownToggle>
      <ARDropdownMenu className="HeaderLanguageDropdown__dropdown-menu" placement="bottom">
        <ARDropdownItem className="HeaderLanguageDropdown__dropdown-item" key={i18n.language}>
          <ARFlag country={i18n.language} className="mr-2" useSVG />
          <span className="HeaderLanguageDropdown__short-language">{i18n.language.toUpperCase()}</span>
        </ARDropdownItem>
        {allLanguages.map(
          (langKey) =>
            langKey !== i18n.language && (
              <ARDropdownItem
                className="HeaderLanguageDropdown__dropdown-item"
                onClick={onLanguageClick.bind(null, langKey)}
                key={langKey}
              >
                <ARFlag country={langKey} className="mr-2" useSVG />
                <span className="HeaderLanguageDropdown__short-language">{langKey.toUpperCase()}</span>
              </ARDropdownItem>
            )
        )}
      </ARDropdownMenu>
    </ARDropdown>
  );
};

export default withErrorBoundary(HeaderLanguageDropdown);
