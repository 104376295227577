import classNames from "classnames";
import { to00 } from "utils/timeHandlers";
import React, { useEffect, useRef } from "react";
import { timeMask } from "../RHFTimeInput.model";
import { generateEvent } from "components/ReactHookForm/utils";

const hours = Array(24)
  .fill(0)
  .map((_, index) => to00(index));
const minutes = Array(60)
  .fill(0)
  .map((_, index) => to00(index));

interface ITimePickerProps {
  name: string;
  value: string;
  onChange: (event: React.SyntheticEvent) => void;
  show: boolean;
  placement: string;
  onBlur: (value: boolean) => void;
}

const TimePicker = ({ name, value, onChange, show, placement, onBlur }: ITimePickerProps) => {
  const timePickerRef = useRef<HTMLDivElement>(null);
  const minutesRef = useRef<HTMLUListElement>(null);
  const hoursRef = useRef<HTMLUListElement>(null);
  const currentMinuteRef = useRef<HTMLOListElement>(null);
  const currentHourRef = useRef<HTMLOListElement>(null);
  const [currentHour, currentMinute] = (value || timeMask).split(":");

  const onTimeClick = (hour: string, minute: string, event: React.SyntheticEvent) => {
    const newValue = hour + ":" + minute;
    const newEvent = generateEvent({ event, newValue });
    onChange && onChange(newEvent);
  };

  const onResetClick = (event: React.SyntheticEvent) => {
    const newValue = null;
    const newEvent = generateEvent({ event, newValue });
    onChange && onChange(newEvent);
  };

  const onClickOutside = React.useCallback(
    (event) => {
      if (show && timePickerRef.current && !timePickerRef.current.contains(event.target)) {
        onBlur(event);
      }
    },
    [show, onBlur]
  );

  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => document.removeEventListener("click", onClickOutside);
  });

  useEffect(() => {
    if (show) {
      if (minutesRef.current && currentMinuteRef.current) {
        minutesRef.current.scrollTo(0, currentMinuteRef.current.offsetHeight * minutes.indexOf(currentMinute));
      }

      if (hoursRef.current && currentHourRef.current) {
        hoursRef.current.scrollTo(0, currentHourRef.current.offsetHeight * hours.indexOf(currentHour));
      }
    }
  }, [show, currentMinuteRef, currentHourRef, currentMinute, currentHour]);

  return (
    <div
      className={classNames("TimePicker", {
        TimePicker_show: show,
        [`TimePicker_placement-${placement}`]: placement,
      })}
      ref={timePickerRef}
    >
      <div
        className={classNames("TimePicker__reset", { TimePicker__reset_selected: !value || value === timeMask })}
        onClick={onResetClick}
      >
        {timeMask}
      </div>
      <div className="TimePicker__container">
        <ul className="TimePicker__hours" ref={hoursRef}>
          {hours.map((hour, index) => (
            <ol
              className="TimePicker__hour-container"
              ref={currentHour === hour ? currentHourRef : null}
              key={`${name}_hour_${index}`}
              onClick={(event) => onTimeClick(hour, currentMinute, event)}
            >
              <div className={classNames("TimePicker__hour", { TimePicker__hour_selected: currentHour === hour })}>
                {hour}
              </div>
            </ol>
          ))}
        </ul>

        <ul className="TimePicker__minutes" ref={minutesRef}>
          {minutes.map((minute, index) => (
            <ol
              className="TimePicker__minute-container"
              ref={currentMinute === minute ? currentMinuteRef : null}
              key={`${name}_minute_${index}`}
              onClick={(event) => onTimeClick(currentHour, minute, event)}
            >
              <div
                className={classNames("TimePicker__minute", { TimePicker__minute_selected: currentMinute === minute })}
              >
                {minute}
              </div>
            </ol>
          ))}
        </ul>
      </div>
    </div>
  );
};

TimePicker.defaultProps = {
  placement: "right",
};

export default TimePicker;
