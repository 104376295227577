import AvatarRow from "../AvatarRow";
import MemberForm from "../MemberForm";
import { useMemberForm } from "../hooks";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import FormControlsButtons from "components/FormControlButtons";
import { ARCard, ARCardBody, ARCardFooter, ARCardHeader } from "components/ARComponents";

const MemberAdd = () => {
  const { t } = useTranslation();
  const { onFormReset, onFormSubmit, form, Prompt } = useMemberForm();

  return (
    <ARCard className="MemberAdd-card">
      <ARCardHeader>
        <AvatarRow
          name="avatar"
          control={form.control}
          className="MemberAdd-card__avatar-row"
          headerText={t("card.title.member.add", "Add member")}
          isSubmittingField={(value: unknown) => typeof value !== "string"}
        />
      </ARCardHeader>
      <ARCardBody>
        <Prompt />
        <MemberForm isAddMode formId="member_add_form" formConfig={form} onFormSubmit={onFormSubmit} />
      </ARCardBody>
      <ARCardFooter>
        <FormControlsButtons form="member_add_form" onCancelClick={onFormReset} />
      </ARCardFooter>
    </ARCard>
  );
};

export default withErrorBoundary(MemberAdd);
