import * as yup from "yup";
import { MESSAGES } from "./messages";
import { phone } from "../validators";
import { INTEGRATION_TYPES } from "utils/constants";

/** yup custom validators */
yup.addMethod(yup.string, "phone", phone);

const getIntegrationFormSchema = (isAddPage: boolean) =>
  yup.object().shape({
    name: yup.string().max(32, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    type: yup.string().required(MESSAGES.REQUIRED),
    token: yup.string().when("type", {
      is: (value: string) => [INTEGRATION_TYPES.JIRA, INTEGRATION_TYPES.TWILIO].includes(value),
      then: yup.string().required(MESSAGES.REQUIRED),
    }),
    url: yup.string().when("type", {
      is: (value: string) => value === INTEGRATION_TYPES.JIRA,
      then: yup.string().max(200, MESSAGES.MAX_LENGTH).url(MESSAGES.URL_INVALID_VALUE).required(MESSAGES.REQUIRED),
    }),
    api_key: yup.string().when("type", {
      is: (value: string) =>
        [INTEGRATION_TYPES.NEXMO, INTEGRATION_TYPES.SELECTEL, INTEGRATION_TYPES.DADATA].includes(value),
      then: yup.string().required(MESSAGES.REQUIRED),
    }),
    app_id: yup.string().when("type", {
      is: (value: string) => value === INTEGRATION_TYPES.NEXMO,
      then: yup.string().max(64, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    }),
    from_phone: yup.string().when("type", {
      is: (value: string) => value === INTEGRATION_TYPES.NEXMO,
      then: yup.string().phone(MESSAGES.PHONE_INVALID_VALUE).required(MESSAGES.REQUIRED),
    }),
    account_cid: yup.string().when("type", {
      is: (value: string) => value === INTEGRATION_TYPES.TWILIO,
      then: yup.string().max(64, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    }),
    login: yup.string().when("type", {
      is: (value: string) => value === INTEGRATION_TYPES.SMSC,
      then: yup.string().max(128, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    }),
    password: yup
      .string()
      .max(128, MESSAGES.MAX_LENGTH)
      .when("type", {
        is: (value: string) => value === INTEGRATION_TYPES.SMSC,
        then: yup.string().when("isAddPage", {
          is: () => isAddPage,
          then: yup
            .string()

            .required(MESSAGES.REQUIRED),
        }),
      }),
    secret_key: yup
      .string()
      .max(128, MESSAGES.MAX_LENGTH)
      .when("type", {
        is: (value: string) => value === INTEGRATION_TYPES.DADATA,
        then: yup.string().when("isAddPage", {
          is: () => isAddPage,
          then: yup.string().required(MESSAGES.REQUIRED),
        }),
      }),
  });

export default getIntegrationFormSchema;
