import classNames from "classnames";
import { PATHS } from "route-dictionary";
import { useSelector } from "react-redux";
import { useIncidentsData } from "./hooks";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { projectsSelector } from "store/selectors/user";
import { globalSelector } from "store/selectors/global";
import { getTableColumnHeaders } from "./Incidents.model";
import { withErrorBoundary } from "components/ErrorBoundary";
import IncidentsOverTableRow from "./IncidentsOverTableRow";
import TakeToWorkDropdown from "components/TakeToWorkDropdown";
import { getAllProjectsSelectItem, getTableNoItemsView } from "utils";
import ReturnButton from "components/ReturnButton";
import { ACTIONS, getProjectRole, hasGlobalPermission, hasProjectPermission } from "utils/user-access";
import { ARCard, ARCardBody, ARCardHeader, ARDataTable, ARPagination, ARStatusText } from "components/ARComponents";
import { ICheck } from "pages/checks/_types";
import { IProject } from "./../../models/types";

interface IItem { 
  check_log: number;
  created: string;
  fixed: string;
  id: number;
  is_paused: boolean;
  my_check: ICheck;
  rules_affected: number[];
  is_user_project_admin: boolean;
}

const Incidents = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isFetching } = useSelector(globalSelector);
  const { tableConfigObj, onReturnButtonClick, onTakeToWorkButtonClick, onSelectedProjectChange } = useIncidentsData();

  const { page, pages, tableItems, onPageChange, itemsPerPage, sorterValue, setSorterValue, setItemsPerPage } =
    tableConfigObj;

  const projects = useSelector(projectsSelector);
  const selectProjects = [getAllProjectsSelectItem(), ...projects];

  const hasViewChecksIncidentsPermission = hasGlobalPermission(ACTIONS.VIEW_CHECKS_INCIDENTS);

  const getDisplayingActionButton = (checkData: ICheck, incidentId: number) => {
    const { is_up, is_paused, is_enabled } = checkData;
    if (is_enabled && is_paused) {
      return <ReturnButton checkId={checkData.id} incidentId={incidentId} onReturnButtonClick={onReturnButtonClick} />;
    } else if (is_up === false && is_enabled) {
      return (
        <TakeToWorkDropdown
          checkId={checkData.id}
          incidentId={incidentId}
          pauses={checkData.pauses_nn}
          onTakeToWorkButtonClick={onTakeToWorkButtonClick}
        />
      );
    }
    return null;
  };

  const getClickAction = ({ id, is_user_project_admin }: Partial<ICheck>) => {
    hasProjectPermission(ACTIONS.VIEW_PROJECT_CHECKS_DETAILS, getProjectRole(is_user_project_admin!)) &&
      history.push(PATHS.CHECKS.getDetailsIdPath!(id));
  };

  return (
    <ARCard className="Incidents-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.incidents", "Incidents")}</h1>
      </ARCardHeader>
      <ARCardBody>
        <ARDataTable
          size="sm"
          addTableClasses="Incidents-card__incidents-table incidents-table"
          overTableSlot={
            <IncidentsOverTableRow
              className="incidents-table__over-table-row"
              projectSelectOptions={selectProjects}
              onSelectedProjectChange={onSelectedProjectChange}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={setItemsPerPage}
              disabled={isFetching}
            />
          }
          items={tableItems}
          fields={getTableColumnHeaders()}
          noItemsView={getTableNoItemsView()}
          loading={isFetching}
          hover
          striped
          border
          clickableRows={hasGlobalPermission(ACTIONS.VIEW_CHECKS_DETAILS) || undefined}
          sorter
          sorterValue={sorterValue}
          onSorterValueChange={setSorterValue}
          scopedSlots={{
            status: hasViewChecksIncidentsPermission
              ? ({ my_check }: { my_check: Partial<ICheck> }) => (
                  <td
                    className="incidents-table__cell incidents-table__cell_text-center"
                    onClick={() => getClickAction(my_check)}
                  >
                    {/*@ts-ignore*/}
                    <ARStatusText {...my_check} />
                  </td>
                )
              : null,
            name: hasViewChecksIncidentsPermission
              ? ({ my_check }: { my_check: ICheck }) => (
                  <td className="incidents-table__cell" onClick={() => getClickAction(my_check)}>
                    {my_check.name || ""}
                  </td>
                )
              : null,
            project: hasViewChecksIncidentsPermission
              ? ({ my_check }: { my_check: ICheck }) => (
                  <td className="incidents-table__cell" onClick={() => getClickAction(my_check)}>
                    {(my_check.project as IProject).name || ""}
                  </td>
                )
              : null,
            down: hasViewChecksIncidentsPermission
              ? (item: IItem) => (
                  <td className="incidents-table__cell" onClick={() => getClickAction(item.my_check)}>
                    {item.created || ""}
                  </td>
                )
              : null,
            up: hasViewChecksIncidentsPermission
              ? (item: IItem) => (
                  <td className="incidents-table__cell" onClick={() => getClickAction(item.my_check)}>
                    {item.fixed || ""}
                  </td>
                )
              : null,
            actions_column: hasGlobalPermission(ACTIONS.TAKE_CHECKS_TO_WORK)
              ? (item: IItem) => (
                  <td className="incidents-table__cell">
                    {hasProjectPermission(
                      ACTIONS.TAKE_PROJECT_CHECKS_TO_WORK,
                      getProjectRole(item.is_user_project_admin)
                    ) && getDisplayingActionButton(item.my_check, item.id)}
                  </td>
                )
              : null,
          }}
        />
        <ARPagination
          pages={pages}
          activePage={page}
          onActivePageChange={onPageChange}
          className={classNames("table-pagination", { "table-pagination_hidden": pages < 2 || isFetching })}
        />
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(Incidents);
