import { useGroupInfo } from "./index";
import { PATHS } from "route-dictionary";
import { useTranslation } from "react-i18next";
import { usePreventTransition } from "components/ReactHookForm/hooks";
import useFormModal from "components/ModalWindow/hooks/useFormModal";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { RHFElementsSelector } from "components/ReactHookForm/fields/custom";
import { getMemberText } from "components/RulesComponents/RulesComponents.model";
import { groupMemberAddModalFormSchema } from "components/ReactHookForm/schemes";
import { IGroupMembers } from "./useGroupInfo";
import { IMember } from "models/types";

const useGroupDetailsForm = () => {
  const { header, formControl, groupMembers, deleteGroup, updateGroupInfo, availableMembers } = useGroupInfo();

  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = formControl;
  const { redirect, Prompt } = usePreventTransition({ isDirty });
  const redirectToGroupsPage = () => redirect(PATHS.GROUPS.ROOT);

  const onFormSubmit = (formData: { name: string }) => {
    const groupInfo = {
      ...formData,
      members_ids: (groupMembers as IGroupMembers).membersIds,
    };

    if (isDirty) {
      updateGroupInfo(groupInfo)
        .then(() => {
          showNotification(
            t("notification:title.success", "Success!"),
            t("notification:message.updated.group", "Group has been updated."),
            "success"
          );
        })
        .then(redirectToGroupsPage);
    } else redirectToGroupsPage();
  };

  const onDeleteMemberClick = (id: number | string) => {
    const newGroupMembersIds = (groupMembers as IGroupMembers).membersIds.filter((memberId) => memberId !== id);
    updateGroupInfo({ members_ids: newGroupMembersIds }).then(() =>
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.deleted.memberFromGroup", "Member has been deleted from group."),
        "success"
      )
    );
  };

  const onDeleteGroupClick = () => {
    showModalWindow.confirm({
      message: t("modal:message.delete.group", "Are you sure you want to delete this group?"),
      color: "danger",
      onConfirm: () =>
        deleteGroup()
          .then(() => {
            showNotification(
              t("notification:title.success", "Success!"),
              t("notification:message.deleted.group", "Group has been deleted."),
              "success"
            );
          })
          .then(redirectToGroupsPage),
    });
  };

  const onFormReset = () => {
    if (isDirty) {
      showModalWindow.confirm({
        message: t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: redirectToGroupsPage,
      });
    } else redirectToGroupsPage();
  };

  const showFormModal = useFormModal(({ newMembers }: { newMembers?: IMember[] }) => {
    if (newMembers!.length) {
      const newGroupMembers = (groupMembers as IGroupMembers).membersIds.concat(newMembers!.map((member: IMember) => member.id));
      updateGroupInfo({ members_ids: newGroupMembers }).then(() =>
        showNotification(
          t("notification:title.success", "Success!"),
          t("notification:message.added.memberToGroup", { count: newMembers!.length }),
          "success"
        )
      );
    }
  });

  const showMembersModal = () => {
    showFormModal({
      title: t("modal:title.memberAdd", "Member add"),
      formId: "new-members-form",
      validationSchema: groupMemberAddModalFormSchema,
      defaultValues: { newMembers: [] },
      fields: (
        <RHFElementsSelector
          id="add_members"
          name="newMembers"
          items={availableMembers}
          getItemText={getMemberText}
        />
      ),
    });
  };

  return {
    Prompt,
    header,
    groupMembers,
    onFormReset,
    onFormSubmit,
    showMembersModal,
    onDeleteGroupClick,
    onDeleteMemberClick,
    formControl: {
      control,
      handleSubmit,
    },
  };
};

export default useGroupDetailsForm;
