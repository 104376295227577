import { FieldValues } from "react-hook-form";
import { PATHS } from "route-dictionary";
import { useQuery } from "utils/useQuery";
import { useActions } from "store/actions";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { loginAction } from "store/actions/auth";
import i18next from "services/language/i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginFormSchema } from "components/ReactHookForm/schemes";
import { AxiosError } from "axios";
import { useFormWithCustomError } from "../../../components/ReactHookForm/hooks";

interface ILoginForm extends FieldValues {
  email: string;
  password: string;
  remember_me: boolean;
}

const useLoginForm = () => {
  const query = useQuery();
  const error = query.get("error");
  const redirect = query.get("redirect");

  const history = useHistory();
  const [login] = useActions(loginAction);

  const [internalErrorText, setInternalErrorText] = useState("");
  const [externalErrorText, setExternalErrorText] = useState(error || "");

  const { handleSubmit, control, reset, watch, setError } = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(loginFormSchema),
    defaultValues: {
      email: "",
      password: "",
      remember_me: false,
    } as ILoginForm,
  });

  const form = watch();

  const onFormSubmit = async (formData: ILoginForm) => {
    setExternalErrorText("");
    const { email, password, remember_me } = formData;

    login(email, password, remember_me)
      .then(() => history.push(redirect || PATHS.CHECKS.ROOT))
      .catch(async (error: AxiosError) => {
        if (error.response && error.response.status === 500) {
          await reset();
          setInternalErrorText(
            i18next.t("notification:message.error.505", "Unfortunately the server is currently unavailable. Try later.")
          );
        } else if (error.response && error.response.status === 401) {
          setInternalErrorText(i18next.t("notification:message.error.credentials", "Wrong email or password!"));
          await reset({
            email: form.email,
            password: "",
            remember_me: form.remember_me,
          });
        } else {
          setInternalErrorText(error.message);
        }
      });
  };

  useEffect(() => {
    !!error && setExternalErrorText(error);
  }, [error]);

  return {
    onFormSubmit,
    externalErrorText,
    internalErrorText,
    form: {
      control,
      handleSubmit,
      setFormError: setError,
    },
  };
};

export default useLoginForm;
