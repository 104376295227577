import {LOCATIONS} from "utils/constants";

class RouteClass {
  constructor(root, withAdditionalRoutes = false) {
    this.ROOT = root;

    this.getRouteWithQueryParam = (queryParam, queryValue, root = this.ROOT) => {
      return `${root}?${queryParam}=${queryValue}`;
    };

    if (withAdditionalRoutes) {
      this.ADD = `${root}/add`;
      this.EDIT = `${root}/:id(\\d+)/edit`;
      this.DETAILS = `${root}/:id(\\d+)`;
      this.SUCCESS = `${root}/success`;
      this.HISTORY = `${root}/:id(\\d+)/history`;
      this.RULE_ADD = `${root}/:id(\\d+)/rule_add`;
      this.RULE_DETAILS = `${root}/:id(\\d+)/rule_:id(\\d+)`;

      this.getDetailsIdPath = (id) => {
        return `${root}/${id}`;
      };

      this.getEditIdPath = (id) => {
        return `${root}/${id}/edit`;
      };

      this.getHistoryIdPath = (id) => {
        return `${root}/${id}/history`;
      };

      this.getAttachedRuleAddPath = (id) => {
        return `${root}/${id}/rule_add`;
      };

      this.getAttachedRuleDetailsPath = ({attachedId, ruleId}) => {
        return `${root}/${attachedId}/rule_${ruleId}`;
      };
    }
  };
}

//TODO: don't forget to remove it when it'll become unnecessary
const isProductionMode = window.location.hostname.endsWith(LOCATIONS.PROD);

export const PATHS = {
  CHECKS: new RouteClass("/checks", true),
  RULES: new RouteClass("/rules", true),
  DIRECTORIES: new RouteClass("/directories", true),
  INCIDENTS: new RouteClass("/incidents"),
  SETTINGS: new RouteClass("/settings"),
  PROFILE: new RouteClass("/profile"),
  MEMBERS: new RouteClass("/members", true),
  INTEGRATIONS: new RouteClass("/integrations", true),
  AuthorizedError404: new RouteClass("/404"),
  NEW_PASSWORD: new RouteClass("/sessions/new-password"),
  FORGOT_PASSWORD: new RouteClass("/forgot_password"),
  SIGN_UP: new RouteClass("/sign_up"),
  LOGIN: new RouteClass("/login"),
  LICENSE: new RouteClass("/license"),
  SUBSCRIBE: new RouteClass(isProductionMode ? "/checks" : "/subscribe", true),
  PROJECTS: new RouteClass("/projects", true),
  GROUPS: new RouteClass("/groups", true)
};