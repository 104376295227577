import React, {useEffect} from "react";
import FreePlan from "./FreePlan";
import PaidPlan from "./PaidPlan";
import Switcher from "./Switcher";
import {useTranslation} from "react-i18next";
import {PlansContext} from "./subscribe-context";
import {getSubscriptionPlans} from "./Subscribe.model";
import {withErrorBoundary} from "components/ErrorBoundary";
import {CancelToken, groupCancelSource} from "services/api";
import {ERR_MESSAGES, SUBSCRIPTION_INFO} from "utils/constants";
import {useCompanyBilling, useDiscount, usePlans} from "./hooks";
import {ARCard, ARCardBody, ARCardHeader} from "components/ARComponents";

const Subscribe = () => {
  const {t} = useTranslation();
  const {hasDiscount, discountHandler} = useDiscount();
  const {companyBilling, updateCompanyBilling} = useCompanyBilling();
  const {selectedPlan, monthlyPrice, yearlyPrice} = usePlans(hasDiscount);

  const subscriptionPlans = getSubscriptionPlans(SUBSCRIPTION_INFO.CHECKS_AMOUNT_ARR);
  const plansContextProps = {
    hasDiscount,
    companyBilling,
    updateCompanyBilling,
    subscriptionPlans,
    selectedPlan,
    monthlyPrice,
    yearlyPrice
  };

  useEffect(() => {
    groupCancelSource.value = CancelToken.source();
    return () => {
      groupCancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
      groupCancelSource.value = null;
    };
  }, []);

  return (
    <ARCard className="Subscribe-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.subscribe", "Subscribe")}</h1>
      </ARCardHeader>
      <ARCardBody className="Subscribe-card-body">
        <Switcher
          className="Subscribe-card-body__discount-group"
          switcherId="discount"
          switcherText={t("plans:discount", "Get 15% discount annually")}
          switcherHandler={discountHandler}
        />
        <PlansContext.Provider value={plansContextProps}>
          <div className="Subscribe-card-body__plans-container">
            <FreePlan className="plan-card"/>
            <PaidPlan className="plan-card"/>
          </div>
        </PlansContext.Provider>
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(Subscribe);