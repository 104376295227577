import { useSelector } from "react-redux";
import { useActions } from "store/actions";
import { getCompanyBilling } from "services/api";
import { SUBSCRIPTION_TYPES } from "utils/constants";
import { useCallback, useEffect, useState } from "react";
import { setSubscriptionAction } from "store/actions/global";
import { subscriptionTypeSelector } from "store/selectors/global";

const useCompanyBilling = () => {
  const [companyBilling, setCompanyBilling] = useState({});
  const subscriptionType = useSelector(subscriptionTypeSelector);
  const [updateSubscriptionStatus] = useActions(setSubscriptionAction);
  const hasSubscription = subscriptionType === SUBSCRIPTION_TYPES.PAID;

  const updateCompanyBilling = useCallback(() => {
    getCompanyBilling().then(({ data }) => {
      setCompanyBilling(data);
      if (hasSubscription !== !!data.id) {
        updateSubscriptionStatus(!!data.id);
      }
    });
  }, [updateSubscriptionStatus, hasSubscription]);

  useEffect(() => updateCompanyBilling(), [updateCompanyBilling]);

  return { companyBilling, updateCompanyBilling };
};

export default useCompanyBilling;
