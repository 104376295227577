import { NOTIFICATION } from "./types";
import { ActionProps } from "../types";

export interface showNotificationActionProps {
  title: string;
  message: string;
  type: string;
}

export function showNotificationAction(title: string, message: string, type = "info"): ActionProps {
  return {
    type: NOTIFICATION.SHOW,
    payload: {
      title,
      message,
      type,
    },
  };
}

export function clearNotificationAction() {
  return {
    type: NOTIFICATION.CLEAR,
  };
}

export function hideNotificationAction() {
  return {
    type: NOTIFICATION.HIDE,
  };
}
