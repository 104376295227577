import { RootState, store } from "store";
import history from "../history";
import { MODAL_WINDOW_TYPES } from "./constants";
import { logoutAction } from "store/actions/auth";
import i18next from "services/language/i18next";
import { showModalWindowAction } from "store/actions/modal";
import { showNotificationAction } from "store/actions/notification";
import { hideSpinnerAction, showSpinnerAction } from "store/actions/global";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

export function setShowingSpinner<T>(promise: Promise<T>) {
  store.dispatch(showSpinnerAction());

  return promise.finally(() => {
    store.dispatch(hideSpinnerAction());
  });
}

export function showNotification(title: string, message: string, type = "info") {
  store.dispatch(showNotificationAction(title, message, type));
}

export function alertErrors<T>(promise: Promise<T>, title = null) {
  return promise.catch((error) => {
    if (!error.ignore) {
      const errorMessage =
        error.response && typeof error.response.data === "object"
          ? Object.values(error.response.data).join(" \n")
          : error.message;
      showNotification(title || i18next.t("notification:title.error", "Something went wrong!"), errorMessage, "danger");
    }
    throw error;
  });
}

export function logoutIfTokenIsInvalid<T>(promise: Promise<T>) {
  return promise.catch((error) => {
    if (error.response?.status === 401) {
      const currentPath = history.location.pathname;
      (store.dispatch as ThunkDispatch<RootState, unknown, AnyAction>)(logoutAction(true));
      // store.dispatch(logoutAction(true));
      history.push(`/login?redirect=${currentPath}&error=${error.response.data.detail}`);
      error.ignore = true;
    }

    throw error;
  });
}

export function redirectNotExist<T>(promise: Promise<T>) {
  return promise.catch((error) => {
    if (error.response?.status === 404) {
      history.replace("/404");
      error.ignore = true;
    }

    throw error;
  });
}

/**
 * @ Entry function params:
 *   title - {string},
 *   message - {string},
 *   size - {string},
 *   type - {MODAL_WINDOW_TYPES},
 *   color - {string},
 *   callbackOK - {function} || {null},
 *   callbackCancel - {function} || {null},
 *   okText - {string},
 *   cancelText - {string},
 *   formOptions - {object: {formId - {string}, subscribeToSuccessfulSubmit - {function}}},
 *   centered - {boolean}
 */

export interface IShowAlertModalProps {
  message: string | React.ReactNode;
  color: string;
  title?: string;
  onConfirm?: () => {} | void;
}

export interface IShowConfirmModalProps extends IShowAlertModalProps {
  onCancel?: () => void;
  centered?: boolean;
  confirmText?: string;
  cancelText?: string;
}

export interface IShowFormModalProps {
  title: string;
  message: React.ReactNode;
  color?: string;
  size?: string;
  formOptions: {
    formId: string;
    subscribeToSuccessfulSubmit: (callback: () => void) => void;
  };
}

export const showModalWindow = {
  alert: function alert(alertModalPayload: IShowAlertModalProps) {
    const { color = "info", onConfirm = null } = alertModalPayload;
    store.dispatch(
      showModalWindowAction(
        Object.assign(alertModalPayload, {
          color,
          onConfirm,
          type: MODAL_WINDOW_TYPES.ALERT,
        })
      )
    );
  },
  confirm: function confirm(confirmModalPayload: IShowConfirmModalProps) {
    const {
      color = "info",
      onConfirm = null,
      onCancel = null,
      title = i18next.t("modal:title.confirm", "Confirm your action"),
    } = confirmModalPayload;
    store.dispatch(
      showModalWindowAction(
        Object.assign(confirmModalPayload, {
          color,
          onConfirm,
          onCancel,
          title,
          type: MODAL_WINDOW_TYPES.CONFIRM,
        })
      )
    );
  },
  form: function form(formModalPayload: IShowFormModalProps) {
    const { color = "info", size = "lg" } = formModalPayload;
    store.dispatch(
      showModalWindowAction(
        Object.assign(formModalPayload, {
          color,
          size,
          type: MODAL_WINDOW_TYPES.FORM,
        })
      )
    );
  },
};
