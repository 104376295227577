import {instance, requestNoSpinnerPromiseWrapper, requestPromiseWrapper, setShowingSpinner} from "./instance";

export function getPlans() {
  return requestPromiseWrapper(instance.get("/plans"));
}

export function getCompanyBilling() {
  return requestPromiseWrapper(instance.get("/company-billing"));
}

export function patchCompanyBilling(billingData, id) {
  return requestPromiseWrapper(instance.patch(`/company-billing/${id}`, billingData));
}

export function getProratedPrice(query) {
  return requestNoSpinnerPromiseWrapper(instance.get(`/company-billing/prorated_price?${query}`));
}

export function createStripeCheckoutSession(paymentData) {
  return requestPromiseWrapper(instance.post("/create-checkout-session", paymentData));
}

export function stripeRedirectToCheckout({stripe, checkoutOptions}) {
  return setShowingSpinner(stripe.redirectToCheckout(checkoutOptions));
}

export function createStripeSetupCardIntent() {
  return requestPromiseWrapper(instance.post("/payment-intent/setup_card", {}));
}

export function confirmCardSetup(stripe, data, cardData) {
  return setShowingSpinner(stripe.confirmCardSetup(data, cardData));
}

export function stripeConfirmCardPayment({stripe, clientSecret, data, options}) {
  return setShowingSpinner(stripe.confirmCardPayment(clientSecret, data, options));
}

export function changeSubscription(paymentData) {
  return requestPromiseWrapper(instance.post("/payment-intent", paymentData));
}

export function deletePaymentIntent(query) {
  return requestPromiseWrapper(instance.delete(`/payment-intent/void_invoice?${query}`));
}