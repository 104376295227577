/**
 * Return elements of the arr1 that are not included in the arr2
 * Filter elements by id
 *
 * @param arr1 Initial array
 * @param arr2 Array to compare with
 * @returns Array of different elements
 */
export function sortArraysAndReturnNotEqualItems<T extends { id: unknown }>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter(({ id: id1 }) => !arr2.some(({ id: id2 }) => id2 === id1));
}
