import {instance, requestPromiseWrapper, requestWithSpinnerNoAlertErrorsPromiseWrapper} from "./instance";

export function getIntegrationsRequest() {
  return requestPromiseWrapper(instance.get("/integrations"));
}

export function getIntegrationRequest(id) {
  return requestPromiseWrapper(instance.get(`/integrations/${id}`));
}

export function createIntegrationRequest(integrationData) {
  return requestPromiseWrapper(instance.post("/integrations", integrationData));
}

export function deleteIntegrationRequest(id) {
  return requestWithSpinnerNoAlertErrorsPromiseWrapper(instance.delete(`/integrations/${id}`));
}

export function updateIntegrationRequest(integrationData, id) {
  return requestPromiseWrapper(instance.patch(`/integrations/${id}`, integrationData));
}