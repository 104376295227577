import * as yup from "yup";
import { Message } from "../schemes/messages";

function timeOnly(this: yup.StringSchema, message: Message) {
  return this.test("timeOnly", message, (value) => {
    if (!value) return true;

    const digitsNum = value.replace(/\D/g, "").length;
    return digitsNum === 4 || digitsNum === 0;
  });
}

export default timeOnly;
