import { NOTIFICATION } from "../actions/types";


interface INotificationGlobalState {
  title: string;
  message: string;
  isShowing: boolean;
  type: string;
};

const initialState: INotificationGlobalState = {
  title: "",
  message: "",
  isShowing: false,
  type: "info",
};

interface INotificationAction {
  type: NOTIFICATION;
  payload: {
    title: string;
    message: string;
    type: string;
  }
}

const notificationReducer = (state = initialState, action: INotificationAction) => {
  switch (action.type) {
    case NOTIFICATION.SHOW:
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        type: action.payload.type,
        isShowing: true,
      };
    case NOTIFICATION.CLEAR:
      return {
        ...state,
        title: "",
        message: "",
        type: "info",
        isShowing: false,
      };
    case NOTIFICATION.HIDE:
      return {
        ...state,
        isShowing: false,
      };
    default:
      return state;
  }
};

export default notificationReducer;
