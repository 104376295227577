import i18next from "i18next";
import useARAvatar from "components/ReactHookForm/hooks/useARAvatar";
import { ARButton, ARFormGroup, ARIcon, ARImg, ARInputFile } from "components/ARComponents";
import { Control } from "components/ReactHookForm/_types";

interface IAvatarProps {
  name: string;
  control: Control;
}

const Avatar = (props: IAvatarProps) => {
  const { name, control } = props;
  const { avatarState, onUploadImageClick, onUploadImage, onDeleteUploadedImage } = useARAvatar({ control, name });

  return (
    <ARFormGroup className="Avatar centered-fit-content">
      <div className="centered-fit-content">
        <ARImg
          className="Avatar__image"
          src={avatarState.src}
          alt="Avatar"
          shape="rounded-circle"
          width={200}
          height={200}
          placeholderColor="lightgrey"
        />
      </div>
      <div className="Avatar__upload centered-fit-content">
        <ARButton color="dark" className="centered-fit-content" variant="outline" onClick={onUploadImageClick}>
          {i18next.t("form:button.uploadAvatar", "Upload user image")}
        </ARButton>
        <ARInputFile className="d-none" value="" accept=".jpg, .jpeg, .png" onChange={onUploadImage} />
        {avatarState.name && (
          <div className="centered-fit-content">
            <ARButton onClick={onDeleteUploadedImage}>
              <ARIcon name="cilTrash" />
            </ARButton>
            <span className="align-middle">{avatarState.name}</span>
          </div>
        )}
      </div>
    </ARFormGroup>
  );
};

export default Avatar;
