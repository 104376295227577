export const directoryDataFields = [
  "isAddPage",
  "name",
  "type",
  "host",
  "login",
  "password",
  "base_dn",
  "ldap_user_filter",
  "ldap_group_filter",
  "use_for_authentication"
];

export const directoryFormDefaultValues = {
  type: "openLDAP",
  use_for_authentication: false
};