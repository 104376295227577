import i18next from "i18next";

export const SelectOptions = {
  get dayType() {
    return [
      { value: "i", name: i18next.t("rule.time.day.any", "Any") },
      { value: "w", name: i18next.t("rule.time.day.weekday", "Working Days") },
      { value: "h", name: i18next.t("rule.time.day.weekend", "Not Working Days") },
    ];
  },
  get delay() {
    return [
      { value: "00:00:00", name: i18next.t("time.immediately", "immediately") },
      { value: "00:03:00", name: i18next.t("time.minute", "3 minutes", { count: 3 }) },
      { value: "00:15:00", name: i18next.t("time.minute", "15 minutes", { count: 15 }) },
      { value: "00:30:00", name: i18next.t("time.minute", "30 minutes", { count: 30 }) },
      { value: "01:00:00", name: i18next.t("time.hour", "1 hour", { count: 1 }) },
      { value: "12:00:00", name: i18next.t("time.hour", "12 hours", { count: 12 }) },
      { value: "1 00:00:00", name: i18next.t("time.day", "1 day", { count: 1 }) },
    ];
  },
  get recur() {
    return [
      { value: "00:03:00", name: i18next.t("time.minute", "3 minutes", { count: 3 }) },
      { value: "00:15:00", name: i18next.t("time.minute", "15 minutes", { count: 15 }) },
      { value: "00:30:00", name: i18next.t("time.minute", "30 minutes", { count: 30 }) },
      { value: "01:00:00", name: i18next.t("time.hour", "1 hour", { count: 1 }) },
      { value: "12:00:00", name: i18next.t("time.hour", "12 hours", { count: 12 }) },
      { value: "1 00:00:00", name: i18next.t("time.day", "1 day", { count: 1 }) },
    ];
  },
};

export const ruleDataFields = [
  "from_time",
  "to_time",
  "day_type",
  "delay",
  "recur",
  "phones",
  "telegram_chats",
  "members_to_call",
  "my_check",
  "is_inverted_condition",
];
