import React from "react";
import { setDocumentTitle } from "utils";
import { Route, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { ARFade } from "components/ARComponents";
import { matchPath, Switch } from "react-router-dom";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";
import { IRoute } from "components/PageWrappers/_types";

interface IContentProps {
  routes: IRoute[];
  className?: string;
}

const Content = ({ routes, className }: IContentProps) => {
  const location = useLocation();
  const currentRoute = routes.find((route) =>
    matchPath(location.pathname, {
      path: route.path,
      exact: route.exact,
    })
  );

  const { t } = useTranslation();

  if (currentRoute?.name) {
    hasGlobalPermission(ACTIONS.VIEW_DOCUMENT_TITLE) && setDocumentTitle(t(currentRoute.name));
  }

  return (
    <Switch>
      {routes.map((route, idx) => {
        return (
          route.component && (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <ARFade className={className}>
                  <route.component {...props} {...route.props} />
                </ARFade>
              )}
            />
          )
        );
      })}
    </Switch>
  );
};

export default withErrorBoundary(React.memo(Content));
