type MessageType = "danger" | "warning";

export type Message = {
  type: MessageType;
  i18nKey: string;
  default: JSX.Element | string;
};

export const MESSAGES_TYPES: { [T: string]: MessageType } = {
  DANGER: "danger",
  WARNING: "warning",
};

export const MESSAGES: { [M: string]: Message } = {
  REQUIRED: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.required",
    default: "This field is required!",
  },
  MAX_LENGTH: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.maxLength",
    default: "Exceeded maximum length",
  },
  EMAIL_INVALID_VALUE: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.email.value",
    default: (
      <>
        You must enter email in the format: <strong>user@domain.com</strong>!
      </>
    ),
  },
  PHONE_INVALID_VALUE: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.phone.value",
    default: "Invalid phone number!",
  },
  NEXMO_INVALID_VALUE: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.nexmo",
    default: "You must enter valid nexmo private key!",
  },
  URL_INVALID_VALUE: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.url",
    default: "You must enter valid URL!",
  },
  DOMAIN_INVALID_VALUE: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.domain.value",
    default: (
      <>
        You must enter subdomain in the format: <strong>latin characters, numbers, length 2 to 63</strong>!
      </>
    ),
  },
  SERVICE_AGREE: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.terms.value",
    default: "You must agree to the terms of service!",
  },
  MATCH_PASSWORDS: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.password.match",
    default: "Passwords must match!",
  },
  DOMAIN_EXIST: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.domain.exist",
    default: "A company with this domain already exists!",
  },
  DOMAIN_INVALID_CHARACTERS: {
    type: MESSAGES_TYPES.WARNING,
    i18nKey: "form:error.domain.characters",
    default: (
      <>
        You can only enter the following characters: <strong>latin characters, numbers</strong>!
      </>
    ),
  },
  EMAIL_INVALID_CHARACTERS: {
    type: MESSAGES_TYPES.WARNING,
    i18nKey: "form:error.email.characters",
    default: (
      <>
        You can only enter the following characters: <strong>latin characters, numbers, '.', '@'</strong>!
      </>
    ),
  },
  EMAIL_NOT_EXIST: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "notification:message.error.notExistingEmail",
    default: "User with that email does not exist.",
  },
  NO_TG_BOT: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.tgToken",
    default: "No bot with such token!",
  },
  EXISTING_EMAIL: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.email.exist",
    default: "User with that email already exists!",
  },
  HOST_INVALID_VALUE: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.ldapHost.value",
    default: (
      <>
        Host must starts with <strong>ldap://, ldaps:// or ldapi://</strong>
      </>
    ),
  },
  TIME_INVALID_VALUE: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.time.value",
    default: (
      <>
        Enter time in the format: <strong>08:00</strong>
      </>
    ),
  },
  NOT_MATCH_TIMES: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.time.zeroPeriod",
    default: (
      <>
        The notification period is <strong>0</strong>!
      </>
    ),
  },
  PORT_MAX_VALUE: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.port",
    default: "Port value cannot exceed 65535",
  },
  DIGITS_ONLY: {
    type: MESSAGES_TYPES.WARNING,
    i18nKey: "form:error.digits",
    default: "You can enter only digits",
  },
  INTEGRATION_REQUIRED: {
    type: MESSAGES_TYPES.DANGER,
    i18nKey: "form:error.integration.required",
    default: "Integration required to create this type of check!",
  },
  BALANCE_CHARACTERS_ONLY: {
    type: "warning",
    i18nKey: "form:error.balance.characters",
    default: "You can enter only digits and dot",
  },
  BALANCE_INVALID_VALUE: {
    type: "danger",
    i18nKey: "form:error.balance.format",
    default: "Enter a value in the format 25.00 or 25. The value must not exceed 999999.99",
  },
};
