import React from "react";
import {useTranslation} from "react-i18next";
import {ARBadge} from "components/ARComponents";

const CurrentPlanBadge = () => {
  const {t} = useTranslation();

  return (
    <ARBadge
      color="success"
      className="CurrentPlanBadge"
    >
      {t("plans:current", "Current")}
    </ARBadge>
  );
};

export default CurrentPlanBadge;