import { getBase64 } from "utils/imagesHandlers";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { Control } from "../_types";
import useControllerWithCustomError from "./useControllerWithCustomError";

type useARAvatarProps = {
  control: Control;
  name: string;
};

type AvatarState = {
  name: string | null;
  src: string;
};

interface IInputElementEventTarget extends EventTarget {
  nextSibling: HTMLInputElement;
}

interface IAvatarMouseEvent extends MouseEvent<HTMLButtonElement> {
  target: IInputElementEventTarget;
}

const useARAvatar = ({ control, name }: useARAvatarProps) => {
  const {
    field: { onChange, value },
  } = useControllerWithCustomError({ name, control });

  const initialAvatarRef = useRef<string>(value);

  const [avatarState, setAvatarState] = useState<AvatarState>({
    name: null,
    src: value,
  });

  useEffect(() => {
    setAvatarState((prevState) => {
      if (prevState.name) {
        return prevState;
      }

      initialAvatarRef.current = value;
      return {
        ...prevState,
        src: value,
      };
    });
  }, [value]);

  const onDeleteUploadedImage = (event: MouseEvent<HTMLButtonElement>) => {
    const newEvent = { ...event, type: "change", target: { ...event.target, value: initialAvatarRef.current } };
    onChange && onChange(newEvent);

    setAvatarState({
      name: null,
      src: initialAvatarRef.current,
    });
  };

  const onUploadImageClick = ({ target }: IAvatarMouseEvent) => {
    if (target) {
      target.nextSibling.click();
    }
  };

  const onUploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const src = await getBase64(file);

      const newEvent = { ...event, target: { ...event.target, value: file } };
      onChange && onChange(newEvent);

      if (typeof src === "string") {
        setAvatarState({
          name: file.name,
          src,
        });
      }
    }
  };

  return {
    avatarState,
    onUploadImageClick,
    onUploadImage,
    onDeleteUploadedImage,
  };
};

export default useARAvatar;
