import { useEffect } from "react";
import { useActions } from "store/actions";
import { getDetailedCheckRequest } from "services/api";
import { resetCheckNameAction, setCheckNameAction } from "store/actions/global";

const useSetCheckName = (checkId: string) => {
  const [resetCheckName] = useActions([resetCheckNameAction]);
  const [setCheckName] = useActions([setCheckNameAction]);

  useEffect(() => {
    if (checkId) {
      getDetailedCheckRequest(checkId).then(({ data }) => setCheckName(data));

      return () => {resetCheckName();};
    }
  }, [checkId, resetCheckName, setCheckName]);
};

export default useSetCheckName;
