import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ARInputGroup, ARLabel, ARSwitch } from "../../ARComponents";
import { ClassName } from "models/types";

interface IRuleSwitcherProps {
  className: ClassName;
  disabled: boolean;
  checked: boolean;
  switcherHandler: () => void;
}

const RuleSwitcher = (props: IRuleSwitcherProps) => {
  const { switcherHandler, className, checked, disabled } = props;
  const { t } = useTranslation();

  return (
    <ARInputGroup className={classNames("RuleSwitcher", className)}>
      <ARLabel htmlFor="ruleSwitcher" className="RuleSwitcher__label">
        {checked ? t("rule.switcher.on", "The rule is On") : t("rule.switcher.off", "The rule is Off")}
      </ARLabel>
      <ARSwitch
        size="sm"
        color="info"
        id="ruleSwitcher"
        checked={checked}
        disabled={disabled}
        onChange={switcherHandler}
        className="RuleSwitcher__switch"
      />
    </ARInputGroup>
  );
};

export default RuleSwitcher;
