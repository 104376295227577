import { ARButton } from "../ARComponents";
import { useTranslation } from "react-i18next";
import { ButtonVariant } from "../../models/types";
import { withErrorBoundary } from "../ErrorBoundary";
import { checkId, incidentId } from "pages/checks/_types";

export type ReturnButtonProps = {
  className?: string;
  color?: string;
  variant?: ButtonVariant;
  size?: string;
  incidentId?: incidentId;
  checkId: checkId;
  onReturnButtonClick: (id: checkId, incidentId?: incidentId) => void;
};

const ReturnButton = (props: ReturnButtonProps) => {
  const {
    className = "btn-block mt-1",
    size = "sm",
    color = "warning",
    variant = "outline",
    onReturnButtonClick,
    checkId,
    incidentId,
  } = props;
  const { t } = useTranslation();

  return (
    <ARButton
      className={className}
      size={size}
      color={color}
      variant={variant}
      onClick={() => onReturnButtonClick(checkId, incidentId)}
    >
      {t("button.return", "Return")}
    </ARButton>
  );
};

export default withErrorBoundary<ReturnButtonProps>(ReturnButton);
