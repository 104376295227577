import { useRef } from "react";
import { useSelector } from "react-redux";
import useUpdateProjects from "./useUpdateProjects";
import { projectsSelector } from "store/selectors/user";
import { createProjectRequest, deleteProjectRequest } from "services/api";
import { IProject } from "./../../../models/types";

const useProjectsData = () => {
  const projects = useSelector(projectsSelector);
  const successfulCreateProjectSubscriberRef = useRef<() => void>();
  const updateProjects = useUpdateProjects();

  const createProject = (projectData: IProject) => {
    return createProjectRequest(projectData)
      .then(updateProjects)
      .then(() => successfulCreateProjectSubscriberRef.current?.());
  };

  const deleteProject = (id: string) => {
    return deleteProjectRequest(id).then(updateProjects);
  };

  return {
    projects,
    createProject,
    successfulCreateProjectSubscriberRef,
    deleteProject,
  };
};

export default useProjectsData;
