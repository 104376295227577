import { useDirectoryForm } from "../hooks";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import FormControlsButtons from "components/FormControlButtons";
import { RHFSwitch } from "components/ReactHookForm/fields/custom";
import { RHFInputWithLabel, RHFSelectWithLabel } from "components/ReactHookForm/fields";
import { ARCard, ARCardBody, ARCardFooter, ARCardHeader, ARForm } from "components/ARComponents";

const Directory = () => {
  const { t } = useTranslation();
  const { Prompt, onFormSubmit, onFormReset, form, formId, headerText, isAddPage } = useDirectoryForm();
  const { control, handleSubmit } = form;

  return (
    <ARCard className="Directory">
      <ARCardHeader>
        <h1 className="card-header-text">{headerText}</h1>
      </ARCardHeader>
      <ARCardBody>
        <Prompt />
        <ARForm id={formId} onSubmit={handleSubmit(onFormSubmit)}>
          <RHFInputWithLabel
            id="name"
            name="name"
            control={control}
            labelText={t("form:label.name", "Name")}
            placeholder={t("form:placeholder.directoryName", "Enter directory name")}
          />
          <RHFSelectWithLabel
            id="type"
            name="type"
            control={control}
            disabled={!isAddPage}
            options={[{ name: "openLDAP", value: "openLDAP" }]}
            labelText={t("form:label.type", "Type")}
          />
          <RHFInputWithLabel
            id="host"
            name="host"
            control={control}
            labelText={t("form:label.host", "Host")}
            placeholder={t("form:placeholder.host", "Enter host")}
          />
          <RHFInputWithLabel
            id="login"
            name="login"
            control={control}
            autoComplete="new-password"
            labelText={t("form:label.login", "Login")}
            placeholder={t("form:placeholder.login", "Enter login")}
          />
          <RHFInputWithLabel
            id="password"
            name="password"
            type="password"
            control={control}
            autoComplete="new-password"
            labelText={isAddPage ? t("form:label.password", "Password") : t("form:label.password_new", "New password")}
            placeholder={
              isAddPage
                ? t("form:placeholder.password", "Enter password")
                : t("form:placeholder.password_new", "Enter new password")
            }
          />
          <RHFInputWithLabel
            id="base_dn"
            name="base_dn"
            control={control}
            labelText={t("form:label.baseDn", "Base DN")}
            placeholder={t("form:placeholder.baseDn", "Enter base DN")}
          />
          <RHFInputWithLabel
            id="ldap_user_filter"
            name="ldap_user_filter"
            control={control}
            labelText={t("form:label.ldapUserFilter", "User filter")}
            placeholder={t("form:placeholder.ldapUserFilter", "Enter user filter")}
          />
          <RHFInputWithLabel
            id="ldap_group_filter"
            name="ldap_group_filter"
            control={control}
            labelText={t("form:label.ldapGroupFilter", "Group filter")}
            placeholder={t("form:placeholder.ldapGroupFilter", "Enter group filter")}
          />
          <RHFSwitch
            control={control}
            id="use_for_authentication"
            name="use_for_authentication"
            className="Directory__switch"
            labelClassName="Directory__switch-label"
            labelText={t("form:label.useForAuthentication", "Use for authentication")}
          />
        </ARForm>
      </ARCardBody>
      <ARCardFooter>
        <FormControlsButtons form={formId} onCancelClick={onFormReset} />
      </ARCardFooter>
    </ARCard>
  );
};

export default withErrorBoundary(Directory);
