import * as yup from "yup";
import { MESSAGES } from "./messages";
import { phone } from "../validators";

/** yup custom validators */
yup.addMethod(yup.string, "phone", phone);

const profileFormSchema = yup.object().shape({
  avatar: yup.mixed(),
  first_name: yup.string().max(30, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
  last_name: yup.string().max(150, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
  email: yup.string().max(254, MESSAGES.MAX_LENGTH).email(MESSAGES.EMAIL_INVALID_VALUE).required(MESSAGES.REQUIRED),
  phone: yup.string().phone(MESSAGES.PHONE_INVALID_VALUE),
  password: yup.string(),
  confirm_password: yup.string().oneOf([yup.ref("password")], MESSAGES.MATCH_PASSWORDS),
});

export default profileFormSchema;
