import i18next from "services/language/i18next";
import { showModalWindow } from "utils/actionWrappers";

export const roleOptions = () => [
  { value: "admin", name: i18next.t("role.admin", "Admin") },
  { value: "viewer", name: i18next.t("role.viewer", "Viewer") },
];

export function projectDeletingHandler(error: { response: { status: number }}) {
  if (error.response.status === 409) {
    showModalWindow.alert({
      title: i18next.t("modal:title.confirm", "Confirm your action"),
      message: i18next.t(
        "modal:message.projectWithChecks",
        "You can't delete the project, because it has checks in it."
      ),
      color: "danger",
    });
  }
}

export const projectInfoInitialState = {
  header: "",
  isMainProject: false,
  isProjectAdmin: false,
  projectId: null,
  projectRules: [],
};
