import { Timeout } from "models/types";
import { IMemberDirectoryElement } from "services/api/types";

export interface IIntervalRefObj {
  intervalId: null | Timeout;
  requestsCount: number;
  clearDirectoriesInterval: () => void;
  refreshInterval: () => void;
  checkForClearInterval: (dataObj: IMemberDirectoryElement[]) => void;
}

export const intervalRefObj: IIntervalRefObj = {
  intervalId: null,
  requestsCount: 0,
  clearDirectoriesInterval: function () {
    if (this.intervalId) clearInterval(this.intervalId);
  },

  refreshInterval: function () {
    if (this.requestsCount < 2) {
      this.clearDirectoriesInterval();
    }
  },

  checkForClearInterval: function (dataObj) {
    dataObj.forEach((item) => {
      if (!item.is_syncing_now) {
        this.clearDirectoriesInterval();
        this.requestsCount = 0;
      }
    });
  },
};
