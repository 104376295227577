import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Control, useController } from "react-hook-form";
import RHFControlList from "../RHFControlList/RHFControlList";
import { generateEvent } from "components/ReactHookForm/utils";
import { sortArraysAndReturnNotEqualItems } from "utils/arrays";
import { getObjectValuesByTypes } from "./RHFElementsSelector.model";
import { ARFormGroup, ARInput, ARInputGroup } from "components/ARComponents";
import { IItemObject, IUser, IChat, IActionParams, IDeleteItemClick } from "components/ReactHookForm/_types";
import { IMember } from "./../../../../../models/types";

interface IRHFElementsSelectorProps<T> {
  id: string;
  name: string;
  items: T[];
  control?: Control;
  className?: string;
  selectedItemsPrependComponent?: string;
  unselectedItemsPrependComponent?: string;
  getItemText: (el: T) => IItemObject | string;
}

const RHFElementsSelector = <T extends IUser | IChat | IMember>(props: IRHFElementsSelectorProps<T>) => {
  const {
    id,
    name,
    items = [],
    control,
    className,
    getItemText,
    selectedItemsPrependComponent,
    unselectedItemsPrependComponent,
  } = props;

  const { t } = useTranslation();
  const [filterInputValue, setFilterInputValue] = useState("");
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const onAddItemClick = ({ item, event }: IActionParams) => {
    const newValue: T[] = [...value, item];
    const newEvent = generateEvent({ event, newValue });

    onChange(newEvent);
    setFilterInputValue("");
  };

  const onDeleteItemClick = ({ item, event }: IDeleteItemClick<T>) => {
    const newValue: T[] = value.filter((selectedItem: T) => selectedItem.id !== item.id);
    const newEvent = generateEvent({ event, newValue });
    onChange(newEvent);
  };

  const unselectedItems = useMemo(
    () =>
      sortArraysAndReturnNotEqualItems(
        items.filter((item) =>
          new RegExp(filterInputValue, "gi").test(getObjectValuesByTypes(item, ["string", "number"]).join(" "))
        ),
        value
      ),
    [filterInputValue, value, items]
  );

  const filterInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => setFilterInputValue(event.target.value);

  return (
    <ARFormGroup className={classNames(className)}>
      <RHFControlList
        items={value}
        getItemText={getItemText}
        onDeleteItemAction={onDeleteItemClick}
        prependComponent={selectedItemsPrependComponent}
        emptyText={t("noData.empty", "Empty")}
        actionIconProps={{
          name: "cilXCircle",
          className: "delete-circle",
        }}
      />
      <ARInputGroup className="mt-1">
        <ARInput
          id={id}
          autoComplete="off"
          value={filterInputValue}
          onInput={filterInputHandler}
          placeholder={t("search.default", "Search")}
          className="mb-1 w-75 field_right-border-radius"
        />
        <RHFControlList
          getItemText={getItemText}
          onAddItemAction={onAddItemClick}
          items={!!filterInputValue ? unselectedItems : []}
          prependComponent={unselectedItemsPrependComponent}
          emptyText={
            items.length !== value.length
              ? t("noData.startTyping", "Start typing to search")
              : t("noData.empty", "Empty")
          }
          actionIconProps={{
            name: "cilXCircle",
            className: "add-circle",
          }}
        />
      </ARInputGroup>
    </ARFormGroup>
  );
};

export default RHFElementsSelector;
