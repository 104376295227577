import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import getNavigation from "./navigation";
import { useTranslation } from "react-i18next";
import { globalRoleSelector } from "store/selectors/user";
import { withErrorBoundary } from "components/ErrorBoundary";
import { subscriptionTypeSelector } from "store/selectors/global";
import {
  ARCreateElement,
  ARLogo,
  ARSidebar,
  ARSidebarBrand,
  ARSidebarMinimizer,
  ARSidebarNav,
  ARSidebarNavDivider,
  ARSidebarNavDropdown,
  ARSidebarNavItem,
  ARSidebarNavTitle,
} from "components/ARComponents";

interface ISidebarProps {
  showSidebar: boolean | "responsive";
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean | "responsive">>;
}

const Sidebar = (props: ISidebarProps) => {
  const { i18n } = useTranslation();
  const globalRole = useSelector(globalRoleSelector);
  const subscriptionType = useSelector(subscriptionTypeSelector);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const navigation = useMemo(() => getNavigation(), [i18n.language, globalRole, subscriptionType]);

  return (
    <ARSidebar show={props.showSidebar} onShowChange={(val: boolean | "responsive") => props.setShowSidebar(val)}>
      <ARSidebarBrand className="d-md-down-none" to="/">
        <div className="c-sidebar-brand-full Sidebar__full-logo">
          <ARLogo className="mr-2" name="logo-negative" width={35} height={35} />
          Alertrules
        </div>

        <ARLogo className="c-sidebar-brand-minimized" name="sygnet" width={35} height={35} />
      </ARSidebarBrand>
      <ARSidebarNav>
        <ARCreateElement
          items={navigation}
          components={{
            ARSidebarNavDivider,
            ARSidebarNavDropdown,
            ARSidebarNavItem,
            ARSidebarNavTitle,
          }}
        />
      </ARSidebarNav>
      <ARSidebarMinimizer className="c-d-md-down-none" />
    </ARSidebar>
  );
};

export default withErrorBoundary(React.memo(Sidebar));
