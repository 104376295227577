import i18next from "services/language/i18next";
import {CHECK_TYPES, INTEGRATION_TYPES} from "utils/constants";
import {convert_DHHMMSS_toMinutes, getTranslatedTimeTextFromMinutes, numberToTimeDuration, TimeType} from "utils/timeHandlers";
import { ICheck, ICheckRules, Iintegration } from "../_types";

export function getDefaultValues(checkData?: Partial<ICheck>) {
  return {
    name: checkData?.name ?? "",
    description: checkData?.description ?? "",
    type: checkData?.type ?? CHECK_TYPES.HTTP,
    url: checkData?.url ?? "",
    extra: {
      header: checkData?.extra?.header ?? "",
      text: checkData?.extra?.text ?? ""
    },
    integration: checkData?.integration?.toString() ?? "",
    balance_threshold: checkData?.balance_threshold ?? "",
    port: checkData?.port ?? "",
    timeout: checkData?.timeout ?? "",
    down_timeout: checkData?.down_timeout ?? "",
    is_telegram_auth_enabled: checkData?.is_telegram_auth_enabled?.toString() ?? "",
    project: checkData?.project?.toString() ?? ""
  };
}

export function getLabel(type: string) {
  return (
    new Map([
      ["http", i18next.t("form:label.url", "Url")],
      ["balance", i18next.t("form:label.balance", "Balance threshold")],
      ["jira", i18next.t("form:label.filter", "Filter")],
      ["header", i18next.t("form:label.url", "Url")]
    ]).get(type)
  );
}

export function getPlaceholder(type: string) {
  return (
    new Map([
      ["http", i18next.t("form:placeholder.url", "Enter url")],
      ["balance", i18next.t("form:placeholder.balance", "Enter balance threshold")],
      ["jira", i18next.t("form:placeholder.filter", "Enter filter")],
      ["header", i18next.t("form:placeholder.url", "Enter url")]
    ]).get(type)
  );
}

export function glueFormData(formData: ICheck, rules: ICheckRules[], isClonedCheck?: boolean | null) {
  const newFormData = {...formData};
  const type = formData.type;

  if (isClonedCheck) {
    newFormData.rules = rules;
  }

  switch (type) {
    case CHECK_TYPES.PORT:
      newFormData.url = `${newFormData.url}:${newFormData.port}`;
      newFormData.down_timeout = newFormData.down_timeout || null;
      delete newFormData.port;
      break;
    default:
      delete newFormData.port;
      newFormData.down_timeout = newFormData.down_timeout || null;
      break;
  }
  return newFormData;
}

export const resetUnnecessaryFields = (formData: ICheck) => {
  const newFormData = {...formData};
  const type = formData.type;

  switch (type) {
    case CHECK_TYPES.HEADER:
      newFormData.integration = null;
      newFormData.balance_threshold = null;
      break;

    case CHECK_TYPES.BALANCE:
      newFormData.url = "";
      newFormData.extra = { header: "", text: ""};
      break;

    case CHECK_TYPES.JIRA:
      newFormData.balance_threshold = null;
      newFormData.extra = { header: "", text: ""};
      break;

    case CHECK_TYPES.EXTERNAL:
      break;

    default:
      newFormData.extra = { header: "", text: ""};
      newFormData.balance_threshold = null;
      newFormData.integration = null;
      break;
  }

  return newFormData;
};

export interface IResult {
  name: string;
  value: number;
}
export const getIntegrationsByType = (type: string, integrations: Iintegration[]) => {
  const isJiraCheck = type === CHECK_TYPES.JIRA;
  const integrationsByType = integrations.reduce((result: IResult[], integration) => {
    const isJiraIntegration = integration.type === INTEGRATION_TYPES.JIRA;
    if ((isJiraCheck && isJiraIntegration) || (!isJiraCheck && !isJiraIntegration)) {
      result.push({
        name: integration.name,
        value: integration.id
      });
  }
    return result;
  }, []);

  if (!integrationsByType.length) {
    return [{value: "", name: i18next.t("noData.integrations", "No integrations")}];
  }
  return integrationsByType;
};

interface ITimeouts{
  name: string;
  value: string;
};

export const getIsNotOldTimeoutStatus = (timeoutsArr: ITimeouts[] = [], timeout = "") => {
  if (!timeout) {
    return true;
  } else {
    return !!timeoutsArr.find((item: { value: string }) => item.value === timeout);
  }
};

export const getTimeouts = (timeoutsArr: ITimeouts[] = [], currentTimeout = "00:03:00") => {
  const isNotOldTimeout = getIsNotOldTimeoutStatus(timeoutsArr, currentTimeout);

  if (isNotOldTimeout) {
    return timeoutsArr;
  } else {
    const minutesAmount = convert_DHHMMSS_toMinutes(currentTimeout);

    const oldTimeout = {
      name: getTranslatedTimeTextFromMinutes(minutesAmount),
      value: currentTimeout
    };

    return [oldTimeout, ...timeoutsArr];
  }
};

export function getTimeout(timeType: TimeType = TimeType.empty, timeAmount?: number) {
  if (!timeAmount) {
    const name = i18next.t("noData.empty", "Empty");
    const value = "";

    return {name, value};
  }

  const name = i18next.t(`time.${timeType}`, {count: timeAmount});
  const value = numberToTimeDuration(timeAmount, timeType);

  return {name, value};
}

export function getChecksTimeouts(type: string) {
  const checksTimeouts = {
    httpPingHeaderPort: [
      getTimeout(TimeType.minute, 3), //тут поменял строку на enum
      getTimeout(TimeType.minute, 15),
      getTimeout(TimeType.minute, 30),
      getTimeout(TimeType.hour, 1),
      getTimeout(TimeType.hour, 12),
      getTimeout(TimeType.day, 1)
    ],
    domainHttpscert: [
      getTimeout(TimeType.day, 1),
      getTimeout(TimeType.day, 3),
    ],
    jira: [
      getTimeout(TimeType.minute, 3),
      getTimeout(TimeType.minute, 15),
      getTimeout(TimeType.minute, 30),
      getTimeout(TimeType.hour, 1),
      getTimeout(TimeType.hour, 6),
      getTimeout(TimeType.hour, 12),
      getTimeout(TimeType.day, 1),
      getTimeout(TimeType.day, 3),
    ],
    balance: [
      getTimeout(TimeType.hour, 6),
      getTimeout(TimeType.hour, 12),
      getTimeout(TimeType.day, 1),
      getTimeout(TimeType.day, 3),
    ]
  };
  type key = keyof typeof checksTimeouts;
  return checksTimeouts[type as key];
}

export function getChecksDownTimeouts(type: string) {
  const checksDownTimeouts = {
    httpPingHeaderPort: [
      getTimeout(TimeType.empty),
      ...getChecksTimeouts("httpPingHeaderPort")
    ],
    domainHttpscert: [
      getTimeout(TimeType.empty),
      getTimeout(TimeType.hour, 1),
      getTimeout(TimeType.hour, 12),
      getTimeout(TimeType.day, 1),
      getTimeout(TimeType.day, 3),
    ],
    jira: [
      getTimeout(TimeType.empty),
      ...getChecksTimeouts("jira")
    ],
    balance: [
      getTimeout(TimeType.empty),
      ...getChecksTimeouts("balance")
    ]
  };
  type key = keyof typeof checksDownTimeouts;
  return checksDownTimeouts[type as key];
}

export function getTimeoutsType(type: string) {
  switch (type) {
    case "domain":
    case "httpscert":
      return "domainHttpscert";

    case "jira":
      return "jira";

    case "balance":
      return "balance";

    default:
      return "httpPingHeaderPort";
  }
}

export function getCheckTgAuthOptions() {
  return [
    {value: "", name: i18next.t("form:label.checkTelegramAuth.default", "Default")},
    {value: true, name: i18next.t("form:label.checkTelegramAuth.on", "On")},
    {value: false, name: i18next.t("form:label.checkTelegramAuth.off", "Off")}
  ];
}

/**
 * Returns translation for timeouts labels text.
 *
 * @param {"timeout" || "downTimeout"} timeoutType - Type of timeout.
 * @param {string} time - Time in the format: d hh:mm:ss.
 * @returns {string} - Generated timeout label text.
 */
export function getTimeoutLabelText(timeoutType: string, time: string) {
  if (typeof time !== "string") return time;
  if (!time) return i18next.t(`form:label.${timeoutType}`, {count: 0});

  const splittedTime = time.split(" ");
  const timeHasDays = splittedTime.length > 1;
  const hms = timeHasDays ? splittedTime[1].split(":") : splittedTime[0].split(":");

  return i18next.t(
    `form:label.${timeoutType}`,
    {count: +([timeHasDays ? splittedTime[0] : 0, ...hms].find((element) => +element > 0)!)}
  );
}