import classNames from "classnames";
import TimePicker from "./TimePicker";
import { generateEvent } from "components/ReactHookForm/utils";
import { useCallback, useRef, useState } from "react";
import { MESSAGES_TYPES } from "components/ReactHookForm/schemes/messages";
import ValidationFeedback from "components/ReactHookForm/ValidationFeedback";
import { formatTime, timeMask } from "./RHFTimeInput.model";
import {
  ARButton,
  ARFormGroup,
  ARIcon,
  ARInput,
  ARInputGroup,
  ARInputGroupAppend,
  ARInputGroupPrepend,
  ARInputGroupText,
} from "components/ARComponents";
import { Control, Trigger } from "../../../_types";
import { useControllerWithCustomError } from "../../../hooks";

interface IRHFTimeInputProps {
  id: string;
  name: string;
  control: Control;
  prependComponent: string;
  className?: string;
  trigger: Trigger;
}

const RHFTimeInput = (props: IRHFTimeInputProps) => {
  const { control, id, name, trigger, className, prependComponent, ...restProps }: IRHFTimeInputProps = props;
  const {
    field: { value, ref, onChange, ...inputProps },
    fieldState: { invalid, isDirty, error },
    formState: { isSubmitted },
  } = useControllerWithCustomError({ name, control });

  const currentValue: string = value || timeMask;
  const oldValue = useRef<string>(value || timeMask);
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);

  const onTimeInputChange = useCallback(
    (event) => {
      oldValue.current = currentValue;
      const inputValue: string = event.target.value;
      const formattedTime: string = formatTime(inputValue);
      const newValue: null | string = formattedTime === timeMask ? null : formattedTime;
      const newEvent = generateEvent({ event, newValue });
      onChange(newEvent);
      trigger && trigger();
    },
    [onChange, currentValue, trigger]
  );

  const hideTimePicker = useCallback(() => setShowTimePicker(false), []);

  const onTimePickerClick = () => {
    setShowTimePicker(!showTimePicker);
  };

  return (
    <ARFormGroup className={classNames(className)}>
      <ARInputGroup>
        {prependComponent && (
          <ARInputGroupPrepend>
            <ARInputGroupText>{prependComponent}</ARInputGroupText>
          </ARInputGroupPrepend>
        )}
        <ARInput
          id={id}
          inputMode="numeric"
          className="field_right-border-radius"
          placeholder={timeMask}
          onChange={onTimeInputChange}
          value={formatTime(currentValue)}
          innerRef={ref}
          valid={(isDirty || isSubmitted) && !invalid}
          invalid={invalid && error.message.type === MESSAGES_TYPES.DANGER}
          {...inputProps}
          {...restProps}
        />
        <ARInputGroupAppend>
          <ARButton color="primary" className="ARTimeInput__time-picker-button" onClick={onTimePickerClick}>
            <ARIcon name="cilClock" />
          </ARButton>
        </ARInputGroupAppend>
        <TimePicker
          name={`TimePicker_${name}`}
          show={showTimePicker}
          value={currentValue}
          onChange={onTimeInputChange}
          onBlur={hideTimePicker}
        />
        <ValidationFeedback error={error} />
      </ARInputGroup>
    </ARFormGroup>
  );
};

export default RHFTimeInput;
