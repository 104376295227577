import i18next from "i18next";
import { timeZonesNames } from "@vvo/tzdb";
import { TG_BOT_LANGUAGES } from "../../utils/constants";

export const settingsDataFields = [
  "telegram_connect_key",
  "telegram_token",
  "is_telegram_auth_enabled",
  "nexmo_app_id",
  "nexmo_from_phone",
  "nexmo_private_key",
  "jira_token",
  "jira_url",
  "timezone",
  "telegram_language",
];

export const timeZoneOptions = timeZonesNames.map((timeZone) => {
  const revertedTimeZoneName = timeZone.split("/").reverse().join("/");

  return {
    value: timeZone,
    name: revertedTimeZoneName,
  };
}, []);

export function getTgLangOptions() {
  return [
    {
      value: TG_BOT_LANGUAGES.RU,
      name: i18next.t("form:label.tgLanguage.ru", "Russian"),
    },
    {
      value: TG_BOT_LANGUAGES.EN,
      name: i18next.t("form:label.tgLanguage.en", "English"),
    },
  ];
}
