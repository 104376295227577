import { useTranslation } from "react-i18next";
import { ARButton } from "components/ARComponents";
import { TelegramUser } from "telegram-login-button";

interface ITelegramLogoutContentProps {
  telegramUserName: string | null;
  onTelegramWidgetClick: (telegramUser: TelegramUser) => void;
}

const TelegramLogoutContent = (props: ITelegramLogoutContentProps) => {
  const { telegramUserName, onTelegramWidgetClick } = props;
  const { t } = useTranslation();

  return (
    <div className={"TelegramLogoutButton"}>
      <span className={"TelegramLogoutButton__text"}>
        {t("telegram.authStatus", "You're logged in as")} {telegramUserName}
      </span>
      <ARButton color="info" variant="outline" onClick={onTelegramWidgetClick}>
        {t("button.logout", "Logout")}
      </ARButton>
    </div>
  );
};

export default TelegramLogoutContent;
