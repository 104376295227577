import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { RHFPhoneInput } from "./index";
import { generateEvent } from "../../utils";
import { useTranslation } from "react-i18next";
import RHFControlList from "./RHFControlList";
import { getPhoneFormSchema } from "../../schemes";
import { yupResolver } from "@hookform/resolvers/yup";
import { Control } from "react-hook-form";
import ValidationFeedback from "../../ValidationFeedback";
import { ARButton, ARInputGroup, ARInputGroupAppend } from "components/ARComponents";
import { useControllerWithCustomError, useFormWithCustomError } from "components/ReactHookForm/hooks";
import { IItemObject, IDeleteItemClick } from "components/ReactHookForm/_types";
import { ClassName } from "models/types";
interface IRHFPhoneSelectorProps {
  id: string;
  name: string;
  emptyText: string;
  className?: ClassName;
  autoComplete?: string;
  buttonClassName?: string;
  prependComponent: string;
  externalFormControl: Control;
  getItemText: (el: string) => IItemObject;
}

interface IFormData {
  [key: string]: string;
}

const RHFPhoneSelector = (props: IRHFPhoneSelectorProps) => {
  const {
    id,
    name,
    emptyText,
    className,
    getItemText,
    autoComplete,
    buttonClassName,
    prependComponent,
    externalFormControl,
  } = props;

  const {
    field: { value, onChange },
  } = useControllerWithCustomError({ name, control: externalFormControl });

  const { t } = useTranslation();
  const phoneInputName = id;
  const phoneFormControl = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(getPhoneFormSchema(phoneInputName)),
    defaultValues: { [phoneInputName]: "7" },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset: resetPhoneForm,
  } = phoneFormControl;

  const onAddItemClick = useCallback(
    (formData: IFormData, event: React.BaseSyntheticEvent | undefined) => {
      const newValue: string[] = [...value, formData[phoneInputName]];
      const newEvent = generateEvent({ event, newValue });

      onChange(newEvent);
    },
    [onChange, value, phoneInputName]
  );

  const onDeleteItemClick = useCallback(
    ({ index, event }: IDeleteItemClick<string>) => {
      const newValue: string[] = [...value];
      newValue.splice(index, 1);
      const newEvent = generateEvent({ event, newValue });

      onChange(newEvent);
    },
    [onChange, value]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      resetPhoneForm({ [phoneInputName]: "7" });
    }
  }, [isSubmitSuccessful, resetPhoneForm, phoneInputName]);

  return (
    <>
      <RHFControlList
        items={value}
        emptyText={emptyText}
        getItemText={getItemText}
        className={classNames(className)}
        prependComponent={prependComponent}
        onDeleteItemAction={onDeleteItemClick}
        actionIconProps={{
          name: "cilXCircle",
          className: "delete-circle",
        }}
      />
      <ARInputGroup className="mt-1 mb-3" id={`${phoneInputName}_form`}>
        <RHFPhoneInput id={id} name={phoneInputName} control={control} autoComplete={autoComplete} />
        <ARInputGroupAppend>
          <ARButton
            color="primary"
            variant="outline"
            className={classNames("field_right-border-radius", buttonClassName)}
            onClick={handleSubmit((data: IFormData, event: React.BaseSyntheticEvent | undefined) =>
              onAddItemClick(data, event)
            )}
          >
            {t("form:button.add", "Add")}
          </ARButton>
        </ARInputGroupAppend>
        <ValidationFeedback error={errors[phoneInputName]} />
      </ARInputGroup>
    </>
  );
};

export default RHFPhoneSelector;
