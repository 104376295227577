import Content from "../Content/Content";
import routeNetwork from "routes";
import { useSelector } from "react-redux";
import { tenantSelector } from "store/selectors/global";
import { isAuthorizedSelector } from "store/selectors/auth";
import { withErrorBoundary } from "components/ErrorBoundary";
import HeaderLanguageDropdown from "../AuthorizedPageWrapper/Header/HeaderLanguageDropdown/HeaderLanguageDropdown";
import {
  ARCard,
  ARCardBody,
  ARCardGroup,
  ARCol,
  ARContainer,
  ARHeaderNav,
  ARLogo,
  ARRow,
} from "components/ARComponents";

const UnAuthorizedPageWrapper = () => {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const tenant = useSelector(tenantSelector);

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <ARContainer>
        <ARRow className="justify-content-center">
          <ARCol md="8">
            <ARCardGroup>
              <ARCard className="p-4">
                <ARCardBody>
                  <div className="d-flex align-items-start">
                    <ARLogo />
                    <ARHeaderNav className="ml-auto">
                      <HeaderLanguageDropdown />
                    </ARHeaderNav>
                  </div>
                  <Content routes={routeNetwork.getRoutes(tenant, isAuthorized)} />
                </ARCardBody>
              </ARCard>
            </ARCardGroup>
          </ARCol>
        </ARRow>
      </ARContainer>
    </div>
  );
};

export default withErrorBoundary(UnAuthorizedPageWrapper);
