import MemberLink from "./MemberLink";
import { ToolTipContentProps } from "../../_types";

const ToolTipContent = (props: ToolTipContentProps) => {
  const { toolTipInfo, paused_by } = props;

  return (
    <span className="ARStatusTextTooltip">
      {toolTipInfo.content}
      <MemberLink paused_by={paused_by} />
    </span>
  );
};

export default ToolTipContent;
