import { ChangeEvent, useEffect } from "react";
import ru from "react-phone-input-2/lang/ru.json";
import { useTranslation } from "react-i18next";
import { ClassName } from "models/types";
import { ARPhoneInput } from "components/ARComponents";
import { generateEvent } from "components/ReactHookForm/utils";
import { Control, ICustomError } from "components/ReactHookForm/_types";
import { useControllerWithCustomError } from "components/ReactHookForm/hooks";

type CountryData = {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
};

export interface IRHFPhoneInputProps {
  id: string;
  name: string;
  block?: boolean;
  control: Control;
  customContainerClass?: ClassName;
  setError?: (error: ICustomError) => void;
  customInputClass?: ClassName;
  autoComplete?: string;
  disabled?: boolean;
}

type ChangeHandler = (
  value: string,
  data: CountryData | {},
  event: ChangeEvent<HTMLInputElement>,
  formattedValue: string
) => void;

const RHFPhoneInput = (props: IRHFPhoneInputProps) => {
  const {
    id,
    name,
    block,
    control,
    customContainerClass = "",
    setError,
    customInputClass = "",
    ...inputProps
  }: IRHFPhoneInputProps = props;
  const { i18n } = useTranslation();
  const { field, fieldState, formState } = useControllerWithCustomError({
    name,
    control,
    shouldUnregister: true,
  });

  const { onChange, onBlur, ref, value, ...reg } = field;
  const { invalid, error, isDirty } = fieldState;
  const { isSubmitted } = formState;

  useEffect(() => {
    setError && setError(error);
  }, [setError, error]);

  // @ts-ignore
  const changeHandler: ChangeHandler = (value, countryData, event) => {
    const newEvent = generateEvent({ event, newValue: value });

    onChange(newEvent);
  };

  const blurHandler = () => {
    onBlur && onBlur();
  };

  const valid = (isDirty || isSubmitted) && !error;

  const validationClass = invalid ? "is-invalid" : valid ? "is-valid" : "";
  const blockClass = block ? "w-100" : "";

  return (
    <ARPhoneInput
      key={i18n.language}
      localization={i18n.language === "ru" ? ru : undefined}
      containerClass={["flags-container", validationClass, blockClass, customContainerClass].join(" ")}
      inputClass={[validationClass, customInputClass].join(" ")}
      inputProps={{ ...inputProps, ...reg, id }}
      country="ru"
      value={value}
      jumpCursorToEnd={false}
      numberInputRef={ref}
      onChange={changeHandler}
      onBlur={blurHandler}
    />
  );
};

export default RHFPhoneInput;
