import { ACTIONS, ROLES } from "../constants";

const permissions = new Map<ACTIONS, ROLES[]>();

//Project role dependent permissions
permissions
  //viewer
  .set(ACTIONS.VIEW_PROJECT_CHECKS_DETAILS, [ROLES.PROJECT_ADMIN, ROLES.PROJECT_VIEWER])
  .set(ACTIONS.VIEW_PROJECT_CHECKS_RULES, [ROLES.PROJECT_ADMIN, ROLES.PROJECT_VIEWER])
  .set(ACTIONS.VIEW_PROJECT_CHECKS_LOG, [ROLES.PROJECT_ADMIN, ROLES.PROJECT_VIEWER])
  .set(ACTIONS.TAKE_PROJECT_CHECKS_TO_WORK, [ROLES.PROJECT_ADMIN, ROLES.PROJECT_VIEWER])

  //admin
  .set(ACTIONS.VIEW_PROJECT, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.CHANGE_CHECK_PROJECT, [ROLES.PROJECT_ADMIN])

  .set(ACTIONS.EDIT_PROJECT_CHECKS, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.ENABLE_DISABLE_PROJECT_CHECKS, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.DUPLICATE_PROJECT_CHECKS, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.DELETE_PROJECT_CHECKS, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.VIEW_PROJECT_CHECKS_ACTION_DROPDOWN, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.VIEW_PROJECT_CHECKS_ACTION_DROPDOWN, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.VIEW_PROJECT_CHECKS_HISTORY, [ROLES.PROJECT_ADMIN])

  .set(ACTIONS.VIEW_CHECKS_RULES, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.CREATE_CHECKS_RULES, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.EDIT_CHECKS_RULES, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.DELETE_CHECKS_RULES, [ROLES.PROJECT_ADMIN])
  .set(ACTIONS.ENABLE_DISABLE_CHECKS_RULES, [ROLES.PROJECT_ADMIN]);

export function getProjectRole(isAdmin: boolean) {
  return isAdmin ? ROLES.PROJECT_ADMIN : ROLES.PROJECT_VIEWER;
}

export function hasProjectPermission(action: ACTIONS, projectRole?: ROLES) {
  if (!projectRole) {
    return false;
  }

  if (permissions.has(action)) {
    return permissions.get(action)!.includes(projectRole);
  }

  return false;
}
