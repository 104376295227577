import i18next from "services/language/i18next";
import { ACTIONS, hasProjectPermission } from "utils/user-access";
import { ROLES } from "utils/constants";
import { ICheck } from "pages/checks/_types";

export function getTableColumnHeaders(projectRole: ROLES) {
  const tableColumnHeaders = [
    {
      key: "status",
      label: i18next.t("table:header.state", "State"),
      _style: { width: "7%" },
      isShown: hasProjectPermission(ACTIONS.VIEW_PROJECT_CHECKS_LOG, projectRole),
    },
    {
      key: "created",
      label: i18next.t("table:header.when", "When"),
      isShown: hasProjectPermission(ACTIONS.VIEW_PROJECT_CHECKS_LOG, projectRole),
    },
  ];

  return tableColumnHeaders.filter((item) => item.isShown);
}

export function getLastCheckStatus({ is_up }: ICheck) {
  return is_up ? "UP" : "DOWN";
}
