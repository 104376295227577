import { useEffect, useState } from "react";
import { IRule } from "components/RulesComponents/_types";
import { ICheckRules } from "pages/checks/_types";

const useRulesState = (checkRules: ICheckRules[]) => {
  const [rulesInfo, setRulesInfo] = useState<ICheckRules[]>([]);

  const deleteRuleFromState = (ruleId: number) => {
    const newRules = rulesInfo.filter((rule) => rule.id !== ruleId);
    setRulesInfo([...newRules]);
  };

  const updateRuleFromState = (updatedRuleData: IRule, ruleId: number) => {
    const newRules = [...rulesInfo];

    const ruleIndex = newRules.findIndex((rule) => rule.id === ruleId);
    newRules[ruleIndex] = {
      ...updatedRuleData,
    };

    setRulesInfo([...newRules]);
  };

  useEffect(() => {
    if (checkRules) {
      setRulesInfo([...checkRules]);
    }
  }, [checkRules]);

  return {
    rulesInfo,
    deleteRuleFromState,
    updateRuleFromState,
  };
};

export default useRulesState;
