import * as yup from "yup";
import parsePhoneNumber from "libphonenumber-js";
import { Message } from "../schemes/messages";

function phone(this: yup.StringSchema, message: Message) {
  return this.test("phone", message, (value) => {
    if (!value) {
      return true;
    }
    const parsedNumber = parsePhoneNumber("+" + value);
    const isValid = Boolean(parsedNumber?.isValid());

    return isValid;
  });
}

export default phone;
