import classNames from "classnames";
import { Control } from "react-hook-form";
import ValidationFeedback from "../../ValidationFeedback";
import { ARFormGroup, ARLabel, ARSwitch } from "components/ARComponents";
import { ARSwitchProps } from "components/ARComponents/_types";
import { useControllerWithCustomError } from "components/ReactHookForm/hooks";
import { ClassName } from "models/types";

interface IRHFSwitch extends ARSwitchProps {
  id: string;
  name: string;
  control: Control;
  className: ClassName;
  labelClassName: string;
  labelText: string;
}

const RHFSwitch = (props: IRHFSwitch) => {
  const {
    control,
    name,
    id,
    labelText,
    className,
    labelClassName,
    size = "sm",
    variant = "3d",
    color = "primary",
    ...rest
  } = props;

  const { field, fieldState } = useControllerWithCustomError({ name, control });
  const { ref, value, ...switchProps } = field;
  const { error } = fieldState;

  return (
    <ARFormGroup className={classNames(className)}>
      <ARSwitch id={id} size={size} variant={variant} color={color} checked={value} {...rest} {...switchProps} />
      <ARLabel htmlFor={id || ""} className={classNames(labelClassName)}>
        {labelText}
      </ARLabel>
      <ValidationFeedback error={error} />
    </ARFormGroup>
  );
};

export default RHFSwitch;
