import { useSelector } from "react-redux";
import { projectsSelector } from "store/selectors/user";
import { useTableConfig } from "utils/hooks";
import i18next from "services/language/i18next";
import { MANAGE_CHECK_ACTIONS } from "utils/constants";
import { showNotification } from "utils/actionWrappers";
import { numberToTimeDuration } from "utils/timeHandlers";
import { deleteCheckRequest, getChecksRequest, manageCheckRequest } from "services/api";
import { IProject } from "models/types";
import { checkId } from "pages/checks/_types";

export interface ICheckData {
  id: checkId;
  is_enabled: boolean;
  is_paused: boolean;
  is_up: boolean;
  is_user_project_admin: boolean;
  name: string;
  pause_finish: null | string;
  paused_by: any;
  pauses_nn?: [number, number | string][];
  project?: IProject;
  state_effective_since: string;
  type: string;
  url: string;
}

const useChecksData = () => {
  const tableConfig = useTableConfig<ICheckData>(getChecksRequest);
  const { setProjectId, setTableItems } = tableConfig;
  const projects: IProject[] = useSelector(projectsSelector);
  const onSelectedProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => setProjectId(event.target.value);

  const updateCheck = (id: string | number, checkData: ICheckData) =>
    setTableItems((oldChecks) => {
      const project = projects.find((project) => String(project.id) === String(checkData.project));
      return oldChecks.map((check) => (String(check.id) === id ? { ...checkData, project } : check));
    });
  const returnCheck = (id: number | string) => {
    manageCheckRequest(id, MANAGE_CHECK_ACTIONS.RESUME)!
      .then(({ data }) => updateCheck(id, data))
      .then(() =>
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t("notification:message.returned.check", "Check has been returned."),
          "success"
        )
      );
  };
  const pauseCheck = (id: string | number, time: number) => {
    manageCheckRequest(id, MANAGE_CHECK_ACTIONS.PAUSE, { duration: numberToTimeDuration(time) })!
      .then(({ data }) => updateCheck(id, data))
      .then(() =>
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t("notification:message.paused.check", "Check has been taken to work."),
          "success"
        )
      );
  };
  const deleteCheck = (id: string | number) => {
    deleteCheckRequest(id)
      .then(() => setTableItems((oldChecks) => oldChecks.filter((check) => check.id !== id)))
      .then(() =>
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t("notification:message.deleted.check", "Check has been deleted."),
          "success"
        )
      );
  };
  const enableDisableCheck = (id: string | number, isEnabled?: boolean) => {
    const command = isEnabled ? MANAGE_CHECK_ACTIONS.DISABLE : MANAGE_CHECK_ACTIONS.ENABLE;
    manageCheckRequest(id, command)!
      .then(({ data }) => updateCheck(id, data))
      .then(() =>
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t(`notification:message.${command + "d"}.check`, `Check has been ${command + "d"}`),
          "success"
        )
      );
  };

  return {
    tableConfig,
    deleteCheck,
    pauseCheck,
    returnCheck,
    enableDisableCheck,
    onSelectedProjectChange,
  };
};

export default useChecksData;
