import * as yup from "yup";
import { Message } from "../schemes/messages";

function port(this: yup.StringSchema, message: Message) {
  return this.test("port", message, (value) => {
    if (!value) {
      return true;
    }
    return Number(value) <= 65535;
  });
}

export default port;
