import useRulesState from "./useRulesState";
import { deleteRuleRequest, updateRuleRequest } from "services/api";
import { IRule } from "components/RulesComponents/_types";
import { ICheckRules } from "pages/checks/_types";

interface IDataWithRule {
  data: IRule;
}

const useRulesInfo = (checkRules: ICheckRules[]) => {
  const { rulesInfo, deleteRuleFromState, updateRuleFromState } = useRulesState(checkRules);

  const RuleActions = {
    deleteRule: (id: number) => {
      return new Promise((resolve) => {
        deleteRuleRequest(id).then(() => {
          deleteRuleFromState(id);
          resolve(null);
        });
      });
    },

    updateRule: (ruleData: Partial<ICheckRules>, id: number) => {
      return new Promise((resolve) => {
        updateRuleRequest(ruleData, id, false).then((response: IDataWithRule) => {
          updateRuleFromState(response.data, id);
          resolve(null);
        });
      });
    },
  };

  return {
    rulesInfo,
    RuleActions,
  };
};

export default useRulesInfo;
