import classNames from "classnames";
import { ARRow } from "components/ARComponents";
import ProjectSelector from "components/ProjectSelector";
import { withErrorBoundary } from "components/ErrorBoundary";
import ItemsPerPageSelector from "../ItemsPerPageSelector/ItemsPerPageSelector";
import { TABLE_ITEMS_PER_PAGE_OPTIONS } from "utils/constants";
import { IProject } from "./../../../models/types";

interface IIncidentsOverTableRowProps {
  disabled: boolean;
  className: string;
  itemsPerPage?: number;
  projectSelectOptions: IProject[];
  onItemsPerPageChange: any;
  onSelectedProjectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const IncidentsOverTableRow = (props: IIncidentsOverTableRowProps) => {
  const { disabled, className, itemsPerPage, projectSelectOptions, onItemsPerPageChange, onSelectedProjectChange } =
    props;

  return (
    <ARRow className={classNames("IncidentsOverTableRow", className)}>
      <ProjectSelector options={projectSelectOptions} onChange={onSelectedProjectChange} disabled={disabled} />
      <ItemsPerPageSelector
        options={TABLE_ITEMS_PER_PAGE_OPTIONS}
        onChange={onItemsPerPageChange}
        disabled={disabled}
        value={itemsPerPage!}
      />
    </ARRow>
  );
};

export default withErrorBoundary(IncidentsOverTableRow);
