/**
 * Convert file to Base64 format
 *
 * @param file
 */
export const getBase64 = (file: File): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    const onLoad = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(new TextDecoder().decode(reader.result));
      } else {
        resolve(reader.result);
      }

      reader.removeEventListener("load", onLoad);
    };

    reader.addEventListener("load", onLoad);

    const onError = (error: ProgressEvent<FileReader>) => {
      reject(error);
      reader.removeEventListener("error", onError);
    };

    reader.addEventListener("error", onError);
  });
};

/**
 * Return "FormData" with infoObject properties
 *
 * @param infoObject entry object
 */
export const getFormDataObject = <T extends Object>(infoObject: T): FormData => {
  const formDataObject = new FormData();

  let itemName: keyof T;
  for (itemName in infoObject) {
    const infoField = String(infoObject[itemName]);
    formDataObject.append(itemName, infoField);
  }

  return formDataObject;
};
