import React from "react";
import { PATHS } from "route-dictionary";
import { IARStatusTextProps } from "../_types";
import ToolTipContent from "./ToolTipContent";
import { ARTooltip } from "components/ARComponents";
import { getStatusText, getToolTipInfo } from "./ARStatusText.model";

const ARStatusText = (props: IARStatusTextProps) => {
  const { info, is_up, is_paused, is_enabled, paused_by, pause_finish, state_effective_since } = props;
  const statusText = getStatusText({ is_paused, is_enabled, is_up });
  const toolTipInfo = getToolTipInfo({ info, is_enabled, is_up, is_paused, pause_finish, state_effective_since });

  const StatusTextName = (
    <div
      className="ARStatusText"
      onClick={(e: React.MouseEvent<HTMLDivElement>): void => e.stopPropagation()}
      style={{ backgroundColor: statusText.color }}
    >
      {statusText.name}
    </div>
  );

  const onMemberLinkClick = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    window.open(PATHS.MEMBERS.getDetailsIdPath?.(paused_by?.id));
  };

  const stopPropagation = (e: MouseEvent): void => {
    e.stopPropagation();
  };

  const onTooltipMount = (): void => {
    const memberLink = document.getElementById("member_link") as HTMLAnchorElement;
    memberLink?.addEventListener("click", onMemberLinkClick);

    const memberLinkSpan = document.getElementById("MemberLink") as HTMLSpanElement;
    memberLinkSpan?.addEventListener("click", stopPropagation);
  };

  const onTooltipHidden = (): void => {
    const memberLink = document.getElementById("member_link") as HTMLAnchorElement;
    memberLink?.removeEventListener("click", onMemberLinkClick);

    const memberLinkSpan = document.getElementById("MemberLink") as HTMLSpanElement;
    memberLinkSpan?.removeEventListener("click", stopPropagation);
  };

  return toolTipInfo.display ? (
    <ARTooltip
      content={<ToolTipContent toolTipInfo={toolTipInfo} paused_by={paused_by} />}
      advancedOptions={{
        allowHTML: true,
        onMount: onTooltipMount,
        onUntrigger: onTooltipHidden,
      }}
      interactive
    >
      {StatusTextName}
    </ARTooltip>
  ) : (
    StatusTextName
  );
};

export default ARStatusText;
