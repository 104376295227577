import i18next from "i18next";
import {PATHS} from "route-dictionary";
import {getTableNoItemsView} from "utils";
import {useHistory} from "react-router-dom";
import React, {useMemo, useState} from "react";
import AddButtonRow from "components/AddButtonRow";
import {ROLES_PROPERTY_NAMES} from "utils/constants";
import {showNotification} from "utils/actionWrappers";
import {roleOptions} from "../ProjectDetails.model";
import {getItemsPerPageSelectValues} from "./TableCard.model";
import {
  ARDataTable,
  ARDropdown,
  ARDropdownItem,
  ARDropdownMenu,
  ARDropdownToggle,
  ARIcon,
  ARRow
} from "components/ARComponents";

const TableCard = (props) => {
  const {
    items,
    fields,
    className,
    headerText,
    updateRoles,
    isMainProject,
    rolePropertyNames,
    getTableFilterText,
    addRowClickCallback,
  } = props;

  const history = useHistory();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [tableFilterValue, setTableFilterValue] = useState("");

  const tableFilteredItems = useMemo(() => {
    return items.filter((item) => item[rolePropertyNames.singular].name.includes(tableFilterValue));
  }, [tableFilterValue, items, rolePropertyNames.singular]);

  const onChangeRoleClick = (item) => {
    const groupOrMemberItem = item[rolePropertyNames.singular];

    const newItems = items.map((roleItem) => {
      const groupOrMemberRoleItem = roleItem[rolePropertyNames.singular];
      if (groupOrMemberRoleItem.id === groupOrMemberItem.id) {
        return {
          ...roleItem,
          role: roleItem.role === "admin" ? "viewer" : "admin"
        };
      }
      return roleItem;
    });
    updateRoles(rolePropertyNames.plural, newItems)
      .then(() => showNotification(
        i18next.t("notification:title.success", "Success!"),
        i18next.t("notification:message.changed.role", "Role has been successfully changed."),
        "success"
      ));
  };

  const onDeleteRoleClick = (item) => {
    const groupOrMemberItem = item[rolePropertyNames.singular];

    const newItems = items.filter((roleItem) => {
      const groupOrMemberRoleItem = roleItem[rolePropertyNames.singular];
      return groupOrMemberRoleItem.id !== groupOrMemberItem.id;
    });

    updateRoles(rolePropertyNames.plural, newItems)
      .then(() => showNotification(
        i18next.t("notification:title.success", "Success!"),
        i18next.t(`notification:message.deleted.fromProject.${rolePropertyNames.singular}`),
        "success"
      ))
    ;
  };

  const getClickAction = (id) => rolePropertyNames.singular === ROLES_PROPERTY_NAMES.members.singular ?
    history.push(PATHS.MEMBERS.getDetailsIdPath(id)) :
    history.push(PATHS.GROUPS.getDetailsIdPath(id));

  return (
    <div className={`${className} TableCard`}>
      <ARRow className="TableCard__header card-header-text">
        <h4>{headerText}</h4>
        <AddButtonRow onClick={addRowClickCallback}/>
      </ARRow>
      {
        !!items?.length &&
        <ARDataTable
          items={tableFilteredItems}
          addTableClasses={"TableCard-table"}
          fields={fields}
          noItemsView={getTableNoItemsView()}
          hover
          striped
          border
          cleaner
          pagination
          clickableRows
          itemsPerPageSelect={getItemsPerPageSelectValues()}
          itemsPerPage={itemsPerPage}
          onPaginationChange={setItemsPerPage}
          tableFilter={getTableFilterText()}
          tableFilterValue={tableFilterValue}
          onTableFilterChange={setTableFilterValue}
          scopedSlots={
            {
              "name":
                (item) => (
                  <td
                    color="primary"
                    className="TableCard-table__cell"
                    onClick={() => getClickAction(item[rolePropertyNames.singular].id)}
                  >
                    {item[rolePropertyNames.singular].name}
                  </td>
                ),
              "role":
                (item) => (
                  <td
                    className="TableCard-table__cell"
                    onClick={() => getClickAction(item[rolePropertyNames.singular].id)}
                  >
                    {roleOptions().find((role) => role.value === item.role)?.name || ""}
                  </td>
                ),
              "actions_column":
                (item) => (
                  <td>
                    <ARDropdown className="dropdown_no-caret">
                      <ARDropdownToggle
                        className="btn-block"
                        size="sm"
                        color="dark"
                        variant="outline"
                      >
                        <ARIcon name="cilMinus" size="sm"/>
                      </ARDropdownToggle>
                      <ARDropdownMenu placement="left">
                        <ARDropdownItem onClick={() => onChangeRoleClick(item)}>
                          {i18next.t("button.changeRole", "Change role")}
                        </ARDropdownItem>
                        {
                          !isMainProject &&
                          <ARDropdownItem onClick={() => onDeleteRoleClick(item)}>
                            {i18next.t("button.delete", "Delete")}
                          </ARDropdownItem>
                        }
                      </ARDropdownMenu>
                    </ARDropdown>
                  </td>
                )
            }
          }
        />
      }
    </div>
  );
};

export default TableCard;