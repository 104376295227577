import { useGroupsInfo } from "./index";
import { PATHS } from "route-dictionary";
import { useHistory } from "react-router-dom";
import i18next from "services/language/i18next";
import { RHFInputWithLabel } from "components/ReactHookForm/fields";
import useFormModal from "components/ModalWindow/hooks/useFormModal";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { groupAddModalFormSchema } from "components/ReactHookForm/schemes";

const useGroupsTable = () => {
  const history = useHistory();
  const { groups, createGroup, successfulCreateGroupSubscriberRef, deleteGroup } = useGroupsInfo();

  const showCreateGroupModal = useFormModal(createGroup);

  const onAddGroupClick = () => {

    successfulCreateGroupSubscriberRef.current = (groupId) => {
      history.push(PATHS.GROUPS.getDetailsIdPath!(groupId));
    };

    showCreateGroupModal({
      title: i18next.t("modal:title.groupAdd", "Add group"),
      formId: "group-add-form",
      validationSchema: groupAddModalFormSchema,
      defaultValues: { name: "" },
      fields: (
        <RHFInputWithLabel
          id="name"
          name="name"
          autoComplete="off"
          labelText={i18next.t("form:label.group", "Group name")}
          placeholder={i18next.t("form:placeholder.group", "Enter group's name")}
        />
      ),
    });
  };

  const onDeleteButtonClick = (groupId: number) => {
    showModalWindow.confirm({
      message: i18next.t("modal:message.delete.group", "Are you sure you want to delete this group?"),
      color: "danger",
      onConfirm: () =>
        deleteGroup(groupId).then(() =>
          showNotification(
            i18next.t("notification:title.success", "Success!"),
            i18next.t("notification:message.deleted.group", "Group has been deleted."),
            "success"
          )
        ),
    });
  };

  return {
    groups,
    onAddGroupClick,
    onDeleteButtonClick,
  };
};

export default useGroupsTable;
