import { DAY_TYPES } from "utils/constants";
import { useChecksRulesData } from "../hooks";
import { useTranslation } from "react-i18next";
import { SelectOptions } from "./RuleForm.model";
import { ARForm } from "components/ARComponents";
import { RHFPrependAppendSelect } from "components/ReactHookForm/fields";
import {
  getMemberText,
  getPhoneNumberText,
  getTelegramChatText,
} from "components/RulesComponents/RulesComponents.model";
import {
  RHFElementsSelector,
  RHFInputCheckbox,
  RHFPhoneSelector,
  RHFTimeInput,
} from "components/ReactHookForm/fields/custom";
import { IRuleFormData } from "../hooks/useRuleFormConfig";
import { UseFormReturn } from "react-hook-form";

interface IRuleFormProps {
  formId: string;
  formControl: UseFormReturn<IRuleFormData, object>;
  onFormSubmit: (values: IRuleFormData) => void;
  onTypeSelectChange: (type: DAY_TYPES) => void;
}

const RuleForm = (props: IRuleFormProps) => {
  const { t } = useTranslation();
  const { formId, formControl, onFormSubmit, onTypeSelectChange } = props;
  const { members, telegramChats } = useChecksRulesData();
  const { control, trigger, handleSubmit, watch } = formControl;
  const isAnyDayType = watch("day_type") === DAY_TYPES.ANY_DAY;

  return (
    <ARForm id={formId} onSubmit={handleSubmit(onFormSubmit)}>
      <RHFTimeInput
        id="from_time"
        name="from_time"
        control={control}
        trigger={() => trigger("to_time")}
        prependComponent={t("form:label.fromTime", "From")}
      />
      <RHFTimeInput
        id="to_time"
        name="to_time"
        control={control}
        trigger={() => trigger("from_time")}
        prependComponent={t("form:label.toTime", "To")}
      />
      <RHFPrependAppendSelect
        id="day_type"
        name="day_type"
        control={control}
        onSelectChange={onTypeSelectChange}
        options={SelectOptions.dayType}
        prependText={t("form:label.dayType", "Day type")}
      />
      <RHFInputCheckbox
        id="is_inverted_condition"
        name="is_inverted_condition"
        control={control}
        disabled={isAnyDayType}
        className="checkbox"
        labelClassName="checkbox__label"
        labelText={t("form:label.inversion", "Invert conditions")}
      />
      <RHFPrependAppendSelect
        id="delay"
        name="delay"
        control={control}
        options={SelectOptions.delay}
        prependText={t("form:label.delay", "Delay")}
      />
      <RHFPrependAppendSelect
        id="recur"
        name="recur"
        control={control}
        options={SelectOptions.recur}
        prependText={t("form:label.recurment", "Recurment")}
      />
      <RHFPhoneSelector
        id="add_phone_input"
        name="phones"
        externalFormControl={control}
        getItemText={getPhoneNumberText}
        prependComponent={t("form:label.phones", "Phones")}
        emptyText={t("noData.empty", "Empty")}
      />
      <RHFElementsSelector
        id="add_telegram_chats"
        name="telegram_chats"
        items={telegramChats}
        control={control}
        getItemText={getTelegramChatText}
        selectedItemsPrependComponent={t("form:label.chats", "Chats")}
      />
      <RHFElementsSelector
        id="add_members_to_call"
        name="members_to_call"
        items={members}
        control={control}
        getItemText={getMemberText}
        selectedItemsPrependComponent={t("form:label.membersToCall", "Members to call")}
      />
    </ARForm>
  );
};

export default RuleForm;
