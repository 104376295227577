import React, { useState } from "react";
import classNames from "classnames";
import ValidationFeedback from "../../ValidationFeedback";
import {
  ARCol,
  ARFormGroup,
  ARInputGroup,
  ARInputGroupAppend,
  ARInputGroupPrepend,
  ARInputGroupText,
  ARLabel,
} from "components/ARComponents";
import { ICustomError } from "components/ReactHookForm/_types";

export interface IRHFLabelProps {
  id: string;
  labelText: string;
  prependText?: string;
  appendText?: string;
  groupClassName?: string;
  labelColClassName?: string;
  children?: React.ReactNode;
}

const RHFLabel = ({
  id,
  labelText,
  prependText,
  appendText,
  groupClassName,
  labelColClassName,
  children,
}: IRHFLabelProps) => {
  const [error, setError] = useState<ICustomError | undefined>(undefined);
  return (
    <ARFormGroup row>
      <ARCol md="3" className={classNames("form-label-col", labelColClassName)}>
        <ARLabel htmlFor={id || ""} className="form-label">
          {labelText}
        </ARLabel>
      </ARCol>
      <ARCol xs="12" md="9">
        <ARInputGroup className={classNames(groupClassName)}>
          {!!prependText && (
            <ARInputGroupPrepend>
              <ARInputGroupText>{prependText}</ARInputGroupText>
            </ARInputGroupPrepend>
          )}
          {React.isValidElement(children) && React.cloneElement(children, { setError })}
          {!!appendText && (
            <ARInputGroupAppend>
              <ARInputGroupText>{appendText}</ARInputGroupText>
            </ARInputGroupAppend>
          )}
        </ARInputGroup>
        <ValidationFeedback error={error} />
      </ARCol>
    </ARFormGroup>
  );
};

export default RHFLabel;
