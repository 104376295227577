import { GLOBAL } from "../actions/types";
import { GlobalActionType, IBaseGlobalState } from "./globalTypes";

const initialState: IBaseGlobalState = {
  isFetching: false,
  requestsNum: 0,
  isShowingSpinner: false,
  requestsWithSpinnerNum: 0,
  tenant: {
    isPublicTenant: null,
    isExist: null,
    error: null,
    hasAdmin: null,
    subscriptionType: null,
    telegramTokenIsSet: null,
  },
  checkName: "",
};

const globalReducer = (state = initialState, action: GlobalActionType): IBaseGlobalState => {
  switch (action.type) {
    case GLOBAL.FETCHING:
      return {
        ...state,
        isFetching: true,
        requestsNum: state.requestsNum + 1,
      };
    case GLOBAL.FETCHED:
      const newRequestsNum = state.requestsNum - 1;
      if (newRequestsNum === 0) {
        return {
          ...state,
          isFetching: false,
          requestsNum: newRequestsNum,
        };
      } else {
        return {
          ...state,
          requestsNum: newRequestsNum,
        };
      }
    case GLOBAL.SHOW_SPINNER:
      return {
        ...state,
        isShowingSpinner: true,
        requestsWithSpinnerNum: state.requestsWithSpinnerNum + 1,
      };
    case GLOBAL.HIDE_SPINNER:
      const newRequestsWithSpinnerNum = state.requestsWithSpinnerNum - 1;
      if (newRequestsWithSpinnerNum === 0) {
        return {
          ...state,
          isShowingSpinner: false,
          requestsWithSpinnerNum: newRequestsWithSpinnerNum,
        };
      } else {
        return {
          ...state,
          requestsWithSpinnerNum: newRequestsWithSpinnerNum,
        };
      }
    case GLOBAL.SET_TENANT:
      return {
        ...state,
        tenant: action.payload,
      };
    case GLOBAL.SET_HAS_ADMIN:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          hasAdmin: action.payload.flag,
        },
      };
    case GLOBAL.SET_SUBSCRIPTION_STATUS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          subscriptionType: action.payload.subscriptionType,
        },
      };
    case GLOBAL.SET_TELEGRAM_TOKEN_STATUS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          telegramTokenIsSet: action.payload.telegramTokenIsSet,
        },
      };
    case GLOBAL.SET_CHECK_NAME:
      return {
        ...state,
        checkName: action.payload.checkName,
      };
    case GLOBAL.RESET_CHECK_NAME:
      return {
        ...state,
        checkName: "",
      };
    default:
      return state;
  }
};

export default globalReducer;
