import { useTranslation } from "react-i18next";
import useProjectsData from "./useProjectsData";
import { RHFInputWithLabel } from "components/ReactHookForm/fields";
import useFormModal from "components/ModalWindow/hooks/useFormModal";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { projectAddModalFormSchema } from "components/ReactHookForm/schemes";
import { projectDeletingHandler } from "../ProjectDetails/ProjectDetails.model";

const useProjectsTable = () => {
  const { t } = useTranslation();
  const { projects, createProject, successfulCreateProjectSubscriberRef, deleteProject } = useProjectsData();

  const showCreateProjectModal = useFormModal(createProject);

  const onAddButtonClick = () => {
    successfulCreateProjectSubscriberRef.current = () => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.added.project", "Project has been successfully added."),
        "success"
      );
    };

    showCreateProjectModal({
      title: t("page.project.add", "Add project"),
      formId: "project-add-form",
      defaultValues: { name: "" },
      validationSchema: projectAddModalFormSchema,
      fields: (
        <RHFInputWithLabel
          id="name"
          name="name"
          autoComplete="off"
          labelText={t("form:label.projectName", "Project name")}
          placeholder={t("form:placeholder.projectName", "Enter project's name")}
        />
      ),
    });
  };

  const onDeleteButtonClick = (id: string) => {
    showModalWindow.confirm({
      message: t("modal:message.delete.project", "Are you sure you want to delete this project?"),
      color: "danger",
      onConfirm: () => {
        deleteProject(id)
          .then(() =>
            showNotification(
              t("notification:title.success", "Success!"),
              t("notification:message.deleted.project", "Project has been deleted."),
              "success"
            )
          )
          .catch((error) => projectDeletingHandler(error));
      },
    });
  };

  return {
    projects,
    onAddButtonClick,
    onDeleteButtonClick,
  };
};

export default useProjectsTable;
