import React from "react";
import {ARInputGroup, ARLabel, ARSwitch} from "components/ARComponents";

const Switcher = (props) => {
  const {switcherHandler, className, checked, switcherId, switcherText} = props;

  return (
    <ARInputGroup className={`input-group ${className}`}>
      <ARSwitch
        id={switcherId}
        size="sm"
        variant="3d"
        color="info"
        className="input-group__switch"
        checked={checked}
        onChange={switcherHandler}
      />
      <ARLabel htmlFor={switcherId} className="input-group__label">
        {switcherText}
      </ARLabel>
    </ARInputGroup>
  );
};

export default Switcher;