import * as yup from "yup";
import { Message } from "../schemes/messages";
import { getTenantInfoRequest } from "services/api";
import { API_DOMAIN, REG_EXS } from "utils/constants";

function subdomain(this: yup.StringSchema, message: Message) {
  return this.test("subdomain", message, (value) => {
    if (!value) {
      return true;
    }
    return REG_EXS.VALID_DOMAIN.test(value);
  });
}

function domainExist(this: yup.StringSchema, message: Message) {
  return this.test("domainExist", message, (value) => {
    if (!value) {
      return true;
    }

    return getTenantInfoRequest(`${value.toLowerCase()}.${API_DOMAIN}`).then((response) => !response.isExist);
  });
}

export { subdomain, domainExist };
