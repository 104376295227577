import authReducer from "./auth";
import userReducer from "./user";
import modalReducer from "./modal";
import globalReducer from "./global";
import { combineReducers } from "redux";
import notificationReducer from "./notification";

export const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  global: globalReducer,
  modal: modalReducer,
  user: userReducer,
});