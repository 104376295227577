import React, {useContext} from "react";
import {useCardInfo} from "../../../hooks";
import {useTranslation} from "react-i18next";
import {CardElement} from "@stripe/react-stripe-js";
import {ARButton, ARForm} from "components/ARComponents";
import {CollapseCardContext} from "../../../subscribe-context";

const PaymentCardChangeContent = () => {
  const isVisibleHandler = useContext(CollapseCardContext);
  const {t} = useTranslation();
  const {eventHandlers, onCancelClick, onFormSubmit} = useCardInfo(isVisibleHandler);

  return (
    <ARForm id="card-info-form" onSubmit={onFormSubmit}>
      <CardElement {...eventHandlers}/>
      <div className="ChangePaymentCardCard__button-row">
        <ARButton
          color="warning"
          variant="outline"
          className="form-control-button"
          onClick={onCancelClick}
        >
          {t("form:button.cancel", "Cancel")}
        </ARButton>
        <ARButton
          type="submit"
          color="success"
          variant="outline"
          className="form-control-button"
        >
          {t("form:button.save", "Save")}
        </ARButton>
      </div>
    </ARForm>
  );
};

export default PaymentCardChangeContent;