import {instance, requestNoSpinnerPromiseWrapper, requestPromiseWrapper} from "./instance";

export function getRulesRequest({isDefault = true, showSpinner = true} = {}) {
  if (!showSpinner) {
    return requestNoSpinnerPromiseWrapper(instance.get(`/rules?default=${isDefault}`));
  }

  return requestPromiseWrapper(instance.get(`/rules?default=${isDefault}`));
}

export function createRuleRequest(ruleData) {
  return requestPromiseWrapper(instance.post("/rules", ruleData));
}

export function updateRuleRequest(ruleData, id, showSpinner = true) {
  if (!showSpinner) {
    return requestNoSpinnerPromiseWrapper(instance.patch(`/rules/${id}`, ruleData));
  }

  return requestPromiseWrapper(instance.patch(`/rules/${id}`, ruleData));
}

export function getRuleByIdRequest(id) {
  return requestPromiseWrapper(instance.get(`/rules/${id}`));
}

export function deleteRuleRequest(id) {
  return requestPromiseWrapper(instance.delete(`/rules/${id}`));
}