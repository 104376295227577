import {instance, requestNoSpinnerPromiseWrapper, requestPromiseWrapper} from "./instance";

export function getTelegramAuthRequest() {
  return requestPromiseWrapper(instance.get("/telegram-auth"));
}

export function getTelegramChatsRequest(showSpinner = true) {
  if (!showSpinner) {
    return requestNoSpinnerPromiseWrapper(instance.get("/telegram_chats"));
  }

  return requestPromiseWrapper(instance.get("/telegram_chats"));
}