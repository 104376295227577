import { MODAL_WINDOW } from "store/actions/types";
import { MODAL_WINDOW_TYPES } from "utils/constants";

interface IFormOptions{
  formId: string;
  subscribeToSuccessfulSubmit: (callback: () => void) => void;
};

interface IModalGlobalState {
  title: string;
  message: string;
  isShowing: boolean;
  type: string;
  color: string;
  onConfirm?: null | {(): void};
  onCancel?: null | {(): void};
  confirmText: string;
  cancelText: string;
  formOptions: null | IFormOptions;
  centered: boolean;
  size?: "" | "sm" | "lg" | "xl";
}

const initialState: IModalGlobalState = {
  title: "",
  message: "",
  isShowing: false,
  type: MODAL_WINDOW_TYPES.ALERT,
  color: "info",
  onConfirm: null,
  onCancel: null,
  confirmText: "",
  cancelText: "",
  formOptions: null,
  centered: true,
};

interface IModalAction {
  type: MODAL_WINDOW_TYPES | MODAL_WINDOW;
  payload?: object;
}

const modalReducer = (state = initialState, action: IModalAction) => {
  switch (action.type) {
    case MODAL_WINDOW.SHOW:
      return {
        ...state,
        ...action.payload,
        isShowing: true,
      };
    case MODAL_WINDOW.HIDE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default modalReducer;
