import axios, { CancelTokenSource, CancelTokenStatic } from "axios";

type IValue = {
  token: CancelTokenStatic,
  cancel: (message: string) => void,
};
interface IBaseCancelSourceObject {
  sourceValue: IValue | null; // TODO уточнить что это
  value: IValue | null | CancelTokenSource;
}

const baseCancelSourceObject: IBaseCancelSourceObject = {
  sourceValue: null, 

  get value() {
    return this.sourceValue;
  },

  set value(newValue: IValue | null) {
    this.sourceValue = newValue;
  }
};

const CancelToken = axios.CancelToken;
const cancelSource = {...baseCancelSourceObject};
const groupCancelSource = {...baseCancelSourceObject};

export {CancelToken, cancelSource, groupCancelSource};