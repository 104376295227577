import { useEffect, useState } from "react";
import { ERR_MESSAGES } from "utils/constants";
import { cancelSource, getRulesRequest } from "services/api";

const useDefaultRulesData = () => {
  const [defaultRulesData, setDefaultRulesData] = useState([]);

  const updateDefaultRulesData = (showSpinner = false) => {
    return getRulesRequest({ showSpinner: showSpinner }).then(({ data }) => {
      setDefaultRulesData(data);
    });
  };

  useEffect(() => {
    updateDefaultRulesData(true);

    return () => {
      cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
    };
  }, []);

  return { rules: defaultRulesData, updateDefaultRulesData };
};

export default useDefaultRulesData;
