import ARImg from "./ARImg";
import { ARImgProps } from "./_types";

const ARLogo = (props: ARImgProps) => {
  const { width = 60, height = 60, placeholderColor = "lightgrey" }: ARImgProps = props;

  return (
    <ARImg
      alt="ARLogo"
      src="/logo/logo_2.png"
      width={width}
      height={height}
      placeholderColor={placeholderColor}
      {...props}
    />
  );
};

export default ARLogo;
