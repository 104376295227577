import { IARIconProps } from "./_types";
import { ARIconComponent, ARIconsSet } from "./_ARBaseComponents";

const ARIcon = (props: IARIconProps) => {
  return (
    <ARIconComponent content={ARIconsSet[props.name]} style={{ fill: props.color || "currentColor" }} {...props} />
  );
};

export default ARIcon;
