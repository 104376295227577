import React from "react";
import classNames from "classnames";
import { ClassName, Href } from "../../models/types";
import { withErrorBoundary } from "../ErrorBoundary";

type PhoneLinkProps = {
  href?: Href;
  className?: ClassName;
};

const PhoneLink: React.FC<PhoneLinkProps> = (props) => {
  const { children, href, className } = props;

  return (
    <a
      className={classNames(className)}
      onClick={(event) => event.stopPropagation()}
      href={`tel: ${typeof children === "string" ? href || children?.replace(/[^+\d]/g, "") : ""}`}
    >
      {children}
    </a>
  );
};

export default withErrorBoundary<PhoneLinkProps>(PhoneLink);
