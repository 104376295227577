import { useGroupsTable } from "./hooks";
import { PATHS } from "route-dictionary";
import { getTableNoItemsView } from "utils";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import AddButtonRow from "components/AddButtonRow";
import { getTableColumnHeaders } from "./Groups.model";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";
import {
  ARCard,
  ARCardBody,
  ARCardHeader,
  ARDataTable,
  ARDropdown,
  ARDropdownItem,
  ARDropdownMenu,
  ARDropdownToggle,
  ARIcon,
} from "components/ARComponents";
import { IGroupMembers } from "./hooks/useGroupInfo";

interface IGroupsItem extends Omit<IGroupMembers, "idsMembers"> {
  members_ids: number[];
  id: number;
  name: string;
}

const Groups = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { groups, onAddGroupClick, onDeleteButtonClick } = useGroupsTable();

  const hasViewMembersGroupsPermission = hasGlobalPermission(ACTIONS.VIEW_MEMBERS_GROUPS);
  const hasEditMembersGroupsPermission = hasGlobalPermission(ACTIONS.EDIT_MEMBERS_GROUPS);

  const getClickAction = (id: number) => hasEditMembersGroupsPermission && history.push(PATHS.GROUPS.getDetailsIdPath!(id));

  return (
    <ARCard className="Groups-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.groups", "Groups")}</h1>
      </ARCardHeader>
      <ARCardBody>
        <ARDataTable
          items={groups}
          addTableClasses="Groups-table"
          fields={getTableColumnHeaders()}
          noItemsView={getTableNoItemsView()}
          overTableSlot={
            <AddButtonRow
              onClick={onAddGroupClick}
              hasAddPermission={hasGlobalPermission(ACTIONS.CREATE_MEMBERS_GROUPS)}
            />
          }
          hover
          striped
          border
          clickableRows={hasEditMembersGroupsPermission || undefined}
          scopedSlots={{
            name: hasViewMembersGroupsPermission
              ? (item: IGroupsItem) => (
                  <td className="Groups-table__cell" onClick={() => getClickAction(item.id)}>
                    {item.name || ""}
                  </td>
                )
              : null,
            usersCount: hasViewMembersGroupsPermission
              ? (item: IGroupsItem) => (
                  <td className="Groups-table__cell text-center" onClick={() => getClickAction(item.id)}>
                    {item.members_ids.length || "0"}
                  </td>
                )
              : null,
            actionsColumn: hasViewMembersGroupsPermission
              ? (item: IGroupsItem) => (
                  <td>
                    <ARDropdown className="dropdown_no-caret">
                      <ARDropdownToggle className="btn-block" size="sm" color="dark" variant="outline">
                        <ARIcon name="cilMinus" size="sm" />
                      </ARDropdownToggle>
                      <ARDropdownMenu placement="left">
                        {hasEditMembersGroupsPermission && (
                          <ARDropdownItem tag={Link} to={PATHS.GROUPS.getDetailsIdPath!(item.id)}>
                            {t("button.edit", "Edit")}
                          </ARDropdownItem>
                        )}
                        {hasGlobalPermission(ACTIONS.DELETE_MEMBERS_GROUPS) && (
                          <ARDropdownItem onClick={() => onDeleteButtonClick(item.id)}>
                            {t("button.delete", "Delete")}
                          </ARDropdownItem>
                        )}
                      </ARDropdownMenu>
                    </ARDropdown>
                  </td>
                )
              : null,
          }}
        />
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(Groups);
