import { useEffect } from "react";
import Avatar from "./Avatar";
import { useProfileForm } from "./hooks";
import i18next from "services/language/i18next";
import { ERR_MESSAGES, REG_EXS } from "utils/constants";
import TelegramAuthContent from "./TelegramAuthContent";
import { withErrorBoundary } from "components/ErrorBoundary";
import { CancelToken, groupCancelSource } from "services/api";
import FormControlsButtons from "components/FormControlButtons";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { RHFInputWithLabel, RHFPhoneInputWithLabel } from "components/ReactHookForm/fields";
import { ARCard, ARCardBody, ARCardFooter, ARCardHeader, ARForm } from "components/ARComponents";

const Profile = () => {
  const { onFormReset, Prompt, onFormSubmit, form } = useProfileForm();
  const { control, trigger, handleSubmit, setFormError } = form;

  useEffect(() => {
    groupCancelSource.value = CancelToken.source();
    return () => {
      groupCancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
      groupCancelSource.value = null;
    };
  }, []);

  return (
    <ARCard className="ProfileCard">
      <ARCardHeader className="ProfileCard__header profile-header">
        <h1 className="card-header-text">{i18next.t("card.title.profile", "Profile")}</h1>
      </ARCardHeader>
      <ARCardBody>
        <Prompt />
        <ARForm id="profile-form" className="form-horizontal" onSubmit={handleSubmit(onFormSubmit)}>
          <Avatar name="avatar" control={control} />
          <RHFInputWithLabel
            id="first_name"
            name="first_name"
            labelText={i18next.t("form:label.firstName", "First name")}
            placeholder={i18next.t("form:placeholder.firstName", "Enter first name")}
            control={control}
            autoComplete="off"
          />
          <RHFInputWithLabel
            id="last_name"
            name="last_name"
            labelText={i18next.t("form:label.lastName", "Last name")}
            placeholder={i18next.t("form:placeholder.lastName", "Enter last name")}
            control={control}
            autoComplete="off"
          />
          <RHFInputWithLabel
            id="email"
            name="email"
            labelText={i18next.t("form:label.email", "Email")}
            placeholder={i18next.t("form:placeholder.email", "Enter email")}
            control={control}
            pattern={REG_EXS.EMAIL_CHARACTERS}
            setFormError={setFormError}
            patternMismatchMessage={MESSAGES.EMAIL_INVALID_CHARACTERS}
            autoComplete="off"
          />
          <RHFPhoneInputWithLabel
            id="phone"
            name="phone"
            labelText={i18next.t("form:label.phone", "Phone")}
            control={control}
            autoComplete="off"
            block
          />
          <RHFInputWithLabel
            id="password"
            name="password"
            type="password"
            labelText={i18next.t("form:label.password_new", "New password")}
            placeholder={i18next.t("form:placeholder.password_new", "Enter new password")}
            control={control}
            trigger={trigger}
            triggerName="confirm_password"
            autoComplete="new-password"
          />
          <RHFInputWithLabel
            id="confirm_password"
            name="confirm_password"
            type="password"
            labelText={i18next.t("form:label.password_newConfirm", "Confirm new password")}
            placeholder={i18next.t("form:label.password_newConfirm", "Confirm new password")}
            control={control}
            autoComplete="off"
          />
        </ARForm>
      </ARCardBody>
      <ARCardFooter>
        <FormControlsButtons form="profile-form" onCancelClick={onFormReset} />
      </ARCardFooter>
      <TelegramAuthContent />
    </ARCard>
  );
};

export default withErrorBoundary(Profile);
