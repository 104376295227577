export const timeMask = "––:––";
export const timeMaskReplaceableChar = "–";

function fillMask(text: string, mask: string, replaceableChar = ".", options = {}) {
  const defaultOptions = { fromEnd: true, useFullText: true, rightSplitStr: " ", leftSplitStr: "" };
  const usedOptions = { ...defaultOptions, ...options };
  let filledMask = "";
  let nextTextCharIndex;
  let lastMaskReplaceableCharIndex: number | undefined;

  if (usedOptions.fromEnd) {
    nextTextCharIndex = text.length - 1;
    for (let charIndex = mask.length - 1; charIndex > -1; charIndex--) {
      if (mask[charIndex] === replaceableChar && nextTextCharIndex > -1) {
        filledMask = text[nextTextCharIndex] + filledMask;
        lastMaskReplaceableCharIndex = charIndex;
        nextTextCharIndex--;
      } else {
        filledMask = mask[charIndex] + filledMask;
      }
    }
  } else {
    nextTextCharIndex = 0;
    for (let charIndex = 0; charIndex < mask.length; charIndex++) {
      if (mask[charIndex] === replaceableChar && nextTextCharIndex < text.length) {
        filledMask = filledMask + text[nextTextCharIndex];
        lastMaskReplaceableCharIndex = charIndex;
        nextTextCharIndex++;
      } else {
        filledMask = filledMask + mask[charIndex];
      }
    }
  }

  if (usedOptions.useFullText && nextTextCharIndex > -1) {
    filledMask =
      filledMask.slice(0, lastMaskReplaceableCharIndex) +
      usedOptions.leftSplitStr +
      text.slice(0, nextTextCharIndex + 1) +
      usedOptions.rightSplitStr +
      filledMask.slice(lastMaskReplaceableCharIndex);
  }
  return filledMask;
}

export function formatTime(inputValue: string) {
  if (!inputValue) return timeMask;

  const numberValue = inputValue.replace(/\D/g, "");

  const formattedTime = fillMask(numberValue, timeMask, timeMaskReplaceableChar, {
    fromEnd: false,
    useFullText: false,
    rightSplitStr: "",
    leftSplitStr: "",
  });

  const [formattedHours, formattedMinutes] = formattedTime.split(":");

  let result = [];

  Number(formattedHours.replace(/–/g, "0")) < 24 ? result.push(formattedHours) : result.push("23");
  Number(formattedMinutes.replace(/–/g, "0")) < 60 ? result.push(formattedMinutes) : result.push("59");

  return result.join(":");
}

export const isContainReplaceableCharRE = new RegExp(timeMaskReplaceableChar, "g");
