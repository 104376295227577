import * as yup from "yup";
import { MESSAGES } from "./messages";
import { balance, port } from "../validators";
import { CHECK_TYPES } from "utils/constants";

/** yup custom validators */
yup.addMethod(yup.string, "port", port);
yup.addMethod(yup.string, "balance", balance);

const checkFormSchema = yup.object().shape({
  name: yup.string().required(MESSAGES.REQUIRED).max(100, MESSAGES.MAX_LENGTH),
  description: yup.string().max(250, MESSAGES.MAX_LENGTH),
  type: yup.string(),
  integration: yup.string().when("type", {
    is: (value: string) => [CHECK_TYPES.BALANCE, CHECK_TYPES.JIRA].includes(value),
    then: yup.string().required(MESSAGES.INTEGRATION_REQUIRED),
  }),
  url: yup
    .string()
    .when("type", {
      is: (value: string) => [CHECK_TYPES.HTTP, CHECK_TYPES.HEADER].includes(value),
      then: yup.string().url(MESSAGES.URL_INVALID_VALUE),
    })
    .when("type", {
      is: (value: string) => ![CHECK_TYPES.BALANCE].includes(value),
      then: yup.string().max(240, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    }),
  extra: yup.object({}).when("type", {
    is: (value: string) => [CHECK_TYPES.HEADER].includes(value),
    then: yup.object({
      header: yup.string().required(MESSAGES.REQUIRED).max(240, MESSAGES.MAX_LENGTH),
      text: yup.string().required(MESSAGES.REQUIRED).max(250, MESSAGES.MAX_LENGTH),
    }),
  }),
  balance_threshold: yup.string().when("type", {
    is: (value: string) => [CHECK_TYPES.BALANCE].includes(value),
    then: yup.string().required(MESSAGES.REQUIRED).balance(MESSAGES.BALANCE_INVALID_VALUE),
  }),
  port: yup.string().when("type", {
    is: (value: string) => [CHECK_TYPES.PORT].includes(value),
    then: yup.string().required(MESSAGES.REQUIRED).port(MESSAGES.PORT_MAX_VALUE),
  }),
  timeout: yup.string(),
  down_timeout: yup.string(),
  is_telegram_auth_enabled: yup.string(),
  project: yup.string(),
});

export default checkFormSchema;
