import {
  instance,
  requestNoSpinnerPromiseWrapper,
  requestPromiseWrapper,
  requestWithSpinnerNoAlertErrorsPromiseWrapper
} from "./instance";
import {getFormDataObject} from "../../utils/imagesHandlers";

export function getMembersRequest(showSpinner = true) {
  if (!showSpinner) {
    return requestNoSpinnerPromiseWrapper(instance.get("/members"));
  }

  return requestPromiseWrapper(instance.get("/members"));
}

export function getPartialMembersRequest(query) {
  return requestNoSpinnerPromiseWrapper(instance.get(`/members?${query}`));
}

export function getMemberDetailsRequest(id) {
  return requestPromiseWrapper(instance.get(`/members/${id}`));
}

export function editMemberDetailsRequest(memberInfo, id) {
  const formData = getFormDataObject(memberInfo);

  return requestWithSpinnerNoAlertErrorsPromiseWrapper(instance.patch(`/members/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }));
}

export function createMemberRequest(newMemberInfo) {
  const formData = getFormDataObject(newMemberInfo);

  return requestWithSpinnerNoAlertErrorsPromiseWrapper(instance.post("/members", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }));
}

export function deleteMemberRequest(id) {
  return requestPromiseWrapper(instance.delete(`/members/${id}`));
}