import { Trans } from "react-i18next";
import { ICustomError } from "./_types";

type ValidationFeedbackProps = {
  error?: ICustomError;
};

const ValidationFeedback = ({ error }: ValidationFeedbackProps) => {
  return (
    <>
      {!!error && (
        <div className={`validationFeedback validationFeedback_${error.message.type}`}>
          <Trans i18nKey={error.message.i18nKey}>{error.message.default}</Trans>
        </div>
      )}
    </>
  );
};

export default ValidationFeedback;
