const integrationFields = [
  "name",
  "type",
  "token",
  "url",
  "api_key",
  "app_id",
  "from_phone",
  "account_cid",
  "login",
  "password",
  "secret_key",
];

export { integrationFields };
