import AvatarRow from "../AvatarRow";
import MemberForm from "../MemberForm";
import { useMemberForm } from "../hooks";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";
import FormControlsButtons from "components/FormControlButtons";
import { ARCard, ARCardBody, ARCardFooter, ARCardHeader } from "components/ARComponents";

const MemberDetails = () => {
  const { onFormReset, onFormSubmit, onFormDelete, memberInfo, form, Prompt } = useMemberForm();
  const { memberName, isExternalMember } = memberInfo;

  return (
    <ARCard className="MemberEdit-card">
      <ARCardHeader>
        <AvatarRow
          name="avatar"
          control={form.control}
          className="MemberEdit-card__avatar-row"
          headerText={memberName}
          isSubmittingField={(value) => typeof value !== "string"}
          isAvatarUploadAvailable={hasGlobalPermission(ACTIONS.EDIT_MEMBERS) && !isExternalMember}
        />
      </ARCardHeader>
      <ARCardBody>
        <Prompt />
        <MemberForm
          formConfig={form}
          formId="member-edit-form"
          onFormSubmit={onFormSubmit}
          isExternalMember={isExternalMember}
        />
      </ARCardBody>
      {hasGlobalPermission(ACTIONS.EDIT_MEMBERS) && !isExternalMember && (
        <ARCardFooter>
          <FormControlsButtons form="member-edit-form" onCancelClick={onFormReset} onDeleteClick={onFormDelete} />
        </ARCardFooter>
      )}
    </ARCard>
  );
};

export default withErrorBoundary(MemberDetails);
