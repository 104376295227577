import { PATHS } from "route-dictionary";
import { useTranslation } from "react-i18next";
import { getArrayOfId } from "utils";
import { showModalWindow } from "utils/actionWrappers";
import { getDirtyValues } from "components/ReactHookForm/utils";
import { usePreventTransition } from "components/ReactHookForm/hooks";
import useRuleFormConfig, {IRuleFormData, IRuleFormDataSubmit} from "./useRuleFormConfig";

const useRuleForm = () => {
  const { t } = useTranslation();
  const {
    formId,
    ruleId,
    checkId,
    projectId,
    isAddPage,
    formControl,
    onTypeChange,
    onSaveBtnClick,
    onDeleteBtnClick,
    userIsProjectAdmin,
    subscribeToResetForm,
  } = useRuleFormConfig();
  const {
    formState: { dirtyFields },
    watch,
  } = formControl;

  const { setDefaultValues, hasBlocking: isDirty, redirect, Prompt } = usePreventTransition({ watch });
  subscribeToResetForm(setDefaultValues);

  const redirectToOtherPage = () =>
    redirect(
      checkId
        ? PATHS.CHECKS.getEditIdPath?.(checkId)
        : projectId
        ? PATHS.PROJECTS.getDetailsIdPath?.(projectId)
        : PATHS.RULES.ROOT
    );

  const onFormSubmit = (formData: IRuleFormData) => {
    const newRuleData: IRuleFormDataSubmit = {
      ...formData,
      members_to_call: getArrayOfId(formData.members_to_call) as string[],
      telegram_chats: getArrayOfId(formData.telegram_chats) as string[],
    };

    if (isAddPage) {
      if (checkId) {
        newRuleData.my_check = checkId;
      }

      if (projectId) {
        newRuleData.project = projectId;
      }

      onSaveBtnClick(newRuleData).then(redirectToOtherPage);
    } else {
      if (isDirty) {
        const submitData = getDirtyValues({ dirtyFields, allValues: newRuleData });
        onSaveBtnClick(submitData, ruleId).then(redirectToOtherPage);
      } else redirectToOtherPage();
    }
  };

  const onFormReset = () => {
    if (isDirty) {
      showModalWindow.confirm({
        message: t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: redirectToOtherPage,
      });
    } else redirectToOtherPage();
  };

  const onFormDelete = () => {
    showModalWindow.confirm({
      title: t("modal:title.confirm", "Confirm your action"),
      message: t("modal:message.delete.rule", "Are you sure you want to remove this rule?"),
      color: "danger",
      onConfirm: () => onDeleteBtnClick(ruleId!).then(redirectToOtherPage),
    });
  };

  return {
    formId,
    checkId,
    projectId,
    Prompt,
    onFormReset,
    onFormDelete,
    onFormSubmit,
    userIsProjectAdmin,
    formControl,
    onTypeChange,
  };
};

export default useRuleForm;
