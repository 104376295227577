import { useSelector } from "react-redux";
import { checkTypes } from "../Checks.model";
import { useTranslation } from "react-i18next";
import { ARForm } from "components/ARComponents";
import { CHECK_TYPES, REG_EXS, ROLES } from "utils/constants";
import { getCheckTgAuthOptions, getTimeoutLabelText } from "./CheckForm.model";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ACTIONS, hasProjectPermission } from "utils/user-access";
import { telegramTokenIsSetSelector } from "store/selectors/global";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { RHFInputWithLabel, RHFSelectWithLabel, RHFTextareaWithLabel } from "components/ReactHookForm/fields";
import { IOption } from "./../../../components/ReactHookForm/fields/custom/RHFSelect";
import { FormEventHandler } from "react";
import { ICheck } from "../_types";
import { ICustomSetErrorFromForm } from "components/ReactHookForm/hooks/useFormWithCustomError";

interface ICheckFormProps {
  checkRules: any;
  handleSubmit: (arg: (formData: Partial<ICheck>) => void) => FormEventHandler<any> | undefined;
  onFormSubmit: (formData: Partial<ICheck>) => void;
  control: any;
  setFormError: ICustomSetErrorFromForm;
  formId: string;
  formClassName?: string;
  isAddMode: boolean;
  onTimeoutChange: () => void;
  onDownTimeoutChange: () => void;
  onTypeChange: (typeValue: string) => Promise<void>;
  options: {
    timeoutsOptions: IOption[],
    downTimeoutsOptions: IOption[],
    integrationsOptions: IOption[],
    projectsOptions: IOption[],
  };
  typeValue: string;
  formControl: {
    watch: (arg: string) => string;
  };
  projectRole: ROLES;
  setFocus?: (typeValue: string) => void;
}

const CheckForm = (props: ICheckFormProps) => {
  const {
    handleSubmit,
    onFormSubmit,
    control,
    setFormError,
    formId,
    formClassName,
    isAddMode,
    onTimeoutChange,
    onDownTimeoutChange,
    onTypeChange,
    options,
    typeValue,
    formControl: { watch },
    projectRole,
  } = props;
  const isTelegramTokenSet = useSelector(telegramTokenIsSetSelector);
  const isProjectFieldDisplay = isAddMode || hasProjectPermission(ACTIONS.CHANGE_CHECK_PROJECT, projectRole);
  const { t } = useTranslation();

  const { timeoutsOptions, downTimeoutsOptions, integrationsOptions, projectsOptions } = options;

  const isJiraCheck = typeValue === CHECK_TYPES.JIRA;
  const isPingCheck = typeValue === CHECK_TYPES.PING;
  const isHttpCertCheck = typeValue === CHECK_TYPES.HTTP_CERTIFICATE;
  const isDomainCheck = typeValue === CHECK_TYPES.DOMAIN;
  const isBalanceCheck = typeValue === CHECK_TYPES.BALANCE;
  const isHeaderCheck = typeValue === CHECK_TYPES.HEADER;
  const isPortCheck = typeValue === CHECK_TYPES.PORT;
  const isHttpCheck = typeValue === CHECK_TYPES.HTTP;
  const isExternalCheck = typeValue === CHECK_TYPES.EXTERNAL;

  return (
    <ARForm onSubmit={handleSubmit(onFormSubmit)} id={formId} className={formClassName}>
      <RHFInputWithLabel
        id="name"
        name="name"
        labelText={t("form:label.checkName", "Check name")}
        placeholder={t("form:placeholder.checkName", "Enter check's name")}
        control={control}
        disabled={isExternalCheck}
        autoComplete="off"
      />
      {!isExternalCheck && (
        <RHFTextareaWithLabel
          id="description"
          name="description"
          labelText={t("form:label.description", "Description")}
          labelColClassName="form-textarea-label"
          placeholder={t("form:placeholder.description", "Enter description")}
          className="textarea-size"
          control={control}
          autoComplete="off"
        />
      )}
      <RHFSelectWithLabel
        id="type"
        name="type"
        labelText={t("form:label.checkType", "Check type")}
        control={control}
        onSelectChange={onTypeChange}
        options={isExternalCheck ? checkTypes : checkTypes.filter((checkType) => checkType.isUsed)}
        disabled={isExternalCheck}
      />
      {!isExternalCheck && (
        <>
          {isHttpCheck && (
            <RHFInputWithLabel
              id="url"
              name="url"
              labelText={t("form:label.url", "Url")}
              placeholder={t("form:placeholder.url", "Enter url")}
              control={control}
              shouldUnregister={false}
              autoComplete="off"
            />
          )}
          {isHeaderCheck && (
            <>
              <RHFInputWithLabel
                id="url"
                name="url"
                labelText={t("form:label.url", "Url")}
                placeholder={t("form:placeholder.url", "Enter url")}
                control={control}
                shouldUnregister={false}
                autoComplete="off"
              />
              <RHFInputWithLabel
                id="extra.header"
                name="extra.header"
                labelText={t("form:label.headerName", "Header name")}
                placeholder={t("form:placeholder.headerName", "Enter header name")}
                control={control}
                autoComplete="off"
              />
              <RHFInputWithLabel
                id="extra.text"
                name="extra.text"
                labelText={t("form:label.text", "Text")}
                placeholder={t("form:placeholder.text", "Enter text")}
                control={control}
                autoComplete="off"
              />
            </>
          )}
          {isPingCheck && (
            <RHFInputWithLabel
              id="url"
              name="url"
              labelText={t("form:label.domain", "Domain")}
              placeholder={t("form:placeholder.domain", "Enter domain")}
              control={control}
              shouldUnregister={false}
              autoComplete="off"
            />
          )}
          {isHttpCertCheck && (
            <RHFInputWithLabel
              id="url"
              name="url"
              labelText={t("form:label.domain", "Domain")}
              placeholder={t("form:placeholder.domain", "Enter domain")}
              control={control}
              shouldUnregister={false}
              autoComplete="off"
            />
          )}
          {isDomainCheck && (
            <RHFInputWithLabel
              id="url"
              name="url"
              labelText={t("form:label.domain", "Domain")}
              placeholder={t("form:placeholder.domain", "Enter domain")}
              control={control}
              shouldUnregister={false}
              autoComplete="off"
            />
          )}
          {isJiraCheck && (
            <>
              <RHFSelectWithLabel
                id="integration"
                name="integration"
                labelText={t("form:label.integration", "Integration")}
                control={control}
                setError={setFormError}
                highlightInvalid
                options={integrationsOptions}
              />
              <RHFInputWithLabel
                id="url"
                name="url"
                labelText={t("form:label.filter", "Filter")}
                placeholder={t("form:placeholder.filter", "Enter filter")}
                control={control}
                shouldUnregister={false}
                autoComplete="off"
              />
            </>
          )}
          {isBalanceCheck && (
            <>
              <RHFSelectWithLabel
                id="integration"
                name="integration"
                labelText={t("form:label.integration", "Integration")}
                control={control}
                setError={setFormError}
                highlightInvalid
                options={integrationsOptions}
              />
              <RHFInputWithLabel
                id="balance_threshold"
                name="balance_threshold"
                type="tel"
                labelText={t("form:label.balance", "Balance threshold")}
                placeholder={t("form:placeholder.balance", "Enter balance threshold")}
                control={control}
                setFormError={setFormError}
                autoComplete="off"
                pattern={REG_EXS.BALANCE_CHARACTERS}
                patternMismatchMessage={MESSAGES.BALANCE_CHARACTERS_ONLY}
              />
            </>
          )}
          {isPortCheck && (
            <>
              <RHFInputWithLabel
                id="url"
                name="url"
                labelText={t("form:label.domain", "Domain")}
                placeholder={t("form:placeholder.domain", "Enter domain")}
                control={control}
                shouldUnregister={false}
                autoComplete="off"
              />
              <RHFInputWithLabel
                id="port"
                name="port"
                labelText={t("form:label.port", "Port")}
                placeholder={t("form:placeholder.port", "Enter port")}
                type="tel"
                control={control}
                setFormError={setFormError}
                autoComplete="off"
                pattern={REG_EXS.DIGITS_CHARACTERS}
                patternMismatchMessage={MESSAGES.DIGITS_ONLY}
              />
            </>
          )}
          <RHFSelectWithLabel
            id="timeout"
            name="timeout"
            labelText={getTimeoutLabelText("timeout", watch("timeout"))}
            control={control}
            onSelectChange={onTimeoutChange}
            options={timeoutsOptions}
          />
          <RHFSelectWithLabel
            id="down_timeout"
            name="down_timeout"
            labelText={getTimeoutLabelText("downTimeout", watch("down_timeout"))}
            control={control}
            onSelectChange={onDownTimeoutChange}
            options={downTimeoutsOptions}
          />
          {isTelegramTokenSet && (
            <RHFSelectWithLabel
              id="is_telegram_auth_enabled"
              name="is_telegram_auth_enabled"
              labelText={t("form:label.checkTelegramAuth.label", "Telegram authorization")}
              control={control}
              options={getCheckTgAuthOptions()}
            />
          )}
          {isProjectFieldDisplay && (
            <RHFSelectWithLabel
              id="project"
              name="project"
              labelText={t("form:label.project", "Project")}
              control={control}
              options={projectsOptions}
            />
          )}
        </>
      )}
    </ARForm>
  );
};

export default withErrorBoundary(CheckForm);
