import * as yup from "yup";
import { MESSAGES } from "./messages";
import { phone } from "../validators";

/** yup custom validators */
yup.addMethod(yup.string, "phone", phone);

const getMemberFormSchema = (isAddPage: boolean = false) =>
  yup.object().shape({
    avatar: yup.mixed(),
    first_name: yup.string().max(30, MESSAGES.MAX_LENGTH),
    last_name: yup.string().max(150, MESSAGES.MAX_LENGTH),
    phone: yup.string().phone(MESSAGES.PHONE_INVALID_VALUE),
    email: yup.string().max(254, MESSAGES.MAX_LENGTH).email(MESSAGES.EMAIL_INVALID_VALUE).required(MESSAGES.REQUIRED),
    password: yup.string().when("isAddPage", {
      is: () => isAddPage,
      then: yup.string().required(MESSAGES.REQUIRED),
    }),
    confirm_password: yup
      .string()
      .when("isAddPage", {
        is: () => isAddPage,
        then: yup.string().required(MESSAGES.REQUIRED),
      })
      .oneOf([yup.ref("password")], MESSAGES.MATCH_PASSWORDS),
  });

export default getMemberFormSchema;
