import { useTranslation } from "react-i18next";
import { ARForm } from "components/ARComponents";
import { ACTIONS, REG_EXS } from "utils/constants";
import { hasGlobalPermission } from "utils/user-access";
import { getInputDisable, getPlaceholder } from "./MemberForm.model";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { RHFInputWithLabel, RHFPhoneInputWithLabel } from "components/ReactHookForm/fields";
import { MemberFormControl } from "../hooks/useMemberForm";

interface IMemberFormProps {
  formId: string;
  formConfig: MemberFormControl;
  onFormSubmit: (values: any) => void;
  isExternalMember: boolean;
  isAddMode: boolean;
}

const MemberForm = (props: IMemberFormProps) => {
  const { t } = useTranslation();
  const { formId, formConfig, onFormSubmit, isExternalMember, isAddMode } = props;
  const { control, trigger, handleSubmit, setFormError } = formConfig;

  return (
    <ARForm id={formId} onSubmit={handleSubmit(onFormSubmit)}>
      <RHFInputWithLabel
        id="first_name"
        name="first_name"
        labelText={t("form:label.firstName", "First name")}
        placeholder={getPlaceholder(t("form:placeholder.firstName", "Enter first name"))}
        autoComplete="off"
        control={control}
        disabled={getInputDisable(isExternalMember)}
      />
      <RHFInputWithLabel
        id="last_name"
        name="last_name"
        labelText={t("form:label.lastName", "Last name")}
        placeholder={getPlaceholder(t("form:placeholder.lastName", "Enter last name"))}
        autoComplete="off"
        control={control}
        disabled={getInputDisable(isExternalMember)}
      />
      <RHFPhoneInputWithLabel
        id="phone"
        name="phone"
        labelText={t("form:label.phone", "Phone")}
        control={control}
        disabled={getInputDisable(isExternalMember)}
        block
      />
      <RHFInputWithLabel
        id="email"
        name="email"
        labelText={t("form:label.email", "Email")}
        placeholder={getPlaceholder(t("form:placeholder.email", "Enter email"))}
        autoComplete="off"
        control={control}
        pattern={REG_EXS.EMAIL_CHARACTERS}
        patternMismatchMessage={MESSAGES.EMAIL_INVALID_CHARACTERS}
        setFormError={setFormError}
        disabled={getInputDisable(isExternalMember)}
      />
      {hasGlobalPermission(ACTIONS.EDIT_MEMBERS) && !isExternalMember && (
        <>
          <RHFInputWithLabel
            id="password"
            name="password"
            type="password"
            autoComplete="new-password"
            control={control}
            trigger={trigger}
            triggerName="confirm_password"
            labelText={isAddMode ? t("form:label.password", "Password") : t("form:label.password_new", "New password")}
            placeholder={
              isAddMode
                ? t("form:placeholder.password", "Enter password")
                : t("form:placeholder.password_new", "Enter new password")
            }
          />
          <RHFInputWithLabel
            id="confirm_password"
            name="confirm_password"
            type="password"
            autoComplete="off"
            control={control}
            labelText={
              isAddMode
                ? t("form:label.password_confirm", "Confirm password")
                : t("form:label.password_newConfirm", "Confirm new password")
            }
            placeholder={
              isAddMode
                ? t("form:placeholder.password_confirm", "Confirm password")
                : t("form:placeholder.password_newConfirm", "Confirm new password")
            }
          />
        </>
      )}
    </ARForm>
  );
};

MemberForm.defaultProps = {
  isAddMode: false,
  isExternalMember: false,
};

export default MemberForm;
