import { useQuery } from "utils/useQuery";
import { useParams } from "react-router-dom";
import useGetCheckData from "./useGetCheckData/useGetCheckData";
import i18next from "services/language/i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { showNotification } from "utils/actionWrappers";
import { CHECK_TYPES, ERR_MESSAGES } from "utils/constants";
import checkFormSchema from "components/ReactHookForm/schemes/checkFormSchema";
import { changeCheckProjectRequest, createCheckRequest, editCheckRequest, groupCancelSource } from "services/api";
import useFormWithCustomError from "../../../components/ReactHookForm/hooks/useFormWithCustomError";
import {
  getChecksDownTimeouts,
  getChecksTimeouts,
  getDefaultValues,
  getIntegrationsByType,
  getTimeouts,
  getTimeoutsType,
  IResult,
} from "../CheckForm/CheckForm.model";
import { ICheck, Iintegration } from "../_types";
import { Iitem } from "../Checks";
import { ICheckRules } from "pages/checks/_types";

interface IOptions {
  timeoutsOptions: { name: string; value: string }[];
  downTimeoutsOptions: { name: string; value: number | string }[];
  projectsOptions: { name: string; value: number | string }[]; // TODO разобраться почему хочет string
  integrationsOptions: IResult[] | { name: string; value: string }[];
}

interface IUseCheckFormConfigReturn {
  checkRules: ICheckRules[];
  formControl: any;
  formId: string;
  id?: string;
  isProjectAdmin?: boolean | null;
  onDownTimeoutChange: () => void;
  subscribeToResetForm: (subscriber: (arg: Partial<ICheck>) => void) => void;
  onSaveButtonClick: (checkData: ICheck, isProjectChanged: boolean, isCheckInfoChanged: boolean) => Promise<any>;
  onTypeChange: (typeValue: string) => Promise<void>;
  onTimeoutChange: () => void;
  isClonedCheck?: boolean | null;
}

const useCheckFormConfig = (): IUseCheckFormConfigReturn => {
  const query = useQuery();
  const clonedId = query.get("clonedId");
  const { id } = useParams<{ id: string }>();
  const { getCheckData, integrations, checkRules, isProjectAdmin } = useGetCheckData(clonedId || id, !!clonedId);
  const [options, setOptions] = useState<IOptions>({
    timeoutsOptions: [],
    downTimeoutsOptions: [],
    projectsOptions: [],
    integrationsOptions: [],
  });

  const formControl = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(checkFormSchema),
    defaultValues: getDefaultValues(),
  });
  const { watch, reset, getValues, setValue, trigger } = formControl;
  const typeValue = watch("type");
  const projectValue = watch("project");

  const resetFormSubscriber = useRef<(arg: Partial<ICheck>) => void>();
  const subscribeToResetForm = (
    subscriber: (arg: Partial<ICheck>) => void // TODO понять что это такое
  ) => {
    resetFormSubscriber.current! = subscriber;
  };

  const onTypeChange = async (typeValue: string) => {
    const formValues = getValues();

    const timeoutsType = getTimeoutsType(typeValue);
    const downTimeoutsOptions = getChecksDownTimeouts(timeoutsType);
    const timeoutsOptions = getChecksTimeouts(timeoutsType);

    const integrationsOptions = getIntegrationsByType(typeValue, integrations);

    setOptions((oldState) => ({ ...oldState, timeoutsOptions, downTimeoutsOptions, integrationsOptions }));
    await reset(
      {
        ...formValues,
        type: typeValue,
        timeout: timeoutsOptions[0].value,
        down_timeout: downTimeoutsOptions[1].value,
      },
      { keepDirty: true }
    );

    if (formValues.name) {
      trigger("name");
    }
    trigger("description");
    if (formValues.url) {
      trigger("url");
    }
    if ([CHECK_TYPES.JIRA, CHECK_TYPES.BALANCE].includes(typeValue)) {
      const integrationsOptions = getIntegrationsByType(typeValue, integrations);
      const integrationValue = integrationsOptions[0].value;
      setValue("integration", integrationValue.toString(), {
        shouldDirty: !integrationValue,
        shouldValidate: !integrationValue,
      });
    } else {
      setValue("integration", "");
    }
  };

  const onTimeoutChange = useCallback(() => {
    const timeoutsType = getTimeoutsType(typeValue);
    const timeoutsOptions = getChecksTimeouts(timeoutsType);

    setOptions((oldState) => ({ ...oldState, timeoutsOptions }));
  }, [setOptions, typeValue]);

  const onDownTimeoutChange = useCallback(() => {
    const timeoutsType = getTimeoutsType(typeValue);
    const downTimeoutsOptions = getChecksDownTimeouts(timeoutsType);

    setOptions((oldState) => ({ ...oldState, downTimeoutsOptions }));
  }, [setOptions, typeValue]);

  const addCheck = (checkData: ICheck) => {
    return createCheckRequest(checkData).then((data) => {
      showNotification(
        i18next.t("notification:title.success", "Success!"),
        i18next.t("notification:message.added.check", "Check has been successfully added."),
        "success"
      );

      return data;
    });
  };

  const updateCheck = async (checkData: ICheck, isProjectChanged: boolean, isCheckInfoChanged: boolean) => {
    isCheckInfoChanged && (await editCheckRequest(checkData, id));
    isProjectChanged && (await changeCheckProjectRequest(id, { project: checkData.project }));

    showNotification(
      i18next.t("notification:title.success", "Success!"),
      i18next.t("notification:message.updated.check", "Check has been successfully updated."),
      "success"
    );
  };

  useEffect(() => {
    getCheckData().then(
      ({
        checkData,
        projects,
        integrations,
      }: {
        checkData?: Partial<ICheck>;
        projects: Iitem[]; // TODO переименовать Iitem
        integrations: Iintegration[];
      }) => {
        const typeValue = checkData?.type || getValues("type");
        const timeout = checkData?.timeout || getValues("timeout");
        const down_timeout = checkData?.down_timeout || getValues("down_timeout");

        const timeoutsType = getTimeoutsType(typeValue);
        const checksDownTimeoutsArr = getChecksDownTimeouts(timeoutsType);
        const checksTimeoutsArr = getChecksTimeouts(timeoutsType);
        const timeoutsOptions = getTimeouts(checksTimeoutsArr, timeout);
        const downTimeoutsOptions = getTimeouts(checksDownTimeoutsArr, down_timeout);

        const projectsOptions = projects
          .filter((project) => project.is_user_project_admin)
          .map((project) => ({ value: project.id, name: project.name }));
        const integrationsOptions = getIntegrationsByType(typeValue, integrations);
        setOptions((oldState) => ({
          ...oldState,
          timeoutsOptions,
          downTimeoutsOptions,
          projectsOptions,
          integrationsOptions,
        }));
        const checkFormValues = checkData || {
          ...getValues(),
          project: projectsOptions[0].value,
          down_timeout: downTimeoutsOptions[1].value,
          timeout: timeoutsOptions[0].value,
        };
        reset(getDefaultValues(checkFormValues));
        resetFormSubscriber.current && resetFormSubscriber.current(getDefaultValues(checkFormValues));
      }
    );
    return () => {
      groupCancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
      groupCancelSource.value = null;
    };
  }, [getCheckData, reset, getValues]);

  useEffect(() => {
    onTimeoutChange();
    onDownTimeoutChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language, onTimeoutChange, onDownTimeoutChange]);

  const editCheckConfig = {
    onDownTimeoutChange,
    onSaveButtonClick: updateCheck,
    onTypeChange,
    onTimeoutChange,
    checkRules,
    isProjectAdmin,
    id,
    formId: "check-edit-form",
    formControl: {
      ...formControl,
      typeValue,
      projectValue,
      options,
    },
    subscribeToResetForm,
  };

  const addCheckConfig = {
    onSaveButtonClick: addCheck,
    onTypeChange,
    onTimeoutChange,
    onDownTimeoutChange,
    checkRules,
    isClonedCheck: !!clonedId,
    formId: "check-add-form",
    formControl: {
      ...formControl,
      typeValue,
      projectValue,
      options,
    },
    subscribeToResetForm,
  };
  return id ? editCheckConfig : addCheckConfig;
};
export default useCheckFormConfig;
