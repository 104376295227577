import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ACTIONS, ERR_MESSAGES } from "utils/constants";
import useUpdateProjects from "./useUpdateProjects";
import { projectInfoInitialState } from "../ProjectDetails/ProjectDetails.model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { getFormDataObj } from "components/ReactHookForm/utils";
import { sortArraysAndReturnNotEqualItems } from "utils/arrays";
import { hasGlobalPermission } from "utils/user-access";
import { projectFormSchema } from "components/ReactHookForm/schemes";
import { IMember, IProject } from "./../../../models/types";
import {
  CancelToken,
  deleteProjectRequest,
  getGroupsRequest,
  getMembersRequest,
  getProjectDataRequest,
  getRolesRequest,
  groupCancelSource,
  patchProjectDataRequest,
  updateRolesRequest,
} from "services/api";
import { INewRoles } from "./useProjectDetailsForm";

export interface IRolesGroups {
    id: number;
    name: string;
    members_ids: number[];
    members_representation: IMember[];
}

export interface IProjectRoles {
  roles: {
    members: { member: IMember; role: string }[];
    groups: { group: IRolesGroups }[];
  };
  serverGroupsAndMembers: {
    members: IMember[];
    groups: IRolesGroups[];
  };
}

export interface IAvailableGroupsAndMembers {
  members: IMember[];
  groups: IRolesGroups[];
}

const useProjectInfo = () => {
  const { id } = useParams<{ id: string }>();
  const [projectInfo, setProjectInfo] = useState(projectInfoInitialState);
  const [{ roles, serverGroupsAndMembers }, setProjectRoles] = useState<IProjectRoles>({
    roles: { members: [], groups: [] },
    serverGroupsAndMembers: { members: [], groups: [] },
  });
  const [availableGroupsAndMembers, setAvailableGroupsAndMembers] = useState<IAvailableGroupsAndMembers>({
    members: [],
    groups: [],
  });
  const updateProjects = useUpdateProjects();

  const formControl = useForm({
    mode: "onChange",
    resolver: yupResolver(projectFormSchema),
    defaultValues: { name: "" },
  });

  const { reset } = formControl;

  const updateProjectData = (projectData: IProject) => {
    return patchProjectDataRequest(id, projectData).then(updateProjects);
  };

  const deleteProject = () => {
    return deleteProjectRequest(id).then(updateProjects);
  };

  const getTenantOwnerProjectData = useCallback(() => {
    return Promise.all([getRolesRequest(id), getProjectDataRequest(id), getMembersRequest(), getGroupsRequest()]).then(
      ([rolesResponse, projectDataResponse, membersResponse, groupsResponse]) => {
        setProjectRoles((oldState) => ({
          ...oldState,
          roles: { members: rolesResponse.data.members, groups: rolesResponse.data.groups },
          serverGroupsAndMembers: {
            members: membersResponse.data.filter((member: IMember) => member.is_superuser !== true),
            groups: groupsResponse.data,
          },
        }));
        setProjectInfo({
          header: projectDataResponse.data.name,
          isMainProject: projectDataResponse.data.is_main,
          isProjectAdmin: projectDataResponse.data.is_user_project_admin,
          projectId: projectDataResponse.data.id,
          projectRules: projectDataResponse.data.rules,
        });

        const formData = getFormDataObj({ arrOfFields: ["name"], serverData: projectDataResponse.data });
        reset(formData);
      }
    );
  }, [id, reset]);

  const getAdminProjectData = useCallback(() => {
    return getProjectDataRequest(id).then((projectDataResponse) => {
      setProjectInfo({
        header: projectDataResponse.data.name,
        isMainProject: projectDataResponse.data.is_main,
        isProjectAdmin: projectDataResponse.data.is_user_project_admin,
        projectId: projectDataResponse.data.id,
        projectRules: projectDataResponse.data.rules,
      });
    });
  }, [id]);

  const getRoles = () => {
    return getRolesRequest(id).then((response) => response.data);
  };

  useEffect(() => {
    if (Object.keys(roles).length) {
      setAvailableGroupsAndMembers({
        members: sortArraysAndReturnNotEqualItems(
          serverGroupsAndMembers.members,
          roles.members.map((item) => item.member)
        ),
        groups: sortArraysAndReturnNotEqualItems(
          serverGroupsAndMembers.groups,
          roles.groups.map((item) => item.group)
        ),
      });
    }
  }, [serverGroupsAndMembers, roles]);

  const getProjectData = hasGlobalPermission(ACTIONS.EDIT_PROJECTS) ? getTenantOwnerProjectData : getAdminProjectData;

  useEffect(() => {
    if (id) {
      groupCancelSource.value = CancelToken.source();
      getProjectData();
    }

    return () => {
      groupCancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
      groupCancelSource.value = null;
    };
  }, [id, getProjectData]);

  const updateRolesWithApi = (newRoles: any) => {
    return updateRolesRequest(newRoles, id)
      .then(() => getRoles())
      .then((roles) =>
        setProjectRoles((oldState) => ({ 
          ...oldState,
          roles,
        }))
      );
  };

  const addRoles = (newItemsPluralName: string, newRoles: INewRoles[]) => {
    type rolesKey = keyof typeof roles;
    return updateRolesWithApi(
      {
        ...roles,
        [newItemsPluralName]: [...roles[(newItemsPluralName as rolesKey)], ...newRoles],
      },
    );
  };

  const updateRoles = (newItemsPluralName: string, newRoles: IAvailableGroupsAndMembers[]) => {
    return updateRolesWithApi({
      ...roles,
      [newItemsPluralName]: newRoles,
    });
  };

  return {
    roles,
    addRoles,
    updateRoles,
    formControl,
    projectInfo,
    deleteProject,
    updateProjectData,
    availableGroupsAndMembers,
  };
};

export default useProjectInfo;
