import { useDispatch } from "react-redux";
import { useActions } from "store/actions";
import { loginAction } from "store/actions/auth";
import { userSignUpRequest } from "services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { setHasAdminAction } from "store/actions/global";
import { signUpUserFormSchema } from "components/ReactHookForm/schemes";
import { useFormWithCustomError } from "../../../components/ReactHookForm/hooks";

interface ISignUpUserForm {
  email: string;
  password: string;
  confirm_password: string;
  service_checkbox: boolean;
  is_remember_me: boolean;
}

type IFormSubmit = Pick<ISignUpUserForm, "email" | "password" | "is_remember_me">;

const useSignUpUserForm = () => {
  const dispatch = useDispatch();
  const [login] = useActions(loginAction);

  const { handleSubmit, control, trigger, setError } = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(signUpUserFormSchema),
    defaultValues: {
      email: "",
      password: "",
      confirm_password: "",
      service_checkbox: false,
      is_remember_me: false,
    },
  });

  const onFormSubmit = ({ email, password, is_remember_me }: IFormSubmit) => {
    userSignUpRequest(email, password)
      .then(() => login(email, password, is_remember_me))
      .then(() => dispatch(setHasAdminAction(true)));
  };

  return {
    onFormSubmit,
    form: {
      control,
      trigger,
      handleSubmit,
      setFormError: setError,
    },
  };
};

export default useSignUpUserForm;
