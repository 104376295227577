import { PATHS } from "route-dictionary";
import { useMemberFormConfig } from "./index";
import { useTranslation } from "react-i18next";
import { getDirtyValues } from "components/ReactHookForm/utils";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { usePreventTransition } from "components/ReactHookForm/hooks";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { Control, Trigger } from "components/ReactHookForm/_types";
import { UseFormHandleSubmit } from "react-hook-form";
import { ICustomSetErrorFromForm } from "components/ReactHookForm/hooks/useFormWithCustomError";

interface IServerData {
  avatar: string;
}

export interface IMemberForm {
  arrOfFields: string[];
  serverData: IServerData;
}

export interface MemberFormControl {
  control: Control;
  trigger: Trigger;
  handleSubmit: UseFormHandleSubmit<IMemberForm>;
  setFormError: ICustomSetErrorFromForm;
}

const useMemberForm = () => {
  const { t } = useTranslation();
  const { memberId, memberInfo, onSaveBtnClick, onDeleteBtnClick, formControl } = useMemberFormConfig();
  const {
    handleSubmit,
    control,
    trigger,
    setError,
    formState: { isDirty, dirtyFields },
  } = formControl;
  const { redirect, Prompt } = usePreventTransition({ isDirty });
  const redirectToMembersPage = () => redirect(PATHS.MEMBERS.ROOT);

  const existingMemberEmailHandler = () => {
    setError("email", { type: "FieldError", message: MESSAGES.EXISTING_EMAIL }, { shouldFocus: true });
    window.scrollTo(0, 0);
  };

  const onFormDelete = () => {
    showModalWindow.confirm({
      message: t("modal:message.delete.member", "Are you sure you want to delete this member?"),
      color: "danger",
      onConfirm: () => {
        if (onDeleteBtnClick && memberId) {
          onDeleteBtnClick(memberId).then(redirectToMembersPage);
        }
      },
    });
  };

  const onFormSubmit = (formData: IMemberForm) => {
    if (isDirty) {
      const submitData = getDirtyValues({ dirtyFields, allValues: formData });

      onSaveBtnClick(submitData, memberId)
        .then(redirectToMembersPage)
        .catch((error) => {
          if (error.response.status !== 409) {
            showNotification(t("notification:title.error", "Something went wrong!"), error.message, "danger");
          } else {
            existingMemberEmailHandler();
          }
        });
    } else {
      redirectToMembersPage();
    }
  };

  const onFormReset = () => {
    if (isDirty) {
      showModalWindow.confirm({
        message: t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: redirectToMembersPage,
      });
    } else {
      redirectToMembersPage();
    }
  };

  return {
    memberInfo,
    onFormReset,
    onFormSubmit,
    ...(onDeleteBtnClick && { onFormDelete: onFormDelete }),
    Prompt,
    form: {
      control,
      trigger,
      handleSubmit,
      setFormError: setError,
    } as MemberFormControl,
  };
};

export default useMemberForm;
