import { PATHS } from "route-dictionary";
import { useRouteMatch } from "react-router";

const useCheckIdParams = () => {
  // TODO: clear ! and manual generic when routes class is translated
  const matchObj = useRouteMatch<{id: string}>(PATHS.CHECKS.DETAILS!);
  const isChecksRulePage = !!matchObj;
  const checkId = isChecksRulePage ? matchObj.params.id : null;

  return { isChecksRulePage, checkId };
};

export default useCheckIdParams;
