/**
 * Return Object values by types
 *
 * @param {Object} obj
 * @param {Array <String>} types
 * @returns {Array} - of values
 */
export function getObjectValuesByTypes(obj: object, types: string[]) {
  return Object.values(obj).filter((value) => types.indexOf(typeof value) !== -1);
}
