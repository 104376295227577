import { createSelector } from "reselect";
import { RootState } from "../store";

const globalStoreSelect = (state: RootState) => state.global;

export const globalSelector = createSelector(
  globalStoreSelect,
  (state) => state
);

export const checkNameSelector = createSelector(
  globalStoreSelect,
  (state) => state.checkName
);

export const tenantSelector = createSelector(
  globalStoreSelect,
  (state) => state.tenant
);

export const subscriptionTypeSelect = (state: RootState) => state.global.tenant.subscriptionType;

export const subscriptionTypeSelector = createSelector(
  tenantSelector,
  (state) => state.subscriptionType
);

export const tenantErrorSelector = createSelector(
  tenantSelector,
  (state) => state.error
);

export const hasAdminSelector = createSelector(
  tenantSelector,
  (state) => state.hasAdmin
);

export const telegramTokenIsSetSelector = createSelector(
  tenantSelector,
  (state) => state.telegramTokenIsSet
);