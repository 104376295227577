import {useEffect, useState} from "react";
import {getPlans} from "services/api";

const usePlans = (hasDiscount) => {
  const [plansState, setPlanState] = useState({
    plans: [],
    selectedPlan: {},
    monthlyPrice: 0,
    yearlyPrice: 0
  });

  useEffect(() => {
    getPlans()
      .then(({data}) => {
        setPlanState((state) => {
          return {
            ...state,
            plans: data,
            monthlyPrice: data[0].monthly_price.unit_amount,
            yearlyPrice: data[0].yearly_price.unit_amount
          };
        });
      });
  }, []);

  useEffect(() => {
    if (!plansState.plans.length) {
      return;
    }

    const newSelectedPlan = hasDiscount ? plansState.plans[0].yearly_price : plansState.plans[0].monthly_price;

    setPlanState((state) => {
      return {
        ...state,
        selectedPlan: newSelectedPlan,
      };
    });
  }, [hasDiscount, plansState.plans]);

  return {
    plans: plansState.plans,
    selectedPlan: plansState.selectedPlan,
    monthlyPrice: plansState.monthlyPrice,
    yearlyPrice: plansState.yearlyPrice
  };
};

export default usePlans;