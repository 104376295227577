export enum AUTH {
  LOGIN_SUCCESS = "AUTH/LOGIN_SUCCESS",
  LOGOUT_SUCCESS = "AUTH/LOGOUT_SUCCESS",
  SIGN_UP_SUCCESS = "AUTH/SIGN_UP_SUCCESS",
  LOGIN_FAILURE = "AUTH/LOGIN_FAILURE",
  SIGN_UP_FAILURE = "AUTH/SIGN_UP_FAILURE"
};

export enum USER {
  SET_USER_INFO = "USER/SET_USER_INFO",
  SET_GLOBAL_ROLE = "USER/SET_GLOBAL_ROLE",
  SET_PROJECTS = "USER/SET_PROJECTS",
  RESET_USER_STATE = "USER/RESET_USER_STATE",
  SET_TELEGRAM_INFO = "USER/SET_TELEGRAM_INFO"
};

export enum GLOBAL {
  FETCHING = "GLOBAL/FETCHING",
  SET_HAS_ADMIN = "GLOBAL/SET_HAS_ADMIN",
  FETCHED = "GLOBAL/FETCHED",
  SET_TENANT = "GLOBAL/SET_TENANT",
  SET_SUBSCRIPTION_STATUS ="GLOBAL/SET_SUBSCRIPTION_STATUS",
  SHOW_SPINNER = "GLOBAL/SHOW_SPINNER",
  HIDE_SPINNER = "GLOBAL/HIDE_SPINNER",
  SET_TELEGRAM_TOKEN_STATUS = "GLOBAL/SET_TELEGRAM_TOKEN_STATUS",
  SET_CHECK_NAME = "GLOBAL/SET_CHECK_NAME",
  RESET_CHECK_NAME = "GLOBAL/RESET_CHECK_NAME"
};

export enum NOTIFICATION {
  SHOW = "NOTIFICATION/SHOW",
  HIDE = "NOTIFICATION/HIDE",
  CLEAR = "NOTIFICATION/CLEAR"
};

export enum MODAL_WINDOW {
  SHOW = "MODAL_WINDOW/SHOW",
  HIDE = "MODAL_WINDOW/HIDE",
};