import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ERR_MESSAGES } from "utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { showNotification } from "utils/actionWrappers";
import { getFormDataObj } from "components/ReactHookForm/utils";
import { getDirectoryFormSchema } from "components/ReactHookForm/schemes";
import { directoryDataFields, directoryFormDefaultValues } from "../Directory/Directory.model";
import {
  cancelSource,
  createMemberDirectoryRequest,
  getMemberDirectoryRequest,
  updateDirectoryRequest,
} from "services/api";
import { IMemberDirectoryElement, IMemberDirectoryElementData, IMemberDirectoryFromBack } from "services/api/types";

const useDirectoryFormConfig = () => {
  const { t } = useTranslation();
  const { id: directoryId } = useParams<Pick<IMemberDirectoryElement, "id">>();
  const isAddPage = !directoryId;
  const [headerText, setHeaderText] = useState<string>(t("card.title.directory.add", "Add directory"));

  const formControl = useForm({
    mode: "onChange",
    resolver: yupResolver(getDirectoryFormSchema(isAddPage)),
    defaultValues: getFormDataObj({
      arrOfFields: directoryDataFields,
      serverData: directoryFormDefaultValues,
    }),
  });
  const { reset } = formControl;

  const addDirectory = (directoryData: IMemberDirectoryElementData) => {
    return createMemberDirectoryRequest(directoryData).then(({ data }: IMemberDirectoryFromBack) => {
      if (data.connection_error) {
        return { ...data };
      } else {
        showNotification(
          t("notification:title.success", "Success!"),
          t("notification:message.added.directory", "Directory has been successfully added."),
          "success"
        );
      }
    });
  };

  const editDirectory = (formData: IMemberDirectoryElementData) => {
    const directoryData: IMemberDirectoryElementData = { ...formData };
    if (directoryData.password === "") {
      delete directoryData.password;
    }

    return updateDirectoryRequest(directoryId, directoryData).then(({ data }: IMemberDirectoryFromBack) => {
      if (data.connection_error) {
        return { ...data };
      } else {
        showNotification(
          t("notification:title.success", "Success!"),
          t("notification:message.updated.directory", "Directory has been updated."),
          "success"
        );
      }
    });
  };

  const getDirectoryInfo = useCallback(() => {
    return getMemberDirectoryRequest(directoryId).then(({ data }: IMemberDirectoryFromBack) => {
      setHeaderText(data.name);

      const formData = getFormDataObj({ arrOfFields: directoryDataFields, serverData: data });
      reset(formData);
    });
  }, [directoryId, reset]);

  useEffect(() => {
    if (directoryId) {
      getDirectoryInfo();
    }

    return () => cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
  }, [directoryId, getDirectoryInfo]);

  return {
    isAddPage,
    formControl,
    headerText,
    setHeaderText,
    onSaveBtnClick: isAddPage ? addDirectory : editDirectory,
    formId: isAddPage ? "directory-add-form" : "directory-edit-form",
  };
};

export default useDirectoryFormConfig;
