import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { getTenantInfoRequest } from "services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { DOMAIN, PORT, PROTOCOL } from "utils/constants";
import { loginSubFormSchema } from "components/ReactHookForm/schemes";
import { useFormWithCustomError } from "components/ReactHookForm/hooks";

interface ILoginSubForm extends FieldValues {
  subdomain: string;
}

const useLoginSubForm = () => {
  const [subdomain, setSubdomain] = useState("");
  const [nonExistentSubdomain, setNonExistentSubdomain] = useState("");

  const { handleSubmit, control, setError } = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(loginSubFormSchema),
    defaultValues: {
      subdomain: "",
    } as ILoginSubForm,
  });

  const onFormSubmit = (formData: ILoginSubForm) => {
    const { subdomain } = formData;
    setSubdomain(subdomain);

    getTenantInfoRequest(`${subdomain}.${DOMAIN}`).then((result) => {
      if (!result.isExist) {
        setNonExistentSubdomain(subdomain);
      } else {
        window.location.assign(`${PROTOCOL}//${subdomain}.${DOMAIN}${PORT ? `:${PORT}` : ""}/login`);
      }
    });
  };

  return {
    subdomain,
    onFormSubmit,
    nonExistentSubdomain,
    form: {
      handleSubmit,
      setFormError: setError,
      control,
    },
  };
};

export default useLoginSubForm;
