import { useEffect, useRef, useState } from "react";
import { last as getLastItemOfArray } from "lodash";
import { cancelSource } from "../../services/api";
import { ERR_MESSAGES, TableElPerPage, TABLE_ITEMS_PER_PAGE_OPTIONS } from "../constants";
import { AxiosResponse } from "axios";

interface IResult<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

enum PageInitValue {
  Page = 1,
  Pages = 5,
}

const useTableConfig = <T extends object>(getItemsRequest: (query: string) => Promise<AxiosResponse<IResult<T>>>) => {
  const totalItemsAmountRef = useRef<number>(0);
  const [page, setPage] = useState<number>(PageInitValue.Page);
  const [pages, setPages] = useState<number>(PageInitValue.Pages);
  const [tableItems, setTableItems] = useState<T[]>([]);
  const [projectId, setProjectId] = useState<string>("");
  const [itemsPerPage, setItemsPerPage] = useState<TableElPerPage | undefined>(
    getLastItemOfArray(TABLE_ITEMS_PER_PAGE_OPTIONS)
  );
  const [filterTimeout, setFilterTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [tableFilterValue, setTableFilterValue] = useState<string>("");
  const [sorterValue, setSorterValue] = useState({ column: "", asc: true });

  const params = {
    project_id: projectId,
    search: tableFilterValue,
    ordering: sorterValue.asc === true ? sorterValue.column : `-${sorterValue.column}`,
    page: `${page}`,
    page_size: `${itemsPerPage}`,
  };

  const query = new URLSearchParams(params).toString();

  const onPageChange = (pageNumber: number) => {
    if (pageNumber < 1) {
      return setPage(PageInitValue.Page);
    }
    return setPage(pageNumber);
  };

  const onTableFilterValueChange = (value: string) => {
    filterTimeout && clearTimeout(filterTimeout);

    const timeout = setTimeout(() => {
      setPage(PageInitValue.Page);
      setTableFilterValue(value);
    }, 400);

    setFilterTimeout(timeout);
  };

  useEffect(() => {
    const totalPagesAmountNumber = itemsPerPage && Math.ceil(totalItemsAmountRef.current / itemsPerPage);

    if (totalPagesAmountNumber && page > totalPagesAmountNumber && totalItemsAmountRef.current) {
      setPage(totalPagesAmountNumber);
    } else {
      getItemsRequest(query).then((result) => {
        setTableItems(result.data.results);
        setPages(itemsPerPage ? Math.ceil(result.data.count / itemsPerPage) : PageInitValue.Pages);

        if (result.data.count !== totalItemsAmountRef.current) {
          totalItemsAmountRef.current = result.data.count;
        }
      });
    }

    return () => cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
  }, [query, itemsPerPage, page, getItemsRequest]);

  return {
    page,
    pages,
    setProjectId,
    onPageChange,
    tableItems,
    setTableItems,
    itemsPerPage,
    setItemsPerPage,
    sorterValue,
    setSorterValue,
    tableFilterValue,
    onTableFilterValueChange,
  };
};

export default useTableConfig;
