import { useEffect } from "react";
import { PATHS } from "route-dictionary";
import { useQuery } from "utils/useQuery";
import { companySignUpRequest } from "services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { DOMAIN, PORT, PROTOCOL } from "utils/constants";
import { signUpFormSchema } from "components/ReactHookForm/schemes";
import { useFormWithCustomError } from "../../../components/ReactHookForm/hooks";

interface ISignUpForm {
  subdomain: string;
  service_checkbox: boolean;
}

const useSignUpForm = () => {
  const query = useQuery();
  const querySubdomain = query.get("subdomain");

  const { handleSubmit, control, reset, setValue, setError } = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(signUpFormSchema),
    defaultValues: {
      subdomain: "",
      service_checkbox: false,
    } as ISignUpForm,
  });

  useEffect(() => {
    if (querySubdomain) {
      setValue("subdomain", querySubdomain, { shouldValidate: true, shouldDirty: true });
    }
  }, [querySubdomain, setValue]);

  const onFormSubmit = (formData: ISignUpForm) => {
    const { subdomain } = formData;

    companySignUpRequest(subdomain)
      .then(() =>
        window.location.assign(`${PROTOCOL}//${subdomain}.${DOMAIN}${PORT ? `:${PORT}` : ""}${PATHS.SIGN_UP.ROOT}`)
      )
      .catch(async (error) => {
        if (error.response && error.response.status === 500) {
          await reset();
        }
      });
  };

  return {
    onFormSubmit,
    form: {
      control,
      handleSubmit,
      setFormError: setError,
    },
  };
};

export default useSignUpForm;
