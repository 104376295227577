import Login from "pages/login";
import LoginSub from "pages/loginsub";
import ForgotPassword from "pages/recoverypassword/ForgotPassword";
import SignUp from "pages/signup";
import SignUpUser from "pages/signup/SignUpUser";
import Profile from "pages/profile";
import NewPassword from "pages/recoverypassword/NewPassword";
import Rules from "pages/rules";
import RuleAdd from "pages/rules/RuleAdd";
import RuleDetails from "pages/rules/RuleDetails";
import Directories from "pages/directories";
import Members from "pages/members";
import MemberAdd from "pages/members/MemberAdd";
import MemberDetails from "pages/members/MemberDetails";
import Settings from "pages/settings/Settings";
import Incidents from "pages/incidents";
import Directory from "pages/directories/Directory";
import Integrations from "pages/integrations";
import IntegrationAddOrEditFormCard from "pages/integrations/IntegrationAddOrEditFormCard";
import AuthorizedError404 from "pages/error/AuthorizedError404";
import { Redirect } from "react-router";
import RedirectSaveCurrentPage from "pages/RedirectSaveCurrentPage";
import TenantError from "pages/error/TenantError";
import License from "pages/license";
import Subscribe from "pages/subscribe";
import Projects from "pages/projects";
import ProjectDetails from "pages/projects/ProjectDetails";
import SubscribeSuccess from "pages/subscribe/success";
import Groups from "pages/groups";
import GroupDetails from "pages/groups/GroupDetails";
import { PATHS } from "route-dictionary";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";
import CheckEdit from "pages/checks/edit";
import CheckDetails from "pages/checks/details";
import CheckHistory from "pages/checks/history";
import CheckAdd from "./pages/checks/add/CheckAdd";
import Checks from "./pages/checks/Checks";

/**
 * Contains objects of behavior when given certain access
 *
 * Access Behavior Objects:
 * {
 *   @param GROUP {string} - Group name used to identify not accessible routes.
 *   @param MAIN_PAGE_PATH {string?} - Default (/) page path.
 *   (add route: {path: "/", exact: true, props: {to: MAIN_PAGE_PATH}, component: Redirect})
 *   @param NO_ACCESS_ROUTE {object?} - Parameters for routes not accessible to the user.
 *   (default: {props: {to: MAIN_PAGE_PATH}, component: Redirect})
 *   @param NOT_FOUND_ROUTE {object?} - Parameters for non-existent routes.
 *   default: {path: "*", props: {to: MAIN_PAGE_PATH}, component: Redirect})
 * }
 *
 */
const ROUTE_ACCESSES = {
  PRIVATE_TENANT: {
    AUTHORIZED: {
      GROUP: "PRIVATE_TENANT",
      MAIN_PAGE_PATH: PATHS.CHECKS.ROOT,
      NOT_FOUND_ROUTE: { name: "page.error.404", component: AuthorizedError404 },
    },
    UNAUTHORIZED: {
      GROUP: "PRIVATE_TENANT",
      MAIN_PAGE_PATH: PATHS.LOGIN.ROOT,
      NO_ACCESS_ROUTE: { props: { to: PATHS.LOGIN.ROOT }, component: RedirectSaveCurrentPage },
    },
  },
  PUBLIC_TENANT: {
    GROUP: "PUBLIC_TENANT",
    MAIN_PAGE_PATH: PATHS.LOGIN.ROOT,
  },
  NON_EXISTENT: {
    GROUP: "NON_EXISTENT",
    NOT_FOUND_ROUTE: { component: TenantError },
  },
};

const RouteNetwork = {
  currentAccess: null,

  getRoutes(tenant, isAuthorized) {
    if (tenant.error) {
      this.currentAccess = ROUTE_ACCESSES.NON_EXISTENT;
    } else if (tenant.isPublicTenant) {
      this.currentAccess = ROUTE_ACCESSES.PUBLIC_TENANT;
    } else if (isAuthorized) {
      this.currentAccess = ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED;
    } else {
      this.currentAccess = ROUTE_ACCESSES.PRIVATE_TENANT.UNAUTHORIZED;
    }

    const resultRoutes = [];

    if (this.currentAccess.hasOwnProperty("MAIN_PAGE_PATH")) {
      resultRoutes.push({
        path: "/",
        exact: true,
        props: { to: this.currentAccess.MAIN_PAGE_PATH },
        component: Redirect,
      });
    }

    this.routes.forEach((route) => {
      if (route.access.includes(this.currentAccess)) {
        if (route.action) {
          route.hasPermission(route.action) && resultRoutes.push(route);
        } else {
          resultRoutes.push(route);
        }
      } else {
        if (route.access.find((access) => access.GROUP === this.currentAccess.GROUP)) {
          if (this.currentAccess.hasOwnProperty("NO_ACCESS_ROUTE")) {
            resultRoutes.push({ ...route, ...this.currentAccess.NO_ACCESS_ROUTE });
          } else if (this.currentAccess.hasOwnProperty("MAIN_PAGE_PATH")) {
            resultRoutes.push({ ...route, props: { to: this.currentAccess.MAIN_PAGE_PATH }, component: Redirect });
          }
        }
      }
    });

    if (this.currentAccess.hasOwnProperty("NOT_FOUND_ROUTE")) {
      resultRoutes.push({ path: "*", ...this.currentAccess.NOT_FOUND_ROUTE });
    } else if (this.currentAccess.hasOwnProperty("MAIN_PAGE_PATH")) {
      resultRoutes.push({ path: "*", props: { to: this.currentAccess.MAIN_PAGE_PATH }, component: Redirect });
    }

    return resultRoutes;
  },

  routes: [
    // PRIVATE_TENANT.AUTHORIZED
    {
      path: PATHS.RULES.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.rules",
      action: ACTIONS.VIEW_DEFAULT_RULES,
      hasPermission: hasGlobalPermission,
      component: Rules,
    },
    {
      path: PATHS.RULES.ADD,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.rule.add",
      action: ACTIONS.CREATE_DEFAULT_RULES,
      hasPermission: hasGlobalPermission,
      component: RuleAdd,
    },
    {
      path: PATHS.RULES.DETAILS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.rule.details",
      action: ACTIONS.EDIT_DEFAULT_RULES,
      hasPermission: hasGlobalPermission,
      component: RuleDetails,
    },
    {
      path: PATHS.CHECKS.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.checks",
      action: ACTIONS.VIEW_CHECKS,
      hasPermission: hasGlobalPermission,
      component: Checks,
    },
    {
      path: PATHS.CHECKS.ADD,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.check.add",
      action: ACTIONS.CREATE_CHECKS,
      hasPermission: hasGlobalPermission,
      component: CheckAdd,
    },
    {
      path: PATHS.CHECKS.EDIT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.check.edit",
      action: ACTIONS.EDIT_CHECKS,
      hasPermission: hasGlobalPermission,
      component: CheckEdit,
    },
    {
      path: PATHS.CHECKS.DETAILS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.check.details",
      action: ACTIONS.VIEW_CHECKS_DETAILS,
      hasPermission: hasGlobalPermission,
      component: CheckDetails,
    },
    {
      path: PATHS.CHECKS.HISTORY,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.check.history",
      action: ACTIONS.VIEW_CHECKS_HISTORY,
      hasPermission: hasGlobalPermission,
      component: CheckHistory,
    },
    {
      path: PATHS.CHECKS.RULE_ADD,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.rule.add",
      action: ACTIONS.VIEW_CREATE_CHECKS_RULES_PAGE,
      hasPermission: hasGlobalPermission,
      component: RuleAdd,
    },
    {
      path: PATHS.CHECKS.RULE_DETAILS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.rule.details",
      action: ACTIONS.VIEW_EDIT_CHECKS_RULES_PAGE,
      hasPermission: hasGlobalPermission,
      component: RuleDetails,
    },
    {
      path: PATHS.DIRECTORIES.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.directories",
      action: ACTIONS.VIEW_MEMBERS_DIRECTORIES,
      hasPermission: hasGlobalPermission,
      component: Directories,
    },
    {
      path: PATHS.DIRECTORIES.DETAILS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.directory.edit",
      action: ACTIONS.EDIT_MEMBERS_DIRECTORIES,
      hasPermission: hasGlobalPermission,
      component: Directory,
    },
    {
      path: PATHS.DIRECTORIES.ADD,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.directory.add",
      action: ACTIONS.CREATE_MEMBERS_DIRECTORIES,
      hasPermission: hasGlobalPermission,
      component: Directory,
    },
    {
      path: PATHS.INCIDENTS.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.incidents",
      action: ACTIONS.VIEW_CHECKS_INCIDENTS,
      hasPermission: hasGlobalPermission,
      component: Incidents,
    },
    {
      path: PATHS.GROUPS.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.groups",
      action: ACTIONS.VIEW_MEMBERS_GROUPS,
      hasPermission: hasGlobalPermission,
      component: Groups,
    },
    {
      path: PATHS.GROUPS.DETAILS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.group.details",
      action: ACTIONS.EDIT_MEMBERS_GROUPS,
      hasPermission: hasGlobalPermission,
      component: GroupDetails,
    },
    {
      path: PATHS.SETTINGS.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.settings",
      action: ACTIONS.VIEW_SETTINGS,
      hasPermission: hasGlobalPermission,
      component: Settings,
    },
    {
      path: PATHS.PROFILE.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.profile",
      action: ACTIONS.VIEW_PROFILE,
      hasPermission: hasGlobalPermission,
      component: Profile,
    },
    {
      path: PATHS.MEMBERS.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.members",
      action: ACTIONS.VIEW_MEMBERS_LIST,
      hasPermission: hasGlobalPermission,
      component: Members,
    },
    {
      path: PATHS.MEMBERS.DETAILS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.member.details",
      action: ACTIONS.VIEW_MEMBER_DETAILS,
      hasPermission: hasGlobalPermission,
      component: MemberDetails,
    },
    {
      path: PATHS.MEMBERS.ADD,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.member.add",
      action: ACTIONS.CREATE_MEMBERS,
      hasPermission: hasGlobalPermission,
      component: MemberAdd,
    },
    {
      path: PATHS.INTEGRATIONS.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.integrations",
      action: ACTIONS.VIEW_INTEGRATIONS,
      hasPermission: hasGlobalPermission,
      component: Integrations,
    },
    {
      path: PATHS.INTEGRATIONS.ADD,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.integration.add",
      action: ACTIONS.CREATE_INTEGRATIONS,
      hasPermission: hasGlobalPermission,
      component: IntegrationAddOrEditFormCard,
    },
    {
      path: PATHS.INTEGRATIONS.DETAILS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.integration.details",
      action: ACTIONS.EDIT_INTEGRATIONS,
      hasPermission: hasGlobalPermission,
      component: IntegrationAddOrEditFormCard,
    },
    {
      path: PATHS.AuthorizedError404.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.error.404",
      component: AuthorizedError404,
    },
    {
      path: PATHS.SUBSCRIBE.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.subscribe",
      action: ACTIONS.VIEW_PLANS_AND_PRICING,
      hasPermission: hasGlobalPermission,
      component: Subscribe,
    },
    {
      path: PATHS.SUBSCRIBE.SUCCESS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.subscribeSuccess",
      action: ACTIONS.VIEW_PLANS_AND_PRICING,
      hasPermission: hasGlobalPermission,
      component: SubscribeSuccess,
    },
    {
      path: PATHS.PROJECTS.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.projects",
      action: ACTIONS.VIEW_PROJECTS,
      hasPermission: hasGlobalPermission,
      component: Projects,
    },
    {
      path: PATHS.PROJECTS.DETAILS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.project.details",
      action: ACTIONS.VIEW_PROJECTS_DETAILS,
      hasPermission: hasGlobalPermission,
      component: ProjectDetails,
    },
    {
      path: PATHS.PROJECTS.RULE_ADD,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.rule.add",
      action: ACTIONS.VIEW_PROJECTS_DETAILS,
      hasPermission: hasGlobalPermission,
      component: RuleAdd,
    },
    {
      path: PATHS.PROJECTS.RULE_DETAILS,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.AUTHORIZED],
      exact: true,
      name: "page.rule.details",
      action: ACTIONS.VIEW_PROJECTS_DETAILS,
      hasPermission: hasGlobalPermission,
      component: RuleDetails,
    },
    // PRIVATE_TENANT.UNAUTHORIZED
    {
      path: PATHS.NEW_PASSWORD.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.UNAUTHORIZED],
      exact: false,
      name: "page.restorePassword.new",
      component: NewPassword,
    },
    {
      path: PATHS.FORGOT_PASSWORD.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.UNAUTHORIZED],
      exact: true,
      name: "page.restorePassword.forgot",
      component: ForgotPassword,
    },
    {
      path: PATHS.SIGN_UP.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.UNAUTHORIZED],
      exact: true,
      name: "page.register",
      component: SignUpUser,
    },
    {
      path: PATHS.LOGIN.ROOT,
      access: [ROUTE_ACCESSES.PRIVATE_TENANT.UNAUTHORIZED],
      exact: true,
      name: "page.login",
      component: Login,
    },

    // PUBLIC_TENANT
    {
      path: PATHS.LOGIN.ROOT,
      access: [ROUTE_ACCESSES.PUBLIC_TENANT],
      exact: true,
      name: "page.loginSubdomain",
      component: LoginSub,
    },
    {
      path: PATHS.SIGN_UP.ROOT,
      access: [ROUTE_ACCESSES.PUBLIC_TENANT],
      exact: true,
      name: "page.registerSubdomain",
      component: SignUp,
    },
    {
      path: PATHS.LICENSE.ROOT,
      access: [ROUTE_ACCESSES.PUBLIC_TENANT, ROUTE_ACCESSES.PRIVATE_TENANT.UNAUTHORIZED],
      exact: true,
      name: "page.termsOfService",
      component: License,
    },
  ],
};

export default RouteNetwork;
