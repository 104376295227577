import axios from "axios";
import {store} from "../../store";
import config from "../../configs";
import i18next from "../language/i18next";
import {REQUEST_TYPES} from "../../utils/constants";
import {cancelSource, CancelToken, groupCancelSource} from "./cancellation";
import {setIsFetchedAction, setIsFetchingAction} from "../../store/actions/global";
import {alertErrors, logoutIfTokenIsInvalid, redirectNotExist, setShowingSpinner} from "../../utils/actionWrappers";

const instance = axios.create({
  baseURL: `${config.ORIGIN}${config.API_BASE_URL}`,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

instance.interceptors.request.use(
  config => {
    store.dispatch(setIsFetchingAction());

    if (config.method === REQUEST_TYPES.GET) {
      if (!!groupCancelSource.value) {
        config.cancelToken = groupCancelSource.value.token;
      } else {
        cancelSource.value = CancelToken.source();
        config.cancelToken = cancelSource.value.token;
      }
    }

    if (i18next?.language) {
      config.headers["Accept-Language"] = `${i18next.language}, *;q=0.9`;
    }

    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => {
    store.dispatch(setIsFetchedAction());
    return response;
  },
  error => {
    store.dispatch(setIsFetchedAction());

    if (axios.isCancel(error)) {
      const cancellationError = {...error};
      cancellationError.ignore = true;
      return Promise.reject(cancellationError);
    } else {
      return Promise.reject(error);
    }
  }
);

function requestPromiseWrapper(promise) {
  return setShowingSpinner(alertErrors(redirectNotExist(logoutIfTokenIsInvalid(promise))));
}

function requestNoSpinnerPromiseWrapper(promise) {
  return alertErrors(redirectNotExist(logoutIfTokenIsInvalid(promise)));
}

function requestWithSpinnerNoAlertErrorsPromiseWrapper(promise) {
  return setShowingSpinner(redirectNotExist(logoutIfTokenIsInvalid(promise)));
}

export {
  instance,
  alertErrors,
  setShowingSpinner,
  redirectNotExist,
  logoutIfTokenIsInvalid,
  requestPromiseWrapper,
  requestNoSpinnerPromiseWrapper,
  requestWithSpinnerNoAlertErrorsPromiseWrapper
};