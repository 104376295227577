import {
  FieldPath,
  FieldValues, FormState,
  LiteralUnion,
  MultipleFieldErrors,
  RegisterOptions,
  useForm,
  UseFormProps,
} from "react-hook-form";
import { ICustomError } from "components/ReactHookForm/_types";
import { Message } from "components/ReactHookForm/schemes/messages";

interface ICustomErrorsFromForm {
  [key: string | number]: ICustomError;
}

interface IFormStateWithCustomErrors extends FormState<FieldValues> {
  errors: ICustomErrorsFromForm,
}

interface CustomErrorOption {
  message?: Message;
  type?: LiteralUnion<keyof RegisterOptions, string>;
  types?: MultipleFieldErrors;
}

export type ICustomSetErrorFromForm = (
  name: FieldPath<FieldValues> | ICustomError,
  error?: CustomErrorOption,
  options?: {
    shouldFocus: boolean;
  }
) => void;

const useFormWithCustomError = (props: UseFormProps) => {
  const form = useForm(props);
  return {
    ...form,
    formState: form.formState as IFormStateWithCustomErrors,
    setError: form.setError as ICustomSetErrorFromForm,
  };
};

export default useFormWithCustomError;
