import i18next from "i18next";
import { PATHS } from "route-dictionary";
import { showModalWindow } from "utils/actionWrappers";
import { CHECK_TYPES } from "utils/constants";
import useCheckFormConfig from "./useCheckFormConfig";
import { useRulesInfo } from "components/RulesComponents/hooks";
import { usePreventTransition } from "components/ReactHookForm/hooks";
import { glueFormData, resetUnnecessaryFields } from "../CheckForm/CheckForm.model";
import { ICheck } from "../_types";

const useCheckForm = () => {
  const formConfig = useCheckFormConfig();

  const { formControl, onSaveButtonClick, id, isClonedCheck, checkRules, subscribeToResetForm } = formConfig;
  const isAddMode = !id;

  const {
    setError,
    formState: { dirtyFields },
    watch,
    ...form
  } = formControl;
  const isExternalCheck = form.typeValue === CHECK_TYPES.EXTERNAL;

  const {
    setDefaultValues,
    hasBlocking: isDirty,
    redirect,
    Prompt,
  } = usePreventTransition({ isDirty: isExternalCheck ? false : undefined, watch });
  subscribeToResetForm(setDefaultValues);

  const { rulesInfo, RuleActions } = useRulesInfo(checkRules);

  const onFormReset = (event?: React.MouseEvent) => {
    event && event.preventDefault();
    if (isDirty && !isAddMode) {
      showModalWindow.confirm({
        message: i18next.t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: () => redirect(isAddMode ? PATHS.CHECKS.ROOT : PATHS!.CHECKS!.getDetailsIdPath!(id)),
      });
    } else {
      redirect(isAddMode ? PATHS.CHECKS.ROOT : PATHS.CHECKS.getDetailsIdPath!(id));
    }
  };

  const onFormSubmit = (formData: ICheck) => {
    if (isDirty || isClonedCheck) {
      const changedFormData = resetUnnecessaryFields(formData);
      const newCheckInfo = glueFormData(changedFormData, rulesInfo, isClonedCheck);
      const isProjectChanged = dirtyFields.project;
      const isCheckInfoChanged = !!Object.keys(dirtyFields).filter((projectName) => projectName !== "project").length;

       onSaveButtonClick(newCheckInfo, isProjectChanged, isCheckInfoChanged).then((result) => {
         const checkId: string = isAddMode ? result?.data?.id : id;

         if (PATHS.CHECKS.getEditIdPath && PATHS.CHECKS.getDetailsIdPath) {
           const path: string = isAddMode
             ? PATHS.CHECKS.getEditIdPath(checkId)
             : PATHS.CHECKS.getDetailsIdPath(checkId);
           redirect(path);
         }
       });
    } else {
      redirect(PATHS.CHECKS.ROOT);
    }
  };

  return {
    formConfig: {
      ...formConfig,
      isAddMode,
      onFormReset,
      onFormSubmit,
      rules: {
        rulesInfo,
        RuleActions,
      },
    },
    Prompt,
    form: {
      ...form,
      setFormError: setError,
    },
    checkId: id,
  };
};

export default useCheckForm;
