import * as yup from "yup";
import { MESSAGES } from "./messages";

const signUpUserFormSchema = yup.object().shape({
  email: yup.string().email(MESSAGES.EMAIL_INVALID_VALUE).required(MESSAGES.REQUIRED),
  password: yup.string().required(MESSAGES.REQUIRED),
  confirm_password: yup
    .string()
    .required(MESSAGES.REQUIRED)
    .oneOf([yup.ref("password")], MESSAGES.MATCH_PASSWORDS),
  service_checkbox: yup.boolean().oneOf([true], MESSAGES.SERVICE_AGREE),
  remember_me: yup.boolean(),
});

export default signUpUserFormSchema;
