import { getDate, getTime } from "../../../utils/timeHandlers";
import i18next from "services/language/i18next";
import { ICheckStatus, ICheckStatusInfo, ICheckStatusText, ITooltipInfo } from "models/types";

export const getStatusText = ({ is_paused, is_enabled, is_up }: ICheckStatus): ICheckStatusText => {
  if (!is_enabled && is_enabled !== undefined) {
    return { name: i18next.t("table:status.disabled", "Disabled"), color: "var(--dark)" };
  } else if (is_paused) {
    return { name: i18next.t("table:status.inWork", "In Work"), color: "var(--warning)" };
  } else if (is_up) {
    return { name: i18next.t("table:status.up", "Up"), color: "var(--success)" };
  } else if (is_up === false) {
    return { name: i18next.t("table:status.down", "Down"), color: "var(--danger)" };
  }

  return { name: i18next.t("table:status.pending", "Pending"), color: "#c0c4c9" };
};

export const getToolTipInfo = ({
  is_up,
  is_enabled,
  is_paused,
  pause_finish,
  state_effective_since,
  info,
}: ICheckStatusInfo): ITooltipInfo => {
  if (!is_enabled && is_enabled !== undefined) {
    return { display: false, content: "" };
  }
  if (is_paused) {
    const date = getDate(pause_finish!);
    const time = getTime(pause_finish!);
    return {
      content: i18next.t("table:tooltip.checkPause", `The pause will end at ${time}, ${date}`, { time, date }),
      display: true,
    };
  }
  const date = getDate(state_effective_since!);
  const time = getTime(state_effective_since!);

  const stateText = `${is_up ? "Up" : "Down"} ${i18next.t("table:tooltip.since", "since")} ${time}, ${date}`;
  const stateTranslation = i18next.t("table:tooltip.stateSince", `State ${stateText}`, { stateText });

  // info - переменная для страницы check_details, она не равна null || undefined, только если status Down.
  return {
    content: info || stateTranslation,
    display: !!info || !!state_effective_since,
  };
};
