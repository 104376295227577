import i18next from "services/language/i18next";
import {ACTIONS, hasGlobalPermission} from "utils/user-access";

export function getTableColumnHeaders() {
  return (
    [
      {
        key: "name",
        label: i18next.t("table:header.projectName",
          "Project name"),
        _style: {width: "92%"},
        isShown: hasGlobalPermission(ACTIONS.VIEW_PROJECTS)
      },
      {
        key: "actions_column",
        label: "",
        _style: {width: "8%"},
        isShown: hasGlobalPermission(ACTIONS.VIEW_PROJECTS_ACTION_DROPDOWN)
      }
    ].filter((item) => item.isShown)
  );
}

export const projects = [
  {name: "alertrules", id: 1},
  {name: "teamtreaker", id: 2},
  {name: "jobswidget", id: 3},
  {name: "`H>=MVX]?'(6u;MrZxWUChuf99H`-5V}ukRR]pj}cU)9@4Qm(=f!Z$:_fjvp*#M", id: 4}
];