import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ERR_MESSAGES } from "utils/constants";
import useSetCheckName from "./useSetCheckName";
import { cancelSource, getCheckHistoryRequest } from "services/api";
import { ICheckHistory } from "../_types";

const useCheckHistoryData = () => {
  const { id: checkId } = useParams<{ id: string }>();
  useSetCheckName(checkId);
  const [checkHistoryData, setCheckHistoryData] = useState<ICheckHistory>({});
  
  useEffect(() => {
    getCheckHistoryRequest(checkId).then(({ data }) => setCheckHistoryData(data));

    return () => cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
  }, [checkId]);

  return {
    checkId,
    checkHistory: checkHistoryData.history,
    checkCreator: checkHistoryData.created_by,
    checkCreationDate: checkHistoryData.created_at,
    isProjectAdmin: checkHistoryData.is_user_project_admin,
  };
};

export default useCheckHistoryData;
