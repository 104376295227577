import i18next from "services/language/i18next";
import {ROLES_PROPERTY_NAMES} from "utils/constants";

const getItemsPerPageSelectValues = () => ({
  label: i18next.t("table:itemsAmount", "items per page:"),
  values: [5, 10, 15]
});

function getMembersTableColumnHeaders() {
  return (
    [
      {key: "name", label: i18next.t("table:header.memberName", "Member name"), _style: {width: "55%"}},
      {key: "role", label: i18next.t("table:header.role", "Role"), _style: {width: "40%"}},
      {key: "actions_column", label: "", _style: {width: "5%"}}
    ]
  );
}

function getGroupsTableColumnHeaders() {
  return ([
    {key: "name", label: i18next.t("table:header.groupName", "Group Name"), _style: {width: "55%"}},
    {key: "role", label: i18next.t("table:header.role", "Role"), _style: {width: "40%"}},
    {key: "actions_column", label: "", _style: {width: "5%"}}
  ]);
}

const getMembersTableFilterText = () => ({
  label: i18next.t("table:filter.label", "Search:"),
  placeholder: i18next.t("table:filter.placeholder.members", "Search for members"),
  external: true
});

const getGroupsTableFilterText = () => ({
  label: i18next.t("table:filter.label", "Search:"),
  placeholder: i18next.t("table:filter.placeholder.groups", "Search for groups"),
  external: true
});

/**
 * Convert members/groups data for table
 *
 * For members: change name property
 depending on the first_name, last_name, email
 *
 * For groups: return entry array
 *
 * @param items - groups/members roles [{group/member:{}, role:}]
 * @param rolePropertyName member || group
 * @returns {*[]|*}
 */
const convertItemsForTable = (items, rolePropertyName) => {
  if (!items) return [];

  const isMembers = rolePropertyName === ROLES_PROPERTY_NAMES.members.singular;
  if (isMembers) {
    return items.map((item) => {
      const member = item[rolePropertyName];
      return {
        ...item,
        [rolePropertyName]: {
          ...item[rolePropertyName],
          name: (member.last_name && member.first_name + " " + member.last_name) || member.first_name || member.email
        }
      };
    });
  }

  return items;
};

export {
  getItemsPerPageSelectValues,
  getGroupsTableColumnHeaders,
  getGroupsTableFilterText,
  getMembersTableFilterText,
  getMembersTableColumnHeaders,
  convertItemsForTable
};