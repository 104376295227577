import * as yup from "yup";
import { MESSAGES } from "./messages";

const loginFormSchema = yup.object().shape({
  email: yup.string().email(MESSAGES.EMAIL_INVALID_VALUE).required(MESSAGES.REQUIRED),
  password: yup.string().required(MESSAGES.REQUIRED),
  remember_me: yup.boolean(),
});

export default loginFormSchema;
