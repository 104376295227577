import { useRouteMatch } from "react-router";
import { PATHS } from "../../../route-dictionary";

const useProjectsIdParams = () => {
  // TODO: clear ! and manual generic when routes class is translated
  const matchObj = useRouteMatch<{id: string}>(PATHS.PROJECTS.DETAILS!);
  const isProjectsRulePage = !!matchObj;
  const projectId = isProjectsRulePage ? matchObj.params.id : null;

  return { isProjectsRulePage, projectId };
};

export default useProjectsIdParams;
