import { useQuery } from "utils/useQuery";
import i18next from "services/language/i18next";
import { newPasswordRequest } from "services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { showNotification } from "utils/actionWrappers";
import { newPasswordFormSchema } from "components/ReactHookForm/schemes";
import { useFormWithCustomError } from "components/ReactHookForm/hooks";

interface INewPasswordForm {
  password: string;
  confirm_password: string;
}

const useNewPasswordForm = () => {
  const query = useQuery();

  const { handleSubmit, reset, control, trigger } = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(newPasswordFormSchema),
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  const onFormSubmit = ({ password }: INewPasswordForm) => {
    const email = query.get("email");
    const token = query.get("token");
    newPasswordRequest(email, token, password)
      .then(async () => {
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t("notification:message.updated.password", "Password changed successfully."),
          "success"
        );
        await reset();
      })
      .catch(async () => {
        showNotification(
          i18next.t("notification:title.error", "Something went wrong!"),
          i18next.t("notification:message.error.token", "Your token has expired."),
          "danger"
        );
        await reset();
      });
  };

  return {
    onFormSubmit,
    form: {
      control,
      handleSubmit,
      trigger,
    },
  };
};

export default useNewPasswordForm;
