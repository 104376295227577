import { getTableNoItemsView } from "utils";
import { useTranslation } from "react-i18next";
import { useGroupDetailsForm } from "../hooks";
import PhoneLink from "components/PhoneLink";
import AddButtonRow from "components/AddButtonRow";
import { defaultAvatarSource } from "utils/constants";
import { formatPhoneNumber } from "utils/phoneHandlers";
import { getTableColumnHeaders } from "./GroupDetails.model";
import { withErrorBoundary } from "components/ErrorBoundary";
import FormControlsButtons from "components/FormControlButtons";
import { RHFInputWithLabel } from "components/ReactHookForm/fields";
import {
  ARCard,
  ARCardBody,
  ARCardFooter,
  ARCardHeader,
  ARDataTable,
  ARDropdown,
  ARDropdownItem,
  ARDropdownMenu,
  ARDropdownToggle,
  ARForm,
  ARIcon,
  ARImg,
  ARRow,
} from "components/ARComponents";
import { IGroupMembers } from "../hooks/useGroupInfo";
import { IMember } from "./../../../models/types";

const GroupDetails = () => {
  const { t } = useTranslation();
  const {
    Prompt,
    header,
    formControl,
    groupMembers,
    onFormReset,
    onFormSubmit,
    showMembersModal,
    onDeleteGroupClick,
    onDeleteMemberClick,
  } = useGroupDetailsForm();

  const { handleSubmit, control } = formControl;

  return (
    <ARCard className="GroupDetailsCard">
      <ARCardHeader className="GroupDetailsCard__header">
        <h1 className="GroupDetailsCard__header-text card-header-text">{header}</h1>
      </ARCardHeader>
      <ARCardBody className="GroupDetailsCard__body">
        <Prompt />
        <ARForm id="group-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
          <RHFInputWithLabel
            id="name"
            name="name"
            control={control}
            autoComplete="off"
            labelText={t("form:label.group", "Group name")}
            placeholder={t("form:placeholder.group", "Enter group's name")}
          />
        </ARForm>
      </ARCardBody>
      <ARCardFooter className="GroupDetailsCard__footer">
        <FormControlsButtons
          className="mr-3"
          form="group-edit-form"
          onCancelClick={onFormReset}
          onDeleteClick={onDeleteGroupClick}
        />
      </ARCardFooter>
      <ARCard className="mr-3 ml-3">
        <ARCardBody>
          <ARRow className="justify-content-between card-header-text">
            <h4>{t("card.title.members", "Members")}</h4>
            <AddButtonRow onClick={showMembersModal} />
          </ARRow>
          {!!(groupMembers as IGroupMembers).members?.length && (
            <ARDataTable
              items={(groupMembers as IGroupMembers).members}
              addTableClasses="members-table"
              fields={getTableColumnHeaders()}
              noItemsView={getTableNoItemsView()}
              hover
              striped
              border
              scopedSlots={{
                firstName: (item: IMember) => (
                  <td className="members-table__cell">
                    <div className="user-info-content">
                      <div className="user-info-content__c-avatar c-avatar">
                        <ARImg
                          src={item.avatar || defaultAvatarSource}
                          className="c-avatar__image c-avatar-img"
                          alt="member"
                        />
                      </div>
                      <div className="user-info-content__text">{item.first_name || item.email || ""}</div>
                    </div>
                  </td>
                ),
                lastName: (item: IMember) => <td className="members-table__cell">{item.last_name || ""}</td>,
                phone: (item: any) => (
                  <td className="members-table__cell">
                    <div className="members-table__phone">
                      {item.phone && <PhoneLink className="link-content">{formatPhoneNumber(item.phone)}</PhoneLink>}
                    </div>
                  </td>
                ),
                email: (item: IMember) => (
                  <td className="members-table__cell">
                    {item.email && (
                      <a href={`mailto: ${item.email}`} onClick={(event) => event.stopPropagation()}>
                        <div className="link-content">{item.email}</div>
                      </a>
                    )}
                  </td>
                ),
                actionsColumn: (item: IMember) => (
                  <td className="members-table__cell">
                    <ARDropdown className="dropdown_no-caret">
                      <ARDropdownToggle className="btn-block" size="sm" color="dark" variant="outline">
                        <ARIcon name="cilMinus" size="sm" />
                      </ARDropdownToggle>
                      <ARDropdownMenu placement="left">
                        <ARDropdownItem onClick={() => onDeleteMemberClick(item.id)}>
                          {t("button.delete", "Delete")}
                        </ARDropdownItem>
                      </ARDropdownMenu>
                    </ARDropdown>
                  </td>
                ),
              }}
            />
          )}
        </ARCardBody>
      </ARCard>
    </ARCard>
  );
};

export default withErrorBoundary(GroupDetails);
