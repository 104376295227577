import parsePhoneNumber from "libphonenumber-js";

/**
 * Returns a string converted to international phone format
 *
 * Parse phone number using parsePhoneNumber of 'libphonenumber-js'
 *
 * Correctly work with string without '+' before country code
 *
 * @param phone
 */
export function formatPhoneNumber(phone: string | number): string {
  const phoneText = String(phone);

  if (!phoneText) {
    return "";
  }

  let correctedPhoneText = phoneText;

  if (correctedPhoneText[0] !== "+") {
    correctedPhoneText = "+" + phoneText;
  }

  const phoneNumber = parsePhoneNumber(correctedPhoneText);

  return (phoneNumber && phoneNumber.formatInternational().replace(/\s/g, "\u00A0")) || phoneText;
}
