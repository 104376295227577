import * as yup from "yup";
import { MESSAGES } from "./messages";

export const groupAddModalFormSchema = yup.object().shape({
  name: yup.string().max(128, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
});

export const groupMemberAddModalFormSchema = yup.object().shape({
  newMembers: yup.array().of(yup.object()).required(MESSAGES.REQUIRED),
});

export const projectAddModalFormSchema = yup.object().shape({
  name: yup.string().max(64, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
});

export const getProjectMemberAddModalFormSchema = (propName: string = "members") =>
  yup.object().shape({
    role: yup.string().required(MESSAGES.REQUIRED),
    [propName]: yup.array().of(yup.object()).required(MESSAGES.REQUIRED),
  });

export const getProjectGroupAddModalFormSchema = (propName: string = "groups") =>
  yup.object().shape({
    role: yup.string().required(MESSAGES.REQUIRED),
    [propName]: yup.array().of(yup.object()).required(MESSAGES.REQUIRED),
  });
