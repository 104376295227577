import { PATHS } from "route-dictionary";
import { useRouteMatch } from "react-router";

const useRuleIdParams = () => {
  // TODO: clear ! and manual generic when routes class is translated
  const ruleDetailsPageRouteObj = useRouteMatch<{id: string}>(PATHS.RULES.DETAILS!);
  const checkRuleDetailsPageRouteObj = useRouteMatch<{id: string}>(PATHS.CHECKS.RULE_DETAILS!);
  const projectRuleDetailsPageRouteObj = useRouteMatch<{id: string}>(PATHS.PROJECTS.RULE_DETAILS!);

  const pathsObjects = [ruleDetailsPageRouteObj, checkRuleDetailsPageRouteObj, projectRuleDetailsPageRouteObj];
  const matchObj = pathsObjects.find((item) => item);
  const ruleId = matchObj?.params.id;

  return { isRuleDetailsPage: !!ruleId, ruleId };
};

export default useRuleIdParams;
