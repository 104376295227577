import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ARForm } from "../../ARComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "yup";

interface IARModalFormProps {
  formId: string;
  validationSchema: Yup.AnyObjectSchema;
  defaultValues: object;
  onFormSubmit: SubmitHandler<object>;
  className: string;
}

const ARModalForm: React.FC<IARModalFormProps> = (props) => {
  const { formId, children, validationSchema, defaultValues, onFormSubmit, ...otherProps } = props;

  const { handleSubmit, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const elemProps = { control }

  return (
    <ARForm id={formId} onSubmit={handleSubmit(onFormSubmit)} {...otherProps}>
      {React.isValidElement(children) && React.cloneElement(children, elemProps)}
    </ARForm>
  );
};

export default ARModalForm;
