import * as yup from "yup";
import { MESSAGES } from "./messages";
import { subdomain } from "../validators";

/** yup custom validators */
yup.addMethod(yup.string, "subdomain", subdomain);

const loginSubFormSchema = yup.object().shape({
  subdomain: yup.string().required(MESSAGES.REQUIRED).subdomain(MESSAGES.DOMAIN_INVALID_VALUE),
});

export default loginSubFormSchema;
