import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import { useSelector } from "react-redux";
import { REG_EXS } from "utils/constants";
import { useSignUpUserForm } from "./hooks";
import { Trans, useTranslation } from "react-i18next";
import { hasAdminSelector } from "store/selectors/global";
import { withErrorBoundary } from "components/ErrorBoundary";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { RHFPrependAppendInput } from "components/ReactHookForm/fields";
import { ARButton, ARForm, ARFormGroup } from "components/ARComponents";
import { RHFInputCheckbox } from "components/ReactHookForm/fields/custom";

const SignUpUser = () => {
  const { t } = useTranslation();
  const { form, onFormSubmit } = useSignUpUserForm();
  const hasAdmin = useSelector(hasAdminSelector);
  const { handleSubmit, control, trigger, setFormError } = form;

  return (
    <>
      {hasAdmin ? (
        <>
          <h1>{t("card.title.register", "Lets Start")}</h1>
          <p className="text-muted">
            {t(
              "card.description.hasAdmin",
              "Please, contact domain administrator to get your account information before using the service"
            )}
          </p>
          <ARFormGroup className="w-100 text-center">
            <Link to={PATHS.LOGIN.ROOT}>{t("button.loginToExistingUser", "Sign in to existing account")}</Link>
          </ARFormGroup>
        </>
      ) : (
        <>
          <ARForm onSubmit={handleSubmit(onFormSubmit)}>
            <h1>{t("card.title.register", "Lets Start")}</h1>
            <p className="text-muted">{t("card.description.register", "Register your company to use the service")}</p>
            <RHFPrependAppendInput
              iconName="cilLink"
              className="SignUpUser__input-group"
              id="email"
              name="email"
              control={control}
              placeholder={t("form:label.email", "Email")}
              autoComplete="email"
              pattern={REG_EXS.EMAIL_CHARACTERS}
              patternMismatchMessage={MESSAGES.EMAIL_INVALID_CHARACTERS}
              setFormError={setFormError}
            />
            <RHFPrependAppendInput
              iconName="cilLockLocked"
              className="SignUpUser__input-group"
              id="password"
              name="password"
              type="password"
              control={control}
              trigger={trigger}
              triggerName="confirm_password"
              placeholder={t("form:label.password_new", "New password")}
              autoComplete="new-password"
            />
            <RHFPrependAppendInput
              iconName="cilLockLocked"
              className="SignUpUser__input-group"
              id="confirm_password"
              name="confirm_password"
              type="password"
              control={control}
              placeholder={t("form:label.password_newConfirm", "Confirm new password")}
              autoComplete="new-password"
            />
            <RHFInputCheckbox
              id="service_checkbox"
              name="service_checkbox"
              control={control}
              className="checkbox"
              labelClassName="checkbox__label"
              labelText={
                <Trans i18nKey="form:label.terms">
                  I have read and agree to the{" "}
                  <Link to={PATHS.LICENSE.ROOT} target={"_blank"}>
                    terms of the service
                  </Link>
                  .
                </Trans>
              }
            />
            <RHFInputCheckbox
              id="is_remember_me"
              name="is_remember_me"
              control={control}
              className="checkbox"
              labelClassName="checkbox__label"
              labelText={t("form:label.rememberMe", "Remember me")}
            />
            <ARFormGroup>
              <ARButton className="w-100" type="submit" color="primary">
                {t("button.register", "Register")}
              </ARButton>
            </ARFormGroup>
            <ARFormGroup className="w-100 text-center">
              <Link to={PATHS.LOGIN.ROOT}>{t("button.loginToExistingUser", "Sign in to existing account")}</Link>
            </ARFormGroup>
          </ARForm>
        </>
      )}
    </>
  );
};

export default withErrorBoundary(SignUpUser);
