import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ARButton, ARFormGroup } from "../ARComponents";
import { ClassName, Disabled } from "models/types";

type FormControlsButtonsProps = {
  className?: ClassName;
  disabled?: Disabled;
  formGroupClassName?: ClassName;
  form: string;
  onCancelClick: (event?: React.MouseEvent) => void;
  onDeleteClick?: false | {(): void};
};

const FormControlsButtons = (props: FormControlsButtonsProps) => {
  const { className, formGroupClassName, form, onCancelClick, onDeleteClick, disabled } = props;
  const { t } = useTranslation();

  return (
    <ARFormGroup className={classNames("FormControlsButtons", formGroupClassName)}>
      {onDeleteClick && (
        <ARButton
          color="danger"
          variant="outline"
          className={classNames("form-control-button", className)}
          onClick={onDeleteClick}
        >
          {t("form:button.delete", "Delete")}
        </ARButton>
      )}
      <ARButton
        type="reset"
        form={form}
        color="warning"
        variant="outline"
        className={classNames("form-control-button", className)}
        onClick={onCancelClick}
      >
        {t("form:button.cancel", "Cancel")}
      </ARButton>
      <ARButton
        type="submit"
        form={form}
        className={classNames("form-control-button_mr0", className)}
        color="success"
        variant="outline"
        disabled={disabled}
      >
        {t("form:button.save", "Save")}
      </ARButton>
    </ARFormGroup>
  );
};

export default FormControlsButtons;
