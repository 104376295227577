import "core-js";
import App from "./App";
import config from "configs";
import history from "./history";
import ReactDOM from "react-dom";
import "services/language/i18next";
import { Router } from "react-router";
import browser from "utils/browser";
import { Provider } from "react-redux";
import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { IOS_BROWSERS, LOCATIONS } from "utils/constants";
import "./index.scss";
import { store } from "./store";

if (config.ERUDA) {
  import("eruda").then((eruda) => eruda.default.init());
}

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (!error.ignore) {
        return event;
      }
    },
  });
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function zoomOutMobile() {
  const viewport = document.querySelector("meta[name=\"viewport\"]");
  if (viewport) {
    if (IOS_BROWSERS.includes(browser.name)) {
      viewport.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0");
    } else {
      const appliedScale = 1 - Math.random() * 0.01;
      viewport.setAttribute("content", `width=device-width, initial-scale=${appliedScale}`);
    }
  }
}

const ResetZoom = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    zoomOutMobile();
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("orientationchange", zoomOutMobile);

    return () => window.removeEventListener("orientationchange", zoomOutMobile);
  });

  return null;
};

//TODO: don't forget to remove it when it'll become unnecessary
const isProductionMode = window.location.hostname.endsWith(LOCATIONS.PROD);
const stripePromise = isProductionMode ? null : loadStripe(config.STRIPE_API_KEY);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        {isProductionMode ? (
          <>
            <ResetZoom />
            <ScrollToTop />
            <App />
          </>
        ) : (
          <Elements stripe={stripePromise}>
            <ResetZoom />
            <ScrollToTop />
            <App />
          </Elements>
        )}
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
