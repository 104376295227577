import React from "react";
import TableCard from "./TableCard";
import {useTranslation} from "react-i18next";
import {useProjectDetailsForm} from "../hooks";
import {ACTIONS, ROLES_PROPERTY_NAMES} from "utils/constants";
import {hasGlobalPermission} from "utils/user-access";
import {withErrorBoundary} from "components/ErrorBoundary";
import FormControlsButtons from "components/FormControlButtons";
import {RHFInputWithLabel} from "components/ReactHookForm/fields";
import {ARCard, ARCardBody, ARCardFooter, ARCardHeader, ARForm, ARIcon, ARTooltip} from "components/ARComponents";
import AuthorizedError404 from "pages/error/AuthorizedError404";
import {
  convertItemsForTable,
  getGroupsTableColumnHeaders,
  getGroupsTableFilterText,
  getMembersTableColumnHeaders,
  getMembersTableFilterText
} from "./TableCard/TableCard.model";
import RulesCard from "components/RulesComponents/RulesCard";
import {useRulesInfo} from "components/RulesComponents/hooks";
import {PATHS} from "route-dictionary";
import classNames from "classnames";
import "./ProjectDetails.scss";

const ProjectDetails = () => {
  const {t} = useTranslation();
  const {
    Prompt,
    roles,
    formControl,
    projectInfo,
    updateRoles,
    onFormReset,
    onFormSubmit,
    onFormDelete,
    showGroupsModal,
    showMembersModal
  } = useProjectDetailsForm();

  const {header, isMainProject, isProjectAdmin, projectId, projectRules} = projectInfo;
  const {handleSubmit, control} = formControl;

  const {rulesInfo, RuleActions} = useRulesInfo(projectRules);

  if (!isProjectAdmin) {
    return <AuthorizedError404/>;
  }

  return (
    <ARCard className="ProjectDetailsCard">
      <ARCardHeader className="ProjectDetailsCard__header">
        <h1 className="ProjectDetailsCard__header-text card-header-text">
          {header}
        </h1>
      </ARCardHeader>
      {
        hasGlobalPermission(ACTIONS.EDIT_PROJECTS) &&
        <>
          <ARCardBody className="ProjectDetailsCard__body">
            <Prompt/>
            <ARForm id="project-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
              <RHFInputWithLabel
                id="name"
                name="name"
                control={control}
                autoComplete="off"
                disabled={isMainProject}
                labelText={t("form:label.projectName", "Project name")}
                placeholder={t("form:placeholder.projectName", "Enter project's name")}
              />
            </ARForm>
          </ARCardBody>
          <ARCardFooter className="ProjectDetailsCard__footer">
            <FormControlsButtons
              className="mr-3"
              form="project-edit-form"
              onCancelClick={onFormReset}
              disabled={isMainProject}
              onDeleteClick={isMainProject ? null : onFormDelete}
            />
          </ARCardFooter>
        </>
      }
      <ARCard
        className={classNames(
          "ProjectRules", "no-side-borders", {
            "no-top-border no-bottom-border": !hasGlobalPermission(ACTIONS.EDIT_PROJECTS)
          })}>
        <ARCardHeader className="ProjectRules__Header no-bottom-border">
          <h2 className="card-header-text">
            {t("card.title.defaultProjectRules", "Default rules")}
            <ARTooltip
              content={t("tooltip.defaultRules", "Default rules applied when no rules for the check")}
              placement="right"
            >
              <ARIcon className="Rules__tooltip-icon" name="cilInfo" size="lg" color="var(--secondary)"/>
            </ARTooltip>
          </h2>
        </ARCardHeader>
        <ARCardBody>
          <RulesCard
            rules={rulesInfo}
            noRulesText={t("noData.defaultRules")}
            deleteRule={RuleActions.deleteRule}
            updateRule={RuleActions.updateRule}
            showRuleAddButton
            showRuleSwitcher
            showEditButton
            showDeleteButton
            getEditRulePath={(ruleId) => PATHS.PROJECTS.getAttachedRuleDetailsPath({attachedId: projectId, ruleId})}
            addRulePath={PATHS.PROJECTS.getAttachedRuleAddPath(projectId)}
          />
        </ARCardBody>
      </ARCard>
      {
        hasGlobalPermission(ACTIONS.EDIT_PROJECTS) &&
        <ARCardBody className="mr-3 ml-3">
          <ARCardBody>
            <TableCard
              items={convertItemsForTable(roles.members, ROLES_PROPERTY_NAMES.members.singular)}
              rolePropertyNames={ROLES_PROPERTY_NAMES.members}
              getTableFilterText={getMembersTableFilterText}
              updateRoles={updateRoles}
              headerText={t("card.title.members", "Members")}
              fields={getMembersTableColumnHeaders()}
              addRowClickCallback={showMembersModal}
              className={`${!roles.members?.length && "border-bottom"} mb-3 pb-1`}
              isMainProject={isMainProject}
            />
            <TableCard
              items={convertItemsForTable(roles.groups, ROLES_PROPERTY_NAMES.groups.singular)}
              rolePropertyNames={ROLES_PROPERTY_NAMES.groups}
              getTableFilterText={getGroupsTableFilterText}
              updateRoles={updateRoles}
              headerText={t("card.title.groups", "Groups")}
              fields={getGroupsTableColumnHeaders()}
              addRowClickCallback={showGroupsModal}
            />
          </ARCardBody>
        </ARCardBody>
      }
    </ARCard>
  );
};

export default withErrorBoundary(ProjectDetails);