import { Trans } from "react-i18next";
import { PATHS } from "route-dictionary";
import { ARLink } from "components/ARComponents";
import i18next from "services/language/i18next";
import { useIntegrationFormConfig } from "./index";
import { showModalWindow } from "utils/actionWrappers";
import { getDirtyValues } from "components/ReactHookForm/utils";
import { usePreventTransition } from "components/ReactHookForm/hooks";
import { Iintegration } from "pages/checks/_types";

const useIntegrationForm = () => {
  const { formId, isAddPage, className, headerText, onSaveBtnClick, onDeleteBtnClick, formControl, onTypeChange } =
    useIntegrationFormConfig();
  const {
    formState: { dirtyFields },
    ...form
  } = formControl;
  const isDirty = !!Object.keys(dirtyFields).length;
  const { redirect, Prompt } = usePreventTransition({ isDirty });
  const redirectToIntegrationsPage = () => redirect(PATHS.INTEGRATIONS.ROOT);

  const onLinkClick = (e: any, id: string) => {
    e.preventDefault();
    window.open(PATHS.CHECKS.getDetailsIdPath?.(id), "_blank");
  };

  const onSelectChange = (selectValue: string) => {
    onTypeChange(selectValue);
  };

  const onFormSubmit = (formData: Iintegration) => {
    if (isDirty) {
      const formDirtyFields = { ...dirtyFields };
      if (isAddPage) {
        formDirtyFields.type = true;
      }

      const submitData = getDirtyValues({ dirtyFields: formDirtyFields, allValues: formData });
      onSaveBtnClick(submitData).then(() => redirectToIntegrationsPage());
    } else {
      redirectToIntegrationsPage();
    }
  };

  const onFormReset = (event: any) => {
    event.preventDefault();
    if (isDirty) {
      showModalWindow.confirm({
        message: i18next.t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: () => redirectToIntegrationsPage(),
      });
    } else redirectToIntegrationsPage();
  };

  const onFormDelete = () => {
    showModalWindow.confirm({
      title: i18next.t("modal:title.confirm", "Confirm your action"),
      message: i18next.t("modal:message.delete.integration", "Are you sure you want to delete this integration?"),
      color: "danger",
      onConfirm: () =>
      onDeleteBtnClick && onDeleteBtnClick()
          .then(() => redirectToIntegrationsPage())
          .catch((error: { response: { data: { id: string, name: string}[], status: number}}) => {
            const checksWithThisIntegrationElements = error.response.data.map((item, index, arr) => (
              <span key={`${item.name}__${item.id}`}>
                <ARLink onClick={(e: React.MouseEvent) => onLinkClick(e, item.id)} key={`${item.name}__${item.id}`}>
                  {item.name}
                </ARLink>
                {index !== arr.length - 1 ? ", " : ""}
              </span>
            ));
            if (error.response.status === 409) {
              showModalWindow.alert({
                title: i18next.t("modal:title.confirm", "Confirm your action"),
                message: (
                  <Trans i18nKey="modal:message.integrationUsedInCheck">
                    This integration is used in the check: <>{checksWithThisIntegrationElements}</>. To remove the
                    integration, remove it from the check.
                  </Trans>
                ),
                color: "danger",
              });
            }
          }),
    });
  };

  return {
    formId,
    className,
    isAddPage,
    headerText,
    onFormReset,
    onFormSubmit,
    onFormDelete: onDeleteBtnClick && onFormDelete,
    onSelectChange,
    Prompt,
    form,
  };
};

export default useIntegrationForm;
