import React, { useMemo, useState } from "react";
import { PATHS } from "route-dictionary";
import { useHistory } from "react-router-dom";
import camelCaseItemNames from "camelcase-keys";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { getDate, getTime } from "../../../utils/timeHandlers";
import { ARButton, ARCol, ARListGroupItem, ARTooltip } from "components/ARComponents";
import { IIntervalRefObj } from "pages/directories/Directories.model";
import { IMemberDirectoryElement, IGetMemberDirectories } from "services/api/types";
import {
  getMemberDirectoryRequest,
  removeDirectoryRequest,
  testConnectionRequest,
  updateDirectoryRequest,
} from "services/api";
import "./../Directories.scss";

interface IDirectoryCardProps {
  intervalRef: React.MutableRefObject<IIntervalRefObj>;
  directoryData: IMemberDirectoryElement;
  onDeleteDirectorySuccess: () => Promise<IGetMemberDirectories>;
  updateDirectories: () => Promise<void>;
}

const DirectoryCard = ({
  intervalRef,
  directoryData,
  onDeleteDirectorySuccess,
  updateDirectories,
}: IDirectoryCardProps) => {
  const [directoryInfo, setDirectoryInfo] = useState<IMemberDirectoryElement>({ ...directoryData });
  const history = useHistory();
  const { t } = useTranslation();

  useMemo(() => {
    setDirectoryInfo({ ...directoryData });
  }, [directoryData]);

  const onChangeAutoSyncStatusClick = async () => {
    await updateDirectoryRequest(directoryInfo.id, { auto_sync: !directoryInfo.autoSynс });
    setDirectoryInfo((oldState) => ({
      ...oldState,
      autoSync: !oldState.autoSynс,
    }));
  };

  const onEditClick = () => {
    history.push(PATHS.DIRECTORIES.getDetailsIdPath?.(directoryInfo.id) || "");
  };

  const onRemoveDirectoryClick = () => {
    showModalWindow.confirm({
      message: t(
        "modal:message.delete.directory_withItemName",
        `Are you sure you want to delete the directory ${directoryInfo.name}?`,
        { itemName: directoryInfo.name }
      ),
      color: "danger",
      onConfirm: () => {
        removeDirectoryRequest(directoryInfo.id).then(() => {
          showNotification(t("notification:title.success"), t("notification:message.deleted.directory"), "success");
          onDeleteDirectorySuccess();
        });
      },
    });
  };

  const onTestClick = () => {
    testConnectionRequest(directoryInfo.id).then(
      () =>
        showModalWindow.alert({
          title: t("notification:title.success", "Success!"),
          message: t("notification:message.connection.success", "Connection successful"),
          color: "success",
        }),
      (error) => {
        if (error.response.status === 400) {
          showModalWindow.alert({
            title: t("notification:title.error", "Error!"),
            message: `${error.response.data.msg}`,
            color: "danger",
          });
        }
      }
    );
  };

  const onSyncClick = async () => {
    //Удаляем интервал для того чтобы обновить
    intervalRef.current.clearDirectoriesInterval();
    intervalRef.current.intervalId = setInterval(() => updateDirectories(), 5000);
    intervalRef.current.requestsCount++;
    const syncResponse = await updateDirectoryRequest(directoryInfo.id, { sync_now: true });
    setDirectoryInfo((oldState) => ({
      ...oldState,
      isSyncingNow: syncResponse.data.is_syncing_now,
    }));
  };

  const onRefreshClick = async () => {
    intervalRef.current.refreshInterval();
    intervalRef.current.requestsCount--;
    const directoryResponse = await getMemberDirectoryRequest(directoryInfo.id);
    setDirectoryInfo(camelCaseItemNames(directoryResponse.data));
  };
  return (
    <ARListGroupItem action className="Directory-Card text-md-left text-center d-flex flex-md-row flex-column">
      <ARCol xl="3" lg="3" md="3" className="Directory-Card__info mt-2 mb-1 mr-1">
        <span className="mr-0 mr-md-4">{directoryInfo.name}</span>
        <span>{directoryInfo.type}</span>
      </ARCol>
      <ARCol xl="9" lg="9" md="9" className="text-md-right ">
        <ARButton
          onClick={onChangeAutoSyncStatusClick}
          className="Directory-Card__disable-enable-button mb-1 mr-1"
          variant="outline"
          color="primary"
        >
          {directoryInfo.autoSynс ? t("button.disable", "Disable") : t("button.enable", "Enable")}
        </ARButton>
        <ARButton
          onClick={onRemoveDirectoryClick}
          className="mb-1 mr-1"
          variant="outline"
          color="primary"
          disabled={directoryInfo.isSyncingNow}
        >
          {t("button.delete", "Delete")}
        </ARButton>
        <ARButton
          className="mb-1 mr-1"
          variant="outline"
          color="primary"
          onClick={onEditClick}
          disabled={directoryInfo.isSyncingNow}
        >
          {t("button.edit", "Edit")}
        </ARButton>
        <ARButton onClick={onTestClick} className="mb-1 mr-1" variant="outline" color="primary">
          {t("button.test", "Test")}
        </ARButton>
        <ARButton
          onClick={() => (directoryInfo.isSyncingNow ? onRefreshClick() : onSyncClick())}
          className="mb-1 mr-1"
          variant="outline"
          color="primary"
        >
          {directoryInfo.isSyncingNow ? t("button.refresh", "Refresh") : t("button.synchronise", "Synchronise")}
        </ARButton>
        <div className="text-right mr-1">
          {directoryInfo.isSyncingNow
            ? t("synchronizing.pending", "Syncing...")
            : (directoryInfo.lastError && (
                <ARTooltip content={directoryInfo.lastError} placement="bottom">
                  <span className="text-danger">{t("synchronizing.rejected", "Sync Error!")}</span>
                </ARTooltip>
              )) ||
              (directoryInfo.synced &&
                t(
                  "synchronizing.fulfilled",
                  `Last synchronised at ${getTime(directoryInfo.synced)}, ${getDate(directoryInfo.synced)}`,
                  {
                    date: getDate(directoryInfo.synced),
                    time: getTime(directoryInfo.synced),
                  }
                ))}
        </div>
      </ARCol>
    </ARListGroupItem>
  );
};

export default withErrorBoundary(DirectoryCard);
