import classNames from "classnames";
import { ARFlagProps } from "../_types";

const ARFlag = (props: ARFlagProps) => {
  const { country, className, flagClassName, useSVG = false } = props;

  return (
    <div className={classNames("ARFlag", "flags-container", className)}>
      <div
        className={classNames(flagClassName, {
          [`flag ${country}`]: !useSVG,
          [`flag-svg ${country}-svg`]: useSVG,
        })}
      />
    </div>
  );
};

export default ARFlag;
