import React, { useRef } from "react";
import { ARModalForm } from "../ARModalForm";
import { showModalWindow } from "utils/actionWrappers";
import Yup from "yup";
import { IMember, IProject } from "./../../../models/types";
import { SubmitHandler } from 'react-hook-form';

interface IUseFormModalReturnParams {
  title: string;
  formId: string;
  fields: React.ReactNode;
  formClassName?: string;
  validationSchema: Yup.AnyObjectSchema;
  defaultValues: object;
}

export interface IProjectCallback {
  (projectData: IProject): Promise<void>;
}

export interface IGroupCallback {
  (formData: { name: string; role?: string; newMembers?: IMember[] }): void;
}

const useFormModal = <T extends IProjectCallback | IGroupCallback>(submitCallback: T) => {
  const successfulSubmitSubscriber = useRef<() => void>();

  const subscribeToSuccessfulSubmit = (callback: () => void) => {
    successfulSubmitSubscriber.current = callback;
  };

  const formSubmit = (formData: { name: string }) => {
    submitCallback(formData);
    typeof successfulSubmitSubscriber.current === "function" && successfulSubmitSubscriber.current();
  };

  return ({ title, formId, fields, formClassName = "", validationSchema, defaultValues }: IUseFormModalReturnParams) => {
    showModalWindow.form({
      title,
      message: (
        <ARModalForm
          formId={formId}
          className={formClassName}
          onFormSubmit={formSubmit as SubmitHandler<object>}
          defaultValues={defaultValues}
          validationSchema={validationSchema}
        >
          {fields}
        </ARModalForm>
      ),
      formOptions: {
        formId,
        subscribeToSuccessfulSubmit,
      },
    });
  };
};

export default useFormModal;
