import * as yup from "yup";
import { MESSAGES } from "./messages";
import { nexmo, phone } from "../validators";

/** yup custom validators */
yup.addMethod(yup.string, "phone", phone);
yup.addMethod(yup.string, "nexmo", nexmo);

const settingsFormSchema = yup.object().shape({
  telegram_connect_key: yup.string().max(12, MESSAGES.MAX_LENGTH),
  telegram_token: yup.string().max(64, MESSAGES.MAX_LENGTH),
  is_telegram_auth_enabled: yup.boolean(),
  telegram_language: yup.string(),
  nexmo_app_id: yup.string().max(64, MESSAGES.MAX_LENGTH),
  nexmo_from_phone: yup.string().phone(MESSAGES.PHONE_INVALID_VALUE),
  nexmo_private_key: yup.string().nexmo(MESSAGES.NEXMO_INVALID_VALUE),
  jira_url: yup.string().url(MESSAGES.URL_INVALID_VALUE).max(200, MESSAGES.MAX_LENGTH),
  jira_token: yup.string().max(64, MESSAGES.MAX_LENGTH),
  timezone: yup.string(),
});

export default settingsFormSchema;
