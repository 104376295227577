import i18next from "i18next";
import { PATHS } from "route-dictionary";
import { ARIcon } from "components/ARComponents";
import { ACTIONS, hasAccess, hasGlobalPermission } from "utils/user-access";

interface INavElement {
  _tag: string;
  name: string;
  to?: string;
  icon?: JSX.Element;
  isShown: boolean;
  _children?: INavElement[];
}

const getNavigation = () => {
  const navigation: INavElement[] = [
    {
      _tag: "ARSidebarNavItem",
      name: i18next.t("sidebar.checks", "Checks"),
      to: PATHS.CHECKS.ROOT,
      icon: <ARIcon name="cilAvTimer" customClasses="c-sidebar-nav-icon" />,
      isShown: hasGlobalPermission(ACTIONS.VIEW_CHECKS),
    },
    {
      _tag: "ARSidebarNavItem",
      name: i18next.t("sidebar.incidents", "Incidents"),
      to: PATHS.INCIDENTS.ROOT,
      icon: <ARIcon name="cilFire" customClasses="c-sidebar-nav-icon" />,
      isShown: hasGlobalPermission(ACTIONS.VIEW_CHECKS_INCIDENTS),
    },
    {
      _tag: "ARSidebarNavItem",
      name: i18next.t("sidebar.projects", "Projects"),
      to: PATHS.PROJECTS.ROOT,
      icon: <ARIcon name="cilLibrary" customClasses="c-sidebar-nav-icon" />,
      isShown: hasGlobalPermission(ACTIONS.VIEW_PROJECTS),
    },
    {
      _tag: "ARSidebarNavItem",
      name: i18next.t("sidebar.rules", "Rules"),
      to: PATHS.RULES.ROOT,
      icon: <ARIcon name="cilFlagAlt" customClasses="c-sidebar-nav-icon" />,
      isShown: hasGlobalPermission(ACTIONS.VIEW_DEFAULT_RULES),
    },
    {
      _tag: "ARSidebarNavDropdown",
      name: i18next.t("sidebar.members", "Members"),
      icon: <ARIcon name="cilPeople" customClasses="c-sidebar-nav-icon" />,
      isShown: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_NAV_ITEM),
      _children: [
        {
          _tag: "ARSidebarNavItem",
          name: i18next.t("sidebar.membersChildren.list", "List"),
          to: PATHS.MEMBERS.ROOT,
          isShown: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST),
        },
        {
          _tag: "ARSidebarNavItem",
          name: i18next.t("sidebar.membersChildren.directories", "Directories"),
          to: PATHS.DIRECTORIES.ROOT,
          isShown: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_DIRECTORIES),
        },
        {
          _tag: "ARSidebarNavItem",
          name: i18next.t("sidebar.membersChildren.groups", "Groups"),
          to: PATHS.GROUPS.ROOT,
          isShown: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_GROUPS),
        },
      ],
    },
    {
      _tag: "ARSidebarNavItem",
      name: i18next.t("sidebar.integrations", "Integrations"),
      to: PATHS.INTEGRATIONS.ROOT,
      icon: <ARIcon name="cilLan" customClasses="c-sidebar-nav-icon" />,
      isShown: hasGlobalPermission(ACTIONS.VIEW_INTEGRATIONS),
    },
    {
      _tag: "ARSidebarNavItem",
      name: i18next.t("sidebar.settings", "Settings"),
      to: PATHS.SETTINGS.ROOT,
      icon: <ARIcon name="cilSettings" customClasses="c-sidebar-nav-icon" />,
      isShown: hasGlobalPermission(ACTIONS.VIEW_SETTINGS),
    },
    {
      _tag: "ARSidebarNavItem",
      name: i18next.t("sidebar.subscription", "Plans and pricing"),
      to: PATHS.SUBSCRIBE.ROOT,
      icon: <ARIcon name="cilWallet" customClasses="c-sidebar-nav-icon" />,
      isShown: hasAccess(ACTIONS.VIEW_PLANS_AND_PRICING),
    },
  ];

  return navigation.reduce((acc: INavElement[], navItem) => {
    const newNavItem = { ...navItem };

    if (navItem.isShown) {
      if (navItem._children) {
        newNavItem._children = navItem._children.filter((child) => child.isShown);
      }
      acc.push(newNavItem);
    }

    return acc;
  }, []);
};

export default getNavigation;
