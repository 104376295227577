import classNames from "classnames";
import RulesInfo from "../RuleInfo";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "../../ErrorBoundary";
import { ARButton, ARCard, ARCardBody, ARCardTitle } from "components/ARComponents";
import { IChat, IUser } from "../../ReactHookForm/_types";
import { ClassName } from "../../../models/types";
import { ICheckRules } from "pages/checks/_types";

interface IRulesCardProps {
  rules: ICheckRules[];
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showRuleSwitcher?: boolean;
  showRuleAddButton?: boolean;
  getEditRulePath?: (id: number | string) => string;
  addRulePath?: string;
  updateStates?: () => void; //?
  members?: IUser[];
  telegramChats?: IChat[];
  updateRule?: (ruleData: Partial<ICheckRules>, id: number, showSpinner?: boolean) => Promise<unknown>;
  deleteRule?: (id: number) => Promise<unknown>;
  className?: ClassName;
  noRulesText: JSX.Element;
}

const RulesCard = (props: IRulesCardProps) => {
  const {
    rules = [],
    showEditButton,
    showDeleteButton,
    showRuleSwitcher,
    showRuleAddButton,
    getEditRulePath,
    addRulePath,
    updateStates,
    noRulesText,
    updateRule,
    deleteRule,
    className,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ARCard className={classNames("RulesCard", className)}>
      <ARCardBody>
        <ARCardTitle className="RulesCard-header">
          <span className="RulesCard-header__text">{t("card.title.rules", "Rules")}</span>
          {showRuleAddButton && (
            <ARButton
              color="info"
              variant="outline"
              className="RulesCard-header__add-button"
              onClick={() => history.push(addRulePath!)}
            >
              {t("button.addRule", "Add Rule")}
            </ARButton>
          )}
        </ARCardTitle>
        {rules.length === 0 ? (
          <p>{noRulesText}</p>
        ) : (
          <div className="RulesCard__rules">
            {rules.map((rule) => (
              <div className="RulesCard__rule" key={`rule_${rule.id}`}>
                <RulesInfo
                  rule={rule}
                  updateStates={updateStates!}
                  showRuleSwitcher={showRuleSwitcher}
                  showEditButton={showEditButton}
                  getEditRulePath={getEditRulePath}
                  showDeleteButton={showDeleteButton}
                  updateRule={updateRule}
                  deleteRule={deleteRule}
                />
              </div>
            ))}
          </div>
        )}
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary<IRulesCardProps>(RulesCard);
