import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TelegramLoginButton from "telegram-login-button";
import { ARIcon, ARTooltip } from "components/ARComponents";
import { telegramInfoSelector } from "store/selectors/user";
import { withErrorBoundary } from "components/ErrorBoundary";
import TelegramLogoutContent from "./TelegramLogoutContent";
import { useTelegramAuthInfo, useTelegramWidget } from "../hooks";

const TelegramAuthContent = () => {
  const { t } = useTranslation();
  const telegramAuthInfo = useTelegramAuthInfo();
  const onTelegramWidgetClick = useTelegramWidget();
  const { id: telegramId, userName: telegramUserName } = useSelector(telegramInfoSelector);

  const isLoggedIn = !!telegramId;
  const hasTelegramBot = !!telegramAuthInfo?.telegram_bot;

  return hasTelegramBot || isLoggedIn ? (
    <div className="TelegramAuthContent">
      <div className="TelegramAuthContent-container">
        <div className="TelegramAuthContent-header">
          <h5 className="TelegramAuthContent-header__text">{t("telegram.auth", "Telegram authorization")}</h5>
          <ARTooltip
            placement="right"
            className="TelegramAuthContent-header__tooltip tooltip"
            content={t("tooltip.telegramAuth", "If you see 'Bot domain invalid', send '/setdomain' to the @BotFather")}
          >
            <ARIcon className="tooltip__icon" name="cilInfo" color="var(--secondary)" />
          </ARTooltip>
        </div>
        {isLoggedIn ? (
          <TelegramLogoutContent telegramUserName={telegramUserName} onTelegramWidgetClick={onTelegramWidgetClick} />
        ) : (
          <div className="TelegramAuthContent__login-widget">
            <TelegramLoginButton
              usePic={false}
              cornerRadius={5}
              dataOnauth={onTelegramWidgetClick}
              botName={telegramAuthInfo?.telegram_bot?.username || ""}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default withErrorBoundary(TelegramAuthContent);
