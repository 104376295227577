import { useIntegrationForm } from "../hooks";
import { useTranslation } from "react-i18next";
import { hasGlobalPermission } from "utils/user-access";
import { integrationsTypes } from "../Integrations.model";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ACTIONS, INTEGRATION_TYPES } from "utils/constants";
import FormControlsButtons from "components/FormControlButtons";
import { ARCard, ARCardBody, ARCardFooter, ARCardHeader, ARForm } from "components/ARComponents";
import { RHFInputWithLabel, RHFPhoneInputWithLabel, RHFSelectWithLabel } from "components/ReactHookForm/fields";

const IntegrationAddOrEditFormCard = () => {
  const { t } = useTranslation();
  const {
    formId,
    className,
    isAddPage,
    headerText,
    onFormReset,
    onFormSubmit,
    onFormDelete,
    onSelectChange,
    Prompt,
    form,
  } = useIntegrationForm();
  const { control, typeValue, handleSubmit } = form;

  const isNexmoType = typeValue === INTEGRATION_TYPES.NEXMO;
  const isJiraType = typeValue === INTEGRATION_TYPES.JIRA;
  const isTwilioType = typeValue === INTEGRATION_TYPES.TWILIO;
  const isSMSCType = typeValue === INTEGRATION_TYPES.SMSC;
  const isSelectelType = typeValue === INTEGRATION_TYPES.SELECTEL;
  const isDadataType = typeValue === INTEGRATION_TYPES.DADATA;

  return (
    <ARCard className={className}>
      <ARCardHeader className={`${className}__header`}>
        <h1 className={`${className}__header-text card-header-text`}>{headerText}</h1>
      </ARCardHeader>
      <ARCardBody className={`${className}__body`}>
        <Prompt />
        <ARForm id={formId} className="integration-form" onSubmit={handleSubmit(onFormSubmit)}>
          <RHFInputWithLabel
            id="name"
            name="name"
            labelText={t("form:label.integrationName", "integration name")}
            placeholder={t("form:placeholder.integrationName", "Enter integration's name")}
            control={control}
            autoComplete="off"
          />
          <RHFSelectWithLabel
            id="type"
            name="type"
            labelText={t("form:label.integrationType", "Integration type")}
            control={control}
            onSelectChange={onSelectChange}
            options={integrationsTypes}
            disabled={!isAddPage}
          />
          {isJiraType && (
            <>
              <RHFInputWithLabel
                id="token"
                name="token"
                labelText={t("form:label.specificToken", "Jira token", { what: "Jira" })}
                placeholder={t("form:placeholder.specificToken", "Enter Jira token", { what: "Jira" })}
                control={control}
                shouldUnregister={false}
                autoComplete="off"
              />
              <RHFInputWithLabel
                id="url"
                name="url"
                labelText="Jira URL"
                placeholder={t("form:placeholder.default", "Enter Jira URL", { what: "Jira URL" })}
                control={control}
                autoComplete="off"
              />
            </>
          )}
          {isNexmoType && (
            <>
              <RHFInputWithLabel
                id="api_key"
                name="api_key"
                labelText="Api Key"
                placeholder={t("form:placeholder.default", "Enter Api Key", { what: "Api Key" })}
                control={control}
                autoComplete="off"
              />
              <RHFInputWithLabel
                id="app_id"
                name="app_id"
                labelText="App ID"
                placeholder={t("form:placeholder.default", "Enter App ID", { what: "App ID" })}
                control={control}
                autoComplete="off"
              />
              <RHFPhoneInputWithLabel
                id="from_phone"
                name="from_phone"
                labelText={t("form:label.callFromPhone", "Call from phone")}
                control={control}
                autoComplete="off"
                block
              />
            </>
          )}
          {isTwilioType && (
            <>
              <RHFInputWithLabel
                id="account_cid"
                name="account_cid"
                labelText="Account CID"
                placeholder={t("form:placeholder.default", "Enter Account CID", { what: "Account CID" })}
                control={control}
                autoComplete="off"
              />
              <RHFInputWithLabel
                id="token"
                name="token"
                labelText={t("form:label.specificToken", "Twilio token", { what: "Twilio" })}
                placeholder={t("form:placeholder.specificToken", "Enter Twilio token", { what: "Twilio" })}
                control={control}
                shouldUnregister={false}
                autoComplete="off"
              />
            </>
          )}
          {isSMSCType && (
            <>
              <RHFInputWithLabel
                id="login"
                name="login"
                labelText={t("form:label.login", "Login")}
                placeholder={t("form:placeholder.login", "Enter login")}
                control={control}
                autoComplete="new-password"
              />
              <RHFInputWithLabel
                id="password"
                name="password"
                type="password"
                labelText={
                  isAddPage ? t("form:label.password", "Password") : t("form:label.password_new", "New password")
                }
                placeholder={
                  isAddPage
                    ? t("form:placeholder.password", "Enter password")
                    : t("form:placeholder.password_new", "Enter new password")
                }
                control={control}
                autoComplete="new-password"
              />
            </>
          )}
          {isSelectelType && (
            <RHFInputWithLabel
              id="api_key"
              name="api_key"
              labelText="Api Key"
              placeholder={t("form:placeholder.default", "Enter Api Key", { what: "Api Key" })}
              control={control}
              autoComplete="off"
            />
          )}
          {isDadataType && (
            <>
              <RHFInputWithLabel
                id="api_key"
                name="api_key"
                labelText="Api Key"
                placeholder={t("form:placeholder.default", "Enter Api Key", { what: "Api Key" })}
                control={control}
                autoComplete="off"
              />
              <RHFInputWithLabel
                id="secret_key"
                name="secret_key"
                labelText="Secret Key"
                placeholder={t("form:placeholder.default", "Enter Secret Key", { what: "Secret Key" })}
                control={control}
                autoComplete="off"
              />
            </>
          )}
        </ARForm>
      </ARCardBody>
      <ARCardFooter className={`${className}__footer`}>
        <FormControlsButtons
          className="mr-3"
          form={formId}
          onCancelClick={onFormReset}
          onDeleteClick={hasGlobalPermission(ACTIONS.DELETE_INTEGRATIONS) && onFormDelete}
        />
      </ARCardFooter>
    </ARCard>
  );
};

export default withErrorBoundary(IntegrationAddOrEditFormCard);
