import { useEffect, useState } from "react";
import { useActions } from "store/actions";
import { ERR_MESSAGES } from "utils/constants";
import { cancelSource, getDetailedCheckRequest, getProjectDataRequest } from "services/api";
import { resetCheckNameAction, setCheckNameAction } from "store/actions/global";

const useProjectAdminStatus = (checkId: number, projectId: number) => {
  const [isProjectAdmin, setIsProjectAdmin] = useState(false);
  const [setCheckName] = useActions([setCheckNameAction]);
  const [resetCheckName] = useActions([resetCheckNameAction]);

  useEffect(() => {
    if (checkId) {
      getDetailedCheckRequest(checkId).then(({ data }) => {
        setCheckName(data);
        setIsProjectAdmin(data.is_user_project_admin);
      });
    } else if (projectId) {
      getProjectDataRequest(projectId).then(({ data }) => {
        setIsProjectAdmin(data.is_user_project_admin);
      });
    }

    return () => {
      resetCheckName();
      cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
    };
  }, [checkId, setCheckName, resetCheckName, projectId]);

  return isProjectAdmin;
};

export default useProjectAdminStatus;
