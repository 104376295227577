import classNames from "classnames";
import { Control } from "react-hook-form";
import { ClassName } from "models/types";
import { ARFormGroup, ARLabel, ARInputCheckbox } from "components/ARComponents";
import { ARInputCheckboxProps } from "components/ARComponents/_types";
import ValidationFeedback from "../../ValidationFeedback";
import useControllerWithCustomError from "../../hooks/useControllerWithCustomError";

interface IRHFInputCheckboxProps extends ARInputCheckboxProps {
  id: string;
  name: string;
  control: Control;
  className: ClassName;
  labelText: string | JSX.Element;
  labelClassName: string;
}

const RHFInputCheckbox = ({
  id,
  name,
  control,
  className,
  labelText,
  labelClassName,
  ...props
}: IRHFInputCheckboxProps) => {
  const { field, fieldState } = useControllerWithCustomError({
    name,
    control,
    defaultValue: false,
  });

  const { ref, value, ...inputProps } = field;
  const { error } = fieldState;

  return (
    <ARFormGroup className={classNames(className)}>
      <ARInputCheckbox id={id} innerRef={ref} checked={value} {...inputProps} {...props} />
      <ARLabel htmlFor={id || ""} className={classNames(labelClassName)}>
        {labelText}
      </ARLabel>
      <ValidationFeedback error={error} />
    </ARFormGroup>
  );
};

export default RHFInputCheckbox;
