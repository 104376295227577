import classNames from "classnames";
import RHFSelect from "./custom/RHFSelect";
import { RHFPrependAppend } from "./wrappers";
import { ARFormGroup } from "components/ARComponents";
import { IRHFPrependAppendProps } from "./wrappers/RHFPrependAppend";
import { IRHFSelectProps } from "./custom/RHFSelect";

interface IRHFPrependAppendSelectProps extends IRHFSelectProps, IRHFPrependAppendProps {
  selectClassName?: string;
}

const RHFPrependAppendSelect = ({
  className,
  iconName,
  prependText,
  appendText,
  selectClassName,
  ...inputProps
}: IRHFPrependAppendSelectProps) => {
  return (
    <ARFormGroup className={classNames(className)}>
      <RHFPrependAppend iconName={iconName} prependText={prependText} appendText={appendText}>
        <RHFSelect {...inputProps} className={classNames(selectClassName)} />
      </RHFPrependAppend>
    </ARFormGroup>
  );
};

export default RHFPrependAppendSelect;
