import * as yup from "yup";
import { MESSAGES } from "./messages";
import { domainExist, subdomain } from "../validators";

/** yup custom validators */
yup.addMethod(yup.string, "domainExist", domainExist);
yup.addMethod(yup.string, "subdomain", subdomain);

const signUpFormSchema = yup.object().shape({
  subdomain: yup
    .string()
    .subdomain(MESSAGES.DOMAIN_INVALID_VALUE)
    .domainExist(MESSAGES.DOMAIN_EXIST)
    .required(MESSAGES.REQUIRED),
  service_checkbox: yup.boolean().oneOf([true], MESSAGES.SERVICE_AGREE),
});

export default signUpFormSchema;
