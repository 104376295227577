import * as yup from "yup";
import { MESSAGES } from "./messages";
import { ldapHost } from "../validators";

/** yup custom validators */
yup.addMethod(yup.string, "ldapHost", ldapHost);

const getDirectoryFormSchema = (isAddPage = false) =>
  yup.object().shape({
    name: yup.string().max(128, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    type: yup.string().required(MESSAGES.REQUIRED),
    host: yup.string().ldapHost(MESSAGES.HOST_INVALID_VALUE).max(128, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    login: yup.string().max(128, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    password: yup
      .string()
      .max(128, MESSAGES.MAX_LENGTH)
      .when("isAddPage", {
        is: () => isAddPage,
        then: yup.string().required(MESSAGES.REQUIRED),
      }),
    base_dn: yup.string().max(128, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    ldap_user_filter: yup.string().max(128, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    ldap_group_filter: yup.string().max(128, MESSAGES.MAX_LENGTH).required(MESSAGES.REQUIRED),
    use_for_authentication: yup.boolean(),
  });

export default getDirectoryFormSchema;
