import { useMemo } from "react";
import classNames from "classnames";
import { ARIcon, ARInputGroup, ARInputGroupPrepend, ARInputGroupText } from "components/ARComponents";
import { freeSet } from "@coreui/icons";
import { IActionParams, IDeleteItemClick, IItemObject } from "components/ReactHookForm/_types";
import { ClassName } from "models/types";

interface IOnAddItemActionParams<T> extends Omit<IActionParams, "item"> {
  item: T;
}

interface IRHFControlListProps<T> {
  className?: ClassName;
  prependComponent?: string;
  items: T[];
  getItemText: (el: T) => IItemObject | string;
  emptyText: string;
  actionIconProps: { name: keyof typeof freeSet; className: string };
  onDeleteItemAction?: ({ index, event }: IDeleteItemClick<T>) => void;
  onAddItemAction?: ({ event, item }: IOnAddItemActionParams<T>) => void;
}

function RHFControlList<T>(props: IRHFControlListProps<T>) {
  const {
    className,
    prependComponent,
    items,
    getItemText,
    emptyText = "Empty",
    actionIconProps,
    onDeleteItemAction,
    onAddItemAction,
  } = props;

  const elements = useMemo(() => {
    return items.map((item) => ({ text: getItemText(item).toString(), item }));
  }, [items, getItemText]);

  return (
    <ARInputGroup className={classNames(className)}>
      {prependComponent && (
        <ARInputGroupPrepend>
          <ARInputGroupText className="ARControlList__prepend-text">{prependComponent}</ARInputGroupText>
        </ARInputGroupPrepend>
      )}
      <div className="form-control ARControlList">
        {elements.length > 0 ? (
          elements.map((element: { text: string; item: T }, index: number) => (
            <div key={`${element.text} ${index}`} className="ARControlList__elements align-middle">
              <span className="ARControlList__element-text">{element.text}</span>&nbsp;
              <ARIcon
                {...actionIconProps}
                className={`ml-1 ${actionIconProps.className}`}
                onClick={(event) => {
                  if (onAddItemAction) {
                    onAddItemAction({ item: element.item, event });
                  } else if (onDeleteItemAction) {
                    onDeleteItemAction({ item: element.item, index, event });
                  }
                }}
              />
            </div>
          ))
        ) : (
          <div className="ARControlList__empty-text">{emptyText}</div>
        )}
      </div>
    </ARInputGroup>
  );
}

export default RHFControlList;
