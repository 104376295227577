import classNames from "classnames";
import React, { useEffect } from "react";
import { Control } from "react-hook-form";
import { useControllerWithCustomError } from "components/ReactHookForm/hooks";
import { IARSelectProps } from "components/ARComponents/_types";
import { ARSelect } from "components/ARComponents";
import { ClassName } from "models/types";
import { ICustomSetErrorFromForm } from "components/ReactHookForm/hooks/useFormWithCustomError";

export interface IOption {
  value: string | number | boolean;
  name: string;
}

export interface IRHFSelectProps extends Omit<IARSelectProps, "children"> {
  id: string;
  name: string;
  control?: Control;
  className?: ClassName;
  highlightInvalid?: boolean;
  options: IOption[];
  setError?: ICustomSetErrorFromForm;
  onSelectChange?: (value: any) => void;
}

const RHFSelect = (props: IRHFSelectProps) => {
  const {
    name,
    control,
    className,
    onSelectChange,
    highlightInvalid = false,
    options,
    setError,
    ...selectProps
  }: IRHFSelectProps = props;
  const {
    field: { ref, onChange, onBlur, ...register },
    fieldState: { invalid, error },
  } = useControllerWithCustomError({ name, control, shouldUnregister: true });

  useEffect(() => {
    setError && setError(error);
  }, [setError, error]);

  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event);
    onSelectChange && onSelectChange(event.target.value);
  };


  return (
    <ARSelect
      custom
      innerRef={ref}
      className={classNames("field_right-border-radius", className)}
      onChange={changeHandler}
      invalid={highlightInvalid ? invalid : undefined}
      {...register}
      {...selectProps}
    >
      {options.map((option, index) => {
        let key = typeof option.value === "boolean" ? index : option.value;
        return (
        <option key={key} value={String(option.value)}>
          {option.name}
        </option>
      );})}
    </ARSelect>
  );
};

export default RHFSelect;
