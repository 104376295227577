import { PATHS } from "route-dictionary";
import { checkTypes } from "../Checks.model";
import { CHECK_TYPES } from "utils/constants";
import { useCheckDetailsData } from "../hooks";
import { updateRuleRequest } from "services/api";
import { Trans, useTranslation } from "react-i18next";
import CheckControlButtons from "./CheckControlButtons";
import { withErrorBoundary } from "components/ErrorBoundary";
import RulesCard from "components/RulesComponents/RulesCard";
import NoRulesText from "components/RulesComponents/NoRulesText";
import { getFullNameFromType, getTableNoItemsView, validateUrl } from "utils";
import { getLastCheckStatus, getTableColumnHeaders } from "./ChecksDetails.model";
import { ACTIONS, getProjectRole, hasProjectPermission } from "utils/user-access";
import { getDate, getTime } from "../../../utils/timeHandlers";
import { ARCard, ARCardBody, ARCardHeader, ARDataTable, ARLink, ARStatusText } from "components/ARComponents";
import { ICheck } from "pages/checks/_types";
import { IARStatusTextProps } from "components/ARComponents/_types";
import { IProject } from "./../../../models/types";

const ChecksDetails = () => {
  const { checkDetailsData, actionFunctions, updateCheckDetailsData } = useCheckDetailsData();
  const {
    id,
    type,
    name,
    description,
    state_effective_since,
    url,
    last_checked,
    check_log,
    rules,
    ext_system,
    extra,
    project,
    is_user_project_admin,
  } = checkDetailsData as ICheck;
  const projectRole = getProjectRole(is_user_project_admin);

  const { t } = useTranslation();

  const isBalanceCheck = type === CHECK_TYPES.BALANCE;
  const isJiraCheck = type === CHECK_TYPES.JIRA;
  const isHeaderCheck = type === CHECK_TYPES.HEADER;
  const isExternalCheck = type === CHECK_TYPES.EXTERNAL;
  const isJiraOrBalanceCheck = isJiraCheck || isBalanceCheck;
  const isInvalidUrl = !validateUrl(url);
  const isInvalidHeaderUrl = extra ? !validateUrl(extra.text) : true;

  if (!Object.keys(checkDetailsData!).length) {
    return null;
  }

  return (
    <ARCard className="CheckDetails-card">
      <ARCardHeader>
        <h1 className="card-header-text">{name}</h1>
      </ARCardHeader>
      <ARCardBody>
        <p>
          {getFullNameFromType(type, checkTypes)} <br />
          {isJiraOrBalanceCheck || isInvalidUrl ? (
            url
          ) : (
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          )}
          {isExternalCheck && ext_system}
        </p>
        {isHeaderCheck && (
          <p>
            {extra.header} <br />
            {isInvalidHeaderUrl ? (
              extra.text
            ) : (
              <a href={extra.text} target="_blank" rel="noreferrer">
                {extra.text}
              </a>
            )}
          </p>
        )}
        <p>{description || null}</p>
        <div className="mb-2">
          <p>
            {t("page.project.singular", "Project:")}{" "}
            {hasProjectPermission(ACTIONS.VIEW_PROJECT, projectRole) ? (
              <ARLink to={PATHS.PROJECTS.getDetailsIdPath!((project as IProject)?.id)}>{(project as IProject)?.name || ""}</ARLink>
            ) : (
              <>{(project as IProject)?.name || ""}</>
            )}
          </p>
        </div>
        {last_checked === null ? (
          <p>{t("check.pending", "Check has not been performed yet")}</p>
        ) : (
          <p>
            <Trans i18nKey="check.info" values={{ date: last_checked, status: getLastCheckStatus(checkDetailsData as ICheck) }}>
              Last checked: {last_checked} status was <b>{getLastCheckStatus(checkDetailsData as ICheck)}</b>
            </Trans>
            {state_effective_since
              ? t("check.since", `since ${getTime(state_effective_since)}, ${getDate(state_effective_since)}`, {
                  date: getDate(state_effective_since),
                  time: getTime(state_effective_since),
                })
              : ""}
          </p>
        )}
        <CheckControlButtons
          checkDetailsData={checkDetailsData as ICheck}
          actionFunctions={actionFunctions}
          isUsed={checkTypes.find((checkType) => checkType.value === (checkDetailsData as ICheck).type)?.isUsed || false}
          checkId={id}
        />
        {hasProjectPermission(ACTIONS.VIEW_PROJECT_CHECKS_RULES, projectRole) && (
          <RulesCard
            rules={rules}
            updateRule={updateRuleRequest}
            updateStates={() => updateCheckDetailsData(id, false)}
            showRuleSwitcher={hasProjectPermission(ACTIONS.ENABLE_DISABLE_CHECKS_RULES, projectRole)}
            noRulesText={<NoRulesText projectId={(project as IProject)?.id} projectRole={projectRole} />}
          />
        )}
        {hasProjectPermission(ACTIONS.VIEW_PROJECT_CHECKS_LOG, projectRole) && (
          <ARDataTable
            overTableSlot={<h4>{t("table:header.checkLog", "Check log")}</h4>}
            className="CheckDetails-card__check-data-table"
            items={check_log}
            fields={getTableColumnHeaders(projectRole)}
            noItemsView={getTableNoItemsView()}
            hover
            size="sm"
            striped
            border
            scopedSlots={{
              status: (item: IARStatusTextProps) => (
                <td className="text-center">
                  <ARStatusText {...item} />
                </td>
              ),
            }}
          />
        )}
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(ChecksDetails);
