import React from "react";
import {ARSelect} from "components/ARComponents";

const ChecksAmountSelect = (props) => {
  const {className, onChange, options} = props;

  return (
    <ARSelect
      custom
      id="checks_amount"
      name="checks_amount"
      className={className}
      onChange={onChange}
    >
      {options.map((option) => <option key={option.value} value={option.value}>{option.name}</option>)}
    </ARSelect>
  );
};

export default ChecksAmountSelect;