import React from "react";
import { useTranslation } from "react-i18next";
import CheckActionButton from "./CheckActionButton";
import { ARFormGroup } from "components/ARComponents";
import { withErrorBoundary } from "components/ErrorBoundary";
import TakeToWorkDropdown from "components/TakeToWorkDropdown";
import { ACTIONS, getProjectRole, hasProjectPermission } from "utils/user-access";
import { checkId, ICheck } from "../_types";
import { incidentId } from "pages/checks/_types";

interface ICheckControlButtonsProps {
  checkDetailsData: ICheck;
  actionFunctions: {
    onReturnCheckClick: (id: number) => void;
    onPauseCheckClick:  (id: number | string, time: number, incidentId?: incidentId) => void;
    onEnableDisableCheckClick: (checkId: checkId, isEnabled?: boolean) => void;
    onEditCheckClick: (checkId: checkId, isEnabled?: boolean) => void;
    onDeleteCheckClick: (checkId: checkId, isEnabled?: boolean) => void;
    onCheckHistoryClick: (checkId: checkId, isEnabled?: boolean) => void;
  };
  isUsed?: boolean;
  checkId?: checkId;
}

const CheckControlButtons = (props: ICheckControlButtonsProps) => {
  const { checkDetailsData, actionFunctions, isUsed } = props;
  const { is_enabled, is_paused, is_up, id, pauses_nn, is_user_project_admin } = checkDetailsData;
  const projectRole = getProjectRole(is_user_project_admin);

  const { t } = useTranslation();

  let displayedButton;
  if (is_enabled && is_paused) {
    displayedButton = (
      <CheckActionButton
        className="mr-3"
        color="warning"
        variant="outline"
        text={t("button.return", "Return")}
        checkId={id}
        onCheckActionButtonClick={actionFunctions.onReturnCheckClick}
      />
    );
  } else {
    displayedButton = (
      <TakeToWorkDropdown
        className="mr-3"
        checkId={id}
        placement="bottom"
        modifiers={[{ name: "flip", enabled: false }]}
        size=""
        pauses={pauses_nn}
        onTakeToWorkButtonClick={actionFunctions.onPauseCheckClick}
      />
    );
  }

  return (
    <ARFormGroup className="CheckDetails-card__buttons mb-3">
      {isUsed && (
        <React.Fragment>
          {hasProjectPermission(ACTIONS.ENABLE_DISABLE_PROJECT_CHECKS, projectRole) && (
            <CheckActionButton
              className="mr-3"
              color={is_enabled ? "dark" : "success"}
              variant="outline"
              text={is_enabled ? t("button.disable", "Disable") : t("button.enable", "Enable")}
              checkId={id}
              isEnabled={is_enabled}
              onCheckActionButtonClick={actionFunctions.onEnableDisableCheckClick}
            />
          )}
          {!(is_up !== false || !is_enabled) &&
            hasProjectPermission(ACTIONS.TAKE_PROJECT_CHECKS_TO_WORK, projectRole) &&
            displayedButton}
        </React.Fragment>
      )}
      {hasProjectPermission(ACTIONS.EDIT_PROJECT_CHECKS, projectRole) && (
        <CheckActionButton
          color="primary"
          variant="outline"
          text={t("button.edit", "Edit")}
          className="mr-3"
          checkId={id}
          isEnabled={is_enabled}
          onCheckActionButtonClick={actionFunctions.onEditCheckClick}
        />
      )}
      {hasProjectPermission(ACTIONS.DELETE_PROJECT_CHECKS, projectRole) && (
        <CheckActionButton
          color="danger"
          variant="outline"
          text={t("button.delete", "Delete")}
          className="mr-3"
          checkId={id}
          onCheckActionButtonClick={actionFunctions.onDeleteCheckClick}
        />
      )}
      {hasProjectPermission(ACTIONS.VIEW_PROJECT_CHECKS_HISTORY, projectRole) && (
        <CheckActionButton
          checkId={id}
          color="dark"
          variant="outline"
          text={t("button.history", "History")}
          onCheckActionButtonClick={actionFunctions.onCheckHistoryClick}
        />
      )}
    </ARFormGroup>
  );
};

export default withErrorBoundary(CheckControlButtons);
