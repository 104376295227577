import i18next from "services/language/i18next";
import {ACTIONS, hasGlobalPermission} from "utils/user-access";

function getTableColumnHeaders() {
  const hasViewMembersGroupsPermission = hasGlobalPermission(ACTIONS.VIEW_MEMBERS_GROUPS);
  const tableColumnHeaders = [
    {
      key: "name",
      label: i18next.t("table:header.groupName", "Group name"),
      _style: {width: "70%"},
      isShown: hasViewMembersGroupsPermission
    },
    {
      key: "usersCount",
      label: i18next.t("table:header.usersCount", "Users count"),
      _style: {width: "22%"},
      isShown: hasViewMembersGroupsPermission
    },
    {
      key: "actionsColumn",
      label: "",
      _style: {width: "8%"},
      isShown: hasViewMembersGroupsPermission
    }
  ];

  return tableColumnHeaders.filter(item => item.isShown);
}

export {
  getTableColumnHeaders
};