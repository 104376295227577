import { ARDataTableProps } from "./_types";
import { ARDataTableComponent } from "./_ARBaseComponents";

interface IARDataTableCustomProps extends ARDataTableProps {
  className?: string;
}

const ARDataTable = (props: IARDataTableCustomProps) => {
  return <ARDataTableComponent {...props} />;
};

export default ARDataTable;
