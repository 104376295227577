import { useController, UseControllerProps } from "react-hook-form";
import { ICustomError } from "components/ReactHookForm/_types";

const useControllerWithCustomError = (props: UseControllerProps) => {
  const controller = useController(props);
  const customError = controller.fieldState.error as unknown as ICustomError;
  return {
    ...controller,
    fieldState: {
      ...controller.fieldState,
      error: customError,
    },
  };
};

export default useControllerWithCustomError;
