import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { PATHS } from "route-dictionary";
import { useLocation } from "react-router-dom";
import i18next from "services/language/i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { showNotification } from "utils/actionWrappers";
import { getFormDataObj } from "components/ReactHookForm/utils";
import { ERR_MESSAGES, INTEGRATION_TYPES } from "utils/constants";
import { integrationFields } from "../IntegrationAddOrEditFormCard/IntegrationAddOrEdit.model";
import getIntegrationFormSchema from "components/ReactHookForm/schemes/integrationFormSchema";
import {
  cancelSource,
  createIntegrationRequest,
  deleteIntegrationRequest,
  getIntegrationRequest,
  updateIntegrationRequest,
} from "services/api";
import { Iintegration } from "pages/checks/_types";
import { useFormWithCustomError } from "components/ReactHookForm/hooks";

interface IUseIntegrationFormConfigReturn {
  className: string;
  formId: string;
  headerText: () => never;
  onSaveBtnClick: (arg: Iintegration) => Promise<unknown>;
  formControl: any;
  isAddPage?: boolean;
  onTypeChange: (arg: string) => void;
  onDeleteBtnClick?: () => Promise<unknown>;
}

const useIntegrationFormConfig = (): IUseIntegrationFormConfigReturn => {
  const { pathname } = useLocation();
  const { id: integrationId } = useParams<{ id: string }>();
  const isAddPage = pathname.endsWith(PATHS.INTEGRATIONS.ADD!);
  const [headerText, setHeaderText] = useState(i18next.t("card.title.integration.add", "Add integration"));

  const formControl = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(getIntegrationFormSchema(isAddPage)),
    defaultValues: getFormDataObj({
      arrOfFields: integrationFields,
      serverData: { type: INTEGRATION_TYPES.JIRA },
    }),
  });
  const { reset, watch, setValue } = formControl;
  const typeValue = watch("type");

  const onTypeChange = async (typeValue: string) => {
    const name = watch("name");
    const integrationDefaultValues = getFormDataObj({
      arrOfFields: integrationFields,
      serverData: { type: INTEGRATION_TYPES.JIRA },
    });
    await reset({ ...integrationDefaultValues, type: typeValue });
    if (name) {
      setValue("name", name, { shouldDirty: true, shouldValidate: true });
    }
  };

  const addIntegration = (integrationData: Iintegration) => {
    return createIntegrationRequest(integrationData).then(() => {
      showNotification(
        i18next.t("notification:title.success", "Success!"),
        i18next.t("notification:message.added.integration", "Integration has been successfully added."),
        "success"
      );
    });
  };

  const editIntegration = (data: Iintegration) => {
    return updateIntegrationRequest(data, integrationId).then(() => {
      showNotification(
        i18next.t("notification:title.success", "Success!"),
        i18next.t("notification:message.updated.integration", "Integration has been updated"),
        "success"
      );
    });
  };

  const deleteIntegration = () => {
    return deleteIntegrationRequest(integrationId)
      .then(() => {
        showNotification(
          i18next.t("notification:title.success", "Success!"),
          i18next.t("notification:message.deleted.integration", "Integration has been deleted"),
          "success"
        );
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    if (!isAddPage) {
      getIntegrationRequest(integrationId).then((response) => {
        setHeaderText(response.data.name);
        const formData = getFormDataObj({ arrOfFields: integrationFields, serverData: response.data });
        reset(formData);
      });
    }
    return () => cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
  }, [isAddPage, reset, integrationId]);

  const integrationAddPageConfig = {
    className: "IntegrationAdd-card",
    formId: "integration-add-form",
    onSaveBtnClick: addIntegration,
    headerText,
    formControl: {
      ...formControl,
      typeValue,
    },
    isAddPage,
    onTypeChange,
  };

  const integrationEditPageConfig = {
    className: "IntegrationEdit-card",
    formId: "integration-edit-form",
    onSaveBtnClick: editIntegration,
    onDeleteBtnClick: deleteIntegration,
    headerText,
    formControl: {
      ...formControl,
      typeValue,
    },
    onTypeChange,
  };

  return isAddPage ? integrationAddPageConfig : integrationEditPageConfig;
};

export default useIntegrationFormConfig;
