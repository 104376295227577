import React from "react";
import {useVisibility} from "../../../hooks";
import {withErrorBoundary} from "components/ErrorBoundary";
import {CollapseCardContext} from "../../../subscribe-context";
import {ARButton, ARCard, ARCardBody, ARCollapse, ARIcon} from "components/ARComponents";

const CollapseCard = (props) => {
  const {headerText, collapseBodyContent, className} = props;
  const {isVisible, isVisibleHandler} = useVisibility();

  return (
    <ARCard className={`CollapseCard ${className}`}>
      <ARButton
        className={`CollapseCard__header-button ${isVisible ? "collapse-button_active" : "collapse-button"}`}
        color="info"
        variant="outline"
        onClick={isVisibleHandler}
        onMouseDown={(e) => e.preventDefault()}
      >
        <h6 className="CollapseCard__header-text">
          {headerText}
        </h6>
        {
          isVisible ?
            <ARIcon name="cilChevronCircleUpAlt"/> :
            <ARIcon name="cilChevronCircleDownAlt"/>
        }
      </ARButton>
      <ARCollapse show={isVisible}>
        <ARCardBody className="CollapseCard__body">
          <CollapseCardContext.Provider value={isVisibleHandler}>
            {collapseBodyContent}
          </CollapseCardContext.Provider>
        </ARCardBody>
      </ARCollapse>
    </ARCard>
  );
};

export default withErrorBoundary(CollapseCard);