import {useContext, useEffect, useState} from "react";
import usePrice from "./usePrice";
import i18next from "services/language/i18next";
import {useStripe} from "@stripe/react-stripe-js";
import {PlansContext} from "../subscribe-context";
import {showModalWindow, showNotification} from "utils/actionWrappers";
import {
  changeSubscription,
  createStripeCheckoutSession,
  deletePaymentIntent,
  patchCompanyBilling,
  stripeConfirmCardPayment,
  stripeRedirectToCheckout
} from "../../../services/api";

const usePremiumPlan = (hideCollapseCard) => {
  const {hasDiscount, companyBilling, updateCompanyBilling, selectedPlan, subscriptionPlans} = useContext(PlansContext);
  const [checksAmount, setChecksAmount] = useState(subscriptionPlans[0].value);
  const {price, updatePrice} = usePrice();
  const stripe = useStripe();

  const changeSubscriptionHandler = () => {
    const paymentData = {
      price_id: selectedPlan.id,
      quantity: checksAmount
    };

    changeSubscription(paymentData)
      .then(async ({data}) => {
        if (data.requires_action) {
          const clientSecret = data.client_secret;
          const {error} = await stripeConfirmCardPayment({stripe, clientSecret});

          if (error) {
            const voidInvoiceParams = {id: data.invoice};
            const voidInvoiceQuery = new URLSearchParams(voidInvoiceParams).toString();

            deletePaymentIntent(voidInvoiceQuery);
            showNotification(
              i18next.t("notification:title.error", "Something went wrong!"),
              i18next.t("notification:message.error.paymentVerification", "Failed to verify the payment method."),
              "danger"
            );

            return;
          }
        } else if (data.requires_payment_method) {
          showNotification(
            i18next.t("notification:title.error", "Something went wrong!"),
            data.last_payment_error.message,
            "danger"
          );

          return;
        }

        patchCompanyBilling(paymentData, companyBilling.id)
          .then(() => updateCompanyBilling())
          .then(() => {
            hideCollapseCard();
            showNotification(
              i18next.t("notification:title.success", "Success!"),
              i18next.t("notification:message.changed.subscription", "Subscription has been successfully changed."),
              "success"
            );
          });
      });
  };

  const onCheckAmountChange = ({target}) => {
    const newAmount = +target.value;
    setChecksAmount(newAmount);
    updatePrice(newAmount, hasDiscount);
  };

  const onSubscribe = () => {
    if (!stripe) {
      return;
    }

    const paymentData = {
      price: selectedPlan,
      quantity: checksAmount,
      locale: i18next.language
    };

    createStripeCheckoutSession(paymentData)
      .then(async ({data}) => {
        const checkoutOptions = {sessionId: data};
        await stripeRedirectToCheckout({stripe, checkoutOptions});
      });
  };

  const onSubscriptionChange = async () => {
    if (!stripe) {
      return;
    }

    showModalWindow.confirm({
      message: i18next.t("modal:message.subscriptionChange", "Are you sure you want to change subscription?"),
      color: "warning",
      onConfirm: () => changeSubscriptionHandler()
    });
  };

  useEffect(() => updatePrice(checksAmount), [checksAmount, updatePrice, selectedPlan]);

  return {
    price,
    checksAmount,
    onSubscribe,
    onSubscriptionChange,
    onCheckAmountChange
  };
};

export default usePremiumPlan;