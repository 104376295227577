import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ARLabel, ARSelect } from "components/ARComponents";

interface IItemsPerPageSelector {
  className?: string;
  onChange: (arf: string) => void; 
  options: number[];
  disabled: boolean;
  value: number;
}

const ItemsPerPageSelector = (props: IItemsPerPageSelector) => {
  const { t } = useTranslation();
  const { className, onChange, options, disabled, value } = props;

  return (
    <div className={classNames("ItemsPerPageSelector", className)}>
      <ARLabel className="ItemsPerPageSelector__label">{t("table:itemsAmount", "items per page:")}</ARLabel>
      <ARSelect
        custom
        id="itemsPerPage"
        name="itemsPerPage"
        className="ItemsPerPageSelector__select"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)}
        disabled={disabled}
        value={value}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </ARSelect>
    </div>
  );
};

export default ItemsPerPageSelector;
