import * as yup from "yup";
import { Message } from "../schemes/messages";

function balance(this: yup.StringSchema, message: Message) {
  return this.test("balance", message, (value) => {
    if (!value) {
      return true;
    }
    return /^(\d{1,6})(\.\d{1,2})?$/g.test(value);
  });
}

export default balance;
