import {instance, requestPromiseWrapper, requestWithSpinnerNoAlertErrorsPromiseWrapper} from "./instance";

export function getProjectsRequest() {
  return requestPromiseWrapper(instance.get("/projects"));
}

export function createProjectRequest(projectData) {
  return requestPromiseWrapper(instance.post("/projects", projectData));
}

export function deleteProjectRequest(projectId) {
  return requestWithSpinnerNoAlertErrorsPromiseWrapper(instance.delete(`/projects/${projectId}`));
}

export function getProjectDataRequest(projectId) {
  return requestPromiseWrapper(instance.get(`/projects/${projectId}`));
}

export function patchProjectDataRequest(projectId, projectData) {
  return requestPromiseWrapper(instance.patch(`/projects/${projectId}`, projectData));
}

export function getRolesRequest(projectId) {
  return requestPromiseWrapper(instance.get(`/projects/${projectId}/roles`));
}

export function updateRolesRequest(roles, projectId) {
  return requestPromiseWrapper(instance.put(`/projects/${projectId}/roles`, roles));
}