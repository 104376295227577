import config from "../configs";

export const DOMAIN = window?.location?.hostname?.match(/(?:[a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+$/)?.[0];
export const PORT = window.location.port;
export const PROTOCOL = window.location.protocol;
export const API_HOSTNAME = new URL(config.ORIGIN).hostname;
export const API_DOMAIN = API_HOSTNAME.split(".").slice(1).join(".");

export const defaultAvatarSource = "/avatars/1.png";

export const CHECK_TYPES = {
  JIRA: "jira",
  BALANCE: "balance",
  DOMAIN: "domain",
  PORT: "port",
  HEADER: "header",
  HTTP: "http",
  HTTP_CERTIFICATE: "httpscert",
  EXTERNAL: "ext",
  PING: "ping",
};

export const INTEGRATION_TYPES = {
  JIRA: "jira",
  NEXMO: "nexmo",
  TWILIO: "twilio",
  SMSC: "smsc",
  SELECTEL: "selectel",
  DADATA: "dadata",
};

export const REQUEST_TYPES = {
  GET: "get",
};

export enum DAY_TYPES {
  ANY_DAY = "i",
};

export const PATH_ROOTS = {
  CHECKS: "/checks",
  RULES: "/rules",
  DIRECTORIES: "/directories",
  INCIDENTS: "/incidents",
  SETTINGS: "/settings",
  PROFILE: "/profile",
  MEMBERS: "/members",
  INTEGRATIONS: "/integrations",
  AuthorizedError404: "/404",
  NEW_PASSWORD: "/sessions/new-password",
  FORGOT_PASSWORD: "/forgot_password",
  SIGN_UP: "/sign_up",
  LOGIN: "/login",
  LICENSE: "/license",
  SUBSCRIBE: "/subscribe",
  PROJECTS: "/projects",
  GROUPS: "/groups",
};

export const CURRENCY = {
  USD: "$",
};

export const LOCATIONS = {
  PROD: ".alertrules.com",
};

export enum SUBSCRIPTION_TYPES {
  FREE = "FREE",
  PAID = "PAID",
};

export const SUBSCRIPTION_INFO = {
  CURRENCY: CURRENCY.USD,
  CHECKS_AMOUNT_ARR: [10, 25, 50, 100, 200, 400, 500, 750, 1000],
};

export const SUBSCRIPTION_PERIOD = {
  MONTH: "month",
  YEAR: "year",
};

export enum ROLES {
  TENANT_OWNER = "TENANT_OWNER",
  ADMIN = "ADMIN",
  VIEWER = "VIEWER",
  PROJECT_ADMIN = "PROJECT_ADMIN",
  PROJECT_VIEWER = "PROJECT_VIEWER",
};

export enum ACTIONS {
  //global-viewer
  VIEW_CHECKS = "VIEW_CHECKS",
  VIEW_CHECKS_DETAILS = "VIEW_CHECKS_DETAILS",
  VIEW_CHECKS_INCIDENTS = "VIEW_CHECKS_INCIDENTS",
  TAKE_CHECKS_TO_WORK = "TAKE_CHECKS_TO_WORK",

  VIEW_PROJECTS = "VIEW_PROJECTS",
  VIEW_BREADCRUMBS = "VIEW_BREADCRUMBS",
  VIEW_DOCUMENT_TITLE = "VIEW_DOCUMENT_TITLE",
  VIEW_PROFILE = "VIEW_PROFILE",

  //project-viewer
  VIEW_PROJECT_CHECKS_DETAILS = "VIEW_PROJECT_CHECKS_DETAILS",
  VIEW_PROJECT_CHECKS_RULES = "VIEW_PROJECT_CHECKS_RULES",
  VIEW_PROJECT_CHECKS_LOG = "VIEW_CHECK_LOG",
  TAKE_PROJECT_CHECKS_TO_WORK = "TAKE_PROJECT_CHECKS_TO_WORK",

  //project-admin
  VIEW_PROJECT = "VIEW_PROJECT",
  CHANGE_CHECK_PROJECT = "CHANGE_CHECK_PROJECT",

  EDIT_PROJECT_CHECKS = "EDIT_PROJECT_CHECKS",
  ENABLE_DISABLE_PROJECT_CHECKS = "ENABLE_DISABLE_PROJECT_CHECKS",
  DUPLICATE_PROJECT_CHECKS = "DUPLICATE_PROJECT_CHECKS",
  DELETE_PROJECT_CHECKS = "DELETE_PROJECT_CHECKS",
  VIEW_PROJECT_CHECKS_ACTION_DROPDOWN = "VIEW_CHECKS_ACTION_DROPDOWN",
  VIEW_PROJECT_CHECKS_HISTORY = "VIEW_PROJECT_CHECKS_HISTORY",

  VIEW_CHECKS_RULES = "VIEW_CHECKS_RULES",
  CREATE_CHECKS_RULES = "CREATE_CHECKS_RULES",
  EDIT_CHECKS_RULES = "EDIT_CHECKS_RULES",
  DELETE_CHECKS_RULES = "DELETE_CHECKS_RULES",
  ENABLE_DISABLE_CHECKS_RULES = "ENABLE_DISABLE_CHECKS_RULES",

  //global-admin
  VIEW_INTEGRATIONS = "VIEW_INTEGRATIONS",
  VIEW_PROJECTS_DETAILS = "VIEW_PROJECTS_DETAILS",

  CREATE_CHECKS = "CREATE_CHECKS",
  EDIT_CHECKS = "EDIT_CHECKS",
  VIEW_CHECKS_HISTORY = "VIEW_CHECKS_HISTORY",

  VIEW_CREATE_CHECKS_RULES_PAGE = "VIEW_CREATE_CHECKS_RULES_PAGE",
  VIEW_EDIT_CHECKS_RULES_PAGE = "VIEW_EDIT_CHECKS_RULES_PAGE",

  VIEW_DEFAULT_RULES = "VIEW_DEFAULT_RULES",
  ENABLE_DISABLE_DEFAULT_RULES = "ENABLE_DISABLE_DEFAULT_RULES",

  VIEW_MEMBERS_NAV_ITEM = "VIEW_MEMBERS_NAV_ITEM",
  VIEW_MEMBERS_LIST = "VIEW_MEMBERS_LIST",
  VIEW_MEMBER_DETAILS = "VIEW_MEMBER_DETAILS",

  //tenant owner
  CREATE_PROJECTS = "CREATE_PROJECTS",
  EDIT_PROJECTS = "EDIT_PROJECTS",
  DELETE_PROJECTS = "DELETE_PROJECTS",
  VIEW_PROJECTS_ACTION_DROPDOWN = "VIEW_PROJECTS_ACTION_DROPDOWN",

  CREATE_DEFAULT_RULES = "CREATE_DEFAULT_RULES",
  EDIT_DEFAULT_RULES = "EDIT_DEFAULT_RULES",
  DELETE_DEFAULT_RULES = "DELETE_DEFAULT_RULES",

  CREATE_MEMBERS = "CREATE_MEMBERS",
  EDIT_MEMBERS = "EDIT_MEMBERS",
  DELETE_MEMBERS = "DELETE_MEMBERS",

  VIEW_MEMBERS_DIRECTORIES = "VIEW_MEMBERS_DIRECTORIES",
  CREATE_MEMBERS_DIRECTORIES = "CREATE_MEMBERS_DIRECTORIES",
  EDIT_MEMBERS_DIRECTORIES = "EDIT_MEMBERS_DIRECTORIES",
  DELETE_MEMBERS_DIRECTORIES = "DELETE_MEMBERS_DIRECTORIES",

  VIEW_MEMBERS_GROUPS = "VIEW_MEMBERS_GROUPS",
  CREATE_MEMBERS_GROUPS = "CREATE_MEMBERS_GROUPS",
  EDIT_MEMBERS_GROUPS = "EDIT_MEMBERS_GROUPS",
  DELETE_MEMBERS_GROUPS = "DELETE_MEMBERS_GROUPS",

  CREATE_INTEGRATIONS = "CREATE_INTEGRATIONS",
  EDIT_INTEGRATIONS = "EDIT_INTEGRATIONS",
  DELETE_INTEGRATIONS = "DELETE_INTEGRATIONS",

  VIEW_SETTINGS = "VIEW_SETTINGS",
  EDIT_SETTINGS = "EDIT_SETTINGS",

  VIEW_PLANS_AND_PRICING = "VIEW_PLANS_AND_PRICING",
  EDIT_PLANS_AND_PRICING = "EDIT_PLANS_AND_PRICING",

  VIEW_SUBSCRIBE_BUTTON = "VIEW_SUBSCRIBE_BUTTON",
  VIEW_CURRENT_BADGE_FOR_PAID_PLAN = "VIEW_CURRENT_BADGE_FOR_PAID_PLAN",
  VIEW_SUBSCRIPTION_INFO = "VIEW_SUBSCRIPTION_INFO",
};

export const ROLES_PROPERTY_NAMES = {
  members: { singular: "member", plural: "members" },
  groups: { singular: "group", plural: "groups" },
};

export const MANAGE_CHECK_ACTIONS = {
  RESUME: "resume",
  PAUSE: "pause",
  DISABLE: "disable",
  ENABLE: "enable",
};

export enum MODAL_WINDOW_TYPES {
  ALERT = "alert",
  CONFIRM = "confirm",
  FORM = "form",
};

export const IOS_BROWSERS = ["ios", "crios", "safari"];

export type TableElPerPage = 10 | 50 | 100;

export const TABLE_ITEMS_PER_PAGE_OPTIONS: TableElPerPage[] = [10, 50, 100];

export const REG_EXS = {
  EMAIL_CHARACTERS: /^[a-zA-Z0-9_.+\-@]*$/,
  DOMAIN_CHARACTERS: /^[A-Za-z0-9]*$/,
  DIGITS_CHARACTERS: /^[0-9]*$/,
  BALANCE_CHARACTERS: /^(\d)*(\.)*(\d)*$/,
  VALID_DOMAIN: /^[A-Za-z0-9]{2,63}$/,
  NEXMO_PRIVATE_KEY: /^-{5}BEGIN PRIVATE KEY-{5}\n([\w\n\-()/+]*)\n-{5}END PRIVATE KEY-{5}$/,
  VALID_HOST: /^(ldap[si]?:\/\/)/,
};

export const ERR_MESSAGES = {
  CANCELLED_REQUEST: "Request was cancelled by user",
};

export const TIME_ZONES = {
  DEFAULT: "Europe/Moscow",
};

export const TG_BOT_LANGUAGES = {
  EN: "en",
  RU: "ru",
};
