import React from "react";
import {Trans} from "react-i18next";

const AdditionalChecksText = () => {
  return (
    <p className={"AdditionalChecksText"}>
      <small>
        <Trans i18nKey="plans:additionalChecks">
          Haven't found suitable quantity of checks? <a href={"mailto: support@alertrules.com"}>Contact us</a>
        </Trans>
      </small>
    </p>
  );
};

export default AdditionalChecksText;