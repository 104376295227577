import * as yup from "yup";
import { MESSAGES } from "./messages";
import { phone, timeOnly } from "../validators";

/** yup custom validators */
yup.addMethod(yup.string, "phone", phone);
yup.addMethod(yup.string, "timeOnly", timeOnly);

const ruleFormSchema = yup.object().shape(
  {
    from_time: yup
      .string()
      .nullable()
      .timeOnly(MESSAGES.TIME_INVALID_VALUE)
      .when("to_time", {
        is: (value: string) => !!value,
        then: yup
          .string()
          .nullable()
          .required(MESSAGES.REQUIRED)
          .notOneOf([yup.ref("to_time")], MESSAGES.NOT_MATCH_TIMES),
      }),
    to_time: yup
      .string()
      .nullable()
      .timeOnly(MESSAGES.TIME_INVALID_VALUE)
      .when("from_time", {
        is: (value: string) => !!value,
        then: yup
          .string()
          .nullable()
          .required(MESSAGES.REQUIRED)
          .notOneOf([yup.ref("from_time")], MESSAGES.NOT_MATCH_TIMES),
      }),
    day_type: yup.string(),
    is_inverted_condition: yup.boolean(),
    delay: yup.string(),
    recur: yup.string(),
    phones: yup.array().of(yup.string().phone(MESSAGES.PHONE_INVALID_VALUE)),
    telegram_chats: yup.array().of(yup.object()),
    members_to_call: yup.array().of(yup.object()),
  },
  [["to_time", "from_time"]]
);

const getPhoneFormSchema = (name: string = "phone_input") =>
  yup.object().shape({
    [name]: yup.string().phone(MESSAGES.PHONE_INVALID_VALUE).required(),
  });

export { ruleFormSchema, getPhoneFormSchema };
