import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import { Trans, useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ACTIONS, hasGlobalPermission, hasProjectPermission } from "utils/user-access";
import { ROLES } from "utils/constants";

interface INoRulesTextProps {
  projectRole?: ROLES;
  projectId?: number | string;
  showRuleSwitcher?: boolean;
  updateStates?: () => void;
  updateRule?: () => void;
}

const NoRulesText = ({ projectRole, projectId }: INoRulesTextProps) => {
  const { t } = useTranslation();

  const exactPath = PATHS.PROJECTS.getDetailsIdPath && PATHS.PROJECTS.getDetailsIdPath(projectId);

  return (
    <Trans
      i18nKey="noData.checkRules"
      values={{ link: t("check.defaultRules"), projectLink: t("check.projectDefaultRules") }}
    >
      There are no specific rules for the check, so
      {hasGlobalPermission(ACTIONS.VIEW_DEFAULT_RULES) ? (
        <Link to={PATHS.RULES.ROOT}>default rules</Link>
      ) : (
        <>default rules</>
      )}
      {hasProjectPermission(ACTIONS.VIEW_PROJECT, projectRole) ? (
        <Link to={exactPath || PATHS.AuthorizedError404.ROOT}>project default rules</Link>
      ) : (
        <>project default rules</>
      )}
      will be used
    </Trans>
  );
};

export default withErrorBoundary<INoRulesTextProps>(NoRulesText);
