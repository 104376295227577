import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "../ErrorBoundary";
import { ButtonVariant, Placement } from "models/types";
import { getTranslatedTimeTextFromMinutes } from "utils/timeHandlers";
import { ARDropdown, ARDropdownItem, ARDropdownMenu, ARDropdownToggle } from "../ARComponents";
import { checkId, incidentId } from "pages/checks/_types";

type TakeToWorkDropdownProps = {
  className?: string;
  color?: string;
  variant?: ButtonVariant;
  size?: string;
  placement?: Placement;
  incidentId?: incidentId;
  checkId: checkId;
  pauses?: [number, number | string][];
  disabled?: boolean;
  onTakeToWorkButtonClick: (id: checkId, time: number, incidentId?: incidentId) => void;
  modifiers?: object[];
};

const TakeToWorkDropdown = (props: TakeToWorkDropdownProps) => {
  const { t } = useTranslation();

  const {
    className = "btn-block mt-1",
    color = "info",
    variant = "outline",
    size = "sm",
    placement = "left",
    incidentId,
    checkId,
    pauses,
    disabled,
    onTakeToWorkButtonClick,
    modifiers,
  } = props;

  return (
    <ARDropdown className="dropdown_no-caret">
      <ARDropdownToggle className={className} color={color} variant={variant} size={size} disabled={disabled}>
        {t("button.pause", "Take to work")}
      </ARDropdownToggle>
      <ARDropdownMenu placement={placement} modifiers={modifiers}>
        {pauses?.map((pause) => (
          <ARDropdownItem key={pause[0]} onClick={() => onTakeToWorkButtonClick(checkId, pause[0], incidentId)}>
            {getTranslatedTimeTextFromMinutes(pause[0])}
          </ARDropdownItem>
        ))}
      </ARDropdownMenu>
    </ARDropdown>
  );
};

export default withErrorBoundary<TakeToWorkDropdownProps>(TakeToWorkDropdown);
