import { useMemo } from "react";
import { useSelector } from "react-redux";
import RuleSwitcher from "../RuleSwitcher";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "../../ErrorBoundary";
import { globalSelector } from "store/selectors/global";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { ARButton, ARButtonGroup, ARIcon } from "components/ARComponents";
import {
  compareElements,
  convertTimeToWordTime,
  getDayType,
  getStringFromArray,
  restoreArray,
  ElementName,
} from "./RulesInfo.model";
import { ICheckRules } from "pages/checks/_types";
import { getArrayOfId } from "utils";

interface IRuleInfoProps {
  rule: ICheckRules;
  showEditButton?: boolean;
  getEditRulePath?: (id: number | string) => string;
  showDeleteButton?: boolean;
  showRuleSwitcher?: boolean;
  updateStates: () => void;
  updateRule?: (ruleData: Partial<ICheckRules>, id: number, showSpinner?: boolean) => Promise<unknown>;
  deleteRule?: (id: number) => Promise<unknown>;
}

const RuleInfo = (props: IRuleInfoProps) => {
  const {
    rule,
    showEditButton,
    getEditRulePath,
    showDeleteButton,
    showRuleSwitcher,
    updateStates,
    updateRule,
    deleteRule,
  } = props;
  const { members_to_call: members, telegram_chats: telegramChats } = rule;

  const { t } = useTranslation();
  const history = useHistory();
  const { isFetching } = useSelector(globalSelector);
  const showActionButtons = showEditButton || showDeleteButton || showRuleSwitcher;

  const correctedRule = useMemo(
    () => ({
      ...rule,
      members_to_call: restoreArray(compareElements, rule.members_to_call, members),
      telegram_chats: restoreArray(compareElements, rule.telegram_chats, telegramChats),
    }),
    [members, rule, telegramChats]
  );

  const deleteRuleHandler = () => {
    showModalWindow.confirm({
      message: t("modal:message.delete.rule", "Are you sure you want to remove this rule?"),
      color: "danger",
      onConfirm: () =>
        deleteRule?.(rule.id!).then(() => {
          showNotification(
            t("notification:title.success", "Success!"),
            t("notification:message.deleted.rule", "Rule has been deleted."),
            "success"
          );
          updateStates && updateStates();
        }),
    });
  };

  const onEditButtonClick = () => {
    history.push(getEditRulePath!(rule.id!));
  };

  const switchRuleHandler = () => {
    const newRuleData = {
      ...rule,
      is_enabled: !rule.is_enabled,
      // TODO уточнить по какой причине делался сброс
      // members_to_call: [],
      // telegram_chats: [],
      members_to_call: getArrayOfId(rule.members_to_call) as [],
      telegram_chats: getArrayOfId(rule.telegram_chats) as [],
    };

    return updateRule?.(newRuleData, rule.id!, false).then(() => updateStates && updateStates());
  };

  return (
    <div className="RulesInfo__content">
      <p className="RulesInfo__content-text">
        {!!correctedRule.from_time || !!correctedRule.to_time ? (
          <>
            {t("rule.time.fromTill", `From ${correctedRule.from_time} till ${correctedRule.to_time}`, {
              fromTime: correctedRule.from_time,
              toTime: correctedRule.to_time,
            })}{" "}
            {getDayType(correctedRule.day_type)} {correctedRule.is_inverted_condition && t("rule.time.inverted")}
          </>
        ) : (
          <>
            {t("rule.time.anyTime")} {getDayType(correctedRule.day_type)}{" "}
            {correctedRule.is_inverted_condition && t("rule.time.inverted")}
          </>
        )}
        <br />
        {convertTimeToWordTime("after", correctedRule.delay)} {convertTimeToWordTime("every", correctedRule.recur)}
        <br />
        {!!correctedRule.telegram_chats.length && (
          <>
            {t("rule.chats", "Chats")}: {getStringFromArray(correctedRule.telegram_chats, ElementName.chats)} <br />
          </>
        )}
        {!!correctedRule.phones.length && (
          <>
            {t("rule.phones", "Phones")}: {getStringFromArray(correctedRule.phones, ElementName.phones)} <br />
          </>
        )}
        {!!correctedRule.members_to_call.length && (
          <>
            {t("rule.membersToCall", "Members to call")}:{" "}
            {getStringFromArray(correctedRule.members_to_call, ElementName.members)}
          </>
        )}
      </p>
      {showActionButtons && (
        <div className="RulesInfo__action-buttons-wrapper">
          {showRuleSwitcher && (
            <RuleSwitcher
              disabled={isFetching}
              checked={rule.is_enabled}
              switcherHandler={switchRuleHandler}
              className="RulesInfo__rule-switcher"
            />
          )}
          {(showEditButton || showDeleteButton) && (
            <ARButtonGroup className="RulesInfo__action-buttons">
              {showEditButton && (
                <ARButton
                  className="RulesInfo__edit-button"
                  color="info"
                  variant="outline"
                  onClick={onEditButtonClick}
                  disabled={isFetching}
                >
                  {t("button.edit")}
                </ARButton>
              )}
              {showDeleteButton && (
                <ARButton
                  className="RulesInfo__delete-button"
                  color="danger"
                  variant="outline"
                  onClick={deleteRuleHandler}
                  disabled={isFetching}
                >
                  <ARIcon name="cilTrash" />
                </ARButton>
              )}
            </ARButtonGroup>
          )}
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(RuleInfo);
