import { MODAL_WINDOW } from "./types";
import { IShowAlertModalProps, IShowConfirmModalProps, IShowFormModalProps } from "../../utils/actionWrappers";
import { ActionProps } from "../types";

export function showModalWindowAction(
  modalWindowPayload: IShowAlertModalProps | IShowConfirmModalProps | IShowFormModalProps
) {
  return {
    type: MODAL_WINDOW.SHOW,
    payload: modalWindowPayload,
  };
}

export function hideModalWindowAction(): ActionProps {
  return {
    type: MODAL_WINDOW.HIDE,
  };
}
