import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ns } from "services/language/i18next";
import Spinner from "components/Spinner";
import Notification from "components/Notification";
import { globalSelector } from "store/selectors/global";
import { isAuthorizedSelector } from "store/selectors/auth";
import { withErrorBoundary } from "components/ErrorBoundary";
import ModalWindow from "components/ModalWindow/ModalWindow";
import AuthorizedPageWrapper from "components/PageWrappers/AuthorizedPageWrapper";
import UnAuthorizedPageWrapper from "components/PageWrappers/UnAuthorizedPageWrapper";
import { useActions } from "./store/actions";
import {setTenantAction} from "./store/actions/global";

function App() {
  const { requestsWithSpinnerNum, tenant, isShowingSpinner } = useSelector(globalSelector);
  const { ready } = useTranslation(ns, { useSuspense: false });
  const isAuthorized = useSelector(isAuthorizedSelector);
  const [setTenant] = useActions(setTenantAction);

  useEffect(() => {
    setTenant();
  }, [setTenant]);

  const showContent = tenant.isExist !== null && ready;

  return (
    <>
      <Spinner
        timeout={50}
        fullScreen={true}
        show={!showContent || isShowingSpinner}
        requestsNum={requestsWithSpinnerNum}
      />
      {showContent && (
        <>
          <ModalWindow />
          <Notification timeout={10} />
          {isAuthorized ? <AuthorizedPageWrapper /> : <UnAuthorizedPageWrapper />}
        </>
      )}
    </>
  );
}

export default withErrorBoundary(App);
