import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ARButton, ARRow } from "../ARComponents";
import ProjectSelector from "../ProjectSelector";
import { withErrorBoundary } from "../ErrorBoundary";
import { ClassName, Disabled, Path, Permission, ProjectOptions } from "models/types";

type AddButtonRowProps = {
  path?: Path;
  disabled?: Disabled;
  className?: ClassName;
  selectOptions?: ProjectOptions;
  hasAddPermission?: Permission;
  onClick?: () => void;
  onSelectChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

const AddButtonRow = (props: AddButtonRowProps) => {
  const {
    path,
    onClick,
    disabled,
    selectOptions,
    onSelectChange,
    hasAddPermission = true,
    className = "add-button-row",
  } = props;
  const { t } = useTranslation();
  const showProjectSelector = !!selectOptions && !!onSelectChange;

  return showProjectSelector || hasAddPermission ? (
    <ARRow className={classNames("AddButtonRow", { "AddButtonRow_space-between": !!selectOptions }, className)}>
      {showProjectSelector && <ProjectSelector options={selectOptions} onChange={onSelectChange} disabled={disabled} />}
      {hasAddPermission && (
        <ARButton
          tag={path && Link}
          to={path}
          color="dark"
          variant="outline"
          className="AddButtonRow__add-button"
          onClick={onClick}
          disabled={disabled}
        >
          {t("button.add", "Add")}
        </ARButton>
      )}
    </ARRow>
  ) : null;
};

export default withErrorBoundary<AddButtonRowProps>(AddButtonRow);
