import classNames from "classnames";
import { useChecksTable } from "./hooks";
import { useSelector } from "react-redux";
import { PATHS } from "route-dictionary";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import AddButtonRow from "components/AddButtonRow";
import { projectsSelector } from "store/selectors/user";
import { globalSelector } from "store/selectors/global";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ACTIONS, getProjectRole, hasGlobalPermission, hasProjectPermission } from "utils/user-access";
import { getAllProjectsSelectItem, getFullNameFromType, getItemsPerPageSelectValues, getTableNoItemsView } from "utils";
import {
  checkTypes,
  getCheckType,
  getDisplayingEnableStatus,
  getTableColumnHeaders,
  getTableFilterText,
} from "./Checks.model";
import { MouseEvent } from "react";
import {
  ARCard,
  ARCardBody,
  ARCardHeader,
  ARDataTable,
  ARDropdown,
  ARDropdownItem,
  ARDropdownMenu,
  ARDropdownToggle,
  ARIcon,
  ARPagination,
  ARStatusText,
} from "components/ARComponents";
import { IARStatusTextProps } from "components/ARComponents/_types";
import { ICheckData } from "./hooks/useChecksData";

export interface Iitem extends IARStatusTextProps {
  name: string;
  type: string;
  id: string | number;
  url: string;
  is_user_project_admin: boolean;
  project: {
    name: string;
  };
}

const Checks = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isFetching } = useSelector(globalSelector);
  const {
    tableConfig,
    hasActionButton,
    enableDisableCheck,
    onDeleteButtonClick,
    getDisplayingActionButton,
    onSelectedProjectChange,
  } = useChecksTable();

  const {
    page,
    pages,
    tableItems,
    onPageChange,
    itemsPerPage,
    sorterValue,
    setSorterValue,
    setItemsPerPage,
    tableFilterValue,
    onTableFilterValueChange,
  } = tableConfig;

  const projects = useSelector(projectsSelector);
  const selectProjects = [getAllProjectsSelectItem(), ...projects];

  const getClickAction = ({ ctrlKey, metaKey }: MouseEvent, { id, is_user_project_admin }: Iitem) => {
    const openInNewWindow = ctrlKey || metaKey;
    const hasPermission = hasProjectPermission(
      ACTIONS.VIEW_PROJECT_CHECKS_DETAILS,
      getProjectRole(is_user_project_admin)
    );
    if (openInNewWindow) {
      hasPermission && window.open(PATHS.CHECKS.getDetailsIdPath?.(id));
    } else {
      hasPermission && history.push(PATHS.CHECKS.getDetailsIdPath!(id));
    }
  };
  const hasViewChecksPermission = hasGlobalPermission(ACTIONS.VIEW_CHECKS);

  return (
    <ARCard className="Checks-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.checks", "Checks")}</h1>
      </ARCardHeader>
      <ARCardBody>
        <ARDataTable
          size="sm"
          addTableClasses="Checks-card__checks-table checks-table"
          overTableSlot={
            <AddButtonRow
              disabled={isFetching}
              path={PATHS.CHECKS.ADD}
              selectOptions={selectProjects}
              onSelectChange={onSelectedProjectChange}
              hasAddPermission={hasGlobalPermission(ACTIONS.CREATE_CHECKS)}
            />
          }
          items={tableItems}
          fields={getTableColumnHeaders(hasActionButton, tableItems)}
          noItemsView={getTableNoItemsView()}
          loading={isFetching}
          hover
          cleaner
          striped
          border
          clickableRows={hasGlobalPermission(ACTIONS.VIEW_CHECKS_DETAILS) || undefined}
          sorter
          sorterValue={sorterValue}
          onSorterValueChange={setSorterValue}
          tableFilter={getTableFilterText()}
          tableFilterValue={tableFilterValue}
          onTableFilterChange={onTableFilterValueChange}
          itemsPerPageSelect={getItemsPerPageSelectValues()}
          itemsPerPage={itemsPerPage}
          onPaginationChange={setItemsPerPage}
          scopedSlots={{
            status: hasViewChecksPermission
              ? (item: Iitem) => {
                  return (
                    <td
                      className="checks-table__cell checks-table__cell_text-center"
                      onClick={(event) => getClickAction(event, item)}
                    >
                      <ARStatusText {...item} />
                    </td>
                  );
                }
              : null,
            name: hasViewChecksPermission
              ? (item: Iitem) => {
                  return (
                    <td className="checks-table__cell" onClick={(event) => getClickAction(event, item)}>
                      {item.name || ""}
                    </td>
                  );
                }
              : null,
            type: hasViewChecksPermission
              ? (item: Iitem) => (
                  <td className="checks-table__cell" onClick={(event) => getClickAction(event, item)}>
                    {getFullNameFromType(item.type, checkTypes) || ""}
                  </td>
                )
              : null,
            project: hasViewChecksPermission
              ? (item: Iitem) => (
                  <td className="checks-table__cell" onClick={(event) => getClickAction(event, item)}>
                    {item.project.name || ""}
                  </td>
                )
              : null,
            state_effective_since: hasViewChecksPermission
              ? (item: Iitem) => (
                  <td className="checks-table__cell" onClick={(event) => getClickAction(event, item)}>
                    {item["state_effective_since"] || ""}
                  </td>
                )
              : null,
            actions_column: hasViewChecksPermission
              ? (item: ICheckData) => {
                  return (
                    <td className="checks-table__cell">
                      {getCheckType(item).isUsed &&
                        hasProjectPermission(
                          ACTIONS.VIEW_PROJECT_CHECKS_ACTION_DROPDOWN,
                          getProjectRole(item.is_user_project_admin)
                        ) && (
                          <ARDropdown className="dropdown_no-caret">
                            <ARDropdownToggle className="btn-block" size="sm" color="dark" variant="outline">
                              <ARIcon name="cilMinus" size="sm" />
                            </ARDropdownToggle>
                            <ARDropdownMenu placement="left">
                              {hasProjectPermission(
                                ACTIONS.EDIT_PROJECT_CHECKS,
                                getProjectRole(item.is_user_project_admin)
                              ) && (
                                <ARDropdownItem tag={Link} to={PATHS.CHECKS.getEditIdPath!(item.id)}>
                                  {t("button.edit", "Edit")}
                                </ARDropdownItem>
                              )}
                              {hasProjectPermission(
                                ACTIONS.ENABLE_DISABLE_PROJECT_CHECKS,
                                getProjectRole(item.is_user_project_admin)
                              ) && (
                                <ARDropdownItem onClick={() => enableDisableCheck(item.id, item.is_enabled)}>
                                  {getDisplayingEnableStatus(item)}
                                </ARDropdownItem>
                              )}
                              {hasProjectPermission(
                                ACTIONS.DUPLICATE_PROJECT_CHECKS,
                                getProjectRole(item.is_user_project_admin)
                              ) && (
                                <ARDropdownItem
                                  tag={Link}
                                  to={PATHS.CHECKS.getRouteWithQueryParam("clonedId", item.id, PATHS.CHECKS.ADD)}
                                >
                                  {t("button.duplicate", "Duplicate")}
                                </ARDropdownItem>
                              )}
                              {hasProjectPermission(
                                ACTIONS.DELETE_PROJECT_CHECKS,
                                getProjectRole(item.is_user_project_admin)
                              ) && (
                                <ARDropdownItem onClick={() => onDeleteButtonClick(item.id)}>
                                  {t("button.delete", "Delete")}
                                </ARDropdownItem>
                              )}
                            </ARDropdownMenu>
                          </ARDropdown>
                        )}
                      {hasProjectPermission(
                        ACTIONS.TAKE_PROJECT_CHECKS_TO_WORK,
                        getProjectRole(item.is_user_project_admin)
                      ) && getDisplayingActionButton(item)}
                    </td>
                  );
                }
              : null,
            url: hasViewChecksPermission
              ? (item: Iitem) => <td className="checks-table__cell checks-table__cell_hidden">{item["url"] || ""}</td>
              : null,
          }}
        />
        <ARPagination
          pages={pages}
          activePage={page}
          onActivePageChange={onPageChange}
          className={classNames("table-pagination", { "table-pagination_hidden": pages < 2 || isFetching })}
        />
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(Checks);
