import { AUTH } from "./types";
import { authRequest, logoutRequest } from "services/api";
import { Dispatch } from "redux";

export function loginAction(email: string, password: string, isRememberMe: boolean) {
  return async (dispatch: Dispatch) => {
    try {
      await authRequest(email, password, isRememberMe);
      dispatch({
        type: AUTH.LOGIN_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: AUTH.LOGIN_FAILURE,
        payload: {
          error: e,
        },
      });
      throw e;
    }
  };
}

export function logoutAction(withoutRequest = false) {
  return async (dispatch: Dispatch) => {
    try {
      if (!withoutRequest) {
        await logoutRequest();
      }
    } finally {
      dispatch({
        type: AUTH.LOGOUT_SUCCESS,
        // payload: {
        //   error: null,
        // },
      });
    }
  };
}
