import { GLOBAL, USER } from "../actions/types";
import { IProject } from "./../../models/types";
import { ROLES } from "utils/constants";

interface IUsersGlobalState {
  info: {
    email: string;
    firstName: string;
    lastName: string;
    avatar: string;
    id: null | string;
  };
  telegram: {
    id: null | string;
    userName: null | string;
  };
  globalRole: null | ROLES;
  projects: IProject[];
}

const initialState: IUsersGlobalState = {
  info: {
    email: "",
    firstName: "",
    lastName: "",
    avatar: "/avatars/1.png",
    id: null,
  },
  telegram: {
    id: null,
    userName: null,
  },
  globalRole: null,
  projects: [],
};

interface IUserAction {
  type?: USER | GLOBAL;
  payload?: {
    email: string;
    firstName: string;
    lastName: string;
    avatar: string;
    id: null | string;
    userName: null | string;
    globalRole: null | ROLES;
    projects: IProject[];
  };
}

const userReducer = (state = initialState, action: IUserAction): IUsersGlobalState  => {
  switch (action.type) {
    case USER.SET_USER_INFO:
      return { 
        ...state,
        info: {
          ...state.info,
          email: action.payload!.email,
          firstName: action.payload!.firstName,
          lastName: action.payload!.lastName,
          avatar: action.payload!.avatar,
          id: action.payload!.id,
        },
      };
    case USER.SET_PROJECTS:
      return {
        ...state,
        projects: action.payload!.projects,
      };
    case USER.SET_GLOBAL_ROLE: {
      return {
        ...state,
        globalRole: action.payload!.globalRole,
      };
    }
    case USER.SET_TELEGRAM_INFO:
      return {
        ...state,
        telegram: {
          id: action.payload!.id,
          userName: action.payload!.userName,
        },
      };
    case USER.RESET_USER_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userReducer;
