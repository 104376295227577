import i18next from "services/language/i18next";

const getTableColumnHeaders = () => ([
  {key: "firstName", label: i18next.t("table:header.firstName", "First Name"), _style: {width: "35%"}},
  {key: "lastName", label: i18next.t("table:header.lastName", "Last Name"), _style: {width: "20%"}},
  {key: "phone", label: i18next.t("table:header.phone", "Phone"), _style: {width: "20%"}},
  {key: "email", label: i18next.t("table:header.email", "Email"), _style: {width: "20%"}},
  {key: "actionsColumn", label: "", _style: {width: "5%"}}
]);

export {
  getTableColumnHeaders
};
