import { ACTIONS, hasGlobalPermission } from "utils/user-access";
import { MemberKeys } from "../Members.model";

export const getPlaceholder = (placeholder: string) => {
  return hasGlobalPermission(ACTIONS.EDIT_MEMBERS) ? placeholder : "";
};

export const getInputDisable = (isExternalMember: boolean) => {
  return !hasGlobalPermission(ACTIONS.EDIT_MEMBERS) || isExternalMember;
};

export const memberDataFields = [
  MemberKeys.AVATAR,
  MemberKeys.FIRST_NAME,
  MemberKeys.LAST_NAME,
  MemberKeys.EMAIL,
  MemberKeys.PHONE,
  // TODO: password strings to enum
  "password",
  "confirm_password",
];
