import i18next from "services/language/i18next";
import {PlansContext} from "../subscribe-context";
import {useContext, useEffect, useRef, useState} from "react";
import {showModalWindow, showNotification} from "utils/actionWrappers";
import {getCardDataFromCompanyBilling} from "./models/useCreditCard.model";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {confirmCardSetup, createStripeSetupCardIntent, patchCompanyBilling} from "services/api";

const useCardInfo = (clearCardSubscriber) => {
  const {companyBilling, updateCompanyBilling} = useContext(PlansContext);
  const stripe = useStripe();
  const elements = useElements();
  const isCardChangedRef = useRef(false);
  const isFocused = useRef(false);
  const [cardInfo, setCardInfo] = useState(getCardDataFromCompanyBilling(companyBilling));

  const onCancelClick = () => {
    if (!elements) {
      return;
    }

    if (isCardChangedRef.current) {
      showModalWindow.confirm({
        message: i18next.t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: clearCardInfo
      });
    } else {
      clearCardInfo();
    }
  };

  const clearCardInfo = () => {
    const cardElement = elements.getElement(CardElement);
    cardElement.clear();
    clearCardSubscriber();
    isCardChangedRef.current = false;
  };

  const onBlur = () => {
    isFocused.current = false;
  };
  const onFocus = () => {
    isFocused.current = true;
  };
  const onChange = () => {
    if (isFocused.current) {
      isCardChangedRef.current = true;
    }
  };

  const cardChangingHandler = () => {
    const cardElement = elements.getElement(CardElement);

    createStripeSetupCardIntent()
      .then(async ({data}) => {
        const cardData = {
          payment_method: {
            card: cardElement
          }
        };
        const {setupIntent, error} = await confirmCardSetup(stripe, data, cardData);

        if (error) {
          showNotification(
            i18next.t("notification:message.error.cardValidation", "Card validation error"),
            error.message,
            "danger"
          );
          return;
        }

        const {payment_method: payment_method_id} = setupIntent;

        patchCompanyBilling({payment_method_id}, cardInfo.id)
          .then(() => {
            updateCompanyBilling();
            clearCardInfo();
            showNotification(
              i18next.t("notification:title.success", "Success!"),
              i18next.t("notification:message.updated.cardSettings", "Card settings have been updated."),
              "success"
            );
          });
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (isCardChangedRef.current) {
      showModalWindow.confirm({
        message: i18next.t("modal:message.paymentCardChange", "Are you sure you want to change your payment card?"),
        color: "warning",
        onConfirm: () => cardChangingHandler(),
        onCancel: () => clearCardInfo()
      });
    } else {
      clearCardInfo();
    }
  };

  useEffect(() => setCardInfo(getCardDataFromCompanyBilling(companyBilling)), [companyBilling]);

  return {
    onFormSubmit,
    cardInfo,
    onCancelClick,
    eventHandlers: {onBlur, onFocus, onChange}
  };
};

export default useCardInfo;