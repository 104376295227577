import { useDefaultRulesData } from "./hooks";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import RulesCard from "components/RulesComponents/RulesCard";
import { PATHS } from "route-dictionary";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";
import { deleteRuleRequest, updateRuleRequest } from "services/api";
import { ARCard, ARCardBody, ARCardHeader, ARIcon, ARTooltip } from "components/ARComponents";

const Rules = () => {
  const { t } = useTranslation();
  const { rules, updateDefaultRulesData } = useDefaultRulesData();

  return (
    <ARCard className="Rules">
      <ARCardHeader>
        <h1 className="card-header-text">
          {t("card.title.defaultRules", "Default rules")}
          <ARTooltip
            content={t("tooltip.defaultRules", "Default rules applied when no rules for the check")}
            placement="right"
          >
            <ARIcon className="Rules__tooltip-icon" name="cilInfo" size="lg" color="var(--secondary)" />
          </ARTooltip>
        </h1>
      </ARCardHeader>
      <ARCardBody>
        <RulesCard
          rules={rules}
          noRulesText={t("noData.defaultRules")}
          updateStates={updateDefaultRulesData}
          updateRule={updateRuleRequest}
          deleteRule={deleteRuleRequest}
          showRuleAddButton={hasGlobalPermission(ACTIONS.CREATE_DEFAULT_RULES)}
          showRuleSwitcher={hasGlobalPermission(ACTIONS.ENABLE_DISABLE_DEFAULT_RULES)}
          showEditButton={hasGlobalPermission(ACTIONS.EDIT_DEFAULT_RULES)}
          showDeleteButton={hasGlobalPermission(ACTIONS.DELETE_DEFAULT_RULES)}
          addRulePath={PATHS.RULES.ADD}
          // TODO: remove !
          getEditRulePath={(ruleId) => PATHS.RULES.getDetailsIdPath!(ruleId)}
        />
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(Rules);
