import { useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ERR_MESSAGES } from "utils/constants";
import { useCheckHistoryData } from "../hooks";
import { getTableNoItemsView, validateUrl } from "utils";
import { withErrorBoundary } from "components/ErrorBoundary";
import { CancelToken, groupCancelSource } from "services/api";
import AuthorizedError404 from "pages/error/AuthorizedError404";
import { ARCard, ARCardBody, ARCardHeader, ARDataTable } from "components/ARComponents";
import {
  convertValues,
  getChangesText,
  getMemberLink,
  getTableColumnHeaders,
  getTranslatedField,
  getTranslatedValues,
} from "./CheckHistory.model";
import { ICheckHistoryChange } from "../_types";

const CheckHistory = () => {
  const { t } = useTranslation();
  const { checkHistory, checkCreator, checkCreationDate, isProjectAdmin, checkId } = useCheckHistoryData();

  const hasCheckCreator = !!checkCreator?.email;
  const checkCreatorText = hasCheckCreator ? getMemberLink(checkCreator) : t("noData.user", "Unknown user");
  const checkCreationText = t("card.description.checkCreated", "created the check");

  useEffect(() => {
    groupCancelSource.value = CancelToken.source();
    return () => {
      groupCancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
      groupCancelSource.value = null;
    };
  }, []);

  if (!isProjectAdmin) {
    return <AuthorizedError404 />;
  }

  return (
    <ARCard className="CheckHistory-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.check.history", "Editing history")}</h1>
      </ARCardHeader>
      <ARCardBody>
        {checkHistory?.map((checkChangeData) => {
          const { changes, date } = checkChangeData;
          const { memberLink, tableHeaderText, ruleCreatorText, ruleCreationText } = getChangesText(
            checkChangeData,
            checkId
          );

          if (ruleCreationText) {
            return (
              <div key={`change_${date}`} className="CheckHistory-card__rule-creation-row rule-creation-row">
                <h5 className="rule-creation-row__text">
                  {ruleCreatorText} {ruleCreationText} - {date}
                </h5>
              </div>
            );
          }

          if (!changes.length) {
            return null;
          }
          const convertedItems = convertValues(changes);
          return (
            <div className="CheckHistory-card__check-history-table-wrapper" key={`change_${date}`}>
              <ARDataTable
                hover
                border
                striped
                size="sm"
                addTableClasses="CheckHistory-card__history-table history-table"
                overTableSlot={
                  <h5>
                    {memberLink} {tableHeaderText} - {date}
                  </h5>
                }
                items={convertedItems}
                fields={getTableColumnHeaders()}
                noItemsView={getTableNoItemsView()}
                scopedSlots={{
                  field: (item: ICheckHistoryChange) => (
                    <td className={classNames("history-table__cell")}>{getTranslatedField(item.field)}</td>
                  ),
                  old: (item: ICheckHistoryChange) => (
                    <td
                      className={classNames("history-table__cell", {
                        "history-table__cell_word-breakable": validateUrl(item.old),
                      })}
                    >
                      {getTranslatedValues(item.field, item.old) || <span>&mdash;</span>}
                    </td>
                  ),
                  new: (item: ICheckHistoryChange) => (
                    <td
                      className={classNames("history-table__cell", {
                        "history-table__cell_word-breakable": validateUrl(item.new),
                      })}
                    >
                      {getTranslatedValues(item.field, item.new) || <span>&mdash;</span>}
                    </td>
                  ),
                }}
              />
            </div>
          );
        })}
        <div className="CheckHistory-card__check-creation-row check-creation-row">
          <h5 className="check-creation-row__text">
            {checkCreatorText} {checkCreationText} - {checkCreationDate}
          </h5>
        </div>
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(CheckHistory);
