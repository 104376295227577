import i18next from "services/language/i18next";
import {ACTIONS, getProjectRole, hasGlobalPermission, hasProjectPermission} from "utils/user-access";
import { ICheckData } from "./hooks/useChecksData";
import { ICheckHistory } from "./_types";

function toUpperFirstChar(str: string) {
  return str && str[0].toUpperCase() + str.slice(1);
}

function getTableColumnHeaders(hasActionButton: boolean, checksData: ICheckHistory[]) {
  const projectRole = getProjectRole(!!checksData.find(check => !!check.is_user_project_admin));
  const hasViewChecksPermission = hasGlobalPermission(ACTIONS.VIEW_CHECKS);
  const tableColumnHeaders = [
    {
      key: "status",
      label: i18next.t("table:header.state", "State"),
      _style: {width: "7%"},
      isShown: hasViewChecksPermission
    },
    {
      key: "name",
      label: i18next.t("table:header.check", "Check"),
      isShown: hasViewChecksPermission
    },
    {
      key: "type",
      label: i18next.t("table:header.type", "Type"),
      _style: {width: "15%"},
      isShown: hasViewChecksPermission
    },
    {
      key: "project",
      label: i18next.t("table:header.project", "Project"),
      _style: {width: "15%"},
      isShown: hasViewChecksPermission
    },
    {
      key: "state_effective_since",
      label: i18next.t("table:header.since", "Since"),
      _style: {width: "15%"},
      isShown: hasViewChecksPermission
    },
    {
      key: "actions_column",
      label: "",
      _style: {width: "3%"},
      isShown: hasProjectPermission(ACTIONS.VIEW_PROJECT_CHECKS_ACTION_DROPDOWN, projectRole) || hasActionButton
    },
    {
      key: "url",
      label: "",
      _style: {display: "none"},
      isShown: hasViewChecksPermission
    }
  ];

  return tableColumnHeaders.filter(item => item.isShown);
}

function getTableFilterText() {
  return {
    label: i18next.t("table:filter.label", "Search:"),
    placeholder: i18next.t("table:filter.placeholder.checks", "Search for checks")
  };
}

const checkTypes = [
  {
    name: "External check",
    value: "ext",
    isUsed: false
  },
  {
    name: "HTTP",
    value: "http",
    isUsed: true
  },
  {
    name: "Header",
    value: "header",
    isUsed: true
  },
  {
    name: "Ping",
    value: "ping",
    isUsed: true
  },
  {
    name: "HTTPs certificate",
    value: "httpscert",
    isUsed: true
  },
  {
    name: "Domain registration",
    value: "domain",
    isUsed: true
  },
  {
    name: "Jira",
    value: "jira",
    isUsed: true
  },
  {
    name: "Balance",
    value: "balance",
    isUsed: true
  },
  {
    name: "Port",
    value: "port",
    isUsed: true
  }
];

function getCheckType(check: ICheckData) {
  const checkType = checkTypes.find((checkType) => checkType.value === check.type);

  return checkType || {
    name: toUpperFirstChar(check.type),
    value: check.type,
    isUsed: false
  };
}

function getDisplayingEnableStatus(checkData: ICheckData) {
  const {is_enabled} = checkData;
  return is_enabled ? i18next.t("button.disable", "Disable") : i18next.t("button.enable", "Enable");
}

export {
  getDisplayingEnableStatus,
  getTableColumnHeaders,
  getTableFilterText,
  checkTypes,
  getCheckType
};