import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActions } from "store/actions";
import { useTranslation } from "react-i18next";
import { MODAL_WINDOW_TYPES } from "utils/constants";
import { withErrorBoundary } from "../ErrorBoundary";
import { modalSelector } from "store/selectors/modal";
import { hideModalWindowAction } from "store/actions/modal";
import { ARButton, ARModal, ARModalBody, ARModalFooter, ARModalHeader } from "../ARComponents";

interface IModalWindowSelector {
  title: string;
  message: string;
  type: string;
  size?: "" | "sm" | "lg" | "xl";
  isShowing: boolean;
  onConfirm?: {(): void} | null;
  onCancel?: {(): void} | null;
  color: string;
  confirmText: string;
  cancelText: string;
  centered: boolean;
  formOptions: null | { formId: string; subscribeToSuccessfulSubmit: (arg: () => void) => void };
}

const ModalWindow = () => {
  const {
    title,
    message,
    type,
    size,
    isShowing,
    onConfirm,
    onCancel,
    color,
    confirmText,
    cancelText,
    centered,
    formOptions,
  }: IModalWindowSelector = useSelector(modalSelector);

  const [showModalWindow, setShowModalWindow] = useState<boolean>(isShowing);
  const [hideModalWindow] = useActions(hideModalWindowAction);
  const { t } = useTranslation();

  const onCloseModalWindow = () => {
    hideModalWindow();
  };

  const onConfirmButtonClick = () => {
    onConfirm && onConfirm();
    hideModalWindow();
  };

  formOptions?.subscribeToSuccessfulSubmit?.(hideModalWindow);

  const onCancelButtonClick = () => {
    onCancel && onCancel();
    hideModalWindow();
  };

  useEffect(() => {
    setShowModalWindow(isShowing);
  }, [title, message, type, isShowing]);

  if (!isShowing) return null;

  return (
    <ARModal show={showModalWindow} onClose={onCloseModalWindow} color={color} size={size} centered={centered}>
      <ARModalHeader closeButton>{title}</ARModalHeader>
      <ARModalBody>{message}</ARModalBody>
      <ARModalFooter>
        {type === MODAL_WINDOW_TYPES.FORM && (
          <ARButton color={color} type="submit" form={formOptions?.formId}>
            {t("form:button.save", "Save")}
          </ARButton>
        )}
        {type !== MODAL_WINDOW_TYPES.FORM && (
          <ARButton color={color} onClick={onConfirmButtonClick}>
            {confirmText || t("button.ok", "OK")}
          </ARButton>
        )}
        {type !== MODAL_WINDOW_TYPES.ALERT && (
          <>
            {" "}
            <ARButton color="secondary" onClick={onCancelButtonClick}>
              {cancelText || t("button.cancel", "Cancel")}
            </ARButton>
          </>
        )}
      </ARModalFooter>
    </ARModal>
  );
};

export default withErrorBoundary(ModalWindow);
