import {createBrowserHistory} from "history";
import {showModalWindow} from "utils/actionWrappers";

const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => {
    showModalWindow.confirm({
      message,
      onConfirm: () => callback(true),
      onCancel: () => callback(false),
      centered: false
    });
  }
});

export default history;