import { PATHS } from "route-dictionary";
import { useTranslation } from "react-i18next";
import useSettingsFormConfig from "./useSettingsFormConfig";
import { getDirtyValues } from "components/ReactHookForm/utils";
import { usePreventTransition } from "components/ReactHookForm/hooks";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { ICompanySettings } from "services/api/types";
import React from "react";

const useSettingsForm = () => {
  const { t } = useTranslation();
  const { formControl, onSaveButtonClick, resetTelegramToken, settingsInfo } = useSettingsFormConfig();
  const {
    formState: { isDirty, dirtyFields },
  } = formControl;

  const { Prompt, redirect } = usePreventTransition({ isDirty });
  const redirectToChecksPage = () => redirect(PATHS.CHECKS.ROOT);

  const onFormSubmit = (formData: Partial<ICompanySettings>) => {
    if (isDirty) {
      const submitData = getDirtyValues({ dirtyFields, allValues: formData });

      onSaveButtonClick(submitData)
        .then(() =>
          showNotification(
            t("notification:title.success"),
            t("notification:message.updated.settings", "Settings have been updated."),
            "success"
          )
        )
        .catch(({ message }) => {
          if (message) {
            showNotification(t("notification:title.error", "Something went wrong!"), message, "danger");
          }
        });
    } else {
      redirectToChecksPage();
    }
  };

  const onResetTelegramTokenClick = () => {
    showModalWindow.confirm({
      message: t("modal:message.resetTelegramToken", "Are you sure you want to reset your Telegram token?"),
      color: "warning",
      onConfirm: () => {
        resetTelegramToken().then(() =>
          showNotification(
            t("notification:title.success"),
            t("notification:message.updated.settings", "Settings have been updated."),
            "success"
          )
        );
      },
    });
  };

  const onFormReset = (event?: React.MouseEvent) => {
    event?.preventDefault();
    if (isDirty) {
      showModalWindow.confirm({
        message: t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: redirectToChecksPage,
      });
    } else {
      redirectToChecksPage();
    }
  };

  return {
    onFormSubmit,
    onFormReset,
    Prompt,
    settingsInfo,
    onResetTelegramTokenClick,
    form: formControl,
  };
};

export default useSettingsForm;
