import {PATHS} from "route-dictionary";
import {useTranslation} from "react-i18next";
import {useDirectoryFormConfig} from "./index";
import {showModalWindow} from "utils/actionWrappers";
import {directoryDataFields} from "../Directory/Directory.model";
import {usePreventTransition} from "components/ReactHookForm/hooks";
import DirectoryConnectionError from "../Directory/DirectoryConnectionError/DirectoryConnectionError";
import {getDirtyValues, getFormDataObj} from "components/ReactHookForm/utils";
import { IMemberDirectoryElementData } from "services/api/types";

const useDirectoryForm = () => {
  const {t} = useTranslation();
  const {formControl, formId, isAddPage, headerText, setHeaderText, onSaveBtnClick} = useDirectoryFormConfig();
  const {handleSubmit, control, reset, formState: {isDirty, dirtyFields}} = formControl;
  const {redirect, Prompt} = usePreventTransition({isDirty});
  const redirectToDirectoriesPage = () => redirect(PATHS.DIRECTORIES.ROOT);

  const onFormSubmit = (formData: IMemberDirectoryElementData) => {
    if (isDirty) {
      let submitData: IMemberDirectoryElementData;

      if (isAddPage) {
        submitData = formData;
      } else {
        const formDirtyFields = {...dirtyFields};
        submitData = getDirtyValues({dirtyFields: formDirtyFields, allValues: formData});
      }

      onSaveBtnClick(submitData)
        .then((data) => {
          if (data && !isAddPage) setHeaderText(data.name);
          const newFormData = getFormDataObj({arrOfFields: directoryDataFields, serverData: data});
          reset(newFormData);

          if (data && data.connection_error) {
            showModalWindow.confirm({
              title: t("modal:title.directoryConnectError", "Directory connection failed!"),
              message: <DirectoryConnectionError
                errorMessage={data.connection_error}
                actionText={t("modal:message.saveDirectoryAnyway", "Would you like to save directory anyway?")}
              />,
              color: "danger",
              onConfirm: redirectToDirectoriesPage,
              // eslint-disable-next-line
              onCancel: isAddPage ? (() => redirect(PATHS.DIRECTORIES.getDetailsIdPath?.(data.id) || "")) : () => {},
              confirmText: t("button.ignore", "Ignore"),
              cancelText: t("button.edit", "Edit")
            });
          } else {
            redirectToDirectoriesPage();
          }
        });
    } else {
      redirectToDirectoriesPage();
    }
  };

  const onFormReset = () => {
    if (isDirty) {
      showModalWindow.confirm({
        message: t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: redirectToDirectoriesPage
      });
    } else {
      redirectToDirectoriesPage();
    }
  };

  return {
    formId,
    isAddPage,
    headerText,
    onFormReset,
    onFormSubmit,
    Prompt,
    form: {
      control,
      handleSubmit,
    }
  };
};

export default useDirectoryForm;