import {useEffect, useState} from "react";
import {getTelegramAuthRequest} from "services/api";

interface ITelegramAuthInfo {
  telegram_bot?: {
    username: string;
  }
}

const useTelegramAuthInfo = () => {
  const [telegramAuthInfo, setTelegramAuthInfo] = useState<ITelegramAuthInfo | null>(null);

  useEffect(() => {
    let cancelled = false;
    getTelegramAuthRequest().then(({data}) => !cancelled && setTelegramAuthInfo(data));
    return () => {
      cancelled = true;
    };
  }, []);

  return telegramAuthInfo;
};

export default useTelegramAuthInfo;