import React, { useCallback, useMemo } from "react";
import { withErrorBoundary } from "components/ErrorBoundary";

type ComponentGroup = {
  [key: string]: React.FC;
};

type ItemEl = {
  _tag: keyof ComponentGroup;
  name: string;
  to?: string;
  icon?: JSX.Element;
  _children?: ItemEl[];
  isShown: boolean;
};

type ARCreateElementProps = {
  items: ItemEl[];
  components: ComponentGroup;
};

/**
 * CCreateElement без оптимизации через JSON.stringify.
 *
 * @param {Array} items - Массив объектов для генерации.
 * @param {Object} [components] - Компоненты, необходимые для генерации items.
 * @returns {React.JSX}
 * @constructor
 */
const ARCreateElement = ({ items, components = {} }: ARCreateElementProps) => {
  const renderItem = useCallback(
    (item: ItemEl, i: number) => {
      const { _tag, _children, isShown, ...rest } = item;
      const Tag = components[_tag];
      const children = _children
        ? _children.map((child, i) => {
            return renderItem(child, i);
          })
        : "";
      return (
        <Tag key={`${_tag}-${i}`} {...rest}>
          {children}
        </Tag>
      );
    },
    [components]
  );

  const generatedItems = useMemo(() => {
    return items && items.map((item, i) => renderItem(item, i));
  }, [items, renderItem]);

  return <React.Fragment>{generatedItems}</React.Fragment>;
};

export default withErrorBoundary<ARCreateElementProps>(ARCreateElement);
