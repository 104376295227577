import { useNewPasswordForm } from "../hooks";
import { useTranslation } from "react-i18next";
import RecoveryPassword from "../RecoveryPassword";
import { withErrorBoundary } from "components/ErrorBoundary";
import { RHFPrependAppendInput } from "components/ReactHookForm/fields";

const NewPassword = () => {
  const { t } = useTranslation();
  const { onFormSubmit, form } = useNewPasswordForm();
  const { handleSubmit, control, trigger } = form;
  return (
    <RecoveryPassword
      onFormSubmit={handleSubmit(onFormSubmit)}
      infoText={t("card.description.restorePassword.new", "Enter new password")}
    >
      <RHFPrependAppendInput
        iconName="cilLockLocked"
        className="RecoveryPassword__input-group"
        id="password"
        name="password"
        type="password"
        trigger={trigger}
        triggerName="confirm_password"
        control={control}
        placeholder={t("form:label.password_new", "New password")}
        autoComplete="off"
        autoFocus
      />
      <RHFPrependAppendInput
        iconName="cilLockLocked"
        className="RecoveryPassword__input-group"
        id="confirm_password"
        name="confirm_password"
        type="password"
        control={control}
        placeholder={t("form:label.password_newConfirm", "Confirm new password")}
        autoComplete="off"
      />
    </RecoveryPassword>
  );
};

export default withErrorBoundary(NewPassword);
