import thunk from "redux-thunk";
import { rootReducer } from "./reducers";
import * as Sentry from "@sentry/react";
import { applyMiddleware, compose, createStore } from "redux";

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = createStore(
  rootReducer, 
  composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer)
);

export type RootState = ReturnType<typeof store.getState>;
