import { useSignUpForm } from "./hooks";
import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import { DOMAIN, REG_EXS } from "utils/constants";
import { Trans, useTranslation } from "react-i18next";
import { withErrorBoundary } from "components/ErrorBoundary";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { RHFPrependAppendInput } from "components/ReactHookForm/fields";
import { RHFInputCheckbox } from "components/ReactHookForm/fields/custom";
import { ARButton, ARCol, ARForm, ARRow } from "components/ARComponents";

const SignUp = () => {
  const { t } = useTranslation();
  const { form, onFormSubmit } = useSignUpForm();
  const { handleSubmit, control, setFormError } = form;

  return (
    <ARForm onSubmit={handleSubmit(onFormSubmit)}>
      <h1>{t("card.title.register", "Lets Start")}</h1>
      <p className="text-muted">{t("card.description.register", "Register your company to use the service")}</p>
      <RHFPrependAppendInput
        iconName="cilLink"
        className="SignUp__subdomain-field"
        appendText={`.${DOMAIN}`}
        id="subdomain"
        name="subdomain"
        control={control}
        inputClassName="SignUp__subdomain-input"
        placeholder={t("form:placeholder.subdomain", "subdomain")}
        autoComplete="your-company-name"
        pattern={REG_EXS.DOMAIN_CHARACTERS}
        patternMismatchMessage={MESSAGES.DOMAIN_INVALID_CHARACTERS}
        setFormError={setFormError}
        autoFocus
      />
      <RHFInputCheckbox
        id="service_checkbox"
        name="service_checkbox"
        control={control}
        className="checkbox"
        labelClassName="checkbox__label"
        labelText={
          <Trans i18nKey="form:label.terms">
            I have read and agree to the{" "}
            <Link to={PATHS.LICENSE.ROOT} target={"_blank"}>
              terms of the service
            </Link>
            .
          </Trans>
        }
      />
      <ARRow>
        <ARCol xs={12} className="card-column-buttons">
          <ARButton type="submit" color="primary" className="w-100 card-column-buttons__button">
            {t("button.register", "Register")}
          </ARButton>
          <ARButton tag={Link} to={PATHS.LOGIN.ROOT} color="link" className="w-100 card-column-buttons__button">
            {t("button.loginWithRegisteredDomain", "Sign in with registered domain")}
          </ARButton>
        </ARCol>
      </ARRow>
    </ARForm>
  );
};

export default withErrorBoundary(SignUp);
