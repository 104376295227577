import { RHFSelect } from "./custom";
import { RHFLabel } from "./wrappers";
import { IRHFLabelProps } from "./wrappers/RHFLabel";
import { IRHFSelectProps } from "./custom/RHFSelect";

interface IRHFSelectWithLabelProps extends IRHFSelectProps, IRHFLabelProps {
  selectClassName?: string;
}

const RHFSelectWithLabel = ({
  id,
  labelText,
  prependText,
  appendText,
  groupClassName,
  selectClassName,
  labelColClassName,
  ...selectProps
}: IRHFSelectWithLabelProps) => {
  return (
    <RHFLabel
      id={id}
      labelText={labelText}
      prependText={prependText}
      appendText={appendText}
      groupClassName={groupClassName}
      labelColClassName={labelColClassName}
    >
      <RHFSelect id={id} className={selectClassName} {...selectProps} />
    </RHFLabel>
  );
};

export default RHFSelectWithLabel;
