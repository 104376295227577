interface IDirectoryConnectionErrorProps {
  errorMessage: string;
  actionText: string;
}

const DirectoryConnectionError = ({errorMessage, actionText}: IDirectoryConnectionErrorProps) => (
  <div className="text-center">
    {errorMessage}
    <div>{actionText}</div>
  </div>
);

export default DirectoryConnectionError;