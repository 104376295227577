import { REG_EXS } from "utils/constants";
import { useTranslation } from "react-i18next";
import { useForgotPasswordForm } from "../hooks";
import RecoveryPassword from "../RecoveryPassword";
import { withErrorBoundary } from "components/ErrorBoundary";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { RHFPrependAppendInput } from "components/ReactHookForm/fields";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { onFormSubmit, form } = useForgotPasswordForm();
  const { handleSubmit, control, setFormError } = form;
  return (
    <RecoveryPassword
      onFormSubmit={handleSubmit(onFormSubmit)}
      infoText={t("card.description.restorePassword.forgot", "New password will be sent to your email address")}
    >
      <RHFPrependAppendInput
        iconName="cilUser"
        className="RecoveryPassword__input-group"
        id="email"
        name="email"
        control={control}
        pattern={REG_EXS.EMAIL_CHARACTERS}
        setFormError={setFormError}
        patternMismatchMessage={MESSAGES.EMAIL_INVALID_CHARACTERS}
        placeholder={t("form:label.email", "Email")}
        autoComplete="email"
        autoFocus
      />
    </RecoveryPassword>
  );
};

export default withErrorBoundary(ForgotPassword);
