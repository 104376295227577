import { RHFInput } from "./custom";
import { RHFLabel } from "./wrappers";
import classNames from "classnames";
import { IRHFLabelProps } from "./wrappers/RHFLabel";
import { IRHFInputProps } from "./custom/RHFInput";

interface IRHFInputWithLabelProps extends IRHFInputProps, IRHFLabelProps {
  disabled?: boolean;
}

const RHFInputWithLabel = ({
  id,
  labelText,
  prependText,
  appendText,
  groupClassName,
  inputClassName,
  labelColClassName,
  className,
  ...inputProps
}: IRHFInputWithLabelProps) => {
  return (
    <RHFLabel
      id={id}
      labelText={labelText}
      prependText={prependText}
      appendText={appendText}
      groupClassName={classNames(groupClassName)}
      labelColClassName={classNames(labelColClassName)}
    >
      <RHFInput id={id} className={classNames(inputClassName)} {...inputProps} />
    </RHFLabel>
  );
};

export default RHFInputWithLabel;
