import { GLOBAL } from "./types";
import { getTenantInfoRequest } from "services/api";
import { SUBSCRIPTION_TYPES } from "utils/constants";
import { IDispatch } from "store/types";

export function setIsFetchingAction() {
  return {
    type: GLOBAL.FETCHING,
  };
}

export function setIsFetchedAction() {
  return {
    type: GLOBAL.FETCHED,
  };
}

export function showSpinnerAction() {
  return {
    type: GLOBAL.SHOW_SPINNER,
  };
}

export function hideSpinnerAction() {
  return {
    type: GLOBAL.HIDE_SPINNER,
  };
}

export function setTelegramTokenStatusAction(telegramTokenStatus: boolean) {
  return {
    type: GLOBAL.SET_TELEGRAM_TOKEN_STATUS,
    payload: { telegramTokenIsSet: telegramTokenStatus },
  };
}

export function setTenantAction() {
  return async (dispatch: IDispatch) => {
    const response = await getTenantInfoRequest();
    dispatch({
      type: GLOBAL.SET_TENANT,
      payload: response,
    });
  };
}

export function setHasAdminAction(flag: boolean) {
  return {
    type: GLOBAL.SET_HAS_ADMIN,
    payload: { flag },
  };
}

export function setSubscriptionAction(hasSubscription: boolean) {
  const subscriptionType = hasSubscription ? SUBSCRIPTION_TYPES.PAID : SUBSCRIPTION_TYPES.FREE;

  return {
    type: GLOBAL.SET_SUBSCRIPTION_STATUS,
    payload: { subscriptionType },
  };
}

export function setCheckNameAction({ name }: { name: string }) {
  return {
    type: GLOBAL.SET_CHECK_NAME,
    payload: {
      checkName: name,
    },
  };
}

export function resetCheckNameAction() {
  return {
    type: GLOBAL.RESET_CHECK_NAME,
  };
}
