import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActions } from "store/actions";
import { ARAlert } from "../ARComponents";
import { withErrorBoundary } from "../ErrorBoundary";
import { hideNotificationAction } from "store/actions/notification";
import { notificationSelector } from "store/selectors/notification";

type NotificationProps = {
  timeout?: number;
};

type NotificationSelector = {
  title: string;
  message: string;
  type: string;
  isShowing: boolean;
};

const Notification = (props: NotificationProps) => {
  const { title, message, type, isShowing }: NotificationSelector = useSelector(notificationSelector);
  const [hideNotification] = useActions(hideNotificationAction);
  const [visible, setVisible] = useState<number>(isShowing ? props.timeout || 20 : 0);

  const onShowChange = (value: number | boolean) => {
    setVisible(+value);
    if (value === 0) hideNotification();
  };

  useEffect(() => {
    if (isShowing) {
      setVisible(props.timeout || 10);
    }
  }, [title, message, type, isShowing, props.timeout]);

  return (
    <div className="Notification-container">
      <ARAlert className="Notification" color={type} show={visible} closeButton onShowChange={onShowChange}>
        <h4 className="alert-heading">{title}</h4>
        <p>{message}</p>
      </ARAlert>
    </div>
  );
};

export default withErrorBoundary<NotificationProps>(Notification);
