import {instance, requestPromiseWrapper} from "./instance";

export function getGroupsRequest() {
  return requestPromiseWrapper(instance.get("/groups"));
}

export function getGroupInfoRequest(groupId) {
  return requestPromiseWrapper(instance.get(`/groups/${groupId}`));
}

export function createGroupRequest(groupInfo) {
  return requestPromiseWrapper(instance.post("/groups", groupInfo));
}

export function updateGroupRequest(groupId, groupInfo) {
  return requestPromiseWrapper(instance.patch(`/groups/${groupId}`, groupInfo));
}

export function deleteGroupRequest(groupId) {
  return requestPromiseWrapper(instance.delete(`/groups/${groupId}`));
}