import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PATHS } from "route-dictionary";
import { useActions } from "store/actions";
import { ERR_MESSAGES } from "utils/constants";
import i18next from "services/language/i18next";
import { profileDataFields } from "../Profile.model";
import { yupResolver } from "@hookform/resolvers/yup";
import { avatarSelector } from "store/selectors/user";
import { setUserInfoAction } from "store/actions/user";
import { profileFormSchema } from "components/ReactHookForm/schemes";
import { MESSAGES } from "components/ReactHookForm/schemes/messages";
import { useFormWithCustomError, usePreventTransition } from "components/ReactHookForm/hooks";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { getDirtyValues, getFormDataObj } from "components/ReactHookForm/utils";
import { cancelSource, getProfileInfoRequest, saveProfileInfoRequest } from "services/api";
import { IUser } from "../../../components/ReactHookForm/_types";

const useProfileForm = () => {
  const [updateProfileInfo] = useActions(setUserInfoAction);
  const stateAvatar = useSelector(avatarSelector);

  const {
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { isDirty, dirtyFields },
    setError,
  } = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(profileFormSchema),
    defaultValues: getFormDataObj({
      arrOfFields: profileDataFields,
      serverData: { avatar: stateAvatar },
    }) as IUser,
  });

  const { redirect, Prompt } = usePreventTransition({ isDirty });
  const redirectToChecksPage = () => redirect(PATHS.CHECKS.ROOT);

  useEffect(() => {
    getProfileInfoRequest().then(({ data }) => {
      updateProfileInfo(data);
      const formData = getFormDataObj({ arrOfFields: profileDataFields, serverData: data });

      reset(formData);
    });

    return () => {
      cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
    };
  }, [updateProfileInfo, reset]);

  const onFormSubmit = (formData: IUser) => {
    if (isDirty) {
      const submitData = getDirtyValues({ dirtyFields, allValues: formData });

      saveProfileInfoRequest(submitData)
        .then(({ data }) => {
          showNotification(
            i18next.t("notification:title.success", "Success!"),
            i18next.t("notification:message.updated.profile", "Profile has been updated."),
            "success"
          );
          updateProfileInfo(data);
          redirectToChecksPage();
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            setError("email", { type: "FieldError", message: MESSAGES.EXISTING_EMAIL }, { shouldFocus: true });
          } else {
            showNotification(
              i18next.t("notification:title.error", "Something went wrong!"),
              Object.values(error.response.data).join(" \n"),
              "danger"
            );
          }
        });
    } else {
      redirectToChecksPage();
    }
  };

  const onFormReset = () => {
    if (isDirty) {
      showModalWindow.confirm({
        message: i18next.t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: redirectToChecksPage,
      });
    } else redirectToChecksPage();
  };

  return {
    onFormReset,
    Prompt,
    onFormSubmit,
    form: {
      control,
      handleSubmit,
      trigger,
      setFormError: setError,
    },
  };
};

export default useProfileForm;
