import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ARLabel, ARSelect } from "../ARComponents";
import { ProjectOptions, Disabled, ClassName } from "models/types";

type ProjectSelectorProps = {
  options: ProjectOptions;
  onChange(event: React.ChangeEvent<HTMLSelectElement>): void;
  disabled?: Disabled;
  className?: ClassName;
};

const ProjectSelector = (props: ProjectSelectorProps) => {
  const { t } = useTranslation();
  const { options, onChange, disabled, className } = props;

  return (
    <div className={classNames("ProjectSelector", className)}>
      <ARLabel className="ProjectSelector__label">{t("page.project.singular", "Project:")}</ARLabel>
      <ARSelect
        custom
        id="project"
        name="project"
        className="ProjectSelector__select"
        onChange={onChange}
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </ARSelect>
    </div>
  );
};

export default ProjectSelector;
