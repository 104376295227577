import {useState} from "react";

const useVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);

  const isVisibleHandler = () => setIsVisible((prevState) => !prevState);

  return {
    isVisible,
    isVisibleHandler
  };
};

export default useVisibility;