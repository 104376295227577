import classNames from "classnames";
import { ChangeEvent, useEffect } from "react";
import { ARInput } from "components/ARComponents";
import { Message, MESSAGES_TYPES } from "../../schemes/messages";
import { Control, ICustomError, Trigger } from "../../_types";
import { ClassName } from "models/types";
import { useControllerWithCustomError } from "../../hooks";

export interface IRHFInputProps {
  id: string;
  control?: Control;
  name: string;
  type?: string;
  trigger?: Trigger;
  triggerName?: string;
  className?: ClassName;
  inputClassName?: ClassName;
  pattern?: RegExp;
  patternMismatchMessage?: Message;
  setFormError?: (name: string, error: { message: Message }) => void;
  setError?: (error: ICustomError) => void;
  shouldUnregister?: boolean;
  placeholder?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const RHFInput = (props: IRHFInputProps) => {
  const {
    control,
    name,
    trigger,
    className,
    pattern,
    setError,
    setFormError,
    patternMismatchMessage,
    shouldUnregister = true,
    triggerName,
    ...restProps
  }: IRHFInputProps = props;

  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, isDirty, error },
    formState: { isSubmitted },
  } = useControllerWithCustomError({ name, control, shouldUnregister });

  useEffect(() => {
    setError && setError(error);
  }, [setError, error]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (pattern) {
      if (pattern.test(event.target.value)) {
        onChange(event);
        trigger && trigger(triggerName);
      } else {
        setFormError && patternMismatchMessage && setFormError(name, { message: patternMismatchMessage });
      }
    } else {
      onChange(event);
      trigger && trigger(triggerName);
    }
  };

  return (
    <ARInput
      className={classNames("field_right-border-radius", className)}
      innerRef={ref}
      invalid={invalid && error.message.type === MESSAGES_TYPES.DANGER}
      valid={(isDirty || isSubmitted) && !invalid}
      onChange={changeHandler}
      {...inputProps}
      {...restProps}
    />
  );
};

export default RHFInput;
