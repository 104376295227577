import * as yup from "yup";
import { MESSAGES } from "./messages";

const newPasswordFormSchema = yup.object().shape({
  password: yup.string().required(MESSAGES.REQUIRED),
  confirm_password: yup
    .string()
    .required(MESSAGES.REQUIRED)
    .oneOf([yup.ref("password")], MESSAGES.MATCH_PASSWORDS),
});

export default newPasswordFormSchema;
