import { useEffect, useRef, useState } from "react";
import { ERR_MESSAGES } from "utils/constants";
import { cancelSource, createGroupRequest, deleteGroupRequest, getGroupsRequest } from "services/api";

const useGroupsInfo = () => {
  const [groups, setGroups] = useState([]);
  const successfulCreateGroupSubscriberRef = useRef<(arg: number) => void>();

  const getGroupsInfo = () => {
    return getGroupsRequest().then(({ data }) => setGroups(data));
  };

  const createGroup = (groupInfo: { name: string }) => {
    const updatedGroupInfo = {
      ...groupInfo,
      members_ids: [],
    };
    return createGroupRequest(updatedGroupInfo).then(({ data }) => successfulCreateGroupSubscriberRef.current?.(data.id));
  };

  const deleteGroup = (groupId: number) => {
    return deleteGroupRequest(groupId).then(() => getGroupsInfo());
  };

  useEffect(() => {
    getGroupsInfo();
    return () => cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
  }, []);

  return {
    groups,
    createGroup,
    successfulCreateGroupSubscriberRef,
    deleteGroup,
  };
};

export default useGroupsInfo;
