import { IARTooltipProps } from "./_types";
import { ARTooltipComponent } from "./_ARBaseComponents";

const ARTooltip = (props: IARTooltipProps) => {
  const { children, placement = "right-start", ...restProps }: IARTooltipProps = props;

  return (
    <ARTooltipComponent placement={placement} {...restProps}>
      {children}
    </ARTooltipComponent>
  );
};

export default ARTooltip;
