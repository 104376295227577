import { RHFLabel } from "./wrappers";
import { RHFTextarea } from "./custom";
import { IRHFLabelProps } from "./wrappers/RHFLabel";
import { IRHFTextareaProps } from "./custom/RHFTextarea";

interface IRHFTextareaWithLabelProps extends IRHFTextareaProps, IRHFLabelProps {}

const RHFTextareaWithLabel = ({
  id,
  labelText,
  prependText,
  appendText,
  groupClassName,
  labelColClassName,
  ...textareaProps
}: IRHFTextareaWithLabelProps) => {
  return (
    <RHFLabel
      id={id}
      labelText={labelText}
      prependText={prependText}
      appendText={appendText}
      groupClassName={groupClassName}
      labelColClassName={labelColClassName}
    >
      <RHFTextarea id={id} {...textareaProps} />
    </RHFLabel>
  );
};

export default RHFTextareaWithLabel;
