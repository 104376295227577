import deployedConfig from "./deployed";
import localConfig from "./local";
import defaultConfig from "./default";
import mobileConfig from "./mobile";
import developConfig from "./develop";
import proxyConfig from "./proxy";

function getConfig() {
  switch (process.env.REACT_APP_SERVER_LOCATION) {
    case "local":
      return localConfig;
    case "deployed":
      return deployedConfig;
    case "mobile":
      return mobileConfig;
    case "develop":
      return developConfig;
    case "proxy":
      return proxyConfig;
    default:
      return defaultConfig;
  }
}

export default getConfig();