import i18next from "services/language/i18next";
import { INTEGRATION_TYPES } from "utils/constants";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";

function getTableColumnHeaders() {
  const tableColumnHeaders = [
    {
      key: "name",
      label: i18next.t("table:header.name", "Name"),
      _style: { width: "50%" },
      isShown: hasGlobalPermission(ACTIONS.VIEW_INTEGRATIONS),
    },
    {
      key: "type",
      label: i18next.t("table:header.type", "Type"),
      _style: { width: "50%" },
      isShown: hasGlobalPermission(ACTIONS.VIEW_INTEGRATIONS),
    },
  ];

  return tableColumnHeaders.filter((item) => item.isShown);
}

const integrationsTypes = [
  {
    name: "Jira",
    value: "jira",
  },
  {
    name: "Nexmo",
    value: "nexmo",
  },
  {
    name: "Twilio",
    value: "twilio",
  },
  {
    name: "SMSC",
    value: "smsc",
  },
  {
    name: "Selectel",
    value: "selectel",
  },
  {
    name: "DaData",
    value: "dadata",
  },
];

function getIntegrationFieldsNamesByType(type: string) {
  const generalFields = ["name", "type"];
  switch (type) {
    case INTEGRATION_TYPES.JIRA:
      return [...generalFields, "token", "url"];
    case INTEGRATION_TYPES.NEXMO:
      return [...generalFields, "api_key", "app_id", "from_phone"];
    case INTEGRATION_TYPES.TWILIO:
      return [...generalFields, "account_cid", "token"];
    case INTEGRATION_TYPES.SMSC:
      return [...generalFields, "login", "password"];
    case INTEGRATION_TYPES.SELECTEL:
      return [...generalFields, "api_key"];
    case INTEGRATION_TYPES.DADATA:
      return [...generalFields, "api_key", "secret_key"];
    default:
      return [...generalFields, "url"];
  }
}

function getAdditionalFieldsByType(type: string) {
  switch (type) {
    case INTEGRATION_TYPES.SMSC:
      return { password: "" };
    case INTEGRATION_TYPES.DADATA:
      return { secret_key: "" };
    default:
      return {};
  }
}

export { getTableColumnHeaders, getIntegrationFieldsNamesByType, getAdditionalFieldsByType, integrationsTypes };
