import { useProjectInfo } from "./index";
import { PATHS } from "route-dictionary";
import { useTranslation } from "react-i18next";
import { ROLES_PROPERTY_NAMES } from "utils/constants";
import { RHFSelectWithLabel } from "components/ReactHookForm/fields";
import { ARModalWrapper } from "components/ModalWindow/ARModalForm";
import { usePreventTransition } from "components/ReactHookForm/hooks";
import useFormModal from "components/ModalWindow/hooks/useFormModal";
import { showModalWindow, showNotification } from "utils/actionWrappers";
import { RHFElementsSelector } from "components/ReactHookForm/fields/custom";
import { getMemberText } from "components/RulesComponents/RulesComponents.model";
import { projectDeletingHandler, roleOptions } from "../ProjectDetails/ProjectDetails.model";
import { IMember, IProject } from "./../../../models/types";
import { getProjectGroupAddModalFormSchema, getProjectMemberAddModalFormSchema } from "components/ReactHookForm/schemes";
import { IRolesGroups } from "./useProjectInfo";


export interface INewRoles {
  member?: IMember;
  role: string;
}

const useProjectDetailsForm = () => {
  const {
    roles,
    addRoles,
    updateRoles,
    formControl,
    projectInfo,
    deleteProject,
    updateProjectData,
    availableGroupsAndMembers,
  } = useProjectInfo();

  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = formControl;
  const { redirect, Prompt } = usePreventTransition({ isDirty });
  const redirectToProjectsPage = () => redirect(PATHS.PROJECTS.ROOT);

  const onFormSubmit = (projectData: IProject) => {
    if (isDirty) {
      updateProjectData(projectData)
        .then(() => {
          showNotification(
            t("notification:title.success", "Success!"),
            t("notification:message.updated.project", "Project has been updated."),
            "success"
          );
        })
        .then(redirectToProjectsPage);
    } else redirectToProjectsPage();
  };

  const onFormDelete = () => {
    showModalWindow.confirm({
      message: t("modal:message.delete.project", "Are you sure you want to delete this project?"),
      color: "danger",
      onConfirm: () => {
        deleteProject()
          .then(() => {
            showNotification(
              t("notification:title.success", "Success!"),
              t("notification:message.deleted.project", "Project has been deleted."),
              "success"
            );
          })
          .then(redirectToProjectsPage)
          .catch((error) => projectDeletingHandler(error));
      },
    });
  };

  const onFormReset = () => {
    if (isDirty) {
      showModalWindow.confirm({
        message: t("modal:message.cancel", "Are you sure you want to cancel all the changes?"),
        color: "warning",
        onConfirm: redirectToProjectsPage,
      });
    } else redirectToProjectsPage();
  };

  const showFormModal = useFormModal((formData: { name: string; role?: string; newMembers?: IMember[] }) => {
    type keyOfRolesPropertyNames = keyof typeof ROLES_PROPERTY_NAMES;
    type keyOfFormData = keyof typeof formData;
    const pluralRolesPropertyName = Object.keys(formData)[1];
    const singularRolesPropertyName = ROLES_PROPERTY_NAMES[pluralRolesPropertyName as keyOfRolesPropertyNames].singular;
    const newRoles = (formData[pluralRolesPropertyName as keyOfFormData]! as []).reduce(
      (acc: INewRoles[], item) => {
        acc.push({ [singularRolesPropertyName]: item, role: formData.role! });
        return acc;
      },
      []
    );

    newRoles.length &&
      addRoles(pluralRolesPropertyName, newRoles).then(() =>
        showNotification(
          t("notification:title.success", "Success!"),
          t(`notification:message.added.toProject.${singularRolesPropertyName}`, { count: newRoles.length }),
          "success"
        )
      );
  });

  const showMembersModal = () => {
    showFormModal({
      title: t("modal:title.memberAdd", "Member add"),
      formId: "new-members-form",
      validationSchema: getProjectMemberAddModalFormSchema(ROLES_PROPERTY_NAMES.members.plural),
      defaultValues: {
        role: "admin",
        [ROLES_PROPERTY_NAMES.members.plural]: [],
      },
      fields: (
        <ARModalWrapper>
          <RHFSelectWithLabel
            key="role"
            id="role"
            name="role"
            options={roleOptions()}
            labelText={t("form:label:addAs", "Add as:")}
          />
          <RHFElementsSelector
            key="add_members"
            id="add_members"
            name={ROLES_PROPERTY_NAMES.members.plural}
            items={availableGroupsAndMembers.members}
            getItemText={getMemberText}
          />
        </ARModalWrapper>
      ),
    });
  };

  const showGroupsModal = () => {
    showFormModal({
      title: t("modal:title.groupAdd", "Group add"),
      formId: "new-groups-form",
      validationSchema: getProjectGroupAddModalFormSchema(ROLES_PROPERTY_NAMES.groups.plural),
      defaultValues: {
        role: "admin",
        [ROLES_PROPERTY_NAMES.groups.plural]: [],
      },
      fields: (
        <ARModalWrapper>
          <RHFSelectWithLabel
            key="role"
            id="role"
            name="role"
            options={roleOptions()}
            labelText={t("form:label:addAs", "Add as:")}
          />
          <RHFElementsSelector
            key="add_groups"
            id="add_groups"
            name={ROLES_PROPERTY_NAMES.groups.plural}
            items={availableGroupsAndMembers.groups}
            getItemText={(item: IRolesGroups) => item.name}
          />
        </ARModalWrapper>
      ),
    });
  };

  return {
    Prompt,
    roles,
    projectInfo,
    updateRoles,
    onFormReset,
    onFormSubmit,
    onFormDelete,
    showGroupsModal,
    showMembersModal,
    formControl: {
      control,
      handleSubmit,
    },
  };
};

export default useProjectDetailsForm;
