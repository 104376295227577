import { useActions } from "store/actions";
import { useCallback, useEffect, useState } from "react";
import { resetCheckNameAction, setCheckNameAction } from "store/actions/global";
import {
  CancelToken,
  getDetailedCheckRequest,
  getIntegrationsRequest,
  getProjectsRequest,
  groupCancelSource,
} from "services/api";
import { ICheck, ICheckRules, Iintegration } from "pages/checks/_types";
import { IRule } from "components/RulesComponents/_types";
import { IProject } from "./../../../../models/types";

const splitCheckData = (checkData: ICheck, isClonedCheck: boolean) => {
  const { type } = checkData;
  const { text, header } = checkData?.extra;
  const { id: project } = checkData.project as IProject;
  const name = isClonedCheck ? checkData?.name + " - clone" : checkData?.name;
  const pulledCheckData = { name, text, header, project };

  if (type === "port") {
    const portRegx = /(\d+$)/g;
    const portValue = checkData?.url.match(portRegx)![0].slice();
    const urlRegx = /(.*)[^\d+$]/;
    const urlValue = checkData?.url.match(urlRegx)![0].slice(0, -1);

    return {
      ...checkData,
      url: urlValue,
      port: portValue,
      ...pulledCheckData,
    };
  }

  return {
    ...checkData,
    port: "",
    ...pulledCheckData,
  };
};
const splitRules = (rules: IRule[], isClonedCheck: boolean) => {
  return rules.map((rule) => ({
    ...rule,
    my_check: isClonedCheck ? null : rule.my_check,
    id: isClonedCheck ? null : rule.id,
  }));
};

const useGetCheckData = (id: string, isClonedCheck: boolean) => {
  const [{ integrations, checkRules }, setCheckData] = useState<{
    checkData?: Partial<ICheck>,
    integrations: Iintegration[];
    checkRules: ICheckRules[];
  }>({
    integrations: [],
    checkRules: [],
  });

  const [isProjectAdmin, setIsProjectAdmin] = useState(null);
  const [setCheckName] = useActions([setCheckNameAction]);
  const [resetCheckName] = useActions([resetCheckNameAction]);

  useEffect(() => {
    return () => {
      resetCheckName();
    };
  }, [resetCheckName]);

  const getCheckAddData = useCallback(() => {
    groupCancelSource.value = CancelToken.source();

    return Promise.all([getIntegrationsRequest(), getProjectsRequest()]).then(
      ([integrationsResponse, projectsResponse]) => {
        setCheckData((oldState) => {
          return {
            ...oldState,
            integrations: integrationsResponse.data,
          };
        });
        return { integrations: integrationsResponse.data, projects: projectsResponse.data };
      }
    );
  }, []);

  const getCheckEditData = useCallback(() => {
    groupCancelSource.value = CancelToken.source();

    return Promise.all([getDetailedCheckRequest(id), getIntegrationsRequest(), getProjectsRequest()]).then(
      ([checkDetailsResponse, integrationsResponse, projectsResponse]) => {
        setCheckName(checkDetailsResponse.data);
        setIsProjectAdmin(checkDetailsResponse.data.is_user_project_admin);
        setCheckData((oldState) => {
          return {
            ...oldState,
            integrations: integrationsResponse.data,
            checkRules: splitRules(checkDetailsResponse.data.rules, isClonedCheck),
          };
        });
        return {
          checkData: splitCheckData(checkDetailsResponse.data, isClonedCheck),
          projects: projectsResponse.data,
          integrations: integrationsResponse.data,
        };
      }
    );
  }, [id, isClonedCheck, setCheckName]);

  const getCheckData = id ? getCheckEditData : getCheckAddData;
  return {
    getCheckData,
    checkRules,
    integrations,
    isProjectAdmin,
  };
};

export default useGetCheckData;
