import PhoneLink from "../PhoneLink/PhoneLink";
import { Link } from "react-router-dom";
import { PATHS } from "route-dictionary";
import { formatPhoneNumber } from "utils/phoneHandlers";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";
import { IItemObject } from "components/ReactHookForm/_types";
import { IChat } from "components/ReactHookForm/_types";
import { IRule } from "components/RulesComponents/_types";
import { IMember } from "./../../models/types";

export const getMemberText = (item: IMember) => {
  const members = (
    <>
      {item.first_name || item.email} {item.last_name}{" "}
    </>
  );

  const exactPath = PATHS.MEMBERS.getDetailsIdPath && PATHS.MEMBERS.getDetailsIdPath(item.id);

  return {
    jsx: (
      <>
        {hasGlobalPermission(ACTIONS.VIEW_MEMBER_DETAILS) ? (
          <Link to={exactPath || PATHS.AuthorizedError404.ROOT}>{members}</Link>
        ) : (
          members
        )}
        {item.phone && (
          <>
            (<PhoneLink>{formatPhoneNumber(item.phone)}</PhoneLink>)
          </>
        )}
      </>
    ),
    toString: () =>
      `${item.first_name || item.email} ${item.last_name ? item.last_name : ""} ${
        item.phone ? `(${formatPhoneNumber(item.phone)})` : ""
      }`,
  };
};

export type GetTelegramChatTextSignature = (item: IChat) => IItemObject;

export const getTelegramChatText: GetTelegramChatTextSignature = (item) => ({
  jsx: item.name,
  toString: () => item.name,
});

export type GetPhoneNumberTextSignature = (item: string) => IItemObject;

export const getPhoneNumberText: GetPhoneNumberTextSignature = (item) => ({
  jsx: <PhoneLink>{formatPhoneNumber(item)}</PhoneLink>,
  toString: () => formatPhoneNumber(item),
});

export const getRuleIndex = (rule: IRule) => (typeof rule._index === "number" ? rule._index : rule.id);
