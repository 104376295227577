import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IAction } from "../types";

interface IThunk {
  (...args: any[]): (dispatch: Dispatch) => Promise<void>;
}

interface IBoundedActionCreator<T extends IThunk> {
  (...args: Parameters<T>): ReturnType<ReturnType<T>>;
}

export function useActions<T extends IAction | IThunk>(actions: T | T[], deps?: []) {
  const dispatch = useDispatch();
  const inputs = deps ? [dispatch, ...deps] : [dispatch];

  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators<T, T extends IThunk ? IBoundedActionCreator<T> : T>(a, dispatch));
      }
      return [bindActionCreators<T, T extends IThunk ? IBoundedActionCreator<T> : T>(actions, dispatch)];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs
  );
}
