import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {ARButton} from "components/ARComponents";
import {Trans, useTranslation} from "react-i18next";
import {globalSelector} from "store/selectors/global";
import {getPaymentPeriod} from "../../PaidPlan.model";
import {getPriceString} from "../../../Subscribe.model";
import ChecksAmountSelect from "../../ChecksAmountSelect";
import AdditionalChecksText from "../../AdditionalChecksText";
import {CollapseCardContext, PlansContext} from "../../../subscribe-context";
import {useCardInfo, usePremiumPlan, useRecalculatedPrice} from "../../../hooks";

const SubscriptionChangeContent = () => {
  const {isFetching} = useSelector(globalSelector);
  const isVisibleHandler = useContext(CollapseCardContext);
  const {hasDiscount, companyBilling, subscriptionPlans, selectedPlan} = useContext(PlansContext);
  const {t} = useTranslation();
  const {cardInfo} = useCardInfo();
  const {
    price,
    checksAmount,
    onCheckAmountChange,
    onSubscriptionChange
  } = usePremiumPlan(isVisibleHandler);

  const checkLimit = cardInfo.checkLimit;
  const subscriptionPeriod = companyBilling.price?.period;
  const isCurrentSubscriptionAmount = checkLimit === checksAmount && selectedPlan?.period === subscriptionPeriod;
  const recalculatedPriceProps = {
    checksAmount,
    selectedPlan,
    isCurrentSubscriptionAmount
  };
  const payment = useRecalculatedPrice(recalculatedPriceProps);
  const {realPayment, stripePayment, totalPayment} = payment;

  const isRefund = totalPayment < 0;
  const paymentPeriod = getPaymentPeriod(hasDiscount);
  const paymentString = getPriceString(price);
  const refundString = getPriceString(Math.abs(totalPayment));
  const totalPaymentString = getPriceString(totalPayment);
  const realPaymentString = getPriceString(realPayment);
  const stripePaymentString = getPriceString(stripePayment);

  return (
    <>
      <h3>{paymentString}/{paymentPeriod}</h3>
      <span>{t("plans:newSubscriptionInfo.checksAmount", "New amount of checks:")}</span>
      <ChecksAmountSelect
        className="ChangeSubscriptionCard__checks-select"
        onChange={onCheckAmountChange}
        options={subscriptionPlans}
      />
      <p>
        {
          isRefund ?
            <Trans i18nKey="plans:newSubscriptionInfo.refund">
              Money that will be returned to your stripe balance: <strong>{{refund: refundString}}</strong><br/>
            </Trans> :
            <Trans i18nKey="plans:newSubscriptionInfo.totalPayment">
              Total payment amount: <strong>{{payment: totalPaymentString}}</strong><br/>
            </Trans>
        }
        <Trans i18nKey="plans:newSubscriptionInfo.cardPayment">
          Payment from card: <strong>{{payment: realPaymentString}}</strong><br/>
        </Trans>
        {
          !isRefund &&
          <Trans i18nKey="plans:newSubscriptionInfo.stripePayment">
            Payment from your stripe balance: <strong>{{payment: stripePaymentString}}</strong>
          </Trans>
        }
      </p>
      <ARButton
        color="info"
        variant="outline"
        className="form-control-button_mr0"
        onClick={onSubscriptionChange}
        disabled={isFetching || isCurrentSubscriptionAmount}
      >
        {
          isFetching ? t("button.processing", "Processing...") : t("button.changeSubscription", "Change subscription")
        }
      </ARButton>
      <AdditionalChecksText/>
    </>
  );
};

export default SubscriptionChangeContent;