import { ACTIONS, ROLES, SUBSCRIPTION_TYPES } from "../constants";
import { hasSubscriptionPermission } from "./subscription";
import { hasProjectPermission, getProjectRole } from "./project";
import { hasGlobalPermission } from "./global";

function hasAccess(action: ACTIONS) {
  return hasGlobalPermission(action) && hasSubscriptionPermission(action);
}

export {
  ACTIONS,
  ROLES,
  SUBSCRIPTION_TYPES,
  hasGlobalPermission,
  hasProjectPermission,
  hasSubscriptionPermission,
  getProjectRole,
  hasAccess,
};
