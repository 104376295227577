import CheckForm from "../CheckForm/CheckForm";
import { CHECK_TYPES } from "utils/constants";
import { PATHS } from "route-dictionary";
import { useTranslation } from "react-i18next";
import useCheckForm from "../hooks/useCheckForm";
import { withErrorBoundary } from "components/ErrorBoundary";
import RulesCard from "components/RulesComponents/RulesCard";
import NoRulesText from "components/RulesComponents/NoRulesText";
import FormControlsButtons from "components/FormControlButtons";
import AuthorizedError404 from "pages/error/AuthorizedError404";
import { ACTIONS, getProjectRole, hasProjectPermission } from "utils/user-access";
import { ARCard, ARCardBody, ARCardFooter, ARCardHeader, ARCol, ARRow } from "components/ARComponents";

const CheckEdit = () => {
  const {
    formConfig,
    form,
    Prompt,
    checkId,
  } = useCheckForm();
  
  const { t } = useTranslation();

  const { isProjectAdmin } = formConfig;

  if (!isProjectAdmin) {
    return <AuthorizedError404 />;
  }

  const { formId, onFormReset, rules } = formConfig;
  const { typeValue, projectValue } = form;

  const projectRole = getProjectRole(isProjectAdmin);
  const { rulesInfo, RuleActions } = rules;
  const isExternalCheck = typeValue === CHECK_TYPES.EXTERNAL;

  return (
    <>
      <ARCard>
        <ARCardHeader>
          <h1 className="card-header-text">{t("card.title.check.edit", "Edit check")}</h1>
        </ARCardHeader>
        <ARCardBody>
          <Prompt />
          <CheckForm {...form} {...formConfig} projectRole={projectRole} />
        </ARCardBody>
        <ARCardFooter>
          <FormControlsButtons form={formId} disabled={isExternalCheck} onCancelClick={onFormReset} />
        </ARCardFooter>
      </ARCard>
      {hasProjectPermission(ACTIONS.VIEW_CHECKS_RULES, projectRole) && (
        <ARCard>
          <ARCardBody>
            <ARRow>
              <ARCol>
                <RulesCard
                  className="mt-2"
                  rules={rulesInfo}
                  deleteRule={RuleActions.deleteRule}
                  updateRule={RuleActions.updateRule}
                  getEditRulePath={(ruleId) =>
                    PATHS.CHECKS.getAttachedRuleDetailsPath!({ attachedId: checkId, ruleId })
                  }
                  addRulePath={PATHS.CHECKS.getAttachedRuleAddPath!(checkId)}
                  showRuleAddButton={hasProjectPermission(ACTIONS.CREATE_CHECKS_RULES, projectRole)}
                  showRuleSwitcher={hasProjectPermission(ACTIONS.ENABLE_DISABLE_CHECKS_RULES, projectRole)}
                  showEditButton={hasProjectPermission(ACTIONS.EDIT_CHECKS_RULES, projectRole)}
                  showDeleteButton={hasProjectPermission(ACTIONS.DELETE_CHECKS_RULES, projectRole)}
                  noRulesText={<NoRulesText projectId={projectValue} projectRole={projectRole} />}
                />
              </ARCol>
            </ARRow>
          </ARCardBody>
        </ARCard>
      )}
    </>
  );
};

export default withErrorBoundary(CheckEdit);
