import "core-js";
import i18next from "services/language/i18next";
import moment from "moment";
import { round } from "lodash";
import { IRule } from "../components/RulesComponents/_types";

/**
 * Converts number into time string in format "00|01|02|...|59|etc."; adds 0 to number if necessary
 *
 * @param time Time amount
 * @returns Time string in format "00|01|02|...|59|etc."
 */
export function to00(time: number): string {
  if (time < 0 || time > 99) {
    return "";
  }

  const strTime = time.toString();
  return "0".repeat(2 - strTime.length) + strTime;
}

export enum TimeType {
  empty = "empty",
  second = "second",
  minute = "minute",
  hour = "hour",
  day = "day",
}

const secondsAmountForTimeTypes: { [key in TimeType]: number | null } = {
  empty: null,
  second: 1,
  minute: 60,
  hour: 60 * 60,
  day: 24 * 60 * 60,
};

/**
 * Converts number into time string equivalent
 *
 * @param timeAmount Amount of time
 * @param timeType Type of time (second|minute|hour|day)
 * @returns Time in format "dd hh:mm:ss"
 */
export function numberToTimeDuration(timeAmount: number, timeType: TimeType = TimeType.minute): string {
  const secondsAmountForTimeType = secondsAmountForTimeTypes[timeType];
  if (timeAmount < 0 || !secondsAmountForTimeType) {
    return "";
  }

  const seconds = timeAmount * secondsAmountForTimeType;

  const time = moment.utc(seconds * 1000);
  const days = Math.floor(moment.duration(seconds * 1000).as("days"));

  return `${days ? `${days} ` : ""}${time.format("HH:mm:ss")}`;
}

/**
 * Convert string to seconds
 *
 * @param str format "D HH:MM:SS"
 * @returns seconds
 */
export function convert_DHHMMSS_toSeconds(str: string): number {
  const duration = moment.duration(str);
  return duration.as("seconds");
}

/**
 * Converts "dd hh:mm:ss" time string into minutes amount
 *
 * @param str String of time in format "dd hh:mm:ss"
 * @returns Amount of minutes that are equivalent of time string
 */
export function convert_DHHMMSS_toMinutes(str: string): number {
  if (!/^(\d+ )?(\d\d:\d\d:)?\d\d$/.test(str)) {
    return -1;
  }
  const duration = moment.duration(/^\d+$/.test(str) ? parseInt(str) * 1000 : str);
  return round(duration.as("minutes"), 2);
}

/**
 * Converts number into time string in the format "1 minutes|3 hours|3 days|etc."
 *
 * @param timeAmount Amount of time
 * @param timeType Type of time (second|minute|hour|day|etc.)
 * @returns Time in the format "1 minutes|3 hours|3 days|etc."
 */
export function getTranslatedTimeText(timeAmount: number, timeType: TimeType): string {
  if (timeAmount < 0) {
    return "";
  }

  return i18next.t(`time.${timeType}`, { count: timeAmount });
}

/**
 * Converts number of minutes into time string in the format "1 minutes|3 hours|3 days|etc."
 *
 * @param minutes Amount of minutes
 * @returns Time in the format "1 minutes|3 hours|3 days|etc."
 */
export function getTranslatedTimeTextFromMinutes(minutes: number): string {
  if (minutes < 0) {
    return "";
  }

  const duration = moment.duration(minutes, "minutes");

  if (duration.as("days") >= 1) {
    return getTranslatedTimeText(round(duration.as("days"), 2), TimeType.day);
  }

  if (duration.as("hours") >= 1) {
    return getTranslatedTimeText(round(duration.as("hours"), 2), TimeType.hour);
  }

  return getTranslatedTimeText(minutes, TimeType.minute);
}

/**
 * Converts rule notification times from the format "01:02:00" to the format "01:02"
 *
 * @param ruleData Rule data object
 * @returns Same rule data object but with sliced "from_time" and "to_time" fields
 */
export function sliceRuleNotificationTimes(ruleData: IRule): IRule {
  ruleData.from_time = ruleData.from_time?.slice(0, 5) || null;
  ruleData.to_time = ruleData.to_time?.slice(0, 5) || null;

  return ruleData;
}

export function getDate(field: string) {
  return field?.match(/\d{4}-\d{2}-\d{2}/);
}

export function getTime(field: string) {
  return field?.match(/\d{2}:\d{2}:\d{2}/);
}
