import * as yup from "yup";
import { REG_EXS } from "utils/constants";
import { Message } from "../schemes/messages";

function nexmo(this: yup.StringSchema, message: Message) {
  return this.test("nexmo", message, (value) => {
    if (!value) {
      return true;
    }
    const nexmoPrivateKey = value.match(REG_EXS.NEXMO_PRIVATE_KEY);
    if (!nexmoPrivateKey) {
      return false;
    }

    const base64key = nexmoPrivateKey?.[1];
    try {
      atob(base64key);
    } catch {
      return false;
    }

    return true;
  });
}

export default nexmo;
