import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useActions } from "store/actions";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { userIdSelector } from "store/selectors/user";
import { setUserInfoAction } from "store/actions/user";
import { showNotification } from "utils/actionWrappers";
import { getFormDataObj } from "components/ReactHookForm/utils";
import { memberDataFields } from "../MemberForm/MemberForm.model";
import { defaultAvatarSource, ERR_MESSAGES } from "utils/constants";
import { getMemberFormSchema } from "components/ReactHookForm/schemes";
import {
  cancelSource,
  createMemberRequest,
  deleteMemberRequest,
  editMemberDetailsRequest,
  getMemberDetailsRequest,
} from "services/api";
import { IMemberForm } from "./useMemberForm";
import { useFormWithCustomError } from "components/ReactHookForm/hooks";

interface IMemberFormParams {
  id: string;
}

const useMemberFormConfig = () => {
  const { t } = useTranslation();
  const { id: memberId } = useParams<IMemberFormParams>();
  const currentUserId = useSelector(userIdSelector);
  const isCurrentUser = memberId === currentUserId;
  const isAddPage = !memberId;
  const [updateCurrentUserInfo] = useActions(setUserInfoAction);
  const [memberInfo, setMemberInfo] = useState({
    isExternalMember: false,
    memberName: "",
  });

  const formControl = useFormWithCustomError({
    mode: "onChange",
    resolver: yupResolver(getMemberFormSchema(isAddPage)),
    defaultValues: getFormDataObj({
      arrOfFields: memberDataFields,
      serverData: { avatar: defaultAvatarSource },
    }) as IMemberForm,
  });

  const { reset } = formControl;

  const addMember = (memberData: IMemberForm) => {
    return createMemberRequest(memberData)
      .then((response) => {
        showNotification(
          t("notification:title.success", "Success!"),
          t("notification:message.added.member", "Member has been successfully added!"),
          "success"
        );
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  const editMember = (memberData: IMemberForm, id: string) => {
    return editMemberDetailsRequest(memberData, id)
      .then((response) => {
        if (isCurrentUser) {
          updateCurrentUserInfo(response.data);
        }

        showNotification(
          t("notification:title.success", "Success!"),
          t("notification:message.updated.member", "Member has been updated"),
          "success"
        );
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  const deleteMember = (id: string) => {
    return deleteMemberRequest(id).then(() => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.deleted.member", "Member has been deleted"),
        "success"
      );
    });
  };

  const getMemberInfo = useCallback(() => {
    getMemberDetailsRequest(memberId).then((response) => {
      const { data } = response;
      const hasExternalDirectory = !!data.member_directory_name;
      const memberName = data.first_name || data.email;

      setMemberInfo({
        memberName,
        isExternalMember: hasExternalDirectory,
      });

      const formData = getFormDataObj({ arrOfFields: memberDataFields, serverData: data });
      reset(formData);
    });
  }, [memberId, reset]);

  useEffect(() => {
    if (memberId) {
      getMemberInfo();
    }

    return () => cancelSource.value?.cancel(ERR_MESSAGES.CANCELLED_REQUEST);
  }, [memberId, getMemberInfo]);

  return {
    memberId,
    memberInfo,
    formControl,
    onSaveBtnClick: isAddPage ? addMember : editMember,
    onDeleteBtnClick: !isCurrentUser ? deleteMember : null,
  };
};

export default useMemberFormConfig;
