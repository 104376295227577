import classNames from "classnames";
import { PATHS } from "route-dictionary";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhoneLink from "components/PhoneLink";
import AddButtonRow from "components/AddButtonRow";
import { defaultAvatarSource } from "utils/constants";
import { formatPhoneNumber } from "utils/phoneHandlers";
import { getPartialMembersRequest } from "services/api";
import { globalSelector } from "store/selectors/global";
import useTableConfig from "utils/hooks/useTableConfig";
import { withErrorBoundary } from "components/ErrorBoundary";
import { ACTIONS, hasGlobalPermission } from "utils/user-access";
import { getItemsPerPageSelectValues, getTableNoItemsView } from "utils";
import { getTableColumnHeaders, getTableFilterText, IMember } from "./Members.model";
import { ARCard, ARCardBody, ARCardHeader, ARDataTable, ARImg, ARPagination } from "components/ARComponents";

const Members = () => {
  const { isFetching } = useSelector(globalSelector);
  const {
    page,
    pages,
    onPageChange,
    tableItems,
    itemsPerPage,
    setItemsPerPage,
    sorterValue,
    setSorterValue,
    tableFilterValue,
    onTableFilterValueChange,
  } = useTableConfig<IMember>(getPartialMembersRequest);

  const history = useHistory();
  const { t } = useTranslation();

  // TODO: clear ! in getDetailsIdPath when routes class is translated
  const getClickAction = (id: number) =>
    hasGlobalPermission(ACTIONS.VIEW_MEMBER_DETAILS) && history.push(PATHS.MEMBERS.getDetailsIdPath!(id));

  return (
    <ARCard className="Members-card">
      <ARCardHeader>
        <h1 className="card-header-text">{t("card.title.members", "Members")}</h1>
      </ARCardHeader>
      <ARCardBody>
        {
          <AddButtonRow
            disabled={isFetching}
            className="Members-card__add-button-row"
            path={PATHS.MEMBERS.ADD}
            hasAddPermission={hasGlobalPermission(ACTIONS.CREATE_MEMBERS)}
          />
        }
        <ARDataTable
          addTableClasses="Members-card__members-table members-table"
          items={tableItems}
          fields={getTableColumnHeaders()}
          noItemsView={getTableNoItemsView()}
          loading={isFetching}
          hover
          cleaner
          striped
          border
          clickableRows={hasGlobalPermission(ACTIONS.VIEW_MEMBER_DETAILS) || undefined}
          onRowClick={(item: IMember) => getClickAction(item.id)}
          sorter
          sorterValue={sorterValue}
          onSorterValueChange={setSorterValue}
          tableFilter={getTableFilterText()}
          tableFilterValue={tableFilterValue}
          onTableFilterChange={onTableFilterValueChange}
          itemsPerPageSelect={getItemsPerPageSelectValues()}
          itemsPerPage={itemsPerPage}
          onPaginationChange={setItemsPerPage}
          scopedSlots={{
            first_name: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST)
              ? (item: IMember) => (
                  <td className="members-table__cell">
                    <div className="user-info-content">
                      <div className="user-info-content__c-avatar c-avatar">
                        <ARImg
                          src={item.avatar || defaultAvatarSource}
                          className="c-avatar__image c-avatar-img"
                          alt="member"
                        />
                      </div>
                      <div className="user-info-content__text">{item.first_name || item.email || ""}</div>
                    </div>
                  </td>
                )
              : null,
            last_name: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST)
              ? (item: IMember) => <td className="members-table__cell">{item.last_name || ""}</td>
              : null,
            phone: (item: IMember) => (
              <td className="members-table__cell">
                <div className="members-table__phone">
                  {item.phone && <PhoneLink className="link-content">{formatPhoneNumber(item.phone)}</PhoneLink>}
                </div>
              </td>
            ),
            email: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST)
              ? (item: IMember) => (
                  <td className="members-table__cell">
                    {item.email && (
                      <a href={`mailto: ${item.email}`} onClick={(event) => event.stopPropagation()}>
                        <div className="link-content">{item.email}</div>
                      </a>
                    )}
                  </td>
                )
              : null,
            member_directory_name: hasGlobalPermission(ACTIONS.VIEW_MEMBERS_LIST)
              ? (item: IMember) => (
                  <td className="members-table__cell">
                    {item.member_directory_name || t("table:internalDirectory", "Internal directory")}
                  </td>
                )
              : null,
          }}
        />
        <ARPagination
          pages={pages}
          activePage={page}
          onActivePageChange={onPageChange}
          className={classNames("table-pagination", { "table-pagination_hidden": pages < 2 || isFetching })}
        />
      </ARCardBody>
    </ARCard>
  );
};

export default withErrorBoundary(Members);
