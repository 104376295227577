import { instance, requestPromiseWrapper, logoutIfTokenIsInvalid, setShowingSpinner } from "./instance";
import { IGetMemberDirectories, IMemberDirectoryElementData } from "./types";

export function getDirectoriesRequest(): Promise<IGetMemberDirectories> {
  return requestPromiseWrapper(instance.get("/member_directory"));
}

export function createMemberDirectoryRequest(directoryData: IMemberDirectoryElementData) {
  return requestPromiseWrapper(instance.post("/member_directory", directoryData));
}

export function getMemberDirectoryRequest(id: string) {
  return requestPromiseWrapper(instance.get(`/member_directory/${id}`));
}

export function removeDirectoryRequest(id: string) {
  return requestPromiseWrapper(instance.delete(`/member_directory/${id}`));
}

export function updateDirectoryRequest(id: string, directoryData: Partial<IMemberDirectoryElementData>) {
  return requestPromiseWrapper(instance.patch(`/member_directory/${id}`, directoryData));
}

export function testConnectionRequest(id: string) {
  return setShowingSpinner(logoutIfTokenIsInvalid(instance.get(`/member_directory/${id}/test_connection`)));
}
