import React, {useContext} from "react";
import {usePremiumPlan} from "../../hooks";
import {useTranslation} from "react-i18next";
import {getPaymentPeriod} from "../PaidPlan.model";
import {PlansContext} from "../../subscribe-context";
import ChecksAmountSelect from "../ChecksAmountSelect";
import {ARButton, ARIcon} from "components/ARComponents";
import {withErrorBoundary} from "components/ErrorBoundary";
import AdditionalChecksText from "../AdditionalChecksText";
import {getPaidBenefitsArr, getPriceString} from "../../Subscribe.model";

const PaidPlanDescription = () => {
  const {hasDiscount, subscriptionPlans} = useContext(PlansContext);
  const {price, onSubscribe, onCheckAmountChange} = usePremiumPlan();
  const {t} = useTranslation();

  const paymentPeriod = getPaymentPeriod(hasDiscount);
  const paymentString = getPriceString(price);
  const paidBenefitsArr = getPaidBenefitsArr();

  return (
    <>
      <h3>{paymentString}/{paymentPeriod}</h3>
      <ul className="PremiumPlanDescription__list">
        {paidBenefitsArr.map((benefit) => {
          return (
            <li key={benefit}>
              <ARIcon name="cilCheckAlt" color="var(--info)"/> {benefit}
            </li>
          );
        })}
      </ul>
      <span>{t("plans:paidPlan.checksAmount", "Amount of checks:")}</span>
      <ChecksAmountSelect
        className="PremiumPlanDescription__checks-select"
        onChange={onCheckAmountChange}
        options={subscriptionPlans}
      />
      <ARButton
        color="info"
        variant="outline"
        className="form-control-button_mr0"
        onClick={onSubscribe}
      >
        {t("button.subscribe", "Subscribe")}
      </ARButton>
      <AdditionalChecksText/>
    </>
  );
};

export default withErrorBoundary(PaidPlanDescription);